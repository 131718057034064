import { all, call } from 'redux-saga/effects'

import { routinePromiseWatcherSaga } from 'redux-saga-routines'
import { userSagas } from 'ducks/user/sagas'
import { homeSagas } from 'ducks/home/sagas'
import { layoutDataSagas } from 'ducks/layoutData/sagas'
import { protagonistasLayoutSagas } from 'ducks/protagonistasLayout/sagas'
import { countriesSagas } from 'ducks/countries/sagas'
import { categoriesSagas } from 'ducks/categories/sagas'
import { protagonistasSagas } from 'ducks/protagonistas/sagas'
import { protagonistaLayoutSagas } from 'ducks/protagonistaLayout/sagas'
import { movimientosLayoutSagas } from 'ducks/movimientosLayout/sagas'
import { movimientosSagas } from 'ducks/movimientos/sagas'
import { movimientoLayoutSagas } from 'ducks/movimientoLayout/sagas'
import { aboutLayoutSagas } from 'ducks/aboutLayout/sagas'
import { contactoSagas } from 'ducks/contacto/sagas'
import { SagaIterator } from 'redux-saga'
import { pagesSagas } from 'ducks/pages/sagas'
import { loginLayoutSagas } from 'ducks/loginLayout/sagas'
import { voluntariadoLayoutSagas } from 'ducks/voluntariadoLayout/sagas'
import { volunteersSagas } from 'ducks/volunteers/sagas'
import { postularSagas } from 'ducks/postular/sagas'
import { profileLayoutSagas } from 'ducks/profileLayout/sagas'
import { registerLayoutSagas } from 'ducks/registerLayout/sagas'
import { successLayoutSagas } from 'ducks/successLayout/sagas'
import { loadingSagas } from 'ducks/loading/sagas'
import { elColeSagas } from 'ducks/elCole/sagas'
import { tienditaLayoutSagas } from 'ducks/tienditaLayout'
import { colaboraLayoutSagas } from 'ducks/colaboraLayout'
import { blogSagas } from 'ducks/blog'

export default function* rootSaga(): SagaIterator {
  try {
    yield all([
      call(routinePromiseWatcherSaga),
      call(userSagas),
      call(homeSagas),
      call(layoutDataSagas),
      call(protagonistasLayoutSagas),
      call(countriesSagas),
      call(categoriesSagas),
      call(protagonistasSagas),
      call(protagonistaLayoutSagas),
      call(movimientosLayoutSagas),
      call(movimientosSagas),
      call(movimientoLayoutSagas),
      call(aboutLayoutSagas),
      call(pagesSagas),
      call(contactoSagas),
      call(loginLayoutSagas),
      call(voluntariadoLayoutSagas),
      call(volunteersSagas),
      call(postularSagas),
      call(profileLayoutSagas),
      call(registerLayoutSagas),
      call(successLayoutSagas),
      call(loadingSagas),
      call(elColeSagas),
      call(tienditaLayoutSagas),
      call(colaboraLayoutSagas),
      call(blogSagas),
    ])
  } catch (error) {
    console.error(error)
  }
}
