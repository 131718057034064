import React, { FC, ChangeEvent, useState } from 'react'
import { IconType } from 'react-icons'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

import { Container, Input, IconWrapper, Main } from './styled'
import { InputFeedbackMessage } from 'views/components/UI/Typography'

type Props = {
  icon?: IconType
  placeholder?: string
  inputType?: string
  name: string
  value: string
  error?: string
  customErrorMessage?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  noMargin?: boolean
  color?: string
  feedbackColor?: string
  showError?: boolean
}

const TextInput: FC<Props> = (props: Props) => {
  const [isVisible, setIsVisible] = useState(false)
  const {
    icon,
    placeholder,
    inputType,
    value,
    name,
    onChange,
    error,
    customErrorMessage,
    noMargin,
    color,
    feedbackColor,
    showError,
  } = props

  const renderType = () => {
    if (isVisible) {
      return 'text'
    } else {
      return inputType ? inputType : 'text'
    }
  }

  const handleVisible = () => {
    setIsVisible((prevState) => !prevState)
  }

  const renderVisibleIcon = () => {
    if (inputType === 'password') {
      return isVisible ? (
        <AiOutlineEyeInvisible size={20} onClick={handleVisible} />
      ) : (
        <AiOutlineEye size={20} onClick={handleVisible} />
      )
    }
  }

  return (
    <Main noMargin={noMargin}>
      {showError && error && (
        <InputFeedbackMessage color={feedbackColor}>{customErrorMessage || error}</InputFeedbackMessage>
      )}
      <Container error={error} color={color} feedbackColor={feedbackColor}>
        {icon && <IconWrapper>{icon?.call(null, { size: '20px' })}</IconWrapper>}
        <Input
          onChange={onChange}
          type={renderType()}
          placeholder={placeholder}
          value={value}
          name={name}
          color={color}
        />
        {renderVisibleIcon()}
      </Container>
    </Main>
  )
}

export default TextInput
