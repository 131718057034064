import React, { FC, useEffect, useState } from 'react'
import { useLanguage } from 'hooks/useLanguage'
import { useSelector, useDispatch } from 'react-redux'
import { CONTENT, loginLayoutSelector } from 'ducks/loginLayout'
import { LOGIN, userSelector, SOCIAL_LOGIN, CLEAR_ERROR } from 'ducks/user'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Redirect } from 'react-router-dom'
import useQuery from 'hooks/useQuery'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

import { Container, Subtitle } from './styled'
import CreateAccountSection from './CreateAccountSection'
import LoginSection from './LoginSection'
import WithRedirect from 'views/components/WithRedirect'
import Banner from 'views/components/Banner'
import HtmlRender from 'views/components/HtmlRender'
import { BannerContainer } from 'views/components/UI/Contents'
import { BannerTitle } from 'views/components/UI/Typography'

export type FormValues = {
  email: string
  password: string
}

export type ErrorsValues = {
  email?: string
  password?: string
}

const initialValues: FormValues = {
  email: '',
  password: '',
}

const initialErrors: ErrorsValues = {}

const validationSchema = yup.object({
  email: yup.string().email().required('Email is required'),
  password: yup.string().required('Password is required'),
})

const Login: FC = () => {
  const loginLayout = useSelector(loginLayoutSelector)
  const dispatch = useDispatch()
  const { currentLanguage } = useLanguage()
  const userState = useSelector(userSelector)
  const [success, setSuccess] = useState(false)
  const { query, queryString, cleanQuery } = useQuery()
  const { sendEvent } = useGoogleAnalytics()
  const [error, setError] = useState(false)

  useEffect(() => {
    const provider = query.get('id_token[payload][iss]')
    if (provider?.toLowerCase()?.includes('google')) {
      dispatch(SOCIAL_LOGIN.trigger({ provider: 'google', query }))
    } else {
      const accessToken = query.get('access_token')
      if (accessToken) {
        dispatch(SOCIAL_LOGIN.trigger({ provider: 'facebook', query }))
      }
    }
  }, [query, queryString, dispatch])

  // If login is successful token is set on state and redirect to profile
  useEffect(() => {
    if (userState.get('error')) {
      cleanQuery()
      setError(true)
    }
    if (userState.get('token')) {
      setSuccess(true)
      switch (userState.get('provider')) {
        case 'google':
          return sendEvent({
            label: 'Google',
            category: 'Login',
            action: 'User logged in using Google Provider.',
          })
        case 'facebook':
          return sendEvent({
            label: 'Facebook',
            category: 'Login',
            action: 'User logged in using Facebook Provider.',
          })
        default:
          return sendEvent({
            label: 'Email',
            category: 'Login',
            action: 'User logged in using Email Provider.',
          })
      }
    } else {
      setSuccess(false)
    }
  }, [userState, cleanQuery, dispatch, sendEvent])

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_ERROR })
    }
  }, [dispatch])

  const onSubmit = (values: FormValues) => {
    dispatch(LOGIN.trigger(values))
  }

  const { errors, values, handleChange } = useFormik<FormValues>({
    initialValues,
    onSubmit,
    initialErrors,
    validationSchema,
  })

  useEffect(() => {
    dispatch(CONTENT.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage])

  return success ? (
    <Redirect to="/perfil" />
  ) : (
    <>
      <Banner visible={error} backgroundColor="#ED8C9B">
        <BannerContainer>
          <BannerTitle>{loginLayout.getIn(['feedback', 'title'])}</BannerTitle>
          <Subtitle>
            <HtmlRender>{loginLayout.getIn(['feedback', 'description'])}</HtmlRender>
          </Subtitle>
        </BannerContainer>
      </Banner>
      <Container>
        <CreateAccountSection layout={loginLayout.get('create_account_section')} />
        <LoginSection
          layout={loginLayout.get('login_section')}
          errors={errors}
          values={values}
          handleChange={handleChange}
          handleSubmit={onSubmit}
        />
      </Container>
    </>
  )
}

export default WithRedirect(Login, '/perfil')
