import axios from 'axios'
import { MovimientoLayout } from 'ducks/movimientoLayout'
import { parseLayoutContent } from './parser'
import { TranslatedRequest } from 'lib/axios/models'

const getMovimientoLayout = async ({ language }: TranslatedRequest): Promise<MovimientoLayout | undefined> => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
        query {
          movimientosDetailsLayout {
            path {
              description: description_${language}
              sections {
                text: text_${language}
                link
              }
            }
            Details {
              protagonistas: protagonistas_${language}
              socials {
                title: title_${language}
                instagram
                site: site_${language}
                email: email_${language}
              }
            }
            title: title_${language}
            section_title: section_title_${language}
          }
        }
      `,
    })

    return parseLayoutContent(data.data.movimientosDetailsLayout)
  } catch (error) {
    return
  }
}

export default getMovimientoLayout
