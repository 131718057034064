import { IPost } from 'ducks/blog'
import React, { FC } from 'react'

import {
  Author,
  AuthorImage,
  Bookmark,
  BookmarkText,
  Container,
  Content,
  Image,
  MotivationWrapper,
  Text,
  Title,
} from './styled'
import HtmlRender from 'views/components/HtmlRender'
import MotivatedIndicator from '../MotivatedIndicator'

type Props = {
  post: IPost
}

const CardBlogPost: FC<Props> = ({ post }) => (
  <Container to={`/blog/post/${post.url}`}>
    <Image background={`url(${post?.card_image?.url || post?.cover_image?.url})`}>
      <Bookmark backgroundColor={post?.category?.blog_color}>
        <BookmarkText>{post?.sub_category?.name || post?.category?.name}</BookmarkText>
      </Bookmark>
      <MotivationWrapper>
        {post?.motivations && <MotivatedIndicator value={post?.motivations.length} />}
      </MotivationWrapper>
    </Image>
    <Content>
      <Title>{post.title}</Title>
      <Text>
        <HtmlRender>{post.short_description}</HtmlRender>
      </Text>
      <Author>
        <AuthorImage background={`url(${post?.author?.image?.url})`} />
        {post.author?.name}
        {post?.author?.nickname ? ` - ${post?.author?.nickname}` : ''}
      </Author>
    </Content>
  </Container>
)

export default CardBlogPost
