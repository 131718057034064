import { SagaIterator } from '@redux-saga/core'
import { Action } from 'lib/redux/models'
import { all, put, call, takeLatest } from 'redux-saga/effects'
import { getHomeContent } from 'services/elCole'
import { CONTENT, HOME_CONTENT } from 'ducks/elCole'

function* loadHomeCole(action: Action) {
  yield put(CONTENT.request())
  try {
    const data = yield call(getHomeContent, action?.payload)
    if (data) {
      yield put(CONTENT.success({ home: data }))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

export function* elColeSagas(): SagaIterator {
  yield all([takeLatest(HOME_CONTENT.TRIGGER, loadHomeCole)])
}
