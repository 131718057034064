import { SagaIterator } from '@redux-saga/core'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as TYPES from '../actionTypes'
import protagonistasLayout from 'services/protagonistas/protagonistasLayout'
import { Action } from 'lib/redux/models'

function* loadProtagonistasContent(action: Action) {
  yield put(TYPES.CONTENT.request())
  try {
    const protagonistasContent = yield call(protagonistasLayout, action?.payload)
    if (protagonistasContent) {
      yield put(TYPES.CONTENT.success(protagonistasContent))
    }
  } catch (error) {
    yield put(TYPES.CONTENT.failure(error.message))
  } finally {
    yield put(TYPES.CONTENT.fulfill())
  }
}

export function* protagonistasLayoutSagas(): SagaIterator {
  yield all([takeEvery(TYPES.CONTENT.TRIGGER, loadProtagonistasContent)])
}
