import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { ILayoutData } from './../models'
import * as TYPES from './../actionTypes'

export const initialState: ILayoutData = fromJS({
  loading: false,
  error: '',
  footer: null,
  header: null,
  userMenu: {
    options: [],
    logout: {},
  },
})

const layoutDataReducer: Reducer<ILayoutData> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET:
      return state.merge(action.payload)
    case TYPES.LOAD.TRIGGER:
      return state.set('loading', true)
    case TYPES.LOAD.FAILURE:
      return state.set('error', action.payload)
    case TYPES.LOAD.SUCCESS:
      return state.merge(action.payload)
    case TYPES.LOAD.FULFILL:
      return state.set('loading', false)
    default:
      return state
  }
}

export default layoutDataReducer
