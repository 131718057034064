import React, { FC } from 'react'
import { Container } from './styled'

type Props = {
  children: string | undefined
}

const HtmlRender: FC<Props> = (props: Props) => {
  const { children } = props

  const createContent = () => {
    if (children) {
      return { __html: children }
    }
  }

  return <Container id="html-render" dangerouslySetInnerHTML={createContent()} />
}

export default HtmlRender
