import { MovimientoLayout, MovimientoLayoutResponse } from 'ducks/movimientoLayout'

export const parseLayoutContent = ({
  Details,
  section_title,
  path,
  title,
}: MovimientoLayoutResponse): MovimientoLayout => ({
  details: Details,
  section_title,
  path,
  title,
})
