import styled from 'styled-components'

type ContainerProps = {
  open: boolean
  optionsLength: number
}

const renderBoxHeight = (optionsLength: number) => {
  if (optionsLength === 1) {
    return 100
  } else {
    return 50
  }
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 0.02px solid ${({ theme }) => theme.colors.primary};
  transition: height 0.25s ease-out;
  height: ${({ open, optionsLength }) => (open ? `${optionsLength * renderBoxHeight(optionsLength)}px` : '48px')};
  overflow: hidden;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 20px;
`

export const HeaderTitle = styled.div`
  margin: 16px 0;
`

export const Title = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.dark};
`
type OptionProps = {
  selected: boolean
  last: boolean
}

export const Option = styled.div<OptionProps>`
  cursor: pointer;
  width: 100%;
  border-top: none;
  display: flex;
  align-items: center;
  height: 38px;
  background-color: ${({ theme, selected }) => (selected ? theme.colors.lightBackground : theme.colors.white)};
`

export const OptionFlag = styled.img`
  width: 20px;
  height: 17px;
  margin-left: 10px;
`

export const OptionText = styled.p`
  margin: 0;
  margin-left: 16px;
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.default};
  text-transform: capitalize;
`
