import styled from 'styled-components'
import { layout, LayoutProps, background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  padding-top: 20%;
`

export const LeftCard = styled.div`
  z-index: 1;
  height: 310px;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background-color: #eaeaea;
`

export const RightCard = styled.div`
  z-index: 2;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #eaeaea;
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 52px;
  line-height: 50px;
  letter-spacing: 0.35px;
  margin: 0;
  color: ${({ theme }) => theme.colors.dark};
  text-transform: uppercase;
  word-break: break-all;
`

export const Subtitle = styled.p`
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.075px;
  color: ${({ theme }) => theme.colors.dark};
  margin: 0;
  margin-bottom: 10px;
`

type ImageProps = BackgroundProps & LayoutProps

export const RightImage = styled.div<ImageProps>`
  ${background};
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  width: 100%;
  height: 300px;
`

export const LeftImage = styled.div<ImageProps>`
  ${background};
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  width: 50%;
  height: 240px;
  margin-right: 30px;
`

export const Content = styled.div<LayoutProps>`
  ${layout};
  padding: 30px 0 30px 0px;
`

export const LeftBox = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const RightBox = styled.div`
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
