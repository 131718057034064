import React, { FC, useState, useEffect } from 'react'
import ReactPlayer from 'react-player/lazy'

import { Container, Thumbnail, VideoWrapper } from './styled'

type Props = {
  thumbnail: string
  video?: string
  wrapperComponent?: JSX.Element
}

const VideoOrImage: FC<Props> = ({ video, thumbnail, wrapperComponent }: Props) => {
  const [cookiesAllowed, setCookiesAllowed] = useState(false)

  useEffect(() => {
    const youtubeCookieAwareness = localStorage.getItem('cookie_youtube_consent')
    if (youtubeCookieAwareness === 'true') {
      setCookiesAllowed(true)
    } else {
      const cookieAwareness = localStorage.getItem('cookie_awareness')
      setCookiesAllowed(cookieAwareness && cookieAwareness === 'true' ? true : false)
    }
  }, [])

  return (
    <Container>
      {video ? (
        <ReactPlayer
          playsinline
          url={video}
          wrapper={wrapperComponent ? wrapperComponent : VideoWrapper}
          controls
          config={{
            youtube: {
              embedOptions: {
                host: cookiesAllowed ? 'https://www.youtube.com' : 'https://www.youtube-nocookie.com',
              },
            },
          }}
        />
      ) : (
        <Thumbnail background={`url(${thumbnail})`} />
      )}
    </Container>
  )
}

export default VideoOrImage
