import { CompaniesCard } from 'ducks/colaboraLayout'
import { Image } from 'ducks/aboutLayout'
import React, { FC } from 'react'

import { Container, CardBody, CompaniesContainer, CompanyLogo } from './styled'
import { Subtitle } from '../../styled'
import { Card } from 'views/components/UI/Contents'

type Props = {
  layout?: CompaniesCard
  large?: boolean
}

const CompanyCard: FC<Props> = (props: Props) => {
  const { layout, large } = props
  return (
    <Container>
      <Card color={layout?.background_color}>
        <CardBody large={large}>
          <Subtitle>{layout?.title}</Subtitle>
          <CompaniesContainer>
            {layout?.companies_logos.map((logo: Image) => (
              <CompanyLogo src={logo.url} key={logo.url} />
            ))}
          </CompaniesContainer>
        </CardBody>
      </Card>
    </Container>
  )
}

export default CompanyCard
