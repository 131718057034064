import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HOME_CONTENT, elColeSelector, TeamCard } from 'ducks/elCole'
import { useLanguage } from 'hooks/useLanguage'

import { Container, Content, LineSeparatorContainer, CardsContainer } from './styled'
import Banner from './Banner'
import IntroSection from './IntroSection'
import HerramientaSection from './HerramientaSection'
import AboutColeSection from './AboutColeSection'
import SubscribeSection from './SubscribeSection'
import LineSeparator from 'views/components/UI/LineSeparator'
import DisplayCard from 'views/components/DisplayCard'

const ElCole: FC = () => {
  const dispatch = useDispatch()
  const { currentLanguage } = useLanguage()
  const elColeState = useSelector(elColeSelector)

  useEffect(() => {
    dispatch(HOME_CONTENT.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage])

  return (
    <Container>
      <Banner layout={elColeState.getIn(['home', 'banner'])} path={elColeState.getIn(['home', 'path'])} />
      <Content>
        <IntroSection layout={elColeState.getIn(['home', 'intro_section'])} />
        <LineSeparatorContainer>
          <LineSeparator color="black" />
        </LineSeparatorContainer>
        <CardsContainer>
          {elColeState.getIn(['home', 'team_cards'])?.map((card: TeamCard) => (
            <DisplayCard key={card.id} layout={card} />
          ))}
        </CardsContainer>
        <HerramientaSection layout={elColeState.getIn(['home', 'herramienta_section'])} />
        <AboutColeSection layout={elColeState.getIn(['home', 'about_cole_section'])} />
        <SubscribeSection layout={elColeState.getIn(['home', 'subscribe_section'])} />
      </Content>
    </Container>
  )
}

export default ElCole
