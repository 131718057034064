import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useMemo,
  FC,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import { Country } from 'ducks/countries'
import { Category } from 'ducks/categories'
import { useDispatch } from 'react-redux'
import { useLanguage } from 'hooks/useLanguage'

type CallbackType = {
  countries: Array<number>
  categories: Array<number>
  language: string
  start?: number
}

type Props = {
  children: JSX.Element
  callback: ({ countries, categories, language, start }: CallbackType) => void
}

type FilterContextType = {
  countriesFilter: Array<Country>
  categoriesFilter: Array<Category>
  handleCountryFilter: (country: Country) => void
  handleCategoryFilter: (category: Category) => void
  includesCountry: (country: Country) => boolean
  includesCategory: (category: Category) => boolean
  clearCategoryFilter: () => void
  clearCountryFilter: () => void
  clearFilter: () => void
  currentIndex: number
  setCurrentIndex: Dispatch<SetStateAction<number>>
}

const FilterContext = createContext<FilterContextType | null>(null)

const FilterProvider: FC<Props> = ({ children, callback }: Props) => {
  const dispatch = useDispatch()
  const { currentLanguage } = useLanguage()
  const [countriesFilter, setCountriesFilter] = useState<Array<Country>>([])
  const [categoriesFilter, setCategoriesFilter] = useState<Array<Category>>([])
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const countries: Array<number> = countriesFilter.map<number>((countryFilter: Country) => countryFilter.id)
    const categories: Array<number> = categoriesFilter.map<number>((categoryFilter: Category) => categoryFilter.id)
    dispatch(callback({ countries, categories, language: currentLanguage, start: 0 }))
  }, [callback, countriesFilter, categoriesFilter, dispatch, currentLanguage, currentIndex])

  useEffect(() => {
    setCurrentIndex(0)
  }, [countriesFilter, categoriesFilter])

  const includesCountry = useCallback(
    (country: Country) => {
      return countriesFilter.map<number>((cat) => cat.id).includes(country.id)
    },
    [countriesFilter]
  )

  const handleCountryFilter = useCallback(
    (country: Country) => {
      if (includesCountry(country)) {
        setCountriesFilter(countriesFilter.filter((countryFilter) => countryFilter.id !== country.id))
      } else {
        setCountriesFilter([...countriesFilter, country])
      }
    },
    [countriesFilter, includesCountry]
  )

  const includesCategory = useCallback(
    (category: Category) => {
      return categoriesFilter.map<number>((categoryFilter) => categoryFilter.id).includes(category.id)
    },
    [categoriesFilter]
  )

  const handleCategoryFilter = useCallback(
    (category: Category) => {
      if (includesCategory(category)) {
        setCategoriesFilter(categoriesFilter.filter((categoryFilter) => categoryFilter.id !== category.id))
      } else {
        setCategoriesFilter([...categoriesFilter, category])
      }
    },
    [categoriesFilter, includesCategory]
  )

  const clearCategoryFilter = useCallback(() => setCategoriesFilter([]), [])

  const clearCountryFilter = useCallback(() => setCountriesFilter([]), [])

  const clearFilter = useCallback(() => {
    clearCategoryFilter()
    clearCountryFilter()
  }, [clearCategoryFilter, clearCountryFilter])

  const value = useMemo(
    () => ({
      countriesFilter,
      categoriesFilter,
      includesCountry,
      handleCountryFilter,
      includesCategory,
      handleCategoryFilter,
      clearCategoryFilter,
      clearCountryFilter,
      clearFilter,
      currentIndex,
      setCurrentIndex,
    }),
    [
      countriesFilter,
      includesCountry,
      handleCountryFilter,
      categoriesFilter,
      includesCategory,
      handleCategoryFilter,
      clearCategoryFilter,
      clearCountryFilter,
      clearFilter,
      currentIndex,
      setCurrentIndex,
    ]
  )

  return <FilterContext.Provider value={value}> {children} </FilterContext.Provider>
}
const useFilter = (): FilterContextType => {
  const context: FilterContextType | null = useContext(FilterContext)
  if (!context) {
    throw new Error('useFilter must be used inside FilterProvider')
  } else {
    return context
  }
}

export { useFilter, FilterProvider }
