import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
  @media screen and (max-width: 600px) {
    width: 95vw;
  }
`

export const Logo = styled.img`
  height: 40px;
  width: 40px;
`

export const Title = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 900;
  font-size: 16.6288px;
  line-height: 23px;
  letter-spacing: 0.012px;
  color: ${({ theme }) => theme.colors.footerLogoText};
  text-transform: uppercase;
`

export const Subtitle = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 15.4411px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.footerLogoText};
  text-transform: uppercase;
`

export const Content = styled.div`
  margin-left: 21px;
`
