import { IntroSection } from 'ducks/aboutLayout'
import React, { FC } from 'react'
import {
  Container,
  LeftCard,
  Title,
  Content,
  Subtitle,
  LeftBox,
  RightBox,
  RightCard,
  LeftImage,
  RightImage,
} from './styled'
import { Text } from 'views/screens/AboutUs/styled'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  layout: IntroSection
}

const InitialSection: FC<Props> = (props: Props) => {
  const { layout } = props

  return (
    <Container>
      <LeftBox>
        <LeftCard>
          <LeftImage background={`url(${layout?.left_image?.url})`} width="50%" />
          <Content width="50%">
            <Title>{layout?.left_title}</Title>
          </Content>
        </LeftCard>
      </LeftBox>
      <RightBox>
        <RightCard>
          <RightImage background={`url(${layout?.right_image?.url})`} />
          <Content width="100%">
            <Subtitle>{layout?.right_title}</Subtitle>
            <Text>
              <HtmlRender>{layout?.right_description}</HtmlRender>
            </Text>
          </Content>
        </RightCard>
      </RightBox>
    </Container>
  )
}

export default InitialSection
