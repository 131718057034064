import { Protagonista, Protagonistas } from 'ducks/protagonistas'

export const filterNullData = ({
  protagonistas,
  protagonistasCount,
}: {
  protagonistas: Array<Protagonista>
  protagonistasCount: number
}): Protagonistas => {
  return {
    protagonistas: protagonistas.filter((protagonista: Protagonista) => protagonista),
    count: protagonistasCount,
  }
}
