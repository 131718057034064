import React, { FC, useEffect, useRef, useState, MutableRefObject, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLanguage } from 'hooks/useLanguage'
import { aboutLayoutSelector } from 'ducks/aboutLayout/selectors'
import { CONTENT } from 'ducks/aboutLayout'
import { useMediaQuery } from '@material-ui/core'

import { Container, BackgroundContainer, GrayScale, PathContainerWrapper, Content } from './styled'
import InitialSection from './InitialSection'
import InitialSectionMobile from './InitialSectionMobile'
import MidSection from './MidSection'
import FinalSection from './FinalSection'
import Founders from './Founders'
import PathIndicator from 'views/components/PathIndicator'

const AboutUs: FC = () => {
  const dispatch = useDispatch()
  const { currentLanguage } = useLanguage()
  const aboutLayout = useSelector(aboutLayoutSelector)
  const [grayHeight, setGrayHeight] = useState(100)
  const contentRef = useRef() as MutableRefObject<HTMLDivElement>
  const match = useMediaQuery('(max-width: 450px)')

  useEffect(() => {
    dispatch(CONTENT.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage])

  useLayoutEffect(() => {
    const containerHeight = contentRef.current?.scrollHeight
    setGrayHeight(containerHeight / 2.35)
  }, [contentRef])

  return (
    <Container>
      <BackgroundContainer background={`url(${aboutLayout.getIn(['background', 'url'])})`}>
        <PathContainerWrapper>
          <PathIndicator
            description={aboutLayout.get('path').description}
            sections={aboutLayout.get('path').sections}
            color="white"
          />
        </PathContainerWrapper>
        <GrayScale gradientHeight={grayHeight} />
        <Content ref={contentRef}>
          {match ? (
            <InitialSectionMobile layout={aboutLayout.get('intro_section')} />
          ) : (
            <InitialSection layout={aboutLayout.get('intro_section')} />
          )}
          <MidSection layout={aboutLayout.get('middle_section')} />
          <FinalSection layout={aboutLayout.get('end_section')} />
          <Founders layout={aboutLayout.get('founders_section')} />
        </Content>
      </BackgroundContainer>
    </Container>
  )
}

export default AboutUs
