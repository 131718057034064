import axios from 'axios'
import { ProtagonistaLayout } from 'ducks/protagonistaLayout'
import { parseLayoutContent } from './parsers'
import { TranslatedRequest } from 'lib/axios/models'

const getProtagonistaLayout = async ({ language }: TranslatedRequest): Promise<ProtagonistaLayout | undefined> => {
  const {
    data: { data },
  } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
    query: `
      query {
        detailsLayout {
          about {
            prota_category: prota_category_${language}
            votes: votes_${language}
            movement: movement_${language}
            Socials {
              instagram
              site: site_${language}
              email: email_${language}
            }
            share: share_${language}
            share_list {
              title: title_${language}
              copy: copy_${language}
            }
          }
          history {
            title: title_${language}
            subtitle: subtitle_${language}
            read_more: read_more_${language}
          }
          recommendation: recommendation_${language}
        }
      }
    `,
  })
  return parseLayoutContent(data.detailsLayout)
}

export default getProtagonistaLayout
