import axios from 'axios'
import { ProtagonistaPayload } from 'ducks/protagonistas'
import { parseProtagonistaData } from './parser'
import { TranslatedRequest } from 'lib/axios/models'

interface ProtagonistaRequest extends TranslatedRequest {
  username: string
}

const getProtagonista = async ({
  language,
  username,
}: ProtagonistaRequest): Promise<ProtagonistaPayload | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        protagonistas(where:{username: "${username}"}) {
          id
          nickname
          name
          username
          description: description_${language}
          history: history_${language}
          image {
            url
          }
          cover_image {
            url
          }
          gallery {
            video_url
            label
            image {
              url
            }
          }
          country {
            shortname
            name: name_${language}
            image {
              url
            }
          }
          category {
            name: name_${language}
            overlay
            Badge {
              text
              background
            }
          }
          group {
            id
            name
            codename
          }
          socials {
            email
            instagram
            website
          }
        }
      }
      `,
    })
    return parseProtagonistaData(data.protagonistas)
  } catch (error) {
    return
  }
}
export default getProtagonista
