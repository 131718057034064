import React, { FC, useState } from 'react'
import { Container, SectionTitle, Image, DotIndicator, Title, Description } from './styled'
import { Slider } from 'ducks/home'
import { List, Record } from 'immutable'
import { Block } from '../UI/Contents'
import { LinkButton, ButtonIcon } from 'views/components/UI/Buttons'
import { useMediaQuery } from '@material-ui/core'
import HtmlRender from 'views/components/HtmlRender'

interface Props {
  data: List<Record<Slider>>
}

const ImageController: FC<Props & { index: number; setIndex: any }> = ({ data, index, setIndex }) => {
  const handleIndex = (newIndex: number) => (): void => {
    setIndex(newIndex)
  }
  const matches = useMediaQuery('(max-width: 768px)')
  const image = data?.getIn([index, 'image']) || ''
  return (
    <Block display="flex" flexDirection="column">
      <Block alignSelf="flex-start" display="flex" flexDirection="column" width={matches ? '100%' : '90%'}>
        <Image backgroundImage={`url(${image})`} backgroundSize="cover" />
        <Block display="flex" alignSelf="flex-end" px="1rem" overflow="visible">
          {data
            ?.map((item, cIndex) => (
              <Block
                width="1.4rem"
                height="2rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
                onClick={handleIndex(cIndex)}
                key={cIndex}
              >
                <DotIndicator isActive={cIndex === index}>
                  <span />
                </DotIndicator>
              </Block>
            ))
            ?.toArray()}
        </Block>
      </Block>
    </Block>
  )
}

interface PropsController {
  button_mobile: string
  button: string
  data: List<Record<Slider>>
}

const ContentController: FC<PropsController> = ({ data, button_mobile, button }) => {
  const [index, setIndex] = useState(0)
  const matches = useMediaQuery('(max-width: 768px)')

  return (
    <>
      <Block width="100%">
        <ImageController data={data} index={index} setIndex={setIndex} />
        <Block width={matches ? '100%' : '80%'} my={matches ? '0.5rem' : '3rem'} px="12px !important">
          <Title>{data?.getIn([index, 'title'])}</Title>
          <Description>
            <HtmlRender>{data?.getIn([index, 'description'])}</HtmlRender>
          </Description>
        </Block>
      </Block>
      {matches
        ? data?.getIn([index, 'link']) && (
            <LinkButton to={data?.getIn([index, 'link'])} bg="transparent" color="rgba(0, 0, 0, 0.87)">
              {button_mobile}
              <ButtonIcon />
            </LinkButton>
          )
        : data?.getIn([index, 'link']) && (
            <Block width="90%" display="flex" flexDirection="row" justifyContent="flex-end">
              <LinkButton to={data?.getIn([index, 'link'])}>
                {button}
                <ButtonIcon />
              </LinkButton>
            </Block>
          )}
    </>
  )
}

interface ComponentProps {
  data: List<Record<Slider>>
  title?: string
  button_mobile: string
  button: string
}

const SliderComponent: FC<ComponentProps> = (props) => {
  const matches = useMediaQuery('(max-width: 768px)')
  const { title } = props

  return (
    <Container>
      {!matches && <SectionTitle>{title}</SectionTitle>}
      <ContentController {...props} />
    </Container>
  )
}

export default SliderComponent
