import { SagaIterator } from '@redux-saga/core'
import { all, takeLatest, put, call } from 'redux-saga/effects'
import { LoadLayoutAction } from '../models'
import { CONTENT } from '../actionTypes'
import getColaboraLayout from 'services/getColaboraLayout'

function* loadColaboraLayout(action: LoadLayoutAction) {
  yield put(CONTENT.request())
  try {
    const { colaboraLayout } = yield call(getColaboraLayout, action.payload)
    if (colaboraLayout) {
      yield put(CONTENT.success(colaboraLayout))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

export function* colaboraLayoutSagas(): SagaIterator {
  yield all([takeLatest(CONTENT.TRIGGER, loadColaboraLayout)])
}
