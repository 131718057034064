import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
  height: 600px;
  @media screen and (min-width: 1600px) {
    height: 720px;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: unset;
  }
`

export const BackgroundImage = styled.div<BackgroundProps>`
  width: 50%;
  height: 100%;
  padding: 40px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${background};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 400px;
  }
  @media screen and (max-width: 450px) {
    height: 300px;
  }
`

export const Card = styled.div`
  width: 50%;
  z-index: 1;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-height: 90%;
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  -moz-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  border-radius: 4px;
  @media screen and (min-width: 1600px) {
    min-height: 85%;
  }
  @media screen and (max-width: 1024px) {
    position: unset;
    width: 100%;
    margin-top: 40px;
  }
  @media screen and (max-width: 450px) {
    margin-top: 20px;
  }
`

export const Label = styled.p`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.label};
  @media screen and (min-width: 1600px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 450px) {
    min-width: 70px;
  }
`

export const FeedbackMessage = styled.p`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.error};
`
export const CategoryAndContryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`

export const SocialMediaInputContainer = styled.div`
  margin-top: 6px;
  width: 48%;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`

export const InputContainer = styled.div`
  width: 48%;
`
