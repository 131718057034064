import React, { FC, useContext } from 'react'
import { Container, IconWrapper, Label } from './styled'
import { MdRadioButtonUnchecked, MdRadioButtonChecked } from 'react-icons/md'
import { ThemeContext } from 'styled-components'

type Props = {
  selected?: boolean
  label?: string
  onClick?: () => void
  error?: string
}

const Checkbox: FC<Props> = ({ selected, label, onClick, error }: Props) => {
  const { colors } = useContext(ThemeContext)

  return (
    <Container>
      <IconWrapper onClick={onClick}>
        {selected ? (
          <MdRadioButtonChecked color={colors.checkbox} />
        ) : (
          <MdRadioButtonUnchecked color={error ? colors.error : colors.checkbox} />
        )}
      </IconWrapper>
      {label && <Label>{label}</Label>}
    </Container>
  )
}

export default Checkbox
