import { useEffect } from 'react'

const useMetaTag = (property: string, content?: string): void => {
  useEffect(() => {
    if (content) {
      const metaTagImage = document.createElement('meta')
      metaTagImage.setAttribute('property', property)
      metaTagImage.content = content
      metaTagImage.setAttribute('id', property + content)
      document.getElementsByTagName('head')[0].appendChild(metaTagImage)
    }
    return () => {
      document.getElementById(property + content)?.remove()
    }
  }, [property, content])
}

export default useMetaTag
