import { HomeHerramienta, ToolGoal } from 'ducks/elCole'
import React, { FC } from 'react'

import {
  Container,
  Image,
  CardWrapper,
  CardBody,
  CardLeftContent,
  CardRightContent,
  Title,
  DescriptionContainer,
  Text,
  Goal,
  GoalsContainer,
} from './styled'
import { Card } from 'views/components/UI/Contents'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  layout?: HomeHerramienta
}

const HerramientaSection: FC<Props> = (props: Props) => {
  const { layout } = props
  return (
    <Container>
      <Image src={layout?.image?.url} />
      <CardWrapper>
        <Card color={layout?.card_color}>
          <CardBody>
            <CardLeftContent>
              <Title>{layout?.title}</Title>
            </CardLeftContent>
            <CardRightContent>
              <DescriptionContainer>
                <Text>
                  <HtmlRender>{layout?.description}</HtmlRender>
                </Text>
              </DescriptionContainer>
              <GoalsContainer>
                {layout?.tool_goals?.map((goal: ToolGoal) => (
                  <Goal key={goal.id}>
                    <Text>
                      <HtmlRender>{goal.description}</HtmlRender>
                    </Text>
                  </Goal>
                ))}
              </GoalsContainer>
            </CardRightContent>
          </CardBody>
        </Card>
      </CardWrapper>
    </Container>
  )
}

export default HerramientaSection
