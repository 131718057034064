import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CONTENT, profileLayoutSelector, BaseFormValues, FormErrors } from 'ducks/profileLayout'
import { CONTENT as CATEGORIES_CONTENT, categoriesSelector, Category } from 'ducks/categories'
import { userSelector, GET_PROFILE, UPDATE_PROFILE, SET } from 'ducks/user'
import { GalleryItem } from 'ducks/protagonistas'
import { useLanguage } from 'hooks/useLanguage'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useHistory, Redirect } from 'react-router-dom'
import _ from 'lodash'
import { useMediaQuery } from '@material-ui/core'
import { getImageFile } from 'services/user'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

import {
  Container,
  PathIndicatorContainer,
  Description,
  Content,
  ButtonWrapper,
  BottomBannerContainer,
  CancelButtonWrapper,
} from './styled'
import PathIndicator from 'views/components/PathIndicator'
import HtmlRender from 'views/components/HtmlRender'
import { Block } from 'views/components/UI/Contents'
import { ButtonIcon, ButtonWithIcon, OutlineLinkButton } from 'views/components/UI/Buttons'
import AboutSection from './AboutSection'
import HistorySection from './HistorySection'
import Banner from 'views/components/Banner'

const initialErrors: FormErrors = {}

const ProtagonistaProfile: FC = () => {
  const [redirect, setRedirect] = useState(false)
  const dispatch = useDispatch()
  const { currentLanguage } = useLanguage()
  const matches = useMediaQuery('(max-width: 768px)')
  const history = useHistory()
  const { sendEvent } = useGoogleAnalytics()

  const profileLayoutState = useSelector(profileLayoutSelector)
  const userState = useSelector(userSelector)
  const categoriesState = useSelector(categoriesSelector)

  const profile = userState.get('profile')
  const userId = userState.get('id')

  const initialValues: BaseFormValues = {
    category: profile?.category.name || '',
    country: profile?.country.name || '',
    nickname: profile?.nickname || '',
    name: profile?.name || '',
    about_me: profile?.description || '',
    instagram: profile?.socials?.instagram || '',
    facebook: profile?.socials?.facebook || '',
    twitter: profile?.socials?.twitter || '',
    linkedin: profile?.socials?.linkedin || '',
    email: profile?.socials?.email || '',
    history: profile?.history || '',
    image: null,
    gallery: [],
  }

  const validationSchema = yup.object({
    category: yup.string().required('Category is required'),
    country: yup.string().required('Country is required'),
    nickname: yup.string(),
    name: yup.string().required('Name is required'),
    about_me: yup
      .string()
      .max(profileLayoutState.getIn(['about_section', 'max_character']))
      .required('About me section is required'),
    instagram: yup.string(),
    facebook: yup.string(),
    twitter: yup.string(),
    linkedin: yup.string(),
    email: yup.string().required(),
    history: yup.string().required('History is required'),
    image: yup.mixed().required(),
    gallery: yup.mixed().required(),
  })

  const onSubmit = (values: BaseFormValues) => {
    const {
      category,
      country,
      nickname,
      name,
      about_me,
      instagram,
      facebook,
      twitter,
      linkedin,
      email,
      history,
      image,
      gallery,
    } = values
    const profile = {
      category,
      country,
      nickname,
      first_name: name,
      about_me,
      instagram,
      facebook,
      twitter,
      linkedin,
      email,
      history,
      picture: image,
      gallery,
      user: userState.get('id'),
    }
    dispatch(UPDATE_PROFILE.trigger(profile))
    sendEvent({
      label: 'Protagonista Profile',
      category: 'Attempt to Update Profile',
      action: `${profile.first_name} tried to update his Protagonista profile.`,
    })
  }

  const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    initialErrors,
    validationSchema,
    onSubmit,
    validateOnChange: false,
  })

  useEffect(() => {
    if (profile) {
      ;(async () => {
        const pictureFile = await getImageFile(profile.cover_image?.id)
        setFieldValue('image', pictureFile)
        const galleryFiles = await Promise.all(
          profile.gallery
            .map(async (galleryItem: GalleryItem) =>
              galleryItem.image ? await getImageFile(galleryItem.image.id) : null
            )
            .filter((value) => value !== null)
        )
        setFieldValue('gallery', galleryFiles)
      })()
    }
  }, [profile, setFieldValue])

  useEffect(() => {
    const isEmpty = _.isEmpty(errors)
    if (!isEmpty) {
      window.scrollTo(0, 0)
    }
  }, [errors])

  useEffect(() => {
    if (!userState.get('profile')) {
      setRedirect(true)
    }
  }, [userState])

  useEffect(() => {
    dispatch(CONTENT.trigger({ language: currentLanguage }))
    dispatch(CATEGORIES_CONTENT.trigger({ language: currentLanguage }))
    dispatch({
      type: GET_PROFILE,
      payload: {
        language: currentLanguage,
        userId,
      },
    })
  }, [dispatch, currentLanguage, userId])

  useEffect(() => {
    if (userState.get('update_success')) {
      sendEvent({
        label: 'Protagonista Profile',
        category: 'Updated Profile',
        action: `${values.name} has updated his his Protagonista profile.`,
      })
      history.push({
        pathname: '/perfil',
      })
    }
  }, [userState, history, values, sendEvent])

  useEffect(() => {
    return () => {
      dispatch({ type: SET, payload: { update_success: false, updatedProfile: false } })
    }
  }, [dispatch])

  return redirect ? (
    <Redirect to="/crear-perfil" />
  ) : (
    <Container backgroundColor={profile?.category?.Badge?.background}>
      <PathIndicatorContainer>
        <PathIndicator
          description={profileLayoutState.getIn(['path', 'description'])}
          sections={profileLayoutState.getIn(['path', 'sections'])}
        />
      </PathIndicatorContainer>
      <Content>
        <AboutSection
          layout={profileLayoutState.get('about_section')}
          values={values}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          maxChar={profileLayoutState.getIn(['about_section', 'max_character'])}
          maxCharMessage={profileLayoutState.get('max_char_message')}
          fieldFeedback={profileLayoutState.get('feedback_field')}
          categoriesOptions={categoriesState.get('categories').map((category: Category) => ({
            value: category.name,
            label: category.name,
          }))}
        />
        <HistorySection
          layout={profileLayoutState.get('history_section')}
          values={values}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          fieldFeedback={profileLayoutState.get('feedback_field')}
        />
      </Content>
      <BottomBannerContainer>
        <Block width={matches ? '100%' : '70%'}>
          <Banner backgroundColor="#ED8C9B" visible={true}>
            <Description>
              <HtmlRender>{profileLayoutState.get('message')}</HtmlRender>
            </Description>
            <ButtonWrapper>
              <ButtonWithIcon mobileFullWidth onClick={handleSubmit}>
                {profileLayoutState.get('button')}
                <ButtonIcon />
              </ButtonWithIcon>
              <CancelButtonWrapper>
                <OutlineLinkButton to="/perfil">{profileLayoutState.get('cancel')}</OutlineLinkButton>
              </CancelButtonWrapper>
            </ButtonWrapper>
          </Banner>
        </Block>
      </BottomBannerContainer>
    </Container>
  )
}

export default ProtagonistaProfile
