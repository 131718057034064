import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { IProtagonistas } from './../models'
import * as TYPES from './../actionTypes'

export const initialState: IProtagonistas = fromJS({
  loading: true,
  protagonistas: [],
  protagonista: {},
})

const protagonistasReducer: Reducer<IProtagonistas> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET:
      return state.merge(action.payload)
    case TYPES.CONTENT.REQUEST:
      return state.set('loading', true)
    case TYPES.CONTENT.SUCCESS:
      return state.merge(action.payload)
    case TYPES.CONTENT.FAILURE:
      return state.set('error', action.payload)
    case TYPES.CONTENT.FULFILL:
      return state.set('loading', false)
    case TYPES.GET.TRIGGER:
      return state.set('loading', true)
    case TYPES.GET.SUCCESS:
      return state.merge(action.payload)
    case TYPES.GET.FAILURE:
      return state.set('error', action.payload)
    case TYPES.GET.FULFILL:
      return state.set('loading', false)
    case TYPES.LOAD_PROTAGONISTAS.SUCCESS:
      return state.merge({
        protagonistas: state.get('protagonistas').concat(action.payload.protagonistas),
        count: action.payload.count,
      })
    case TYPES.LOAD_PROTAGONISTAS.FULFILL:
      return state.set('loading_protagonistas', false)
    case TYPES.LOAD_PROTAGONISTAS.REQUEST:
      return state.set('loading_protagonistas', true)
    default:
      return state
  }
}

export default protagonistasReducer
