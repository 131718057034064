import React, { FC, useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { blogSelector, TRIGGER_BLOG_LAYOUT, IPost } from 'ducks/blog'
import { useLanguage } from 'hooks/useLanguage'
import { countriesSelector } from 'ducks/countries'
import { categoriesSelector } from 'ducks/categories'
import { useFilter } from 'hooks/useFilter'

import {
  Container,
  ContainerPath,
  ContainerFeatured,
  ContainerFilter,
  ContainerFeatureds,
  ContainerHighlighted,
  ContainerPosts,
  ContainerTopResults,
  ContainerPostsResults,
  MotivatedSeparator,
  MotivatedTitle,
  MotivatedTitleContainer,
  ContainerCreatePost,
  ContainerMotivated,
  ContainerMotivatedPosts,
  CreateContent,
  CreateTitle,
  CreateSubtitle,
  CreateCard,
  GreenCreateCard,
  GreenCreateCardButton,
  CreateCardTitle,
  CreateCardMessage,
  CreateCardButton,
  FilterInUseContainer,
} from './styled'
import { ButtonIcon, LinkButton } from 'views/components/UI/Buttons'
import FeaturedBlogPost from 'views/components/FeaturedBlogPost'
import HighlightedBlogPost from 'views/components/HighlightedBlogPost'
import PathIndicator from 'views/components/PathIndicator'
import Filter from 'views/components/Filter'
import FilterInUse from 'views/components/Filter/FilterInUse'
import CardBlogPost from 'views/components/CardBlogPost'
import MotivatedPost from 'views/components/MotivatedPost'
import HtmlRender from 'views/components/HtmlRender'

const BlogHome: FC = () => {
  const dispatch = useDispatch()
  const blogState = useSelector(blogSelector)
  const countries = useSelector(countriesSelector).get('countries')
  const categories = useSelector(categoriesSelector).get('categories')
  const { currentLanguage } = useLanguage()
  const { countriesFilter, categoriesFilter, clearFilter } = useFilter()
  const featuredBlogPosts: Array<IPost> = blogState.getIn(['home_layout', 'featured_posts']) || []
  const [highlightedPost, setHighlightedPost] = useState<IPost | undefined>(undefined)
  const [featuredPosts, setFeaturedPosts] = useState<Array<IPost>>([])
  const posts = blogState.get('posts') || []

  useEffect(() => {
    if (categoriesFilter.length > 0) {
      const filteredFeaturedPosts = featuredBlogPosts.filter((post) => post.category.name === categoriesFilter[0].name)
      if (filteredFeaturedPosts.length > 0) {
        const missingFeatureds = featuredBlogPosts.filter((post) => post.category.name !== categoriesFilter[0].name)
        setHighlightedPost(filteredFeaturedPosts[0])
        setFeaturedPosts(missingFeatureds)
        return
      }
      setHighlightedPost(featuredBlogPosts[0])
      setFeaturedPosts(featuredBlogPosts.slice(1))
      return
    }
    setHighlightedPost(featuredBlogPosts[0])
    setFeaturedPosts(featuredBlogPosts.slice(1))
    return
  }, [categoriesFilter, featuredBlogPosts])

  useEffect(() => {
    dispatch({ type: TRIGGER_BLOG_LAYOUT, payload: { language: currentLanguage } })
  }, [dispatch, currentLanguage])

  useEffect(() => {
    return () => {
      clearFilter()
    }
  }, [clearFilter])

  const isFilterInUse = useCallback(() => {
    return countriesFilter.length > 0 || categoriesFilter.length > 0
  }, [categoriesFilter, countriesFilter])

  const renderHighlightedColor = useCallback(() => {
    if (categoriesFilter.length > 0) {
      return categoriesFilter[0].blog_color
    }
    return
  }, [categoriesFilter])

  return (
    <Container>
      <ContainerPath>
        <PathIndicator
          description={blogState.getIn(['home_layout', 'path', 'description'])}
          sections={blogState.getIn(['home_layout', 'path', 'sections'])}
        />
      </ContainerPath>
      {isFilterInUse() && (
        <FilterInUseContainer>
          <FilterInUse
            title={blogState.getIn(['home_layout', 'filter', 'in_use'])}
            categoriesFilter={categoriesFilter}
            countriesFilter={countriesFilter}
            emptyMessage={blogState.getIn(['home_layout', 'filter', 'no_filters'])}
          />
        </FilterInUseContainer>
      )}
      <ContainerFeatured>
        <ContainerFeatureds>
          {isFilterInUse() ? (
            <Filter
              title={blogState.getIn(['home_layout', 'filter', 'title'])}
              country={blogState.getIn(['home_layout', 'filter', 'country'])}
              category={blogState.getIn(['home_layout', 'filter', 'category'])}
              countriesOptions={countries}
              categoriesOptions={categories}
            />
          ) : (
            featuredPosts.map((blogPost) => <FeaturedBlogPost key={blogPost.url} post={blogPost} />)
          )}
        </ContainerFeatureds>
        <ContainerHighlighted>
          {highlightedPost && (
            <HighlightedBlogPost post={highlightedPost} highlightedColor={renderHighlightedColor()} />
          )}
        </ContainerHighlighted>
      </ContainerFeatured>
      <ContainerPosts filterInUse={isFilterInUse()}>
        {isFilterInUse() && (
          <GreenCreateCard>
            <CreateCardTitle>{blogState.getIn(['home_layout', 'create_title'])}</CreateCardTitle>
            <CreateCardMessage>
              <HtmlRender>{blogState.getIn(['home_layout', 'create_message'])}</HtmlRender>
            </CreateCardMessage>
            <GreenCreateCardButton>
              <LinkButton mobileFullWidth to={blogState.getIn(['home_layout', 'create_link']) || ''}>
                {blogState.getIn(['home_layout', 'create_button'])}
                <ButtonIcon />
              </LinkButton>
            </GreenCreateCardButton>
          </GreenCreateCard>
        )}
        {!isFilterInUse() && (
          <ContainerFilter>
            <Filter
              title={blogState.getIn(['home_layout', 'filter', 'title'])}
              country={blogState.getIn(['home_layout', 'filter', 'country'])}
              category={blogState.getIn(['home_layout', 'filter', 'category'])}
              countriesOptions={countries}
              categoriesOptions={categories}
            />
          </ContainerFilter>
        )}
        <ContainerTopResults filterInUse={isFilterInUse()}>
          {posts.slice(0, 2).map((post) => (
            <CardBlogPost key={post.id} post={post} />
          ))}
        </ContainerTopResults>
        <ContainerPostsResults>
          {posts.slice(2).map((post) => (
            <CardBlogPost key={post.id} post={post} />
          ))}
        </ContainerPostsResults>
      </ContainerPosts>
      <MotivatedTitleContainer>
        <MotivatedSeparator />
        <MotivatedTitle>{blogState.getIn(['home_layout', 'most_motivated'])}</MotivatedTitle>
        <MotivatedSeparator />
      </MotivatedTitleContainer>
      <ContainerMotivated align={isFilterInUse() ? 'center' : 'flex-end'}>
        <ContainerMotivatedPosts>
          {blogState?.get('mostMotivatedPosts')?.map((post) => (
            <MotivatedPost key={post.id} post={post} />
          ))}
        </ContainerMotivatedPosts>
        <ContainerCreatePost>
          {isFilterInUse() ? (
            <ContainerFeatureds width="100%">
              {featuredPosts.map((blogPost) => (
                <FeaturedBlogPost key={blogPost.url} post={blogPost} />
              ))}
            </ContainerFeatureds>
          ) : (
            <>
              <CreateContent>
                <CreateTitle>{blogState.getIn(['home_layout', 'most_motivated_message'])}</CreateTitle>
                <CreateSubtitle>{blogState.getIn(['home_layout', 'most_motivated_label'])}</CreateSubtitle>
              </CreateContent>
              <CreateCard>
                <CreateCardTitle>{blogState.getIn(['home_layout', 'create_title'])}</CreateCardTitle>
                <CreateCardMessage>
                  <HtmlRender>{blogState.getIn(['home_layout', 'create_message'])}</HtmlRender>
                </CreateCardMessage>
                <CreateCardButton>
                  <LinkButton
                    color=" rgba(100, 100, 100, 1)"
                    mobileFullWidth
                    to={blogState.getIn(['home_layout', 'create_link']) || ''}
                  >
                    {blogState.getIn(['home_layout', 'create_button'])}
                    <ButtonIcon />
                  </LinkButton>
                </CreateCardButton>
              </CreateCard>
            </>
          )}
        </ContainerCreatePost>
      </ContainerMotivated>
    </Container>
  )
}

export default BlogHome
