import axios from 'axios'
import { Pages } from 'ducks/pages'
import { parsePagesContent } from './parser'
import { TranslatedRequest } from 'lib/axios/models'

interface PageRequest extends TranslatedRequest {
  slug: string
}

const getPages = async ({ language, slug }: PageRequest): Promise<Pages | undefined> => {
  const {
    data: { data },
  } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
    query: `
    query {
      pages(where: {
        slug: "${slug}"
      }){
          slug
          name: name_${language}
          title: title_${language}
          content: content_${language}         
      }
    }
    `,
  })
  return parsePagesContent(data)
}

export default getPages
