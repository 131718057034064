import axios from 'axios'
import { SubmitFormType } from 'ducks/contacto'

const submitContacto = async (form: SubmitFormType): Promise<boolean | undefined> => {
  try {
    const data = await axios.post(`${process.env.REACT_APP_MAIN_API}/contacts/trigger`, form)
    if (data) {
      return true
    }
  } catch (error) {
    throw error
  }
}

export default submitContacto
