import styled from 'styled-components'

export const Container = styled.div`
  height: 85vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 8% 4%;
  @media screen and (max-width: 1024px) {
    padding: 20% 4%;
  }
  @media screen and (max-width: 450px) {
    padding: 0;
    justify-content: center;
    align-items: center;
  }
`

export const Card = styled.div`
  background: #91cc8d;
  padding: 40px;
  min-width: 400px;
  width: 40%;
  max-width: 550px;
  position: relative;
  @media screen and (min-width: 1600px) {
    padding: 80px 60px;
  }
  @media screen and (max-width: 450px) {
    width: 90%;
    min-width: unset;
    padding: 20px;
    padding-bottom: 40px;
  }
`
export const Title = styled.p`
  font-weight: bold;
  font-size: 34px;
  line-height: 34px;
  letter-spacing: 0.01px;
  color: ${({ theme }) => theme.colors.dark};
  margin: 0;
  margin-bottom: 30px;
`

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: -7%;
  right: -5%;
  @media screen and (min-width: 1600px) {
    bottom: -4%;
  }
  @media screen and (max-width: 450px) {
    bottom: -5%;
    right: 5%;
  }
`
