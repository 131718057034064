import styled from 'styled-components'

export const Container = styled.div`
  width: 60%;
  padding: 20px 20px 20px 0;
  border-right: 1px solid ${({ theme }) => theme.colors.black};
  @media screen and (max-width: 900px) {
    width: 100%;
    border: none;
    padding: 0;
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    width: 100%;
    flex-direction: column;
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    width: 100%;
    flex-direction: row;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
    margin-bottom: 0%;
  }
`

export const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 49px;
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: 40px;
  @media screen and (max-width: 650px) {
    font-size: 32px;
  }
`
