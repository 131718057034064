import _ from 'lodash'
import { privateApi } from 'lib/axios'
import { ProfileType } from 'ducks/protagonistas'

const submitProfile = async (profile: ProfileType): Promise<boolean> => {
  try {
    const model = [
      'category',
      'country',
      'nickname',
      'first_name',
      'last_name',
      'about_me',
      'instagram',
      'facebook',
      'twitter',
      'linkedin',
      'email',
      'history',
      'user',
    ]
    const formData = new FormData()
    const formatedProfile = _.pick(profile, model)
    formData.append('files.picture', profile.picture as Blob)
    formData.append('data', JSON.stringify(formatedProfile))

    _.map(profile.gallery, (value) => {
      formData.append('files.gallery', value as Blob)
    })
    const response = await privateApi.post('/new-profiles', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    if (response.status === 200) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

export default submitProfile
