import { SagaIterator } from '@redux-saga/core'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { Action } from 'lib/redux/models'
import { CONTENT, GET } from '../actionTypes'
import { movimientos as getMovimientos, getFeaturedMovimientos } from 'services/movimientos'
import getMovimiento from 'services/movimientos/getMovimiento'

function* loadMovimientos(action: Action) {
  yield put(CONTENT.request())
  try {
    const movimientos = yield call(getMovimientos, action.payload)
    if (movimientos) {
      yield put(CONTENT.success(movimientos))
    }
    const featured = yield call(getFeaturedMovimientos, action.payload)
    if (featured) {
      yield put(CONTENT.success(featured))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* getOneMovimiento(action: Action) {
  yield put(GET.request())
  try {
    const movimiento = yield call(getMovimiento, action?.payload)
    if (movimiento) {
      yield put(GET.success(movimiento))
    }
  } catch (error) {
    yield put(GET.failure(error.message))
  } finally {
    yield put(GET.fulfill())
  }
}

export function* movimientosSagas(): SagaIterator {
  yield all([takeLatest(CONTENT.TRIGGER, loadMovimientos), takeLatest(GET.TRIGGER, getOneMovimiento)])
}
