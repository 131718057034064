import axios from 'axios'
import { parseLayoutContent } from './parser'
import { MovimientosLayout } from 'ducks/movimientosLayout'
import { TranslatedRequest } from 'lib/axios/models'

const getLayout = async ({ language }: TranslatedRequest): Promise<MovimientosLayout | undefined> => {
  const {
    data: { data },
  } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
    query: `
    query {
      movimientosLayout {
        path {
          sections {
            link
            text: text_${language}
          }
          description: description_${language}
        }
        filter {
          title: title_${language}
          country: country_${language}
          category: category_${language}
          in_use: in_use_${language}
          no_filters: no_filters_${language}
        }
        Movimientos {
          month: month_${language}
          recommendations: recommendations_${language}
          protagonistas: protagonistas_${language}
          load_more: load_more_${language}
          load_more_button: load_more_button_${language}
          card_button: card_button_${language}
          protagonistas_label: protagonistas_label_${language}
          and: and_${language}
          more: more_${language}
          prota_number_to_show
          max_character
        }
      }
    }
    `,
  })
  return parseLayoutContent(data.movimientosLayout)
}

export default getLayout
