import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  margin: 2rem;
  padding-top: 20px;
  position: relative;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 2rem;
  }
  @media screen and (max-width: 450px) {
    margin: 0 0 2rem;
  }
`

export const Container = styled.div`
  width: 100%;
  padding: 2rem;
  @media screen and (max-width: 450px) {
    padding: 0;
  }
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.135px;
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: 10px;
`

export const Text = styled.div`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: 10px;
`
