import styled from 'styled-components'

type ContainerProps = {
  fullWidth?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '60%')};
  position: absolute;
  background: white;
  padding: 20px 0;
  box-shadow: 0 0 3px 1px #dad6d6;
  top: 55px;
  border-radius: 10px;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    margin-top: 23px;
    align-items: flex-end;
  }
`

export const Title = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 50px;
  color: ${({ theme }) => theme.colors.dark};
`

export const TitleContainer = styled.div`
  width: calc(90% - 30px);
  border-bottom: 1px solid black;
  margin: 0 30px;
  margin-bottom: 20px;
  padding-bottom: 10px;
`

export const Button = styled.div`
  align-items: baseline;
  display: flex;
  padding: 10px 30px;
  color: ${({ theme }) => theme.colors.dark};
  &:hover {
    background: #ececec;
  }
`

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.dark};
  cursor: pointer;
  font-size: 30px;
  @media screen and (min-width: 1600px) {
    font-size: 48px;
    top: 20px;
    right: 20px;
  }
  @media screen and (max-width: 768px) {
    top: 15px;
    right: 15px;
  }
`

export const SocialText = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 21.7412px;
  line-height: 33px;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 10px;
`
