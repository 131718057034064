import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin-top: 4rem;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

export const Header = styled.div`
  padding-left: 3rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 1024px) {
    padding-left: 2rem;
  }
  @media screen and (max-width: 768px) {
    padding-left: 0;
  }
`

export const StackedCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 0 3rem 0 0;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

export const StackedTopCard = styled.div`
  transform: translateY(20px);
  z-index: 1;
  width: 95%;
  @media screen and (max-width: 450px) {
    width: 100%;
    transform: none;
    margin-bottom: 2rem;
  }
`

export const RightCardContainer = styled.div`
  transform: translateY(20px);
  width: 50%;
  @media screen and (max-width: 1024px) {
    width: 100%;
    transform: none;
  }
`
