import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CONTENT, loadingSelector } from 'ducks/loading'
import LoadingAnimation from 'assets/loading_button.gif'

import { Image } from './styled'

const LoadingButtonAnimation: FC = () => {
  const dispatch = useDispatch()
  const loadingState = useSelector(loadingSelector)

  useEffect(() => {
    dispatch(CONTENT.trigger())
  }, [dispatch])

  return <Image src={loadingState.getIn(['button_animation', 'url']) || LoadingAnimation} />
}

export default LoadingButtonAnimation
