import React, { FC } from 'react'
import { Container, LightText, Content, Bottom, LineSeparator, Separator, BottomLink } from './styled'
import { Record } from 'immutable'
import { BottomFooter, Footer as IFooter, FooterColumn as IFooterColumn } from 'ducks/layoutData/models'
import FooterColumn from './FooterColumn'
import NewsletterSubscription from './NewsletterSubscription'

interface Props {
  content: Record<IFooter>
}

const Footer: FC<Props> = ({ content }) => {
  const renderBottomElement = (element: Record<BottomFooter>) => {
    if (element.get('link')) {
      return <BottomLink to={element.get('link')}>{element.get('label')}</BottomLink>
    }
    return <LightText>{element.get('label')}</LightText>
  }

  return (
    <Container>
      <Content>
        {content?.get('columns')?.map((column: Record<IFooterColumn>) => (
          <FooterColumn key={column.get('id')} content={column.toJS()} />
        ))}
        <NewsletterSubscription layout={content?.get('newsletter')} />
      </Content>
      <LineSeparator />
      <Bottom>
        {content?.get('bottom_part')?.map((element: Record<BottomFooter>) => {
          return (
            <React.Fragment key={element.get('label')}>
              {renderBottomElement(element)}
              <Separator />
            </React.Fragment>
          )
        })}
      </Bottom>
    </Container>
  )
}

export default Footer
