import styled from 'styled-components'

type ContainerProps = {
  color?: string
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${({ color, theme }) => (color ? color : theme.colors.defaultCardBackground)};
  @media screen and (max-width: 768px) {
    padding: 4rem;
  }
  @media screen and (max-width: 450px) {
    padding: 1rem;
    padding-bottom: 20px;
  }
`

export const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  @media screen and (max-width: 450px) {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
  }
`
