import { WithCard } from 'ducks/postular'
import { Link } from 'ducks/home'
import React, { FC } from 'react'

import { Container, CardBody, CardText, CardTitle, CardButtonWrapper } from './styled'
import HtmlRender from 'views/components/HtmlRender'
import { ExternalLinkButton, ButtonIcon, LinkButton } from 'views/components/UI/Buttons'
import { Card } from 'views/components/UI/Contents'

type Props = {
  layout: WithCard
}

const EndSection: FC<Props> = (props: Props) => {
  const { layout } = props

  const renderLinkButton = (link: Link) => {
    if (link?.external) {
      return (
        <ExternalLinkButton href={link?.value} target="blank" mobileFullWidth>
          {layout?.card_button}
          <ButtonIcon />
        </ExternalLinkButton>
      )
    } else {
      return (
        <LinkButton to={link?.value || ''} mobileFullWidth>
          {layout?.card_button}
          <ButtonIcon />
        </LinkButton>
      )
    }
  }

  return (
    <Container>
      <Card color={layout?.card_color}>
        <CardBody>
          <CardTitle>{layout?.card_title}</CardTitle>
          <CardText>
            <HtmlRender>{layout?.card_description}</HtmlRender>
          </CardText>
          <CardButtonWrapper>{renderLinkButton(layout?.card_link)}</CardButtonWrapper>
        </CardBody>
      </Card>
    </Container>
  )
}

export default EndSection
