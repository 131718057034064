import { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

type UseQueryType = {
  query: URLSearchParams
  queryString: string
  queryParsed: unknown
  cleanQuery: () => void
}

const useQuery = (): UseQueryType => {
  const location = useLocation()
  const [query, setQuery] = useState(new URLSearchParams(location.search))
  const [queryParsed, setQueryParsed] = useState(queryString.parse(location.search))
  const [queryAsString, setQueryAsString] = useState(query.toString())

  const cleanQuery = useCallback(() => {
    const newQuery = new URLSearchParams(location.search)
    newQuery.forEach((value, key) => newQuery.delete(key))
    setQuery(newQuery)
    setQueryParsed({})
    setQueryAsString(newQuery.toString())
  }, [location])

  return { query, queryString: queryAsString, queryParsed, cleanQuery }
}

export default useQuery
