import { SagaIterator } from '@redux-saga/core'
import { all, put, call, takeLatest } from 'redux-saga/effects'
import { CONTENT } from 'ducks/tienditaLayout'
import { Action } from 'lib/redux/models'
import { getTienditaLayout } from 'services/getTienditaLayout'

function* loadTienditaLayout(action: Action) {
  yield put(CONTENT.request())
  try {
    const { data } = yield call(getTienditaLayout, action?.payload)
    if (data) {
      yield put(CONTENT.success(data.tiendita))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

export function* tienditaLayoutSagas(): SagaIterator {
  yield all([takeLatest(CONTENT.TRIGGER, loadTienditaLayout)])
}
