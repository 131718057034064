import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { layoutDataSelector } from 'ducks/layoutData'
import { FiChevronRight } from 'react-icons/fi'

import { Container, Button, ButtonText, Description } from './styled'
import HtmlRender from '../HtmlRender'

const LearnMoreTerms: FC = () => {
  const layoutState = useSelector(layoutDataSelector)
  const termsAndPolicy = layoutState.getIn(['termsAndPolicy'])
  const [show, setShow] = useState(false)

  const showMore = () => {
    setShow(true)
  }
  return (
    <Container>
      {!show ? (
        <Button onClick={showMore}>
          <ButtonText>{termsAndPolicy?.getIn(['learn_more', 'button'])}</ButtonText>
          <FiChevronRight size={12} />
        </Button>
      ) : (
        <Description>
          <HtmlRender>{termsAndPolicy?.getIn(['learn_more', 'description'])}</HtmlRender>
        </Description>
      )}
    </Container>
  )
}

export default LearnMoreTerms
