import styled from 'styled-components'

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#91cc8d'
  }
  if (props.isDragReject) {
    return '#ff0000'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#fff'
}

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #0003;
  outline: none;
  transition: border 0.24s ease-in-out;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  align-items: center;
`

export const Text = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.09px;
`
