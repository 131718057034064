import React, { FC, useState } from 'react'
import { Container, FeedbackContainer, FeedbaackText, ButtonContainer } from './styled'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FiCopy, FiCheck } from 'react-icons/fi'

type Props = {
  text: string
  withFeedback?: boolean
  feedbackMessage?: string
  color?: string
  size?: number
}

const CopyButton: FC<Props> = (props: Props) => {
  const { text, withFeedback, feedbackMessage, color, size } = props
  const [copied, setCopied] = useState(false)

  const confirmCopy = () => {
    if (withFeedback) {
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }

  return (
    <Container color={color}>
      {copied ? (
        <FeedbackContainer>
          <FeedbaackText>{feedbackMessage}</FeedbaackText>
          <FiCheck />
        </FeedbackContainer>
      ) : (
        <CopyToClipboard text={text} onCopy={confirmCopy}>
          <ButtonContainer>
            <FiCopy size={size ? size : 20} />
          </ButtonContainer>
        </CopyToClipboard>
      )}
    </Container>
  )
}

export default CopyButton
