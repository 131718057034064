import { VolunteerType } from 'ducks/volunteers'
import React, { FC } from 'react'

import { Container, Text } from './styled'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  type: VolunteerType
  active: boolean
  onClick: (type: VolunteerType | null) => void
}

const VolunteerTypeCard: FC<Props> = (props: Props) => {
  const { type, active, onClick } = props

  const handleClick = () => {
    onClick(type)
  }

  return (
    <Container color={active ? type.color : undefined} onClick={handleClick}>
      <Text color={!active ? type.color : undefined}>
        <HtmlRender>{type.display_name}</HtmlRender>
      </Text>
    </Container>
  )
}

export default VolunteerTypeCard
