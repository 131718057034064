import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HOME_CONTENT, postularSelector } from 'ducks/postular'
import { useLanguage } from 'hooks/useLanguage'
import PathIndicator from 'views/components/PathIndicator'

import { Container } from './styled'
import IntroSection from './IntroSection'
import InviteSection from './InviteSection'
import EndSection from './EndSection'

const PostularHome: FC = () => {
  const dispatch = useDispatch()
  const postularState = useSelector(postularSelector)
  const { currentLanguage } = useLanguage()

  useEffect(() => {
    dispatch(HOME_CONTENT.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage])

  return (
    <Container>
      <PathIndicator
        description={postularState.getIn(['home', 'path', 'description'])}
        sections={postularState.getIn(['home', 'path', 'sections'])}
      />
      <IntroSection layout={postularState.getIn(['home', 'intro_section'])} />
      <InviteSection
        inviteLayout={postularState.getIn(['home', 'invite_section'])}
        postularLayout={postularState.getIn(['home', 'postular_section'])}
      />
      <EndSection layout={postularState.getIn(['home', 'end_section'])} />
    </Container>
  )
}

export default PostularHome
