import axios from 'axios'
import { ColaboraLayout } from 'ducks/colaboraLayout'
import { TranslatedRequest } from 'lib/axios/models'

const getColaboraLayout = async ({ language }: TranslatedRequest): Promise<ColaboraLayout | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        colaboraLayout {
          path {
            description: description_${language}
            sections {
              link
              text: text_${language}
            }
          }
          intro_section {
            image {
              url
            }
            title: title_${language}
            description: description_${language}
          }
          where_we_are_section {
            title: title_${language}
            collaborators {
              name
              location
              email
              role: role_${language}
              description: description_${language}
              image {
                url
              }
            }
          }
          collaborate_section {
            title: title_${language}
            donation {
              title:title_${language}
              description: description_${language}
              link
              external_link
              button: button_${language}
              background_color
            }
            follow {
              title:title_${language}
              description: description_${language}
              link
              external_link
              button: button_${language}
              background_color
            }
            responsibility {
              title:title_${language}
              description: description_${language}
              link
              external_link
              button: button_${language}
              background_color
            }
          }
          example_section {
            title: title_${language}
            right_card_color
            right_card_description: right_card_description_${language}
            right_sentence: right_sentence_${language}
            right_image_label: right_image_label_${language}
            right_image {
              url
            }
            left_image {
              url
            }
            left_card_background {
              url
            }
            left_card_color
            left_card_title: left_card_title_${language}
            left_card_description: left_card_description_${language}
            left_description: left_description_${language}
          }
          companies_section {
            title: title_${language}
            origin_section {
              title: title_${language}
              background_color
              companies_logos {
                url
              }
            }
            partners_section {
              title: title_${language}
              background_color
              companies_logos {
                url
              }
            }
            trust_section {
              title: title_${language}
              background_color
              companies_logos {
                url
              }
            }
          }
        }
      }
      `,
    })
    return data
  } catch (error) {
    return
  }
}

export default getColaboraLayout
