import React, { FC, useState, useEffect, useCallback } from 'react'
import { Container, SlideButton, ImageContainer, ComponentWrapper, Paginator, Dot } from './styled'
import { ChevronLeft as LeftIcon, ChevronRight as RightIcon } from '@material-ui/icons'

type Props = {
  children: Array<JSX.Element>
}

enum SlideActions {
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS',
}

const ProtagonistaSlider: FC<Props> = ({ children }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [hasSlide, setHasSlide] = useState<boolean>(false)

  useEffect(() => {
    if (children?.length > 1) {
      setHasSlide(true)
    } else {
      setHasSlide(false)
    }
  }, [children])

  const slideNavigation = useCallback(
    (action: SlideActions) => {
      const slideSize = children?.length || 1
      if (action === SlideActions.NEXT) {
        setCurrentIndex((prevState) => (prevState + 1) % slideSize)
      } else if (currentIndex === 0) {
        setCurrentIndex(slideSize - 1)
      } else {
        setCurrentIndex((prevState) => prevState - 1)
      }
    },
    [children, currentIndex]
  )

  useEffect(() => {
    if (children.length === currentIndex) {
      slideNavigation(SlideActions.PREVIOUS)
    }
  }, [children, currentIndex, slideNavigation])

  return (
    <Container>
      {hasSlide && (
        <SlideButton onClick={() => slideNavigation(SlideActions.PREVIOUS)} data-testid="previousButton">
          <LeftIcon className="icon" fontSize="inherit" />
        </SlideButton>
      )}
      <ImageContainer>
        <ComponentWrapper data-testid="componentWrapper">{children[currentIndex]}</ComponentWrapper>
        {hasSlide && (
          <Paginator>
            {children.map((child: JSX.Element, index: number) => (
              <Dot key={index} active={index === currentIndex} onClick={() => setCurrentIndex(index)} />
            ))}
          </Paginator>
        )}
      </ImageContainer>
      {hasSlide && (
        <SlideButton onClick={() => slideNavigation(SlideActions.NEXT)} data-testid="nextButton">
          <RightIcon className="icon" fontSize="inherit" />
        </SlideButton>
      )}
    </Container>
  )
}

export default ProtagonistaSlider
