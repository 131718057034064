import styled from 'styled-components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

type ContainerProps = {
  paddingLeft?: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: ${(props) => (props.paddingLeft ? '3em' : '2rem')};
  padding-right: ${(props) => (props.paddingLeft ? '0' : '2rem')};
  @media (max-width: 768px) {
    padding-left: 0;
    margin-bottom: 20px;
  }
  @media (max-width: 1250px) {
    padding-right: 0;
  }
`

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 0 1rem;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

type FullRowProps = {
  marginVertical?: boolean
}

export const FullRow = styled.div<FullRowProps>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 40px;
  margin: ${({ marginVertical }) => (marginVertical ? '24px 0' : '0')};
  @media (max-width: 600px) {
    padding-left: 0;
    justify-content: center;
  }
`

export const LoadMoreButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.dark};
  border-radius: 4px;
  height: 34px;
  min-width: 180px;
  width: 50%;
  font-size: 14px;
  margin-bottom: 16px;
  padding: 0 35px;
  font-weight: bold;
`

export const LoadMoreButtonIcon = styled(ChevronRightIcon)`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 14px;
`

export const FilterContainer = styled.div`
  width: 40%;
  min-width: 220px;
  @media (max-width: 1600px) {
    width: 25%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`
