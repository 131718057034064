import { Image as ImageType } from 'ducks/aboutLayout'
import { InviteAboutYouSection, FormValues, FormErrors } from 'ducks/postular'
import React, { ChangeEvent, FC, useState } from 'react'
import { FiUser, FiMail } from 'react-icons/fi'
import ReCAPTCHA from 'react-google-recaptcha'
import verifyCaptcha from 'services/verifyCaptcha'

import {
  Container,
  Image,
  Content,
  CheckboxWrapper,
  ButtonWrapper,
  CaptchaWrapper,
  CheckboxContainer,
  CheckboxText,
} from './styled'
import { Title, Text } from 'views/screens/PostularInviteProtaForm/styled'
import { ButtonWithIcon, ButtonIcon } from 'views/components/UI/Buttons'
import HtmlRender from 'views/components/HtmlRender'
import TextInput from 'views/components/TextInput'
import TextArea from 'views/components/UI/TextArea'
import UseAndPrivacyTerms from 'views/components/UseAndPrivacyTerms'
import Checkbox from 'views/components/Checkbox'
import LearnMoreTerms from 'views/components/LearnMoreTerms'

type Props = {
  layout: InviteAboutYouSection
  image: ImageType
  values: FormValues
  errors: FormErrors
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onSubmit: () => void
  setFieldValue: (field: string, value: boolean) => void
}

const AboutYouSection: FC<Props> = (props: Props) => {
  const { layout, image, values, errors, onChange, onSubmit, setFieldValue } = props
  const [disabled, setDisabled] = useState(true)

  const onCaptcha = async (token: string | null) => {
    try {
      if (token) {
        const response = await verifyCaptcha(token)
        if (response) {
          setDisabled(false)
        }
      } else {
        setDisabled(true)
      }
    } catch (error) {
      setDisabled(true)
    }
  }

  const handleSubmit = () => {
    if (!disabled) {
      onSubmit()
    }
  }

  const onCheckTerms = () => {
    setFieldValue('terms_checked', !values.terms_checked)
  }

  const onCheck = () => {
    setFieldValue('checked', !values.terms_checked)
  }

  return (
    <Container>
      <Image background={`url(${image?.url})`} />
      <Content>
        <Title>{layout?.title}</Title>
        <Text>
          <HtmlRender>{layout?.description}</HtmlRender>
        </Text>
        <TextInput
          name="name"
          value={values.name}
          error={errors.name}
          onChange={onChange}
          placeholder={layout?.name?.label}
          icon={FiUser}
        />
        <TextInput
          name="email"
          value={values.email}
          error={errors.email}
          onChange={onChange}
          placeholder={layout?.email?.label}
          icon={FiMail}
        />
        <TextArea
          name="message"
          placeholder={layout?.message?.label}
          rows={5}
          value={values.message}
          onChange={onChange}
        />
        <CheckboxWrapper>
          <UseAndPrivacyTerms
            checked={values.terms_checked}
            error={errors.terms_checked}
            handleCheckbox={onCheckTerms}
          />
          <CheckboxContainer>
            <Checkbox value={values.checked} onChange={onCheck} error={errors.checked} />
            <CheckboxText>{layout.check}</CheckboxText>
          </CheckboxContainer>
          <LearnMoreTerms />
        </CheckboxWrapper>
        <CaptchaWrapper>
          <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_KEY || ''} onChange={onCaptcha} />
        </CaptchaWrapper>
        <ButtonWrapper>
          <ButtonWithIcon mobileFullWidth onClick={handleSubmit} disabled={disabled}>
            {layout?.button}
            <ButtonIcon />
          </ButtonWithIcon>
        </ButtonWrapper>
      </Content>
    </Container>
  )
}

export default AboutYouSection
