import axios from 'axios'
import { Country } from 'ducks/countries'
import { parseCountriesData } from './parser'
import { TranslatedRequest } from 'lib/axios/models'

const countries = async ({ language }: TranslatedRequest): Promise<Array<Country> | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        countries {
          protagonistas {
            id
          }
          movimientos {
            id
          }
          shortname
          image {
            url
          }
          id
          name:name_${language}
        }
      }
      `,
    })
    return parseCountriesData(data)
  } catch (error) {
    return
  }
}

export default countries
