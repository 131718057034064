import styled from 'styled-components'

export const Container = styled.div`
  width: 60%;
  padding: 2rem 0 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 1600px) {
    padding-left: 4rem;
    width: 65%;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
  }
`

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const CardBig = styled.div`
  width: 100%;
  margin-top: 3rem;
  @media screen and (min-width: 1600px) {
    margin-top: 4rem;
  }
  @media screen and (max-width: 450px) {
    margin-top: 0;
    margin-bottom: 2rem;
  }
`

export const CardMedium = styled.div`
  width: 49%;
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 3rem;
  }
  @media screen and (max-width: 450px) {
    margin-bottom: 2rem;
    margin-top: 0;
  }
`

export const Header = styled.div`
  padding-left: 1rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 450px) {
    padding-left: 0;
  }
`
