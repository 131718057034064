import React, { FC, useState, useEffect } from 'react'
import { TeamCard } from 'ducks/elCole'
import { useMediaQuery } from '@material-ui/core'

import { Container, Image, Content, Title, Text, LineSeparatorContainer, Subtitle, Label, Email } from './styled'
import { FloatingCard, Row } from 'views/components/UI/Contents'
import LineSeparator from 'views/components/UI/LineSeparator'
import HtmlRender from 'views/components/HtmlRender'
import CopyButton from 'views/components/CopyButton'

type Props = {
  layout: TeamCard
}

const DisplayCard: FC<Props> = ({ layout }: Props) => {
  const { image, title, description, email, subtitle, label } = layout
  const [emailToDisplay, setEmailToDisplay] = useState<string>('')
  const matches = useMediaQuery('(max-width: 1024px)')

  useEffect(() => {
    if (email) {
      if (matches) {
        if (email.length > 25) {
          const emailShort = email.slice(0, 25)
          setEmailToDisplay(`${emailShort}...`)
        } else if (email) {
          setEmailToDisplay(email)
        }
      } else {
        setEmailToDisplay(email)
      }
    }
  }, [email, matches])

  return (
    <FloatingCard rounded>
      <Container>
        <Image src={image?.url} />
        <Content>
          <Label>{label}</Label>
          <div>
            <Title>{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
            <LineSeparatorContainer>
              <LineSeparator />
            </LineSeparatorContainer>
            <Text>
              <HtmlRender>{description}</HtmlRender>
            </Text>
          </div>
          <div data-testid="emailContainer">
            {email && (
              <Row>
                <Email>{emailToDisplay}</Email>
                <CopyButton text={email} withFeedback size={12} />
              </Row>
            )}
          </div>
        </Content>
      </Container>
    </FloatingCard>
  )
}

export default DisplayCard
