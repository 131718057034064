import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  padding: 4rem;
  @media screen and (max-width: 450px) {
    padding: 2rem 1rem;
  }
`

interface CardProps extends BackgroundProps {
  color?: string
}

export const Card = styled.div<CardProps>`
  ${background};
  position: relative;
  width: 100%;
  display: flex;
  padding: 2rem 1rem 4rem;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-color: ${({ theme, color }) => (color ? color : theme.colors.defaultCardBackground)};
  @media screen and (max-width: 768px) {
    padding: 2rem 2rem 4rem;
  }
  @media screen and (max-width: 450px) {
    padding: 1rem 1rem 3rem;
  }
`

export const CardBody = styled.div`
  width: 70%;
  @media screen and (min-width: 1600px) {
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const CardTitle = styled.p`
  font-weight: bold;
  font-size: 42px;
  line-height: 50px;
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: 20px;
  @media screen and (min-width: 1600px) {
    font-size: 48px;
    line-height: 55px;
  }
  @media screen and (max-width: 450px) {
    font-size: 22px;
    line-height: 30px;
  }
`

export const CardText = styled.div`
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.135px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 450px) {
    font-size: 14px;
    line-height: 20px;
  }
`

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 15%;
  bottom: -4%;
  @media screen and (min-width: 1600px) {
    bottom: -8%;
    right: 25%;
  }
  @media screen and (max-width: 1024px) {
    bottom: -3%;
  }
  @media screen and (max-width: 450px) {
    width: 90%;
    right: 5%;
    bottom: -8%;
  }
`

export const Recommendations = styled.div`
  width: 100%;
  margin-top: 60px;
`
