import { SagaIterator } from '@redux-saga/core'
import { all, put, call, takeLatest } from 'redux-saga/effects'
import { HOME_CONTENT, CONTENT, INVITE_FORM_CONTENT, SUBMIT_PROTAGONISTA_SUGGESTION } from 'ducks/postular/actionTypes'
import { getPostularHome, getPostularInviteProtaForm, submitProtagonistaSuggestion } from 'services/postular'
import { Action } from 'lib/redux/models'

function* loadHome(action: Action) {
  yield put(CONTENT.request())
  try {
    const { data } = yield call(getPostularHome, action?.payload)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* loadInviteProtaForm(action: Action) {
  yield put(CONTENT.request())
  try {
    const { data } = yield call(getPostularInviteProtaForm, action?.payload)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* sendProtagonistaSuggestion(action: Action) {
  try {
    const success = yield call(submitProtagonistaSuggestion, action?.payload)
    if (success) {
      yield put(CONTENT.success({ invite_success: true }))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  }
}

export function* postularSagas(): SagaIterator {
  yield all([
    takeLatest(HOME_CONTENT.TRIGGER, loadHome),
    takeLatest(INVITE_FORM_CONTENT.TRIGGER, loadInviteProtaForm),
    takeLatest(SUBMIT_PROTAGONISTA_SUGGESTION.TRIGGER, sendProtagonistaSuggestion),
  ])
}
