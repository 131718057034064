import styled from 'styled-components'

export const Title = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 65px;
  color: rgba(0, 0, 0, 0.87);
`
export const Container = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  text-align: justify;
  padding: 50px 100px;
`
