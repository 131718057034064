import { SagaIterator } from '@redux-saga/core'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { INVITE_CONTENT, PROFILE_CONTENT, CONTENT } from 'ducks/successLayout'
import { Action } from 'lib/redux/models'
import { getSuccessInviteLayout, getSuccessProfileLayout } from 'services/successLayout'

function* loadSuccessInviteLayout(action: Action) {
  yield put(CONTENT.request())
  try {
    const data = yield call(getSuccessInviteLayout, action?.payload)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* loadSuccessProfileLayout(action: Action) {
  yield put(CONTENT.request())
  try {
    const data = yield call(getSuccessProfileLayout, action?.payload)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

export function* successLayoutSagas(): SagaIterator {
  yield all([
    takeLatest(INVITE_CONTENT.TRIGGER, loadSuccessInviteLayout),
    takeLatest(PROFILE_CONTENT.TRIGGER, loadSuccessProfileLayout),
  ])
}
