import React, { FC } from 'react'
import { IconType } from 'react-icons'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

import { Container, IconWrapper, ProviderContainer } from './styled'

type Props = {
  label: string
  backgroundColor?: string
  backgroundIcon?: string
  textColor?: string
  icon?: IconType
  provider?: string
}

const RegisterButton: FC<Props> = ({ label, backgroundColor, backgroundIcon, textColor, icon, provider }: Props) => {
  const { sendEvent } = useGoogleAnalytics()

  const renderButton = () => {
    return (
      <>
        {icon && <IconWrapper backgroundColor={backgroundIcon}>{icon?.call(null, { size: '20px' })}</IconWrapper>}
        {label}
      </>
    )
  }

  const sendEventToGoogleAnalytics = () => {
    switch (provider) {
      case 'facebook':
        return sendEvent({
          label: 'Facebook',
          category: 'Register',
          action: 'User is trying to register using facebook account.',
        })
      case 'google':
        return sendEvent({
          label: 'Google',
          category: 'Register',
          action: 'User is trying to register using google account.',
        })
      default:
        return sendEvent({
          label: 'Email',
          category: 'Register',
          action: 'User is trying to register using email.',
        })
    }
  }

  return (
    <div onClick={sendEventToGoogleAnalytics}>
      {provider !== 'email' ? (
        <ProviderContainer
          href={`${process.env.REACT_APP_MAIN_API}/connect/${provider}`}
          backgroundColor={backgroundColor}
          textColor={textColor}
        >
          {renderButton()}
        </ProviderContainer>
      ) : (
        <Container backgroundColor={backgroundColor} textColor={textColor} to="/register">
          {renderButton()}
        </Container>
      )}
    </div>
  )
}

export default RegisterButton
