import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { IMovimientosLayout } from './../models'
import * as TYPES from './../actionTypes'

export const initialState: IMovimientosLayout = fromJS({
  path: {},
  movimientos: [],
  filter: {},
})

const movimientosLayoutReducer: Reducer<IMovimientosLayout> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET:
      return state.merge(action.payload)
    case TYPES.CONTENT.TRIGGER:
      return state.set('loading', true)
    case TYPES.CONTENT.SUCCESS:
      return state.merge(action.payload)
    case TYPES.CONTENT.FAILURE:
      return state.set('error', action.payload)
    case TYPES.CONTENT.FULFILL:
      return state.set('loading', false)
    default:
      return state
  }
}

export default movimientosLayoutReducer
