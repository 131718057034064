import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  position: relative;
`

type ContentProps = {
  open?: boolean
}

export const Content = styled.div<ContentProps>`
  z-index: 10;
  position: absolute;
  top: 50px;
  right: 0px;
  width: 300px;
  background: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 20px 0;
  clip-path: circle(${({ open }) => (open ? '150% at 90% -10%' : '0 at 90% -10%')});
  -webkit-clip-path: circle(${({ open }) => (open ? '150% at 90% -10%' : '0 at 90% -10%')});
  transition: all 0.2s ease-out;
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  width: 100%;
  border-bottom: 1px solid black;
  text-align: left;
  padding-bottom: 20px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 34px;
    line-height: 50px;
  }
`

export const Header = styled.div`
  width: 100%;
  padding: 0 25px;
`

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.dark};
`

export const MenuButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.dark};
  padding: 10px 25px;
  font-size: 16px;
  &:hover {
    background-color: rgba(206, 206, 206, 0.2);
  }
`

export const MenuLink = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.dark};
  padding: 10px 25px;
  font-size: 16px;
  &:hover {
    background-color: rgba(206, 206, 206, 0.2);
  }
`

export const MenuIcon = styled.div`
  margin-right: 10px;
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
`
