import React, { FC, useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { FiMail, FiCheck } from 'react-icons/fi'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

import { Container, TextInputContainer } from './styled'
import HtmlRender from 'views/components/HtmlRender'
import TextInput from 'views/components/TextInput'
import { ButtonIcon, ButtonWithIcon, SuccessFeedbackButton } from 'views/components/UI/Buttons'
import LoadingButtonAnimation from 'views/components/LoadingButtonAnimation'
import SubscribeTerms from 'views/components/SubscribeTerms'
import LearnMoreTerms from 'views/components/LearnMoreTerms'

export interface FormValues {
  email: string
}

export interface FormErrorValues {
  email?: string
}

const initialValues: FormValues = {
  email: '',
}
const validationSchema = yup.object({
  email: yup.string().email().required('Email is Required'),
})

const initialErrors: FormErrorValues = {}

type Props = {
  submit: ({ EMAIL }: { EMAIL: string }) => void
  text: string
  button: string
  placeholder: string
  status?: string
  errorMessage?: string
  feedbackColor?: string
}

const Form: FC<Props> = (props: Props) => {
  const { submit, text, button, placeholder, status, errorMessage = '', feedbackColor } = props
  const { sendEvent, trackModal } = useGoogleAnalytics()
  const [submitedSuccess, setsubmitedSuccess] = useState(false)
  const [checked, setChecked] = useState(false)

  const onSubmit = (values: FormValues) => {
    const { email } = values
    submit({
      EMAIL: email,
    })
    sendEvent({
      label: 'La Tiendita',
      category: 'Attempt to Subscription',
      action: 'Tried to subscribe into La Tiendita Newsletter',
    })
  }

  const { errors, values, handleChange, handleSubmit, resetForm } = useFormik<FormValues>({
    initialValues,
    onSubmit,
    initialErrors,
    validationSchema,
  })

  const handleError = () => {
    if (status === 'error') {
      return errorMessage ? errorMessage : ''
    }
    return errors.email
  }

  const submitWithCheck = () => {
    if (checked) {
      handleSubmit()
    }
  }

  const renderButton = () => {
    switch (status) {
      case 'success':
        if (submitedSuccess) {
          return (
            <SuccessFeedbackButton>
              <FiCheck />
            </SuccessFeedbackButton>
          )
        } else {
          return (
            <ButtonWithIcon mobileFullWidth onClick={submitWithCheck}>
              {button}
              <ButtonIcon />
            </ButtonWithIcon>
          )
        }
      case 'sending':
        return (
          <ButtonWithIcon mobileFullWidth>
            <LoadingButtonAnimation />
          </ButtonWithIcon>
        )
      default:
        return (
          <ButtonWithIcon mobileFullWidth onClick={submitWithCheck}>
            {button}
            <ButtonIcon />
          </ButtonWithIcon>
        )
    }
  }

  const handleCheckbox = () => {
    setChecked((prevState) => !prevState)
  }

  useEffect(() => {
    if (status === 'success') {
      resetForm()
      setsubmitedSuccess((prevState) => {
        if (!prevState) {
          setTimeout(() => setsubmitedSuccess(false), process.env.REACT_APP_TIMEOUT_FEEDBACK || 10000)
        }
        return !prevState
      })
    }
  }, [status, resetForm])

  useEffect(() => {
    if (status === 'success') {
      trackModal('/success-tiendita-subscription')
      sendEvent({
        label: 'La Tiendita',
        category: 'Subscription',
        action: 'Subscribed into La Tiendita Newsletter',
      })
    }
  }, [status, sendEvent, trackModal])

  return (
    <Container>
      <HtmlRender>{text}</HtmlRender>
      <TextInputContainer>
        <TextInput
          placeholder={placeholder}
          name="email"
          value={values.email}
          error={handleError()}
          icon={FiMail}
          onChange={handleChange}
          color="#000"
          noMargin
          feedbackColor={feedbackColor}
        />
      </TextInputContainer>
      <SubscribeTerms checkboxColor="#000" checked={checked} handleCheckbox={handleCheckbox} />
      <LearnMoreTerms />
      {renderButton()}
    </Container>
  )
}

export default Form
