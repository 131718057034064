import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { layoutDataSelector } from 'ducks/layoutData/selectors'

import {
  Button,
  ButtonsContainer,
  ButtonLink,
  Container,
  SettingsContainer,
  SettingsText,
  Text,
  SettingsOption,
  SettingsOptionText,
} from './styled'
import HtmlRender from 'views/components/HtmlRender'
import Checkbox from '../Checkbox'

type Props = {
  onAccept: () => void
}

type CookiesSettings = {
  ga: boolean
  youtube: boolean
}
const CookieConsent: FC<Props> = ({ onAccept }) => {
  const layoutState = useSelector(layoutDataSelector)
  const cookieBanner = layoutState.get('cookieBanner')
  const [visible, setVisible] = useState(true)
  const [settingsVisible, setSettingsVisible] = useState(false)
  const [cookies, setCookies] = useState<CookiesSettings>({
    ga: false,
    youtube: false,
  })

  const handleCookieAwareness = (agreement: boolean) => {
    localStorage.setItem('cookie_awareness', agreement.toString())
    setVisible(false)
  }

  const acceptCookies = () => {
    handleCookieAwareness(true)
    onAccept()
  }

  const denyCookies = () => handleCookieAwareness(false)

  const showCookiesSettings = () => {
    setSettingsVisible(true)
  }

  const handleCheckOption = (key: keyof CookiesSettings) => {
    setCookies((prevState) => ({
      ...prevState,
      [key]: !cookies[key],
    }))
  }

  const acceptCustomCookies = () => {
    if (cookies.ga) {
      onAccept()
    }
    if (cookies.youtube) {
      localStorage.setItem('cookie_youtube_consent', 'true')
    }
    handleCookieAwareness(true)
  }

  return visible ? (
    <Container>
      {settingsVisible ? (
        <SettingsContainer>
          <SettingsText>{cookieBanner?.get('settings_title')}</SettingsText>
          {cookieBanner?.get('options')?.map((option) => (
            <SettingsOption key={option.get('value')}>
              <Checkbox
                value={cookies[option.get('value') as keyof CookiesSettings]}
                onChange={() => handleCheckOption(option.get('value') as keyof CookiesSettings)}
                color="#fff"
              />
              <SettingsOptionText>{option.get('label')}</SettingsOptionText>
            </SettingsOption>
          ))}
          <Button onClick={acceptCustomCookies} outline>
            {cookieBanner?.get('confirm_button')}
          </Button>
        </SettingsContainer>
      ) : (
        <Text id="banner-cookie">
          <HtmlRender>{cookieBanner?.get('description')}</HtmlRender>
          <ButtonLink onClick={showCookiesSettings}>{cookieBanner?.get('custom_button')}</ButtonLink>
        </Text>
      )}
      <ButtonsContainer>
        <Button onClick={acceptCookies}>{cookieBanner?.get('accept_button')}</Button>
        <Button onClick={denyCookies} outline>
          {cookieBanner?.get('deny_button')}
        </Button>
      </ButtonsContainer>
    </Container>
  ) : null
}

export default CookieConsent
