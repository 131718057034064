import styled from 'styled-components'

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 4px;
  background-color: ${({ color, theme }) => (color ? color : theme.colors.primary)};
`

export default Separator
