import React, { FC, useContext } from 'react'
import { Container, Background, Title, Subtitle, BorderLeft } from './styled'
import { Link } from 'ducks/home'
import { Block } from 'views/components/UI/Contents'
import { LinkButton, ButtonIcon, ExternalLinkButton } from 'views/components/UI/Buttons'
import { Record } from 'immutable'
import { ThemeContext } from 'styled-components'

interface Props {
  title: string
  subtitle: string
  background?: string
  image?: string
  link: Record<Link>
  button_mobile?: string
  button?: string
}

const CardTitle: FC<Props> = ({ background = '', title, link, subtitle, button_mobile }) => {
  const { colors } = useContext(ThemeContext)
  const renderLinkButton = () => {
    if (link?.get('external')) {
      return (
        <ExternalLinkButton href={link?.get('value')} target="blank" color="transparent" textColor={colors.dark}>
          {button_mobile}
          <ButtonIcon />
        </ExternalLinkButton>
      )
    } else {
      return (
        <LinkButton to={link?.get('value')} color="transparent" textColor={colors.dark}>
          {button_mobile}
          <ButtonIcon />
        </LinkButton>
      )
    }
  }

  return (
    <Container>
      <Block display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <BorderLeft>&nbsp;</BorderLeft>
        <Background src={background} />
        <Block width="48%" marginLeft="12px">
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Block>
      </Block>
      {link && renderLinkButton()}
    </Container>
  )
}

export default CardTitle
