import { SagaIterator } from '@redux-saga/core'
import * as TYPES from 'ducks/categories/actionTypes'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import categories from 'services/categories'
import { Action } from 'lib/redux/models'

function* getCategories(action: Action) {
  yield put(TYPES.CONTENT.request())
  try {
    const fetchedCategories = yield call(categories, action?.payload)
    if (fetchedCategories) {
      yield put(TYPES.CONTENT.success({ categories: fetchedCategories }))
    }
  } catch (error) {
    yield put(TYPES.CONTENT.failure(error.message))
  } finally {
    yield put(TYPES.CONTENT.fulfill())
  }
}

export function* categoriesSagas(): SagaIterator {
  yield all([takeEvery(TYPES.CONTENT.TRIGGER, getCategories)])
}
