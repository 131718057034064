import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Container = styled.div<ColorProps>`
  ${color};
  padding-bottom: 5rem;
  @media screen and (max-width: 450px) {
    padding-bottom: 2rem;
  }
`

export const Content = styled.div`
  padding: 1rem 4rem;
  @media screen and (max-width: 450px) {
    padding: 1rem 1rem 0;
  }
`

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: -40%;
  right: 5%;
  @media screen and (max-width: 768px) {
    bottom: -45%;
  }
  @media screen and (max-width: 450px) {
    bottom: -55%;
    width: 90%;
  }
`

export const BottomBannerContainer = styled.div`
  width: 100%;
  padding: 1rem 4rem;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 450px) {
    padding: 1rem 1rem 3rem;
  }
`

export const Description = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 450px) {
    font-size: 14px;
    line-height: 20px;
  }
`

export const PathIndicatorContainer = styled.div`
  padding: 0 1rem;
  @media screen and (max-width: 450px) {
    padding: 0;
  }
`

export const CancelButtonWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
`
