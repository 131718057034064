import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { layoutDataSelector } from 'ducks/layoutData'

import { Container, TermsContent, Text, TextLink } from './styled'
import Checkbox from '../Checkbox'

type Props = {
  checked: boolean
  handleCheckbox: () => void
  checkboxColor?: string
  error?: string
}

const UseAndPrivacyTerms: FC<Props> = ({ checked, handleCheckbox, checkboxColor, error }) => {
  const layoutState = useSelector(layoutDataSelector)
  const termsAndPolicy = layoutState.get('termsAndPolicy')
  return (
    <Container>
      <Checkbox error={error} color={checkboxColor} value={checked} onChange={handleCheckbox} />
      <TermsContent>
        <Text>
          {termsAndPolicy?.getIn(['use_and_privacy_policy', 'part_one'])}{' '}
          <TextLink target="blank" to={termsAndPolicy?.getIn(['use_and_privacy_policy', 'use_link']) || ''}>
            {termsAndPolicy?.getIn(['use_and_privacy_policy', 'use_link_label'])}
          </TextLink>{' '}
          {termsAndPolicy?.getIn(['use_and_privacy_policy', 'part_two'])}{' '}
          <TextLink target="blank" to={termsAndPolicy?.getIn(['use_and_privacy_policy', 'privacy_link']) || ''}>
            {termsAndPolicy?.getIn(['use_and_privacy_policy', 'privacy_link_label'])}
          </TextLink>
        </Text>
      </TermsContent>
    </Container>
  )
}

export default UseAndPrivacyTerms
