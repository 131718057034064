import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  min-height: 88vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1024px) {
    min-height: 69vh;
  }
  @media screen and (max-width: 768px) {
    min-height: 57vh;
  }
`

export const Form = styled.div`
  width: 550px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 450px) {
    width: 90%;
  }
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 34px;
  line-height: 49px;
  color: ${({ theme }) => theme.colors.dark};
`

export const ButtonContainer = styled.div`
  margin: 10px 0 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

export const Text = styled.p`
  font-size: 20px;
  margin-right: 10px;
  line-height: 27px;
  color: ${({ theme }) => theme.colors.dark};
`

export const LinkText = styled(Link)`
  font-size: 20px;
  line-height: 27px;
  color: ${({ theme }) => theme.colors.formLink};
`
