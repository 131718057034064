import React, { FC } from 'react'
import { useLocation, Redirect } from 'react-router-dom'

import { Container, Card, CardBody, CardText, CardTitle, ButtonWrapper } from './styled'
import HtmlRender from 'views/components/HtmlRender'
import { LinkButton, ButtonIcon } from 'views/components/UI/Buttons'

type LocationState = {
  success?: boolean
  title?: string
  message?: string
  card_color?: string
  card_background?: string
  link?: string
  button?: string
}

const Success: FC = () => {
  const location = useLocation<LocationState>()

  return !location?.state?.success ? (
    <Redirect to="/" />
  ) : (
    <Container>
      <Card color={location?.state?.card_color} backgroundImage={`url(${location?.state?.card_background})`}>
        <CardBody>
          <CardTitle>{location?.state?.title}</CardTitle>
          <CardText>
            <HtmlRender>{location?.state?.message}</HtmlRender>
          </CardText>
        </CardBody>
        <ButtonWrapper>
          {location?.state?.link && (
            <LinkButton to={location?.state?.link || ''} mobileFullWidth>
              {location?.state?.button}
              <ButtonIcon />
            </LinkButton>
          )}
        </ButtonWrapper>
      </Card>
    </Container>
  )
}

export default Success
