import { LayoutData, RequestedLayoutData } from 'ducks/layoutData'

export const parseLayoutData = (data: RequestedLayoutData): LayoutData => ({
  header: {
    ...data.header,
    logo: data?.header?.logo?.url,
    languages: data.header.Languages,
  },
  footer: {
    ...data.footer,
  },
  userMenu: data.userMenu,
  termsAndPolicy: data.termsAndPolicy,
  cookieBanner: data.cookieBanner,
})
