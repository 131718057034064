import { SagaIterator } from '@redux-saga/core'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { CONTENT } from 'ducks/profileLayout'
import { Action } from 'lib/redux/models'
import getProfileLayout from 'services/getProfileLayout'

function* loadLayout(action: Action) {
  yield put(CONTENT.request())
  try {
    const { data } = yield call(getProfileLayout, action?.payload)
    if (data) {
      yield put(CONTENT.success(data.profileLayout))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

export function* profileLayoutSagas(): SagaIterator {
  yield all([takeLatest(CONTENT.TRIGGER, loadLayout)])
}
