import { Reducer } from 'redux'
import { fromJS, mergeDeepWith } from 'immutable'
import { IBlog } from './../models'
import * as TYPES from './../actionTypes'

export const initialState: IBlog = fromJS({})

const blogReducer: Reducer<IBlog> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET:
      return state.mergeDeep(action.payload)
    case TYPES.CONTENT.FULFILL:
      return state.set('loading', false)
    case TYPES.CONTENT.FAILURE:
      return state.set('error', action.payload)
    case TYPES.CONTENT.REQUEST:
      return state.set('loading', true)
    case TYPES.CONTENT.SUCCESS:
      return state.merge(action.payload)
    case TYPES.MOTIVATION.SUCCESS:
      return state.mergeDeep(action.payload)
    case TYPES.SUBMIT_MOTIVATION.SUCCESS:
      return mergeDeepWith((oldValue, newValue) => oldValue + newValue, state, action.payload)
    default:
      return state
  }
}

export default blogReducer
