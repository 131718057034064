import { SagaIterator } from '@redux-saga/core'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { Action } from 'lib/redux/models'
import { CONTENT } from '../actionTypes'
import getPages from 'services/pages'

function* getPage(action: Action): any {
  yield put(CONTENT.request())
  try {
    const page = yield call(getPages, action.payload)
    if (page) {
      yield put(CONTENT.success(page))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

export function* pagesSagas(): SagaIterator {
  yield all([takeLatest(CONTENT.TRIGGER, getPage)])
}
