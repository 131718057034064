import styled from 'styled-components'

const ShareThumbnail = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
`

export default ShareThumbnail
