import axios from 'axios'
import { Contacto } from 'ducks/contacto/models'
import { TranslatedRequest } from 'lib/axios/models'

const contacto = async ({ language }: TranslatedRequest): Promise<Contacto | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        contacto {
          title: title_${language}
          main_image{
            url
          }
          description: description_${language}
          section_title: section_title_${language}
          visibility {
            title: title_${language}
            subtitle: subtitle_${language}
            description: description_${language}
            button: button_${language}
            link
            background_color
            title_color
          }
          guide {
            title: title_${language}
            subtitle: subtitle_${language}
            description: description_${language}
            button: button_${language}
            link
            background_color
            title_color
          }
          card {
            section {
              title: title_${language}
              subtitle: subtitle_${language}
              description: description_${language}
              button: button_${language}
              link
              background_color
              title_color
            }
            image {
              url
            }
            description: description_${language}
            button: button_${language}
            link
          }
          form {
            title: title_${language}
            subtitle: subtitle_${language}
            terms: terms_${language}
            button: button_${language}
            success_message: success_message_${language}
            terms_link
            name {
              label: label_${language}
            }
            email {
              label: label_${language}
            }
            confirm_email {
              label: label_${language}
            }
            phone {
              label: label_${language}
            }
            query_type {
              label: label_${language}
            }
            query_type_options {
              value
              label: label_${language}
            }
            message {
              label: label_${language}
            }
          }
        }
      }
      `,
    })
    return data.contacto
  } catch (error) {
    return
  }
}

export default contacto
