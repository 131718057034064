import styled from 'styled-components'
import { BackgroundProps, background } from 'styled-system'

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  @media screen and (max-width: 1024px) {
    margin: 20px 0;
  }
`

export const Content = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3rem 2.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  -moz-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  border-radius: 4px;
  @media screen and (max-width: 1250px) {
    width: 80%;
    padding: 2rem;
  }
  @media screen and (min-width: 1600px) {
    width: 70%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    padding: 2rem 1rem;
  }
`

export const Title = styled.p`
  font-family: PTSerif-Caption;
  font-size: 18px;
  line-height: normal;
  letter-spacing: 1.25px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 1250px) {
    text-align: center;
  }
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  position: relative;
`

export const SectionLine = styled.div`
  width: 45%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.black};
  position: absolute;
  left: -6.5rem;
  top: 12px;
  @media screen and (max-width: 1250px) {
    display: none;
  }
`

export const Description = styled.div`
  width: 70%;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.09px;
  margin: 2rem 0;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 1250px) {
    width: 75%;
  }
  @media screen and (min-width: 1600px) {
    width: 85%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const Image = styled.div<BackgroundProps>`
  right: 7.7%;
  position: absolute;
  width: 31.3%;
  z-index: 1;
  height: 90%;
  max-height: 700px;
  ${background}
  background-position-x: right;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (min-width: 1600px) {
    right: 2%;
    width: 35.5%;
  }
  @media screen and (max-width: 1250px) {
    right: 1%;
    width: 38%;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`
