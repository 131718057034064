import React, { FC, useState } from 'react'
import { MdClose } from 'react-icons/md'

import { Container, CloseButton } from './styled'

type Props = {
  closeable?: boolean
  backgroundColor?: string
  children?: JSX.Element | Array<JSX.Element>
  visible: boolean
}

const Banner: FC<Props> = (props: Props) => {
  const { closeable, backgroundColor, children, visible } = props
  const [open, setOpen] = useState(true)

  const closeBanner = () => {
    setOpen(false)
  }

  return visible && open ? (
    <Container color={backgroundColor}>
      {closeable && (
        <CloseButton onClick={closeBanner} data-testid="closeableButton">
          <MdClose />
        </CloseButton>
      )}
      {children}
    </Container>
  ) : null
}

export default Banner
