import { HomeIntro } from 'ducks/elCole'
import React, { FC } from 'react'

import {
  Container,
  LeftContainer,
  RightContainer,
  Image,
  Title,
  Subtitle,
  Text,
  CardBody,
  CardImage,
  CardContent,
} from './styled'
import HtmlRender from 'views/components/HtmlRender'
import { Card } from 'views/components/UI/Contents'

type Props = {
  layout?: HomeIntro
}

const IntroSection: FC<Props> = (props: Props) => {
  const { layout } = props

  return (
    <Container>
      <LeftContainer>
        {layout?.left_content?.image && <Image src={layout?.left_content?.image?.url} />}
        <Title>{layout?.left_content?.title}</Title>
        <Subtitle>
          <HtmlRender>{layout?.left_content?.description}</HtmlRender>
        </Subtitle>
      </LeftContainer>
      <RightContainer>
        <Card color={layout?.right_content?.card_color}>
          <CardBody>
            <CardImage background={`url(${layout?.right_content?.image?.url})`} />
            <CardContent>
              <Title>{layout?.right_content?.title}</Title>
              <Subtitle marginVertical>{layout?.right_content?.subtitle}</Subtitle>
              <Text>
                <HtmlRender>{layout?.right_content?.description}</HtmlRender>
              </Text>
            </CardContent>
          </CardBody>
        </Card>
      </RightContainer>
    </Container>
  )
}

export default IntroSection
