import { SagaIterator } from '@redux-saga/core'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { CONTENT } from 'ducks/loading'
import getLoading from 'services/getLoading'

function* loadLoading() {
  try {
    const data = yield call(getLoading)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  }
}

export function* loadingSagas(): SagaIterator {
  yield all([takeLatest(CONTENT.TRIGGER, loadLoading)])
}
