import { SagaIterator } from '@redux-saga/core'
import { all, put, call, takeLatest } from 'redux-saga/effects'
import {
  LoginAction,
  RegisterAction,
  SocialLoginAction,
  CheckCreateProfileAction,
  GetProfileAction,
} from '../models/actions'
import * as TYPES from '../actionTypes'
import login, { socialLogin } from 'services/login'
import { requestCreatedProfile, getProfile, submitUpdateProfile, requestUpdateProfile } from 'services/user'
import register from 'services/register'
import { SET_LOADING } from 'ducks/loading'
import { Action } from 'lib/redux/models'

function* loginProtagonista(action: LoginAction) {
  yield put(SET_LOADING.trigger())
  try {
    const data = yield call(login, action?.payload)
    if (data) {
      yield put(TYPES.LOGIN.success({ ...data, provider: 'email' }))
    }
  } catch (err) {
    yield put(TYPES.LOGIN.failure(err.message))
  } finally {
    yield put(SET_LOADING.fulfill())
  }
}

function* registerProtagonista(action: RegisterAction) {
  yield put(SET_LOADING.trigger())
  try {
    const data = yield call(register, action.payload)
    if (data) {
      yield put(TYPES.REGISTER.success(data))
    }
  } catch (error) {
    yield put(TYPES.REGISTER.failure(error.message))
  } finally {
    yield put(SET_LOADING.fulfill())
  }
}

function* socialLoginProtagonista(action: SocialLoginAction) {
  yield put(SET_LOADING.trigger())
  try {
    const data = yield call(socialLogin, action?.payload)
    if (data) {
      yield put(TYPES.LOGIN.success(data))
    }
  } catch (error) {
    yield put(TYPES.LOGIN.failure(error.message))
  } finally {
    yield put(SET_LOADING.fulfill())
  }
}

function* logoutProtagonista() {
  try {
    localStorage.removeItem('token')
    yield put(TYPES.LOGOUT.success())
  } catch (error) {
    yield put(TYPES.LOGOUT.failure(error.message))
  }
}

function* checkCreateProfile(action: CheckCreateProfileAction) {
  yield put(SET_LOADING.trigger())
  try {
    const data = yield call(requestCreatedProfile, action.payload)
    yield put({ type: TYPES.SET, payload: { createdProfile: data } })
  } catch (error) {
  } finally {
    yield put(SET_LOADING.fulfill())
  }
}

function* checkUpdateProfile(action: CheckCreateProfileAction) {
  yield put(SET_LOADING.trigger())
  try {
    const data = yield call(requestUpdateProfile, action.payload)
    yield put({ type: TYPES.SET, payload: { updatedProfile: data } })
  } catch (error) {
  } finally {
    yield put(SET_LOADING.fulfill())
  }
}

function* loadProfile(action: GetProfileAction) {
  yield put(SET_LOADING.trigger())
  yield put(TYPES.LOGIN.request())
  try {
    const { protagonista } = yield call(getProfile, action.payload)
    if (protagonista) {
      yield put({
        type: TYPES.SET,
        payload: {
          profile: protagonista,
        },
      })
    } else {
      yield put({
        type: TYPES.SET,
        payload: {
          profile: null,
        },
      })
    }
  } catch (error) {
  } finally {
    yield put(SET_LOADING.fulfill())
    yield put(TYPES.LOGIN.fulfill())
  }
}

function* updateProfile(action: Action) {
  yield put(SET_LOADING.trigger())
  try {
    const data = yield call(submitUpdateProfile, action?.payload)
    if (data) {
      yield put({ type: TYPES.SET, payload: { update_success: true } })
    }
  } catch (error) {
    yield put(TYPES.LOGIN.failure(error.message))
  } finally {
    yield put(SET_LOADING.fulfill())
  }
}

export function* userSagas(): SagaIterator {
  yield all([
    takeLatest(TYPES.LOGIN.TRIGGER, loginProtagonista),
    takeLatest(TYPES.REGISTER.TRIGGER, registerProtagonista),
    takeLatest(TYPES.SOCIAL_LOGIN.TRIGGER, socialLoginProtagonista),
    takeLatest(TYPES.LOGOUT.TRIGGER, logoutProtagonista),
    takeLatest(TYPES.CHECK_CREATE_PROFILE, checkCreateProfile),
    takeLatest(TYPES.CHECK_UPDATE_PROFILE, checkUpdateProfile),
    takeLatest(TYPES.GET_PROFILE, loadProfile),
    takeLatest(TYPES.UPDATE_PROFILE.TRIGGER, updateProfile),
  ])
}
