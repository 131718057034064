import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { protagonistasSelector, CONTENT as PROTA_CONTENT } from 'ducks/protagonistas'
import { protagonistasLayoutSelector, CONTENT as PROTA_LAYOUT_CONTENT } from 'ducks/protagonistasLayout'
import { useLanguage } from 'hooks/useLanguage'

import { Container, Card, CardBody, CardText, CardTitle, ButtonWrapper, Recommendations } from './styled'
import HtmlRender from 'views/components/HtmlRender'
import { LinkButton, ButtonIcon } from 'views/components/UI/Buttons'
import OtherProtagonistas from 'views/components/OtherProtagonistas'

type Props = {
  title: string
  message: string
  card_color?: string
  card_background?: string
  link?: string
  button?: string
}

const Success: FC<Props> = (props: Props) => {
  const { title, message, card_color, card_background, link, button } = props
  const dispatch = useDispatch()
  const { currentLanguage } = useLanguage()
  const protagonistas = useSelector(protagonistasSelector)
  const protagonistasLayout = useSelector(protagonistasLayoutSelector)

  useEffect(() => {
    dispatch(PROTA_CONTENT.trigger({ language: currentLanguage, countries: [], categories: [] }))
    dispatch(PROTA_LAYOUT_CONTENT.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage])

  return (
    <Container>
      <Card color={card_color} backgroundImage={`url(${card_background})`}>
        <CardBody>
          <CardTitle>{title}</CardTitle>
          <CardText>
            <HtmlRender>{message}</HtmlRender>
          </CardText>
        </CardBody>
        <ButtonWrapper>
          {link && (
            <LinkButton to={link || ''} mobileFullWidth>
              {button}
              <ButtonIcon />
            </LinkButton>
          )}
        </ButtonWrapper>
      </Card>
      <Recommendations>
        <OtherProtagonistas
          protagonistas={protagonistas.get('protagonistas')}
          loading={protagonistas.get('loading')}
          button={protagonistasLayout.getIn(['protagonistas', 'load_more_button'])}
          message={protagonistasLayout.getIn(['protagonistas', 'load_more'])}
        />
      </Recommendations>
    </Container>
  )
}

export default Success
