import React, { FC } from 'react'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import { FiFacebook, FiTwitter, FiLinkedin, FiLink } from 'react-icons/fi'
import { MdClose } from 'react-icons/md'
import { Container, Title, Button, ButtonWrapper, TitleContainer, SocialText } from './styled'
import CopyToClipboard from 'react-copy-to-clipboard'
import { ShareList as ShareListType } from 'ducks/protagonistaLayout'

type Props = {
  onClose: () => void
  url: string
  layout: ShareListType
  fullWidth?: boolean
}

const ShareList: FC<Props> = ({ onClose, url, layout, fullWidth }: Props) => {
  return (
    <Container fullWidth={fullWidth}>
      <TitleContainer>
        <Title>{layout?.title}</Title>
        <ButtonWrapper onClick={onClose}>
          <MdClose />
        </ButtonWrapper>
      </TitleContainer>
      <div style={{ width: '100%', textAlign: 'start' }}>
        <CopyToClipboard text={url} onCopy={onClose}>
          <Button>
            <FiLink />
            <SocialText>{layout?.copy}</SocialText>
          </Button>
        </CopyToClipboard>
      </div>
      <FacebookShareButton url={url} style={{ width: '100%', textAlign: 'start' }}>
        <Button>
          <FiFacebook />
          <SocialText>Facebook</SocialText>
        </Button>
      </FacebookShareButton>
      <LinkedinShareButton url={url} style={{ width: '100%', textAlign: 'start' }}>
        <Button>
          <FiLinkedin />
          <SocialText>LinkedIn</SocialText>
        </Button>
      </LinkedinShareButton>
      <TwitterShareButton url={url} style={{ width: '100%', textAlign: 'start' }}>
        <Button>
          <FiTwitter />
          <SocialText>Twitter</SocialText>
        </Button>
      </TwitterShareButton>
    </Container>
  )
}

export default ShareList
