import axios from 'axios'
import { Blog } from 'ducks/blog'
import { TranslatedRequest } from 'lib/axios/models'

interface PostRequest extends TranslatedRequest {
  slug: string
}

interface PostsRequest extends TranslatedRequest {
  countries: Array<number>
  categories: Array<number>
}

export const getBlogLayout = async ({ language }: TranslatedRequest): Promise<Blog | null> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        blogHomeLayout {
         most_motivated:most_motivated_${language}
         most_motivated_label: most_motivated_label_${language}
         most_motivated_message: most_motivated_message_${language}
         create_title: create_title_${language}
         create_message: create_message_${language}
         create_button: create_button_${language}
         path {
           description:description_${language}
           sections {
             link
             text: text_${language}
           }
         }
         filter {
           title: title_${language}
           category: category_${language}
           country: country_${language}
           in_use: in_use_${language}
           no_filters: no_filters_${language}
         }
         featured_posts {
           url
           title: title_${language}
           short_description: short_description_${language}
           content: content_${language}
           author {
             name
             nickname
             image {
               url
             }
           }
           cover_image {
             url
           }
           category {
             name: name_${language}
             color
             blog_color
           }
         }
       }
     }
      `,
    })
    if (data) {
      return {
        home_layout: data.blogHomeLayout,
      }
    }
    return null
  } catch (error) {
    throw new Error(error.message)
  }
}

type MotivationRequest = {
  blogId: number
  userId?: number
}

type MotivationResponse = {
  current_post: {
    motivationsCount: number
  }
}

export const getMotivation = async ({ blogId, userId }: MotivationRequest): Promise<MotivationResponse | null> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        motivationsCount(where: { blog_post: ${blogId} })
        canMotivate(where: { blog_post: ${blogId}, user: ${userId} })
     }
      `,
    })
    if (data) {
      return { current_post: data }
    }
    return null
  } catch (error) {
    throw new Error(error.message)
  }
}

export const postMotivation = async ({ blogId, userId }: MotivationRequest): Promise<MotivationResponse | null> => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_MAIN_API}/motivations`, {
      user: userId,
      blog_post: blogId,
    })
    if (data) {
      return { current_post: { motivationsCount: 1 } }
    }
    return null
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getPost = async ({ language, slug }: PostRequest): Promise<Blog | null> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        blogPosts(where: { url: "${slug}"}) {
         title: title_${language}
         content: content_${language}
         id
         url
         author {
           name
           nickname
           image {
             url
           }
         }
         cover_image {
           url
         }
       }
     }
      `,
    })
    if (data && data.blogPosts.length > 0) {
      return { current_post: data.blogPosts[0] }
    }
    return null
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getPosts = async ({ language, countries, categories }: PostsRequest): Promise<Blog | null> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        mostMotivatedPosts(where: {
          country: [${countries.join(', ')}],
          category: [${categories.join(', ')}],
        }) {
          id
          title: title_${language}
          short_description: short_description_${language}
          url
          motivations {
            id
          }
          author {
            name
            nickname
            image {
              url
            }
          }
          card_image {
            url
          }
          cover_image {
            url
          }
          category {
            blog_color
            name: name_${language}
          }
        }
        blogPosts(sort: "id:DESC", where: {
          country: {
            id: [${countries.join(', ')}]
          },
          category: {
            id: [${categories.join(', ')}]
          }
        }) {
          id
          title: title_${language}
          short_description: short_description_${language}
          url
          motivations {
            id
          }
          author {
            name
            nickname
            image {
              url
            }
          }
          card_image {
            url
          }
          cover_image {
            url
          }
          category {
            blog_color
            name: name_${language}
          }
          sub_category {
            name: name_${language}
          }
        }
      }
      `,
    })
    if (data) {
      return { posts: data.blogPosts, mostMotivatedPosts: data.mostMotivatedPosts }
    }
    return null
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getPostLayout = async ({ language }: TranslatedRequest): Promise<Blog | null> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
        query {
          blogPostLayout {
          button: button_${language}
          motivate: motivate_${language}
          path {
            description: description_${language}
            sections {
              link
              text: text_${language}
            }
          }
        }
      }
      `,
    })
    if (data) {
      return { post_layout: data.blogPostLayout }
    }
    return null
  } catch (error) {
    throw new Error(error.message)
  }
}

export default {
  getPost,
  getPostLayout,
}
