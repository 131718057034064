import styled from 'styled-components'

export const Button = styled.div`
  font-family: Nunito Sans;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  width: 270px;
  cursor: pointer;
  @media screen and (min-width: 1600px) {
    width: 300px;
  }
`

export const Container = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media screen and (max-width: 450px) {
    margin-top: 4rem;
  }
`

export const Content = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const CardBody = styled.div`
  padding: 1rem 2rem;
  height: 400px;
  @media screen and (min-width: 1600px) {
    padding: 2rem 3rem;
    height: 500px;
  }
  @media screen and (max-width: 1024px) {
    padding: 1rem;
    height: 350px;
  }
  @media screen and (max-width: 450px) {
    height: auto;
    padding-bottom: 6rem;
  }
`

export const CardDescription = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.white};
  letter-spacing: 0.09px;
  @media screen and (min-width: 1600px) {
    font-size: 24px;
    line-height: 30px;
  }
`

export const CardTitle = styled.p`
  font-weight: bold;
  font-size: 48px;
  line-height: 61px;
  color: ${({ theme }) => theme.colors.postularCardLabel};
`

export const InviteButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 5%;
  bottom: -6%;
  z-index: 1;
  width: 90%;
  @media screen and (min-width: 1600px) {
    width: 70%;
    left: 15%;
    bottom: -5%;
  }
  @media screen and (max-width: 1024px) {
    bottom: -8%;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
    left: 10%;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    left: 0;
    flex-direction: column;
    align-items: center;
    bottom: -2%;
  }
`

export const InviteCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1rem;
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`

export const InviteContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    width: 63%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 3rem;
  }
`

export const PostularButtonWrapper = styled.div`
  position: absolute;
  bottom: -3%;
  right: 10%;
  @media screen and (max-width: 450px) {
    width: 90%;
    right: 5%;
    bottom: -8%;
  }
`

export const PostularContainer = styled.div`
  width: 45%;
  @media screen and (max-width: 1024px) {
    width: 35%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const SocialText = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.3px;
`

export const Subtitle = styled.p`
  margin-left: 10px;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 34px;
    line-height: 37px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 25px;
    line-height: 25px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 16px;
  }
  @media screen and (max-width: 450px) {
    padding: 0 1rem;
    margin-left: 0;
  }
`

export const TextWrapper = styled.div`
  width: 60%;
  margin-top: 2rem;
  @media screen and (min-width: 1600px) {
    width: 45%;
    margin-top: 3rem;
  }
  @media screen and (max-width: 450px) {
    margin-top: 1rem;
    width: 100%;
  }
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 48px;
    line-height: 61px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 30px;
    line-height: 40px;
  }
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 25px;
  }
  @media screen and (max-width: 450px) {
    padding: 0 1rem;
    font-size: 18px;
  }
`
