import React, { FC, useContext, useState, useEffect } from 'react'
import { Container, Image, Content, Title, Subtitle, Group, Country, CountryFlag, Description } from './styled'
import { ButtonWithIcon, ButtonIcon } from 'views/components/UI/Buttons'
import { Grid } from '@material-ui/core'
import LineSeparator from 'views/components/UI/LineSeparator'
import Badge from 'views/components/UI/Badge'
import { Protagonista } from 'ducks/protagonistas'
import { ThemeContext } from 'styled-components'
import { useSelector } from 'react-redux'
import { protagonistasLayoutSelector } from 'ducks/protagonistasLayout/selectors'
import { useHistory } from 'react-router-dom'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  protagonista: Protagonista
}

const ProtagonistaCard: FC<Props> = ({ protagonista }: Props) => {
  const themeContext = useContext(ThemeContext)
  const { colors } = themeContext
  const { category, country } = protagonista
  const protagonistasLayout = useSelector(protagonistasLayoutSelector)
  const history = useHistory()
  const [textToDisplay, setTextToDisplay] = useState('')

  useEffect(() => {
    const maxCharacter = protagonistasLayout.getIn(['protagonistas', 'max_character'])
    if (protagonista?.card_description?.length > maxCharacter) {
      const shortText = protagonista.card_description.slice(0, maxCharacter)
      setTextToDisplay(`${shortText}...`)
    } else {
      setTextToDisplay(protagonista.card_description)
    }
  }, [protagonista, protagonistasLayout])

  const onClickCard = () => {
    history.push(`/protagonista/${protagonista.username}`)
  }

  return (
    <Container>
      <Image url={protagonista?.image?.url} color={category?.overlay} />
      <Content>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Badge backgroundColor={category?.Badge?.background} textColor={category?.Badge?.text}>
            {category?.name}
          </Badge>
          <Country>
            <CountryFlag src={country?.image?.url} />
            {country?.shortname}
          </Country>
        </Grid>
        <Title>{protagonista?.name}</Title>
        <Grid container>
          <Subtitle>{protagonista?.nickname}</Subtitle>
          <LineSeparator />
        </Grid>
        <Group>{protagonista?.group?.name}</Group>
        <Description>
          <HtmlRender>{textToDisplay}</HtmlRender>
        </Description>
        <Grid container direction="row" justify="flex-end">
          <ButtonWithIcon color={colors.dark} textColor={colors.white} onClick={onClickCard}>
            {protagonistasLayout.getIn(['protagonistas', 'card_button'])} <ButtonIcon />
          </ButtonWithIcon>
        </Grid>
      </Content>
    </Container>
  )
}

export default ProtagonistaCard
