import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  width: 40%;
  z-index: 1;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const Image = styled.div<BackgroundProps>`
  ${background};
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  height: 300px;
  width: 100%;
  @media screen and (min-width: 1600px) {
    height: 400px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
  @media screen and (max-width: 1024px) {
    height: 270px;
  }
`

export const Content = styled.div`
  margin-top: 40px;
  @media screen and (max-width: 450px) {
    padding: 0 1rem;
    margin-top: 20px;
  }
`

export const CaptchaWrapper = styled.div`
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 450px) {
    justify-content: flex-start;
  }
`

export const CheckboxWrapper = styled.div`
  margin: 20px 0;
  width: 100%;
`

export const CheckboxContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
`

export const CheckboxText = styled.p`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.default};
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
