import React, { FC } from 'react'
import { Container } from './styled'

const ProtagonistaLoader: FC = () => (
  <Container>
    <rect width="100%" height="100%" />
  </Container>
)

export default ProtagonistaLoader
