import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem 4rem 0;
  margin-top: 25px;
  @media screen and (max-width: 1024px) {
    padding: 2rem 2rem 0;
  }
  @media screen and (max-width: 768px) {
    padding: 2rem 0 0;
  }
  @media screen and (max-width: 450px) {
    padding: 0;
    margin-top: 0;
  }
`

export const Board = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`
