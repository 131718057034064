import axios from 'axios'
import { TranslatedRequest } from 'lib/axios/models'
import { ProfileLayoutResponse } from 'ducks/profileLayout'

const getProfileLayout = async ({ language }: TranslatedRequest): Promise<ProfileLayoutResponse | undefined> => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        profileLayout {
          title: title_${language}
          description: description_${language}
          inspiration_label: inspiration_label_${language}
          inspiration_link
          feedback_field: feedback_field_${language}
          message: message_${language}
          button: button_${language}
          path {
            description: description_${language}
            sections {
              link
              text: text_${language}
            }
          }
          feedback {
            title: title_${language}
            message: message_${language}
            button: button_${language}
            link
          }
          live_feedback {
            title: title_${language}
            description: description_${language}
            share: share_${language}
            background_color
          }
          update_feedback {
            title: title_${language}
            description: description_${language}
            background_color
          }
          max_char_message {
            start_message: start_message_${language}
            end_message: end_message_${language}
          }
          edit: edit_${language}
          cancel: cancel_${language}
          about_section {
            check: check_${language}
            category: category_${language}
            country: country_${language}
            nickname: known_as_${language}
            name: name_${language}
            first_name: first_name_${language}
            last_name: last_name_${language}
            about_me: about_me_${language}
            socials: socials_${language}
            instagram
            linkedin
            facebook
            twitter
            email: email_${language}
            about_placeholder: about_placeholder_${language}
            max_character
            email_placeholder: email_placeholder_${language}
            add_image: add_image_${language}
            edit_image: edit_image_${language}
            image_placeholder {
              url
            }
          }
          history_section {
            title: title_${language}
            add_image: add_image_${language}
            placeholder: placeholder_${language}
            image_placeholder {
              url
            }
          }
        }
      }
      `,
    })
    return data
  } catch (error) {
    return
  }
}

export default getProfileLayout
