import {
  borders,
  bottom,
  color,
  flexbox,
  position,
  space,
  layout,
  background,
  LayoutProps,
  SpaceProps,
  FlexboxProps,
  ColorProps,
  BordersProps,
  PositionProps,
  BackgroundProps,
} from 'styled-system'
import styled from 'styled-components'

export const BannerContainer = styled.div`
  width: 60%;
  align-self: center;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export type BlockProps = LayoutProps &
  SpaceProps &
  FlexboxProps &
  ColorProps &
  BordersProps &
  PositionProps & { hover?: boolean }

export const Block = styled.div<BlockProps>`
  ${flexbox}
  ${layout}
  ${color}
  ${space}
  ${position}
  ${bottom}
  ${borders}
  @media screen and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
cursor: ${({ hover }) => (hover ? 'pointer' : 'default')};
`

interface CardProps extends BackgroundProps, LayoutProps {
  color?: string
  rounded?: boolean
}

export const Card = styled.div<CardProps>`
  ${background};
  ${layout};
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  border-radius: ${({ rounded }) => (rounded ? '4px' : '0')};
  background-color: ${({ theme, color }) => (color ? color : theme.colors.defaultCardBackground)};
`

export const FloatingCard = styled.div<CardProps>`
  ${background};
  position: relative;
  height: fit-content;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  border-radius: ${({ rounded }) => (rounded ? '4px' : '0')};
  background-color: ${({ theme, color }) => (color ? color : theme.colors.white)};
  -webkit-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  -moz-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
`

export const Row = styled.div<FlexboxProps>`
  display: flex;
  align-items: center;
  ${flexbox};
`
