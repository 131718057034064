import { HomeBanner } from 'ducks/elCole'
import { Path } from 'ducks/protagonistasLayout'
import React, { FC } from 'react'

import { Container, Title } from './styled'
import PathIndicator from 'views/components/PathIndicator'

type Props = {
  layout: HomeBanner
  path: Path
}

const Banner: FC<Props> = (props: Props) => {
  const { layout, path } = props
  return (
    <Container background={`url(${layout.background?.url})`}>
      <PathIndicator description={path.description} sections={path.sections} />
      <Title>{layout.title}</Title>
    </Container>
  )
}

export default Banner
