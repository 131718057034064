import React, { FC, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { CONTENT } from 'ducks/pages/actionTypes'
import { useLanguage } from 'hooks/useLanguage'
import { useParams } from 'react-router-dom'
import { pagesSelector } from 'ducks/pages'
import HtmlRender from 'views/components/HtmlRender'
import { Title, Container } from './styled'
const Page: FC = () => {
  const dispatch = useDispatch()
  const { currentLanguage } = useLanguage()
  const { slug } = useParams<{ slug: string }>()
  const page = useSelector(pagesSelector)
  useEffect(() => {
    dispatch(CONTENT.trigger({ language: currentLanguage, slug }))
  }, [dispatch, currentLanguage, slug])

  return (
    <>
      <Container>
        <Title>{page.getIn(['pages', 'title'])}</Title>
        <HtmlRender>{page.getIn(['pages', 'content'])}</HtmlRender>
      </Container>
    </>
  )
}

export default Page
