import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { layoutDataSelector } from 'ducks/layoutData'

import { Container, TermsContent, Text, TextLink } from './styled'
import Checkbox from '../Checkbox'

type Props = {
  checked: boolean
  handleCheckbox: () => void
  checkboxColor?: string
}

const SubscribeTerms: FC<Props> = ({ checked, handleCheckbox, checkboxColor }) => {
  const layoutState = useSelector(layoutDataSelector)
  const termsAndPolicy = layoutState.get('termsAndPolicy')
  return (
    <Container>
      <Checkbox color={checkboxColor} value={checked} onChange={handleCheckbox} />
      <TermsContent>
        <Text>
          {termsAndPolicy?.getIn(['subscribe_policy', 'part_one'])}{' '}
          <TextLink target="blank" to={termsAndPolicy?.getIn(['subscribe_policy', 'link']) || ''}>
            {termsAndPolicy?.getIn(['subscribe_policy', 'link_label'])}
          </TextLink>{' '}
          {termsAndPolicy?.getIn(['subscribe_policy', 'part_two'])}
        </Text>
      </TermsContent>
    </Container>
  )
}

export default SubscribeTerms
