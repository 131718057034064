import { LogoRow as ILogoRow } from 'ducks/layoutData'
import React, { FC } from 'react'
import { Container, Logo, Title, Subtitle, Content } from './styled'

interface Props {
  content: ILogoRow
}

const LogoRow: FC<Props> = ({ content }: Props) => (
  <Container>
    <Logo src={content.image.url} data-testid="logoImage" />
    <Content>
      <Title>{content.title}</Title>
      <Subtitle>{content.subtitle}</Subtitle>
    </Content>
  </Container>
)

export default LogoRow
