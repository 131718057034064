import { background, BackgroundProps } from 'styled-system'
import styled from 'styled-components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

export const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
`

export const AuthorImage = styled.div<BackgroundProps>`
  ${background};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`

export const AuthorLabel = styled.p`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.466667px;
  color: #2c2e3f;
  margin-left: 20px;
  @media screen and (max-width: 450px) {
    font-size: 14px;
    line-height: 20px;
  }
`

export const Container = styled.div`
  width: 100%;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 450px) {
    padding: 1rem 1rem;
  }
`

export const Content = styled.div`
  width: 100%;
  padding: 1rem 4rem;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e0e0e0;
  @media screen and (max-width: 1024px) {
    padding: 1rem 0rem;
  }
  @media screen and (max-width: 450px) {
    padding: 0rem;
  }
`

export const CoverImage = styled.div<BackgroundProps>`
  ${background};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  width: 100%;
  height: 600px;
  @media screen and (max-width: 1024px) {
    height: 300px;
  }
  @media screen and (max-width: 450px) {
    height: 200px;
  }
`

export const Footer = styled.div`
  padding: 1rem 7rem 3rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  @media screen and (min-width: 1600px) {
    width: 80%;
  }
  @media screen and (max-width: 1024px) {
    padding: 2rem 1rem 3rem;
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    padding: 2rem 0rem 3rem;
  }
`

export const FooterMessage = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.09px;
  color: #7b7b7b;
  margin-bottom: 10px;
`

export const FooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`

export const MotivateButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-width: 180px;
  background: #91cc8d;
  color: #487745;
  border-radius: 4px;
  -webkit-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.5);
`

export const MotivateIcon = styled.img`
  width: 23px;
  height: 27px;
  margin-right: 10px;
`

export const MotivateText = styled.p`
  font-weight: 900;
  font-size: 12.9225px;
  line-height: 22px;
  letter-spacing: 0.27691px;
`

export const Post = styled.div`
  padding: 2rem 4rem 3rem;
  width: 90%;
  align-self: center;
  @media screen and (min-width: 1600px) {
    width: 80%;
  }
  @media screen and (max-width: 1024px) {
    padding: 2rem 1rem 3rem;
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    padding: 2rem 0 3rem;
  }
`

export const Text = styled.div`
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 450px) {
    font-size: 16px;
    line-height: 22px;
  }
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 60px;
  line-height: 61px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 450px) {
    font-size: 30px;
    line-height: 31px;
  }
`

export const ButtonIcon = styled(ChevronRightIcon)`
  font-size: 14px;
`

export const ShareButton = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 0 10px;
  padding-left: 0;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  height: 48px;
  min-width: 250px;
  font-size: 14px;
  margin-left: 20px;
  -webkit-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.5);
  ${ButtonIcon} {
    color: inherit;
    position: absolute;
    right: 10px;
  }
  @media screen and (min-width: 1600px) {
    padding: 1rem;
    font-size: 14px;
    padding-left: 0;
  }
  @media screen and (max-width: 768px) {
    padding: 6px 0 6px 8px;
  }
  @media screen and (max-width: 450px) {
    padding: 15px;
    width: 100%;
    min-width: unset;
    justify-content: flex-start;
  }
`
