import React, { FC, useEffect, useState } from 'react'
import { Container, CoverImage, Content, TitleLabel, Title } from './styled'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { movimientosSelector } from 'ducks/movimientos/selectors'
import { movimientoLayoutSelector } from 'ducks/movimientoLayout/selectors'
import { GET } from 'ducks/movimientos/actionTypes'
import { CONTENT } from 'ducks/movimientoLayout/actionTypes'
import { useLanguage } from 'hooks/useLanguage'
import { Movimiento as IMovimiento, OrderedCategory } from 'ducks/movimientos'
import About from './About'
import FirstSection from './FirstSection'
import SecondSection from './SecondSection'
import ThirdSection from './ThirdSection'
import { Category } from 'ducks/categories'

const MovimientoView: FC = () => {
  const { currentLanguage } = useLanguage()
  const { codename } = useParams<{ codename: string }>()
  const dispatch = useDispatch()
  const movimientos = useSelector(movimientosSelector)
  const movimientoLayout = useSelector(movimientoLayoutSelector)
  const [movimiento, setMovimiento] = useState<IMovimiento | undefined>()
  const [gradientBackground, setGradientBackground] = useState<string>('')
  const [orderedCategories, setOrderedCategories] = useState<Array<Category>>([])

  useEffect(() => {
    dispatch(CONTENT.trigger({ language: currentLanguage }))
    dispatch(GET.trigger({ language: currentLanguage, codename }))
  }, [dispatch, codename, currentLanguage])

  useEffect(() => {
    if (movimiento?.ordered_categories && movimiento?.ordered_categories?.length > 0) {
      const categories = movimiento?.ordered_categories.map((category: OrderedCategory) => ({ ...category.eje }))
      setOrderedCategories(categories)
      if (movimiento?.ordered_categories?.length === 1) {
        const category = movimiento.ordered_categories[0].eje
        setGradientBackground(category.Badge.background)
      } else {
        const colors = categories.map((category: Category) => category.Badge.background)
        const gradient = `linear-gradient(180deg, ${colors?.join(', ')})`
        setGradientBackground(gradient)
      }
    } else {
      if (movimiento?.categories) {
        setOrderedCategories(movimiento?.categories)
      }
      if (movimiento?.categories?.length === 0) {
        setGradientBackground('white')
      } else if (movimiento?.categories?.length === 1) {
        const category = movimiento.categories[0]
        setGradientBackground(category.Badge.background)
      } else {
        const colors = movimiento?.categories?.map((category: Category) => category.Badge.background)
        const gradient = `linear-gradient(180deg, ${colors?.join(', ')})`
        setGradientBackground(gradient)
      }
    }
  }, [movimiento])

  useEffect(() => {
    setMovimiento(movimientos.get('movimiento'))
  }, [movimientos])

  return (
    <Container background={gradientBackground}>
      <CoverImage background={movimiento?.cover_image?.url ? `url(${movimiento?.cover_image?.url})` : 'grey'}>
        <TitleLabel>{movimientoLayout.get('title')}</TitleLabel>
        <Title>{movimiento?.name}</Title>
      </CoverImage>
      {movimiento && (
        <Content>
          <About
            layout={movimientoLayout.get('details')}
            path={movimientoLayout.get('path')}
            articleTitle={movimiento?.history_article?.title}
            movimiento={movimiento}
            orderedCategories={orderedCategories}
          />
          <FirstSection title={movimientoLayout.get('section_title')} section={movimiento?.history_article?.part_one} />
          {movimiento.history_article?.part_two && (
            <SecondSection
              title={movimientoLayout.get('section_title')}
              section={movimiento?.history_article?.part_two}
            />
          )}
          {movimiento.history_article?.part_three && (
            <ThirdSection
              title={movimientoLayout.get('section_title')}
              section={movimiento?.history_article?.part_three}
            />
          )}
        </Content>
      )}
    </Container>
  )
}

export default MovimientoView
