import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
  max-height: 532px;
  height: 70vh;
  @media screen and (max-width: 1250px) {
    flex-direction: column;
    align-items: flex-start;
    max-height: unset;
    margin-bottom: 20px;
    height: auto;
  }
`

export const LeftContainer = styled.div`
  width: 35%;
  height: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 1250px) {
    width: 100%;
    margin-bottom: 20px;
    height: unset;
  }
`

export const ArticleTitle = styled.p`
  margin: 0;
  font-size: 2.3rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  padding: 0 16px 0 10px;
  color: ${({ theme }) => theme.colors.black};
  @media screen and (min-width: 1600px) {
    font-size: 48px;
  }
`

export const Card = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  max-height: 532px;
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  -moz-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  border-radius: 4px;
  @media screen and (max-width: 1250px) {
    width: 100%;
    padding: 20px 10px;
    max-height: unset;
  }
`

export const CardHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  @media screen and (max-width: 450px) {
    padding: 0;
  }
`

export const HeaderLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const CardTitle = styled.p`
  padding: 0 1rem;
  margin: 0;
  margin-top: 32px;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.dark};
  & > .uppercase {
    text-transform: uppercase;
  }
  @media screen and (max-width: 450px) {
    padding: 0;
  }
`

export const CardBody = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 450px) {
    padding: 1rem 0;
  }
`

export const CardFooter = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 450px) {
    padding: 1rem 0;
  }
`

export const FooterTitle = styled.p`
  margin: 0;
  text-transform: uppercase;
  font-size: 16px;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.09px;
`

export const FooterContentRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 30px;
  width: 100%;
  @media screen and (max-width: 450px) {
    padding: 1rem 0 0;
  }
`

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const LinkText = styled(Link)`
  font-size: 14px;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.blue};
  margin-right: 10px;
`

export const ExternalLinkText = styled.a`
  font-size: 14px;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.blue};
  margin-right: 10px;
`

export const LinkLabel = styled.p`
  margin: 0;
  margin-right: 8px;
  font-size: 16px;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.default};
`
