import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const InternalLink = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.lightTextFooter};
  @media screen and (max-width: 500px) {
    max-width: 100px;
  }
`

export const ExternalLink = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.lightTextFooter};
  @media screen and (max-width: 500px) {
    max-width: 100px;
  }
`
