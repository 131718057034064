import React, { FC, useState } from 'react'
import { Category } from 'ducks/categories'
import { Country } from 'ducks/countries'
import { Home as HomeIcon } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import { protagonistasSelector } from 'ducks/protagonistas/selectors'
import { AboutSection } from 'ducks/protagonistaLayout'
import { Group, Socials } from 'ducks/protagonistas'
import useMetaTag from 'hooks/useMetaTag'

import {
  Container,
  BackgroundImage,
  ButtonWithIcon,
  CardContainer,
  Card,
  CardHeader,
  HeaderLabelContainer,
  CardTitle,
  CardBody,
  CardFooter,
  FooterTitle,
  FooterContentRow,
  LinkContainer,
  LinkIcon,
  LinkText,
  LinkLabel,
  ExternalLinkText,
  CardFooterHeader,
  ButtonsContainer,
  ShareButton,
} from './styled'
import Badge from 'views/components/UI/Badge'
import CountryBadge from 'views/components/UI/CountryBadge'
import LineSeparator from 'views/components/UI/LineSeparator'
import HtmlRender from 'views/components/HtmlRender'
import ShareList from 'views/components/ShareList'
import ShareThumbnail from 'views/components/UI/ShareThumbnail'

type Props = {
  layout: AboutSection
}

const About: FC<Props> = ({ layout }: Props) => {
  const protagonistas = useSelector(protagonistasSelector)
  const category: Category = protagonistas.getIn(['protagonista', 'category'])
  const country: Country = protagonistas.getIn(['protagonista', 'country'])
  const socials: Socials = protagonistas.getIn(['protagonista', 'socials'])
  const group: Group = protagonistas.getIn(['protagonista', 'group'])
  const username: string = protagonistas.getIn(['protagonista', 'username'])
  const coverImage =
    protagonistas.getIn(['protagonista', 'cover_image'])?.url || protagonistas.getIn(['protagonista', 'image'])?.url
  const [openShare, setOpenShare] = useState(false)
  const { origin } = new URL(process.env.PUBLIC_URL, window.location.href)
  const url = `${origin}/protagonista/${username}`

  useMetaTag('og:image', coverImage)
  useMetaTag('og:url', url)
  useMetaTag('og:title', `Protagonista - ${protagonistas.getIn(['protagonista', 'name'])}`)
  useMetaTag('og:description', protagonistas.getIn(['protagonista', 'description']))

  const toggleOpenShare = () => {
    setOpenShare(!openShare)
  }

  return (
    <Container>
      <BackgroundImage backgroundImage={`url(${coverImage})`}>
        <ShareThumbnail src={coverImage} />
        <ButtonWithIcon color="rgba(0, 0, 0, 0.87)" bg="#bbdefb">
          <HomeIcon className="icon" />
          Protagonista categoria LOCAL
        </ButtonWithIcon>
        {/* <ButtonWithIcon bold uppercase color="#fff" bg="#6dbed1" borderRadius={4}>
          <FavoriteIcon className="icon" />
          13 votaciones
        </ButtonWithIcon> */}
      </BackgroundImage>
      <CardContainer>
        <Card>
          <CardHeader>
            <HeaderLabelContainer>
              <Badge backgroundColor={category?.Badge.background} textColor={category?.Badge.text}>
                {category?.name}
              </Badge>
            </HeaderLabelContainer>
            <HeaderLabelContainer>
              <CountryBadge country={country} />
            </HeaderLabelContainer>
          </CardHeader>
          <CardTitle>
            {protagonistas.getIn(['protagonista', 'name'])}
            {protagonistas.getIn(['protagonista', 'nickname'])
              ? ` - "${protagonistas.getIn(['protagonista', 'nickname'])}"`
              : ''}
          </CardTitle>
          <LineSeparator color="#e0e0e0" />
          <CardBody>
            <HtmlRender>{protagonistas.getIn(['protagonista', 'description'])}</HtmlRender>
          </CardBody>
          <CardFooter>
            {(group || socials) && (
              <CardFooterHeader>
                <FooterTitle>{layout.movement}:</FooterTitle>
                <LineSeparator color="#e0e0e0" />
              </CardFooterHeader>
            )}
            {group && (
              <FooterContentRow>
                <LinkContainer key={group.codename}>
                  <LinkIcon fontSize="small" />
                  <LinkText to={`/movimiento/${group.codename}`}>{group.name}</LinkText>
                </LinkContainer>
              </FooterContentRow>
            )}
            {socials && (
              <FooterContentRow noPaddingTop>
                {socials.instagram && (
                  <LinkContainer>
                    <LinkLabel>{layout.socials?.instagram}:</LinkLabel>
                    <ExternalLinkText href={`https://www.instagram.com/${socials.instagram}/`} target="blank">
                      @{socials.instagram}
                    </ExternalLinkText>
                  </LinkContainer>
                )}
                {socials.website && (
                  <LinkContainer>
                    <LinkLabel>{layout.socials?.site}:</LinkLabel>
                    <ExternalLinkText href={socials.website}>{socials.website}</ExternalLinkText>
                  </LinkContainer>
                )}
                {socials.email && (
                  <LinkContainer>
                    <LinkLabel>{layout.socials?.email}:</LinkLabel>
                    <ExternalLinkText href={`mailto:${socials.email}`}>{socials.email}</ExternalLinkText>
                  </LinkContainer>
                )}
              </FooterContentRow>
            )}
          </CardFooter>
        </Card>
        <ButtonsContainer>
          {/* <PlusButton /> */}
          <ShareButton onClick={toggleOpenShare}>{layout.share}</ShareButton>
          {openShare && <ShareList onClose={toggleOpenShare} url={url} layout={layout.share_list} />}
        </ButtonsContainer>
      </CardContainer>
    </Container>
  )
}

export default About
