import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`

export const CoverImage = styled.div<BackgroundProps>`
  ${background};
  width: 60%;
  height: 420px;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  @media screen and (max-width: 650px) {
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    height: 320px;
  }
`

export const Content = styled.div`
  width: 40%;
  padding: 20px;
  @media screen and (max-width: 650px) {
    width: 100%;
    padding: 20px 0 0;
  }
`

export const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 65px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 650px) {
    font-size: 32px;
  }
`

export const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.dark};
`
