import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { ICategories } from './../models'
import * as TYPES from './../actionTypes'

export const initialState: ICategories = fromJS({
  loading: false,
  categories: [],
})

const categoriesReducer: Reducer<ICategories> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET:
      return state.merge(action.payload)
    case TYPES.CONTENT.TRIGGER:
      return state.set('loading', true)
    case TYPES.CONTENT.SUCCESS:
      return state.merge(action.payload)
    case TYPES.CONTENT.FAILURE:
      return state.set('error', action.payload)
    case TYPES.CONTENT.FULFILL:
      return state.set('loading', false)
    default:
      return state
  }
}

export default categoriesReducer
