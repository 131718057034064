import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 48%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  -moz-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  margin-bottom: 25px;
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 475px) {
    flex-direction: column;
  }
`
type ImageProps = {
  url: string | undefined
}

export const Image = styled.div<ImageProps>`
  height: 100%;
  width: 45%;
  opacity: 0.87;
  background: url(${(props) => props.url}),
    linear-gradient(to bottom, ${(props) => props.color}, ${(props) => props.color});
  background-blend-mode: luminosity;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 475px) {
    width: 100%;
    height: 150px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 20px;
  width: 55%;
  @media (max-width: 475px) {
    width: 100%;
  }
`

export const Title = styled.p`
  margin: 12px 0;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.dark};
  text-transform: uppercase;
  @media screen and (max-width: 1600px) {
    font-size: 14px;
    font-weight: 700;
  }
`
export const Subtitle = styled.p`
  margin: 0;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.default};
  text-transform: capitalize;
`

export const Group = styled.p`
  margin: 10px 0 16px;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.black};
`

export const Country = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  letter-spacing: 0.3px;
`

export const CountryFlag = styled.img`
  width: 20px;
  height: 17px;
  margin-right: 10px;
`

export const Description = styled.div`
  margin: 0;
  margin-bottom: 40px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.black};
`
