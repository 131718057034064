import styled from 'styled-components'

type ContainerProps = {
  color?: string
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme, color }) => color || theme.colors.white};
`

export const Label = styled.p`
  margin-left: 15px;
  font-weight: 900;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.1px;
`
