import { IPost } from 'ducks/blog'
import React, { FC } from 'react'
import { AiFillStar } from 'react-icons/ai'

import { CategoryMark, Container, Footer, Image, Label, StarWrapper, Text, Title } from './styled'
import HtmlRender from '../HtmlRender'
import MotivatedIndicator from '../MotivatedIndicator'

type Props = {
  post?: IPost
}

const MotivatedPost: FC<Props> = ({ post }) => (
  <Container to={`/blog/post/${post?.url}`}>
    <StarWrapper>
      <AiFillStar size={30} color={post?.category?.blog_color || '#fff'} />
    </StarWrapper>
    <CategoryMark color={post?.category?.blog_color}>
      <p>{post?.category?.name}</p>
    </CategoryMark>
    <Image background={`url(${post?.cover_image?.url})`} />
    <div>
      <Title>{post?.title}</Title>
      <Text>
        <HtmlRender>{post?.short_description}</HtmlRender>
      </Text>
    </div>
    <Footer>
      <Label>{post?.author?.name}</Label>
      {post?.motivations && <MotivatedIndicator value={post?.motivations.length} color={post?.category?.blog_color} />}
    </Footer>
  </Container>
)

export default MotivatedPost
