import React, { FC } from 'react'
import { Section as ISection, Card as ICard } from 'ducks/contacto'

import { Container, Section, Title, Content } from './styled'
import Card from './Card'
import CardWithImage from './CardWithImage'

type Props = {
  title: string
  visibility: ISection
  guide: ISection
  support: ICard
}

const Help: FC<Props> = (props: Props) => {
  const { title, visibility, guide, support } = props

  return (
    <Container>
      <Title>{title}</Title>
      <Content>
        <Section>
          <Card
            title={visibility.title}
            subtitle={visibility.subtitle}
            description={visibility.description}
            button={visibility.button}
            link={visibility.link}
            backgroundColor={visibility.background_color}
            titleColor={visibility.title_color}
          />
          <Card
            title={guide.title}
            subtitle={guide.subtitle}
            description={guide.description}
            button={guide.button}
            link={guide.link}
            backgroundColor={guide.background_color}
            titleColor={guide.title_color}
          />
        </Section>
        <Section>
          <CardWithImage
            title={support.section?.title}
            subtitle={support.section?.subtitle}
            description={support.section?.description}
            button={support.section?.button}
            link={support.section?.link}
            backgroundColor={support.section?.background_color}
            titleColor={support.section?.title_color}
            image={support.image?.url}
            imageDescription={support.description}
            bottomButton={support.button}
            bottomLink={support.link}
          />
        </Section>
      </Content>
    </Container>
  )
}

export default Help
