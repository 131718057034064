import styled from 'styled-components'

export const Container = styled.div`
  width: 40%;
  padding: 40px 40px 40px 100px;
  @media screen and (min-width: 1600px) {
    padding: 80px 80px 80px 160px;
    width: 40%;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
    padding: 20px 0 20px 40px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
  }
`

export const ForgotPasswordLink = styled.a`
  font-size: 14px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.formLink};
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`
