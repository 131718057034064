import { SagaIterator } from '@redux-saga/core'
import { all, call, takeEvery, put } from 'redux-saga/effects'
import * as TYPES from '../actionTypes'
import countries from 'services/countries'
import { Action } from 'lib/redux/models'

function* getCountries(action: Action) {
  yield put(TYPES.CONTENT.request())
  try {
    const fetchedCountries = yield call(countries, action?.payload)
    if (fetchedCountries) {
      yield put(TYPES.CONTENT.success({ countries: fetchedCountries }))
    }
  } catch (error) {
    yield put(TYPES.CONTENT.failure(error.message))
  } finally {
    yield put(TYPES.CONTENT.fulfill())
  }
}

export function* countriesSagas(): SagaIterator {
  yield all([takeEvery(TYPES.CONTENT.TRIGGER, getCountries)])
}
