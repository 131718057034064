import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-style: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
`
