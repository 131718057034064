import React, { FC } from 'react'
import { Container, Thumbnail, VideoWrapper } from './styled'
import ReactPlayer from 'react-player/lazy'

type Props = {
  thumbnail?: string
  video?: string
}

const TopLeftVideo: FC<Props> = (props: Props) => {
  const { thumbnail, video } = props
  return (
    <Container>
      {video ? (
        <ReactPlayer playsinline url={video} wrapper={VideoWrapper} controls />
      ) : (
        <Thumbnail background={`url(${thumbnail})`} />
      )}
    </Container>
  )
}

export default TopLeftVideo
