import { Founder } from 'ducks/aboutLayout'
import React, { FC, useContext } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { Container, Content, Label, Overlay } from './styled'
import { ThemeContext } from 'styled-components'

type Props = {
  founder: Founder
  onClick: (founder: Founder) => void
  active?: boolean
}

const FounderIcon: FC<Props> = (props: Props) => {
  const match = useMediaQuery('(max-width: 768px)')
  const { founder, onClick, active } = props
  const { colors } = useContext(ThemeContext)

  const handleClick = () => {
    onClick(founder)
  }

  return (
    <Container background={`url(${founder.icon.url})`} onClick={handleClick} active={active && !match}>
      <Overlay background={founder.background_color ? founder.background_color : colors.founderDefaultBackground} />
      <Content background={founder.background_color ? founder.background_color : colors.founderDefaultBackground}>
        <Label>{founder.label}</Label>
      </Content>
    </Container>
  )
}

export default FounderIcon
