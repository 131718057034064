import styled from 'styled-components'

export const Container = styled.div`
  padding: 4rem;
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
  @media screen and (max-width: 450px) {
    padding: 2rem 0;
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 40px;
  }
`

type SectionColumnProps = {
  reverseColumn?: boolean
}

export const SectionColumn = styled.div<SectionColumnProps>`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: ${({ reverseColumn }) => (reverseColumn ? 'column-reverse' : 'column')};
  }
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 48px;
    line-height: 65px;
  }
  @media screen and (max-width: 450px) {
    font-size: 26px;
    line-height: 31px;
  }
`

export const Subtitle = styled.p`
  font-weight: bold;
  font-size: 27px;
  line-height: 30px;
  margin: 5px 0;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 34px;
    line-height: 49px;
  }
  @media screen and (max-width: 1024px) {
    line-height: 30px;
  }
  @media screen and (max-width: 450px) {
    font-size: 20px;
    line-height: 20px;
  }
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 20px;
    line-height: 25px;
  }
`

export const CardContainer = styled.div`
  width: 100%;
  padding: 0 4rem 0 0;
  margin-top: 20px;
  @media screen and (max-width: 1024px) {
    padding: 0 2rem 0 0;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin-top: 0;
  }
  @media screen and (max-width: 450px) {
    padding: 0 1rem;
  }
`

export const CardBody = styled.div`
  width: 70%;
  padding: 3rem 0 3rem 3rem;
  @media screen and (min-width: 1600px) {
    padding-left: 5rem;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 2rem;
  }
`
