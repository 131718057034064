import React, { FC, useState } from 'react'
import {
  Container,
  Card,
  CardHeader,
  CardTitle,
  ContentContainer,
  SliderContainer,
  DescriptionContainer,
  Text,
  TextBox,
  ButtonWithIcon,
  FadeOverlay,
  SectionLine,
} from './styled'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useSelector } from 'react-redux'
import { protagonistasSelector } from 'ducks/protagonistas/selectors'
import ProtagonistaSlider from 'views/components/ProtagonistaSlider'
import { HistorySection } from 'ducks/protagonistaLayout'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  layout: HistorySection
}

const History: FC<Props> = ({ layout }: Props) => {
  const protagonistas = useSelector(protagonistasSelector)
  const [showFullText, setShowFullText] = useState(true)

  return (
    <Container>
      <Card>
        <CardHeader>
          <CardTitle>{layout?.title}</CardTitle>
          <SectionLine />
        </CardHeader>
        <ContentContainer>
          <SliderContainer>
            <ProtagonistaSlider protagonista={protagonistas.get('protagonista')} />
          </SliderContainer>
          <DescriptionContainer>
            <Text>{layout?.subtitle}:</Text>
            <TextBox>
              <Text>
                <HtmlRender>{protagonistas.getIn(['protagonista', 'history'])}</HtmlRender>
              </Text>
              {!showFullText && <FadeOverlay />}
            </TextBox>
            {!showFullText && (
              <ButtonWithIcon onClick={() => setShowFullText(true)}>
                {layout?.read_more} <ExpandMoreIcon className="icon" />
              </ButtonWithIcon>
            )}
          </DescriptionContainer>
        </ContentContainer>
      </Card>
    </Container>
  )
}

export default History
