import React, { FC, ChangeEvent } from 'react'
import { Option } from 'ducks/contacto/models'
import { IconType } from 'react-icons'

import { Container, IconWrapper, Selector, Main } from './styled'
import { InputFeedbackMessage } from 'views/components/UI/Typography'

type Props = {
  icon?: IconType
  placeholder?: string
  options: Array<Option>
  name: string
  value: string
  onChange: (e: ChangeEvent<any>) => void
  error?: string
  customErrorMessage?: string
  noMargin?: boolean
}

const Select: FC<Props> = (props: Props) => {
  const { icon, placeholder = '', options = [], name, value, onChange, error, noMargin, customErrorMessage } = props
  return (
    <Main noMargin={noMargin}>
      {error && <InputFeedbackMessage>{customErrorMessage || error}</InputFeedbackMessage>}
      <Container error={error}>
        {icon && <IconWrapper>{icon?.call(null, { size: '20px' })}</IconWrapper>}
        <Selector placeholder={placeholder} onChange={onChange} name={name} value={value}>
          <option value={''}>{placeholder}</option>
          {options.map((opt: Option) => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </Selector>
      </Container>
    </Main>
  )
}

export default Select
