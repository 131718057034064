import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const TitleContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 20px;
`

export const Title = styled.p`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
  line-height: 1.75;
  letter-spacing: 0.28px;
`
