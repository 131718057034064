import React, { FC, useContext } from 'react'
import { Container, IconWrapper, Label, LinkLabel } from './styled'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import { ThemeContext } from 'styled-components'

type Props = {
  value?: boolean
  label?: string
  onChange?: () => void
  error?: string
  link?: string
  color?: string
}

const Checkbox: FC<Props> = ({ value, label, onChange, error, link, color }: Props) => {
  const { colors } = useContext(ThemeContext)

  const renderLabel = () => {
    if (link) {
      return (
        <LinkLabel to={link} target="blank">
          {label}
        </LinkLabel>
      )
    }
    return <Label>{label}</Label>
  }

  return (
    <Container>
      <IconWrapper onClick={onChange} data-testid="checkboxButton">
        {value ? (
          <MdCheckBox color={color || colors.checkbox} />
        ) : (
          <MdCheckBoxOutlineBlank color={error ? colors.error : color || colors.checkbox} />
        )}
      </IconWrapper>
      {label && renderLabel()}
    </Container>
  )
}

export default Checkbox
