import React, { useState, createContext, useContext, FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { loadingSelector } from 'ducks/loading'

type Props = {
  children: Array<JSX.Element> | JSX.Element
}

type LoadingContextType = {
  loading: boolean
  setLoading: (isLoading: boolean) => void
}

const LoadingContext = createContext<LoadingContextType | null>(null)

const LoadingProvider: FC<Props> = ({ children }: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const loadingState = useSelector(loadingSelector)

  useEffect(() => {
    if (loadingState.get('loading')) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [loadingState])

  return <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>
}

const useLoading = (): LoadingContextType => {
  const context: LoadingContextType | null = useContext(LoadingContext)
  if (!context) {
    throw new Error('useLoading must be used inside LoadingProvider')
  } else {
    return context
  }
}

export { useLoading, LoadingProvider }
