import { createRoutine } from 'redux-saga-routines'

export const SET = 'BLOG/SET'
export const CONTENT = createRoutine('BLOG/CONTENT')
export const MOTIVATION = createRoutine('BLOG/MOTIVATION')
export const SUBMIT_MOTIVATION = createRoutine('BLOG/SUBMIT_MOTIVATION')
export const POSTS = createRoutine('BLOG/POSTS')
export const TRIGGER_BLOG_LAYOUT = 'BLOG/HOME_LAYOUT'
export const TRIGGER_POST = 'BLOG/POST'
export const TRIGGER_POST_LAYOUT = 'BLOG/POST_LAYOUT'
