import React, { FC, useEffect } from 'react'
import { Container } from './styled'
import { useSelector, useDispatch } from 'react-redux'
import { homeSelector } from 'ducks/home'
import { CONTENT } from 'ducks/home/actionTypes'
import DualFeaturedSection from './DualFeaturedSection'
import DualFeaturedSectionMobile from './DualFeaturedSectionMobile'
import Indicator from 'views/components/Indicator'
import { Block } from 'views/components/UI/Contents'
import PostVideo from 'views/components/PostVideo'
import FlatArticle from './FlatArticle'
import FlatArticleMobile from './FlatArticleMobile'
import CardTitle from './Cards/CardTitle'
import CardTitleMobile from './Cards/CardTitleMobile'
import Slider from 'views/components/Slider'
import { useLanguage } from 'hooks/useLanguage'
import { useMediaQuery } from '@material-ui/core'

const Home: FC = () => {
  const { currentLanguage } = useLanguage()
  const dispatch = useDispatch()
  const home = useSelector(homeSelector)
  const matches = useMediaQuery('(max-width: 768px)')

  useEffect(() => {
    dispatch(CONTENT.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage])

  return (
    <Container>
      {matches ? (
        <DualFeaturedSectionMobile {...home.get('featured')?.toJSON()} />
      ) : (
        <DualFeaturedSection {...home.get('featured')?.toJSON()} />
      )}
      {home.get('indicators')?.toJS().length > 0 && <Indicator items={home.get('indicators')} />}
      <Block
        display="flex"
        flexDirection={matches ? 'column' : 'row'}
        width="100%"
        alignItems="center"
        my={matches ? '1rem' : '2rem'}
      >
        <Block width={matches ? '100%' : '40%'}>
          <PostVideo
            title={home.getIn(['news_section', 'left_title'])}
            description={home.getIn(['news_section', 'left_description'])}
            image={home.getIn(['news_section', 'left_image'])}
            video={home.getIn(['news_section', 'left_video'])}
          />
        </Block>
        <Block bg="wood" width={matches ? '100%' : '60%'}>
          {matches ? (
            <FlatArticleMobile
              title={home.getIn(['news_section', 'right_title'])}
              subtitle={home.getIn(['news_section', 'right_subtitle'])}
              description={home.getIn(['news_section', 'right_description'])}
              image={home.getIn(['news_section', 'right_image'])}
              right_button={home.getIn(['news_section', 'right_button'])}
              right_link={home.getIn(['news_section', 'right_link'])}
            />
          ) : (
            <FlatArticle
              title={home.getIn(['news_section', 'right_title'])}
              subtitle={home.getIn(['news_section', 'right_subtitle'])}
              description={home.getIn(['news_section', 'right_description'])}
              image={home.getIn(['news_section', 'right_image'])}
              right_button={home.getIn(['news_section', 'right_button'])}
              right_link={home.getIn(['news_section', 'right_link'])}
            />
          )}
        </Block>
      </Block>
      <Block display="flex" width="100%" alignItems="flex-start" my="3rem" flexDirection={matches ? 'column' : 'row'}>
        {matches ? (
          <CardTitleMobile
            link={home.getIn(['card', 'link'])}
            title={home.getIn(['card', 'title'])}
            subtitle={home.getIn(['card', 'subtitle'])}
            image={home.getIn(['card', 'image'])}
            background={home.getIn(['card', 'background'])}
            button_mobile={home.getIn(['card', 'button_mobile'])}
          />
        ) : (
          <CardTitle
            link={home.getIn(['card', 'link'])}
            title={home.getIn(['card', 'title'])}
            subtitle={home.getIn(['card', 'subtitle'])}
            image={home.getIn(['card', 'image'])}
            background={home.getIn(['card', 'background'])}
            button={home.getIn(['card', 'button'])}
          />
        )}
        <Slider
          data={home.get('slider')}
          title={home.get('slide_title')}
          button={home.get('slide_button')}
          button_mobile={home.get('slide_button_mobile')}
        />
      </Block>
    </Container>
  )
}

export default Home
