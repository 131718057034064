import { InfoSection as InfoSectionType } from 'ducks/voluntariadoLayout'
import React, { FC } from 'react'

import { Container, Image, Description } from './styled'
import { Subtitle, Text } from 'views/screens/Voluntariado/styled'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  layout: InfoSectionType
}

const InfoSection: FC<Props> = (props: Props) => {
  const { layout } = props
  return (
    <Container>
      <Image background={`url(${layout?.image?.url})`} />
      <Description>
        <Subtitle>{layout?.title}</Subtitle>
        <Text>
          <HtmlRender>{layout?.description}</HtmlRender>
        </Text>
      </Description>
    </Container>
  )
}

export default InfoSection
