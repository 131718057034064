import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { ITienditaLayout } from '../models'
import * as TYPES from '../actionTypes'

export const initialState: ITienditaLayout = fromJS({
  title: '',
  description: '',
  image_right: '',
  cover_page_left: '',
  cover_page_right: '',
  subscribe_text: '',
  subscribe_placeholder: '',
  subscribe_button: '',
  image_left: '',
  sentence_right: '',
  city_background: '',
  sentence_bottom: '',
})

const tienditaLayoutReducer: Reducer<ITienditaLayout> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET:
      return state.merge(action.payload)
    case TYPES.CONTENT.TRIGGER:
      return state.set('loading', true)
    case TYPES.CONTENT.FULFILL:
      return state.set('loading', false)
    case TYPES.CONTENT.FAILURE:
      return state.set('error', action.payload)
    case TYPES.CONTENT.SUCCESS:
      return state.merge(action.payload)
    default:
      return state
  }
}

export default tienditaLayoutReducer
