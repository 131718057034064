import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 250px;
  @media screen and (min-width: 1600px) {
    height: 300px;
  }
  @media screen and (max-width: 1024px) {
    height: 230px;
  }
  @media screen and (max-width: 768px) {
    height: 380px;
  }
  @media screen and (max-width: 450px) {
    height: 190px;
  }
`

export const Email = styled.p`
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 12px;
    line-height: 14px;
  }
`

export const Image = styled.img`
  width: 45%;
  height: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 55%;
  padding: 0.75rem;
  @media screen and (min-width: 1600px) {
    padding: 1rem;
  }
`

export const Label = styled.p`
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: ${({ theme }) => theme.colors.dark};
  text-transform: uppercase;
  align-self: flex-end;
  @media screen and (min-width: 1600px) {
    font-size: 14px;
    line-height: 17px;
  }
`

export const LineSeparatorContainer = styled.div`
  margin: 10px 0;
`

export const Subtitle = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 16px;
    line-height: 17px;
  }
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
  }
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.dark};
  /* text-transform: uppercase; */
  @media screen and (min-width: 1600px) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 12px;
  }
`
