import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 3rem 0;
  justify-content: center;
`

export const LeftContainer = styled.div`
  max-width: 876px;
  width: 45.62vw;
  max-height: 682px;
  height: 35.52vw;
  position: relative;
`

export const LeftContent = styled.article<BackgroundProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem;
  position: relative;
  z-index: 1;
  font-size: 0.89vw;
  ${background}
  @media (min-width: 1494px) {
    font-size: 16px;
  }
`

export const LeftSubTitle = styled.h3`
  margin: 0;
  font-weight: 400;
  font-size: 1.5em;
  color: ${({ theme }) => theme.colors.white};
`

export const LeftDescription = styled.div`
  margin: 0;
  font-size: 1.25em;
  line-height: 2em;
  color: ${({ theme }) => theme.colors.white};
`

export const LeftTitle = styled.h2`
  margin: 0;
  font-size: 3.7em;
  color: ${({ theme }) => theme.colors.white};
`
export const RightContainer = styled.div`
  position: relative;
  z-index: 1;
  flex: 1;
  align-self: stretch;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  margin-left: -2rem;
`

export const RightImage = styled.div<BackgroundProps>`
  height: 90%;
  max-height: 593px;
  width: 51%;
  max-width: 499px;
  background-position-x: center;
  background-position-y: center;
  ${background}
`
export const RightContent = styled.article`
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
  font-size: 0.89vw;
  @media (min-width: 1494px) {
    font-size: 16px;
  }
`
export const RightTitle = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 1.9rem;
`
export const RightDescription = styled.div`
  margin: 0;
  font-weight: 600;
  font-size: 1.7rem;
`
