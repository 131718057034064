import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
`

export const CustomIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.dark};
`

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-weight: bold;
  margin: 0;
  margin-left: 8px;
  @media screen and (max-width: 450px) {
    text-align: center;
  }
`
