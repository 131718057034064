import styled from 'styled-components'

export const Container = styled.div`
  width: 93%;
  padding: 28px 12px;
  margin: 0 12px;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
export const Background = styled.img`
  width: 52%;
  max-height: 190px;
  object-fit: cover;
  display: block;
`
export const Title = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
`
export const Subtitle = styled.p`
  margin: 0;
  font-size: 9px;
  margin-top: 12px;
`
export const BorderLeft = styled.div`
  position: relative;
  width: 1px;
  top: -42px;
  left: -12px;
  height: 128px;
  background-color: black;
  overflow: hidden;
`
