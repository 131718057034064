import React, { FC } from 'react'
import {
  Container,
  Title,
  Subtitle,
  Description,
  LinkButton,
  ButtonIcon,
  ButtonWrapper,
  TopButtonWrapper,
  Image,
} from './styled'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  title: string
  subtitle: string
  description: string
  button: string
  link?: string
  backgroundColor: string
  titleColor: string
  image: string
  imageDescription: string
  bottomButton: string
  bottomLink?: string
}

const CardWithImage: FC<Props> = (props: Props) => {
  const {
    title,
    subtitle,
    description,
    button,
    link,
    backgroundColor,
    titleColor,
    image,
    imageDescription,
    bottomButton,
    bottomLink,
  } = props

  return (
    <Container color={backgroundColor}>
      <Title color={titleColor}>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Description>
        <HtmlRender>{description}</HtmlRender>
      </Description>
      <TopButtonWrapper>
        {link && (
          <LinkButton to={link}>
            {button} <ButtonIcon />
          </LinkButton>
        )}
      </TopButtonWrapper>
      <Image background={`url(${image})`} />
      <Description>
        <HtmlRender>{imageDescription}</HtmlRender>
      </Description>
      {bottomLink && (
        <ButtonWrapper>
          <LinkButton to={bottomLink}>
            {bottomButton} <ButtonIcon />
          </LinkButton>
        </ButtonWrapper>
      )}
    </Container>
  )
}

export default CardWithImage
