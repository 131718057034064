import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { blogSelector, MOTIVATION, SUBMIT_MOTIVATION, TRIGGER_POST, TRIGGER_POST_LAYOUT } from 'ducks/blog'
import { userSelector } from 'ducks/user'
import { Section } from 'ducks/protagonistasLayout'
import { useLanguage } from 'hooks/useLanguage'
import { useParams } from 'react-router-dom'
import MotivatePNG from 'assets/motivate.png'
import ShareList from 'views/components/ShareList'

import {
  AuthorContainer,
  AuthorImage,
  AuthorLabel,
  Container,
  Content,
  CoverImage,
  Footer,
  FooterMessage,
  MotivateButton,
  MotivateIcon,
  MotivateText,
  Post,
  Text,
  Title,
  FooterButtons,
  ShareButton,
  ButtonIcon,
} from './styled'
import PathIndicator from 'views/components/PathIndicator'
import HtmlRender from 'views/components/HtmlRender'
import { protagonistaLayoutSelector } from 'ducks/protagonistaLayout'
import { CONTENT as LAYOUT_CONTENT } from 'ducks/protagonistaLayout/actionTypes'

const BlogPost: FC = () => {
  const dispatch = useDispatch()
  const blogState = useSelector(blogSelector)
  const userState = useSelector(userSelector)
  const { currentLanguage } = useLanguage()
  const { slug } = useParams<{ slug: string }>()
  const [pathSections, setPathSections] = useState<Array<Section>>([])
  const id = blogState.getIn(['current_post', 'id'])
  const userId = userState.get('id')
  const [openShare, setOpenShare] = useState(false)
  const protagonistaLayout = useSelector(protagonistaLayoutSelector)
  const layout = protagonistaLayout.get('about')
  const [url, setUrl] = useState('')

  useEffect(() => {
    dispatch({ type: TRIGGER_POST, payload: { language: currentLanguage, slug } })
    dispatch({ type: TRIGGER_POST_LAYOUT, payload: { language: currentLanguage } })
  }, [dispatch, currentLanguage, slug])

  useEffect(() => {
    dispatch(MOTIVATION.trigger({ blogId: id, userId }))
    dispatch(LAYOUT_CONTENT.trigger({ language: currentLanguage }))
  }, [currentLanguage, dispatch, userId, id])

  useEffect(() => {
    if (blogState?.get('post_layout')) {
      const sections = blogState.getIn(['post_layout', 'path', 'sections'])?.map((section: Section) => section)
      sections?.push({
        link: `/blog/post/${blogState.getIn(['current_post', 'url'])}`,
        text: blogState.getIn(['current_post', 'title']),
      })
      setPathSections(sections)
      const { origin } = new URL(process.env.PUBLIC_URL, window.location.href)
      const urlToShare = `${origin}/blog/post/${blogState.getIn(['current_post', 'url'])}`
      setUrl(urlToShare)
    }
  }, [blogState])

  const submitMotivation = useCallback(() => {
    dispatch(SUBMIT_MOTIVATION.trigger({ blogId: id, userId }))
  }, [dispatch, userId, id])

  const toggleOpenShare = () => {
    setOpenShare(!openShare)
  }

  return (
    <Container>
      <PathIndicator description={blogState?.getIn(['post_layout', 'path', 'description'])} sections={pathSections} />
      <Content>
        {blogState.getIn(['current_post', 'cover_image', 'url']) && (
          <CoverImage background={`url(${blogState.getIn(['current_post', 'cover_image', 'url'])})`} />
        )}
        <Post>
          <Title>{blogState.getIn(['current_post', 'title'])}</Title>
          <AuthorContainer>
            <AuthorImage background={`url(${blogState.getIn(['current_post', 'author', 'image', 'url'])})`} />
            <AuthorLabel>
              {blogState.getIn(['current_post', 'author', 'name'])}
              {blogState.getIn(['current_post', 'author', 'nickname'])
                ? ` - ${blogState.getIn(['current_post', 'author', 'nickname'])}`
                : ''}
            </AuthorLabel>
          </AuthorContainer>
          <Text>
            <HtmlRender>{blogState.getIn(['current_post', 'content'])}</HtmlRender>
          </Text>
        </Post>
      </Content>
      <Footer>
        <FooterMessage>{blogState.getIn(['post_layout', 'motivate'])}</FooterMessage>
        <FooterButtons>
          <MotivateButton onClick={submitMotivation}>
            <MotivateIcon src={MotivatePNG} />
            <MotivateText>
              {blogState.getIn(['current_post', 'motivationsCount'])} {blogState.getIn(['post_layout', 'button'])}
            </MotivateText>
          </MotivateButton>
          <ShareButton onClick={toggleOpenShare}>
            {layout.share}
            <ButtonIcon />
            {openShare && <ShareList fullWidth onClose={toggleOpenShare} url={url} layout={layout.share_list} />}
          </ShareButton>
        </FooterButtons>
      </Footer>
    </Container>
  )
}

export default BlogPost
