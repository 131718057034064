import React, { FC, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

type RedirectProps = {
  children?: FC
}

function WithRedirect<T extends RedirectProps>(Component: FC<T>, path: string): FC<T> {
  return function WithRedirectComponent(props: T) {
    const [redirect, setRedirect] = useState(false)

    useEffect(() => {
      const token = localStorage.getItem('token')
      if (token) {
        setRedirect(true)
      } else {
        setRedirect(false)
      }
    }, [])

    return redirect ? <Redirect to={path} /> : <Component {...props}>{props?.children}</Component>
  }
}

export default WithRedirect
