import { PostularCard } from 'ducks/postular'
import React, { FC } from 'react'

import { Container, Label } from './styled'

type Props = {
  layout: PostularCard
  onSelect: (value: string) => void
  selected?: boolean
}

const InviteCard: FC<Props> = (props: Props) => {
  const { layout, onSelect, selected } = props

  const onSelectOption = () => {
    onSelect(layout.message)
  }

  return (
    <Container
      onClick={onSelectOption}
      style={{ backgroundColor: layout.color, border: selected ? '2px solid #000000' : 'none' }}
    >
      <Label>{layout.message}</Label>
    </Container>
  )
}

export default InviteCard
