import styled from 'styled-components'

export const Container = styled.div`
  padding: 2% 4% 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 44px;
  line-height: 60px;
  color: ${({ theme }) => theme.colors.dark};
  text-align: center;
  margin-bottom: 20px;
  @media screen and (min-width: 1600px) {
    font-size: 48px;
    line-height: 65px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 768px) {
    text-align: left;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.dark};
  margin: 0;
  @media screen and (min-width: 1600px) {
    font-size: 21px;
    line-height: 24px;
  }
`

export const Content = styled.div`
  width: 68%;
  @media screen and (max-width: 1024px) {
    width: 95%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const FoundersNavigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const NavigationButtonContainer = styled.div`
  height: 48px;
  width: 48px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const NavigationButton = styled.div`
  cursor: pointer;
  font-size: 30px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 48px;
  }
`

export const FoundersContainer = styled.div`
  padding: 20px 0 30px;
  margin-top: 20px;
  display: -webkit-inline-box;
  width: 68%;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media screen and (max-width: 1024px) {
    width: 95%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`
