import styled from 'styled-components'

export const FilterTitle = styled.p`
  margin: 7px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.dark};
`

export const EmptyFilterText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.11px;
  color: ${({ theme }) => theme.colors.default};
  margin-left: 15px;
`
