import React, { FC, useEffect, useState } from 'react'
import { FiMail } from 'react-icons/fi'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Newsletter } from 'ducks/layoutData'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

import { Container, ButtonWrapper, Title, SuccessFeedback, InputContainer } from './styled'
import { ButtonWithIcon, ButtonIcon } from 'views/components/UI/Buttons'
import TextInput from 'views/components/TextInput'
import LoadingButtonAnimation from 'views/components/LoadingButtonAnimation'
import SubscribeTerms from 'views/components/SubscribeTerms'
import LearnMoreTerms from 'views/components/LearnMoreTerms'

export interface FormValues {
  email: string
}

export interface FormErrorValues {
  email?: string
}

const initialValues: FormValues = {
  email: '',
}

const initialErrors: FormErrorValues = {}

const validationSchema = yup.object({
  email: yup.string().email().required('Email is Required'),
})

type Props = {
  submit: ({ EMAIL }: { EMAIL: string }) => void
  layout?: Newsletter
  status: string
}

const Form: FC<Props> = (props: Props) => {
  const { submit, layout, status } = props
  const { sendEvent } = useGoogleAnalytics()
  const [submitedSuccess, setsubmitedSuccess] = useState(false)
  const [checked, setChecked] = useState(false)

  const onSubmit = (values: FormValues) => {
    try {
      const { email } = values
      submit({
        EMAIL: email,
      })
      sendEvent({
        label: 'General',
        category: 'Attempt to Subscription',
        action: 'Tried to subscribe into Protagonistas General Newsletter',
      })
    } catch (error) {}
  }

  const { errors, values, handleChange, handleSubmit, resetForm } = useFormik<FormValues>({
    initialValues,
    onSubmit,
    initialErrors,
    validationSchema,
  })

  const handleError = () => {
    if (status === 'error') {
      return layout?.error ? layout?.error : ''
    }
    return errors.email
  }

  const submitWithCheck = () => {
    if (checked) {
      handleSubmit()
    }
  }

  const renderButton = () => {
    switch (status) {
      case 'success':
        if (submitedSuccess) {
          return <SuccessFeedback>{layout?.success}</SuccessFeedback>
        } else {
          return (
            <ButtonWithIcon mobileFullWidth onClick={submitWithCheck}>
              {layout?.button}
              <ButtonIcon />
            </ButtonWithIcon>
          )
        }
      case 'sending':
        return (
          <ButtonWithIcon mobileFullWidth>
            <LoadingButtonAnimation />
          </ButtonWithIcon>
        )
      default:
        return (
          <ButtonWithIcon mobileFullWidth onClick={submitWithCheck}>
            {layout?.button}
            <ButtonIcon />
          </ButtonWithIcon>
        )
    }
  }

  useEffect(() => {
    if (status === 'success') {
      resetForm()
      setsubmitedSuccess((prevState) => {
        if (!prevState) {
          setTimeout(() => setsubmitedSuccess(false), process.env.REACT_APP_TIMEOUT_FEEDBACK || 10000)
        }
        return !prevState
      })
    }
  }, [status, resetForm])

  useEffect(() => {
    if (status === 'success') {
      sendEvent({
        label: 'General',
        category: 'Subscription',
        action: 'Subscribed into Protagonistas General Newsletter',
      })
    }
  }, [status, sendEvent])

  const handleCheckbox = () => {
    setChecked((prevState) => !prevState)
  }

  return (
    <Container>
      <Title>{layout?.title}</Title>
      <InputContainer>
        <TextInput
          name="email"
          placeholder={layout?.placeholder}
          icon={FiMail}
          value={values.email}
          error={handleError()}
          onChange={handleChange}
          noMargin
        />
      </InputContainer>
      <SubscribeTerms checked={checked} handleCheckbox={handleCheckbox} />
      <LearnMoreTerms />
      <ButtonWrapper>{renderButton()}</ButtonWrapper>
    </Container>
  )
}

export default Form
