import React, { FC, useEffect, useState } from 'react'
import { useLanguage } from 'hooks/useLanguage'
import { CONTENT, registerLayoutSelector, FormValues, FormErrors } from 'ducks/registerLayout'
import { REGISTER, userSelector } from 'ducks/user'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Redirect } from 'react-router-dom'

import { Container, Form, Title, ButtonContainer, LinkContainer, Text, LinkText } from './styled'
import TextInput from 'views/components/TextInput'
import { ButtonIcon, ButtonWithIcon } from 'views/components/UI/Buttons'
import WithRedirect from 'views/components/WithRedirect'

const initialValues: FormValues = {
  name: '',
  email: '',
  password: '',
}

const initialErrors: FormErrors = {}

const validationSchema: yup.ObjectSchema<FormValues> = yup
  .object({
    name: yup.string().required('Name is required').defined(),
    email: yup.string().email().required('Email is required').defined(),
    password: yup.string().required('Password is required').defined(),
  })
  .defined()

const Register: FC = () => {
  const dispatch = useDispatch()
  const registerLayoutState = useSelector(registerLayoutSelector)
  const { currentLanguage } = useLanguage()
  const userState = useSelector(userSelector)
  const [success, setSuccess] = useState(false)

  // If register is successful token is set on state and redirect to profile
  useEffect(() => {
    if (userState.get('token')) {
      setSuccess(true)
    } else {
      setSuccess(false)
    }
  }, [userState])

  const onSubmit = (values: FormValues) => {
    dispatch(REGISTER.trigger({ ...values, username: values.email }))
  }

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues,
    initialErrors,
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    dispatch(CONTENT.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage])

  return success ? (
    <Redirect to="/perfil" />
  ) : (
    <Container>
      <Form>
        <Title>{registerLayoutState.get('title')}</Title>
        <TextInput
          name="name"
          placeholder={registerLayoutState.get('name')}
          value={values.name}
          error={errors.name}
          onChange={handleChange}
        />
        <TextInput
          name="email"
          placeholder={registerLayoutState.get('email')}
          value={values.email}
          error={errors.email}
          onChange={handleChange}
        />
        <TextInput
          name="password"
          placeholder={registerLayoutState.get('password')}
          value={values.password}
          error={errors.password}
          onChange={handleChange}
          inputType="password"
        />
        <ButtonContainer>
          <ButtonWithIcon mobileFullWidth onClick={handleSubmit}>
            {registerLayoutState.get('button')}
            <ButtonIcon />
          </ButtonWithIcon>
        </ButtonContainer>
        <LinkContainer>
          <Text>{registerLayoutState.get('link_description')}</Text>
          <LinkText to={registerLayoutState.get('link')}>{registerLayoutState.get('link_label')}</LinkText>
        </LinkContainer>
      </Form>
    </Container>
  )
}

export default WithRedirect(Register, '/perfil')
