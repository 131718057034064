import styled from 'styled-components'

export const Container = styled.div`
  width: 160px;
  height: 160px;
  padding: 1rem;
  margin: 10px;
  @media screen and (min-width: 1600px) {
    width: 230px;
    height: 230px;
  }
  @media screen and (max-width: 450px) {
    margin: 5px;
  }
`

export const Label = styled.label`
  font-family: Nunito Sans;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.dark};
  cursor: pointer;
  @media screen and (min-width: 1600px) {
    font-size: 22px;
    line-height: 30px;
  }
`
