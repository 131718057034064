import { IntroSection } from 'ducks/aboutLayout'
import React, { FC } from 'react'
import { Container, Card, Title, Content, Subtitle, LeftBox, RightBox, LeftImage, RightImage } from './styled'
import { Text } from 'views/screens/AboutUs/styled'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  layout: IntroSection
}

const InitialSection: FC<Props> = (props: Props) => {
  const { layout } = props

  return (
    <Container>
      <LeftBox>
        <Card>
          <LeftImage background={`url(${layout?.left_image?.url})`} width="50%" />
          <Content width="50%">
            <Title>{layout?.left_title}</Title>
          </Content>
        </Card>
      </LeftBox>
      <RightBox>
        <Card>
          <RightImage background={`url(${layout?.right_image?.url})`} />
          <Content width="40%">
            <Subtitle>{layout?.right_title}</Subtitle>
            <Text>
              <HtmlRender>{layout?.right_description}</HtmlRender>
            </Text>
          </Content>
        </Card>
      </RightBox>
    </Container>
  )
}

export default InitialSection
