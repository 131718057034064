import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 4rem;
  @media screen and (max-width: 450px) {
    flex-direction: column;
    padding: 2rem;
  }
`

export const Subtitle = styled.div`
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 1.245px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 20px;
  }
  @media screen and (min-width: 1600px) {
    font-size: 16px;
  }
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.dark};
  margin: 15px 0 30px;
  @media screen and (min-width: 1600px) {
    font-size: 34px;
    line-height: 39px;
  }
  @media screen and (max-width: 768px) {
    font-size: 22px;
    line-height: 22px;
  }
`
