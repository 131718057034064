import styled from 'styled-components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { space, SpaceProps, flexbox, FlexboxProps } from 'styled-system'

type ContainerProps = {
  paddingLeft?: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: ${(props) => (props.paddingLeft ? '3em' : '2rem')};
  padding-right: ${(props) => (props.paddingLeft ? '0' : '2rem')};
  @media (max-width: 768px) {
    padding-left: 0;
    margin-bottom: 30px;
  }
  @media (max-width: 1250px) {
    padding-right: 0;
  }
`

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 0 1rem;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

type FullRowProps = {
  marginVertical?: boolean
}

export const FullRow = styled.div<FullRowProps>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 40px;
  margin: ${({ marginVertical }) => (marginVertical ? '24px 0' : '0')};
  @media (max-width: 600px) {
    padding-left: 0;
    justify-content: center;
  }
`

export const LoadMoreButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.dark};
  border-radius: 4px;
  height: 34px;
  min-width: 180px;
  width: 50%;
  font-size: 14px;
  margin-bottom: 16px;
  padding: 0 35px;
  font-weight: bold;
`

export const LoadMoreButtonIcon = styled(ChevronRightIcon)`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 14px;
`

export const FilterContainer = styled.div`
  width: 40%;
  min-width: 220px;
  @media (max-width: 1600px) {
    width: 25%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`

interface MovimientosContainerProps extends SpaceProps, FlexboxProps {}

export const MovimientosContainer = styled.div<MovimientosContainerProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${space}
  ${flexbox}
`

export const SectionLine = styled.div`
  width: 40%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.black};
  position: absolute;
  right: -4rem;
  top: 14px;
  @media (max-width: 878px) {
    width: 30%;
  }
  @media (max-width: 600px) {
    width: 0;
  }
`

export const SectionTitle = styled.div`
  margin: 0;
  font-family: 'PT Serif Caption', serif;
  font-size: 18px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.25px;
  color: ${({ theme }) => theme.colors.dark};
`
export const SectionHeader = styled.div`
  margin-top: 120px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  position: relative;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
`
