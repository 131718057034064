import styled from 'styled-components'

type ContainerProps = {
  color?: string
}

export const Container = styled.div<ContainerProps>`
  cursor: pointer;
  padding: 2px;
  color: ${({ theme, color }) => (color ? color : theme.colors.black)};
`

export const ButtonContainer = styled.div`
  cursor: pointer;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }
  @media screen and (max-width: 450px) {
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }
`

export const FeedbackContainer = styled.div`
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }
  @media screen and (max-width: 450px) {
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }
`

export const FeedbaackText = styled.p`
  font-size: 12px;
  margin: 5px 0;
`
