import React, { FC } from 'react'
import {
  Container,
  Header,
  TopMidia,
  Content,
  Title,
  SectionLine,
  TopRightImage,
  Section,
  Card,
  BottomRightImage,
  Description,
} from './styled'
import TopLeftVideo from './TopLeftVideo'
import BottomRightVideo from './BottomRightVideo'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  title: string
  section: {
    description: string
    top_video?: string
    top_thumbnail?: {
      url: string
    }
    top_right_image?: {
      url: string
    }
    bottom_right_video?: string
    bottom_right_thumbnail?: {
      url: string
    }
    bottom_right_image?: {
      url: string
    }
  }
}

const ThirdSection: FC<Props> = (props: Props) => {
  const { title, section } = props

  return (
    <Container>
      <TopMidia>
        <TopLeftVideo video={section?.top_video} thumbnail={section?.top_thumbnail?.url} />
        <TopRightImage background={`url(${section?.top_right_image?.url})`} />
      </TopMidia>
      <Section>
        <Card>
          <Header>
            <Title>{title}</Title>
            <SectionLine />
          </Header>
          <Description>
            <HtmlRender>{section?.description}</HtmlRender>
          </Description>
        </Card>
        <Content>
          <BottomRightVideo video={section?.bottom_right_video} thumbnail={section?.bottom_right_thumbnail?.url} />
          <BottomRightImage background={`url(${section?.bottom_right_image?.url})`} />
        </Content>
      </Section>
    </Container>
  )
}

export default ThirdSection
