import React, { FC } from 'react'
import { Example } from 'ducks/colaboraLayout'

import {
  Container,
  FirstContent,
  FirstLeftImage,
  FirstRightContent,
  CardWrapper,
  CardBody,
  Sentence,
  SecondContent,
  Header,
  ImageLabel,
  SecondLeftContent,
  SecondRightImage,
} from './styled'
import { Title, Text, Subtitle } from '../styled'
import { Card, Block } from 'views/components/UI/Contents'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  layout?: Example
}

const ExampleSection: FC<Props> = (props: Props) => {
  const { layout } = props
  return (
    <Container>
      <FirstContent>
        <FirstLeftImage background={`url(${layout?.left_image?.url})`} />
        <FirstRightContent>
          <Title>{layout?.title}</Title>
          <CardWrapper>
            <Card color={layout?.right_card_color}>
              <CardBody>
                <Text>
                  <HtmlRender>{layout?.right_card_description}</HtmlRender>
                </Text>
              </CardBody>
            </Card>
          </CardWrapper>
          <Sentence>{layout?.right_sentence}</Sentence>
        </FirstRightContent>
      </FirstContent>
      <Header>{layout?.right_image_label && <ImageLabel>{layout?.right_image_label}</ImageLabel>}</Header>
      <SecondContent>
        <SecondLeftContent>
          <CardWrapper bottomCard>
            <Card background={`url(${layout?.left_card_background?.url})`} color={layout?.left_card_color}>
              <CardBody bottomCard>
                <Subtitle>{layout?.left_card_title}</Subtitle>
                <Text>
                  <HtmlRender>{layout?.left_card_description}</HtmlRender>
                </Text>
              </CardBody>
            </Card>
          </CardWrapper>
          <Block marginTop="1rem">
            <Text>
              <HtmlRender>{layout?.left_description}</HtmlRender>
            </Text>
          </Block>
        </SecondLeftContent>
        <SecondRightImage background={`url(${layout?.right_image?.url})`} />
      </SecondContent>
    </Container>
  )
}

export default ExampleSection
