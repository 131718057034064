import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div<BackgroundProps>`
  ${background};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  width: 100%;
  height: 500px;
  padding: 0rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 1600px) {
    height: 600px;
  }
  @media screen and (max-width: 450px) {
    height: 300px;
    padding: 0 1rem 2rem;
  }
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 48px;
  letter-spacing: 0.099px;
  color: ${({ theme }) => theme.colors.white};
  @media screen and (max-width: 450px) {
    font-size: 40px;
  }
`
