import { LinkRow as ILinkRow } from 'ducks/layoutData'
import React, { FC } from 'react'
import { InternalLink, ExternalLink } from './styled'

type Props = {
  content: ILinkRow
}

const LinkRow: FC<Props> = ({ content }: Props) =>
  content?.link?.external ? (
    <ExternalLink href={content?.link?.value} target="blank">
      {content?.label}
    </ExternalLink>
  ) : (
    <InternalLink to={content?.link?.value}>{content?.label}</InternalLink>
  )

export default LinkRow
