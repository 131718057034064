import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CONTENT, profileLayoutSelector, FormValues, FormErrors } from 'ducks/profileLayout'
import { CONTENT as CATEGORIES_CONTENT, categoriesSelector, Category } from 'ducks/categories'
import { CONTENT as COUNTRIES_CONTENT } from 'ducks/countries'
import { PROFILE_CONTENT, successLayoutSelector } from 'ducks/successLayout'
import { userSelector, CHECK_CREATE_PROFILE, SET } from 'ducks/user'
import { SUBMIT_PROFILE, protagonistasSelector, CONTENT as PROTAGONISTAS_CONTENT } from 'ducks/protagonistas'
import { useLanguage } from 'hooks/useLanguage'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { useMediaQuery } from '@material-ui/core'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

import {
  Container,
  PathIndicatorContainer,
  BannerContent,
  Title,
  Description,
  Content,
  ButtonWrapper,
  BottomBannerContainer,
  BannerLink,
} from './styled'
import PathIndicator from 'views/components/PathIndicator'
import HtmlRender from 'views/components/HtmlRender'
import { Block } from 'views/components/UI/Contents'
import { ButtonIcon, ButtonWithIcon } from 'views/components/UI/Buttons'
import AboutSection from './AboutSection'
import HistorySection from './HistorySection'
import SuccessView from 'views/components/SuccessView'
import Banner from 'views/components/Banner'

const initialValues: FormValues = {
  category: '',
  country: '',
  nickname: '',
  name: '',
  last_name: '',
  about_me: '',
  instagram: '',
  facebook: '',
  twitter: '',
  linkedin: '',
  email: '',
  history: '',
  image: null,
  gallery: [],
  terms_checked: false,
  checked: false,
}

const initialErrors: FormErrors = {}

const ProtagonistaProfile: FC = () => {
  const dispatch = useDispatch()
  const { currentLanguage } = useLanguage()
  const matches = useMediaQuery('(max-width: 768px)')
  const history = useHistory()
  const { sendEvent } = useGoogleAnalytics()

  const userState = useSelector(userSelector)
  const profileLayoutState = useSelector(profileLayoutSelector)
  const successLayoutState = useSelector(successLayoutSelector)
  const protagonistasState = useSelector(protagonistasSelector)
  const categoriesState = useSelector(categoriesSelector)

  const { origin } = new URL(process.env.PUBLIC_URL, window.location.href)
  const url = `${origin}/protagonistas`

  const validationSchema = yup.object({
    category: yup.string().required('Category is required'),
    country: yup.string().required('Country is required'),
    nickname: yup.string(),
    name: yup.string().required('Name is required'),
    last_name: yup.string().required('Last Name is required'),
    about_me: yup
      .string()
      .max(profileLayoutState.getIn(['about_section', 'max_character']))
      .required('About me section is required'),
    instagram: yup.string(),
    facebook: yup.string(),
    twitter: yup.string(),
    linkedin: yup.string(),
    email: yup.string().required(),
    history: yup.string().required('History is required'),
    image: yup.mixed().required(),
    gallery: yup.mixed().required(),
    terms_checked: yup.boolean().oneOf([true], 'Checked is required'),
    checked: yup.boolean().oneOf([true], 'Checked is required'),
  })

  const onSubmit = (values: FormValues) => {
    const {
      category,
      country,
      nickname,
      name,
      last_name,
      about_me,
      instagram,
      facebook,
      twitter,
      linkedin,
      email,
      history,
      image,
      gallery,
    } = values
    const profile = {
      category,
      country,
      nickname,
      first_name: name,
      last_name,
      about_me,
      instagram,
      facebook,
      twitter,
      linkedin,
      email,
      history,
      picture: image,
      gallery,
      user: userState.get('id'),
    }
    dispatch(SUBMIT_PROFILE.trigger(profile))
    sendEvent({
      label: 'Create Profile',
      category: 'Protagonista Profile',
      action: `${profile.first_name} tried to create his profile as a Protagonista.`,
    })
  }

  const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    initialErrors,
    validationSchema,
    onSubmit,
    validateOnChange: false,
  })

  useEffect(() => {
    const isEmpty = _.isEmpty(errors)
    if (!isEmpty) {
      window.scrollTo(0, 0)
    }
  }, [errors])

  useEffect(() => {
    if (!userState.get('createdProfile')) {
      dispatch({ type: CHECK_CREATE_PROFILE, payload: userState.get('id') })
    }
    dispatch(CONTENT.trigger({ language: currentLanguage }))
    dispatch(CATEGORIES_CONTENT.trigger({ language: currentLanguage }))
    dispatch(COUNTRIES_CONTENT.trigger({ language: currentLanguage }))
    dispatch(PROFILE_CONTENT.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage, userState])

  useEffect(() => {
    if (protagonistasState.get('profile_success')) {
      sendEvent({
        label: 'Create Profile Succeed',
        category: 'Succeed Protagonista Profile Submission',
        action: `A Protagonista profile was successfully created for ${values.name}.`,
      })
      history.push({
        pathname: '/success',
        state: {
          success: true,
          title: `${successLayoutState.get('title')} ${values.name}.`,
          message: successLayoutState.get('description'),
          card_color: successLayoutState.get('card_color'),
          card_background: successLayoutState.get('card_background'),
          link: successLayoutState.get('link'),
          button: successLayoutState.get('button'),
        },
      })
    }
  }, [protagonistasState, history, dispatch, successLayoutState, values, sendEvent])

  useEffect(() => {
    return () => {
      dispatch(PROTAGONISTAS_CONTENT.success({ profile_success: false }))
      dispatch({ type: SET, payload: { createdProfile: false } })
    }
  }, [dispatch])

  return userState.get('createdProfile') ? (
    <SuccessView
      title={profileLayoutState.getIn(['feedback', 'title'])}
      message={profileLayoutState.getIn(['feedback', 'message'])}
      button={profileLayoutState.getIn(['feedback', 'button'])}
      link={profileLayoutState.getIn(['feedback', 'link'])}
    />
  ) : (
    <Container>
      <PathIndicatorContainer>
        <PathIndicator
          description={profileLayoutState.getIn(['path', 'description'])}
          sections={profileLayoutState.getIn(['path', 'sections'])}
        />
      </PathIndicatorContainer>
      <Block>
        <Banner visible closeable>
          <BannerContent>
            <Title>{profileLayoutState.get('title')}</Title>
            <Description>
              <HtmlRender>{profileLayoutState.get('description')}</HtmlRender>
            </Description>
            <BannerLink target="blank" href={url}>
              {profileLayoutState.get('inspiration_label')}
            </BannerLink>
          </BannerContent>
        </Banner>
      </Block>
      <Content>
        <AboutSection
          layout={profileLayoutState.get('about_section')}
          values={values}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          maxChar={profileLayoutState.getIn(['about_section', 'max_character'])}
          maxCharMessage={profileLayoutState.get('max_char_message')}
          fieldFeedback={profileLayoutState.get('feedback_field')}
          categoriesOptions={categoriesState.get('categories').map((category: Category) => ({
            value: category.name,
            label: category.name,
          }))}
        />
        <HistorySection
          layout={profileLayoutState.get('history_section')}
          values={values}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          fieldFeedback={profileLayoutState.get('feedback_field')}
        />
      </Content>
      <BottomBannerContainer>
        <Block width={matches ? '100%' : '70%'}>
          <Banner backgroundColor="#ED8C9B" visible={true}>
            <Description>
              <HtmlRender>{profileLayoutState.get('message')}</HtmlRender>
            </Description>
            <ButtonWrapper>
              <ButtonWithIcon mobileFullWidth onClick={handleSubmit}>
                {profileLayoutState.get('button')}
                <ButtonIcon />
              </ButtonWithIcon>
            </ButtonWrapper>
          </Banner>
        </Block>
      </BottomBannerContainer>
    </Container>
  )
}

export default ProtagonistaProfile
