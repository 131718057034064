import { HistorySection as HistorySectionType, FormValues, FormErrors, ImagePreview } from 'ducks/profileLayout'
import React, { FC, ChangeEvent, useState, useCallback, useEffect } from 'react'
import { MdDeleteForever } from 'react-icons/md'
import _ from 'lodash'

import {
  Container,
  Card,
  CardHeader,
  CardTitle,
  ContentContainer,
  SliderContainer,
  DescriptionContainer,
  SectionLine,
  BackgroundImage,
  ButtonContainer,
  FeedbackMessage,
} from './styled'
import TextArea from 'views/components/UI/TextArea'
import ImageDropzone from 'views/components/ImageDropzone'
import GallerySlider from 'views/components/GallerySlider'

type Props = {
  layout: HistorySectionType
  values: FormValues
  errors: FormErrors
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  setFieldValue: (field: string, value: File | Array<File>) => void
  fieldFeedback: string
}

const GALLERY_MAX_SIZE = 7

const HistorySection: FC<Props> = (props: Props) => {
  const { layout, values, errors, handleChange, setFieldValue, fieldFeedback } = props
  const [galleryPictures, setGalleryPictures] = useState<Array<File>>([])
  const [galleryPreviews, setGalleryPreviews] = useState<Array<ImagePreview>>([])

  useEffect(() => {
    if (galleryPictures.length > 0) {
      setFieldValue('gallery', galleryPictures)
    }
  }, [galleryPictures, setFieldValue])

  const handleGalleryPreview = (previews: Array<ImagePreview>) => {
    const preview = [...galleryPreviews, ...previews]
    setGalleryPreviews(preview.slice(0, GALLERY_MAX_SIZE))
  }

  const handleGalleryFiles = useCallback(
    (filesFromDropzone: Array<File>) => {
      setGalleryPictures((prevState) => {
        return [...prevState, ...filesFromDropzone].slice(0, GALLERY_MAX_SIZE)
      })
    },
    [setGalleryPictures]
  )

  const deleteFile = (index: number) => {
    setGalleryPictures((prevState) => {
      const newState = _.clone(prevState)
      _.pullAt(newState, [index])
      return newState
    })
    setGalleryPreviews((prevState) => {
      const newState = _.clone(prevState)
      _.pullAt(newState, [index])
      return newState
    })
  }

  const renderGalleryChildren = () => {
    if (galleryPreviews.length >= GALLERY_MAX_SIZE) {
      return galleryPreviews.map((image: ImagePreview, index: number) => (
        <BackgroundImage key={image.name} background={`url(${image.preview})`}>
          <ButtonContainer onClick={() => deleteFile(index)}>
            <MdDeleteForever />
          </ButtonContainer>
        </BackgroundImage>
      ))
    }
    return [
      <BackgroundImage key="image-dropzone" background={`url(${layout.image_placeholder?.url})`}>
        <ImageDropzone
          setPreviews={handleGalleryPreview}
          maxNumberOfFiles={7}
          message={layout.add_image}
          showIcon
          setFiles={handleGalleryFiles}
          error={errors.gallery}
        />
      </BackgroundImage>,
      ...galleryPreviews.map((image: ImagePreview, index: number) => (
        <BackgroundImage key={image.name} background={`url(${image.preview})`}>
          <ButtonContainer onClick={() => deleteFile(index)}>
            <MdDeleteForever />
          </ButtonContainer>
        </BackgroundImage>
      )),
    ]
  }

  return (
    <Container>
      <Card>
        <CardHeader>
          <CardTitle>{layout?.title}</CardTitle>
          <SectionLine />
        </CardHeader>
        <ContentContainer>
          <SliderContainer>
            <GallerySlider>{renderGalleryChildren()}</GallerySlider>
          </SliderContainer>
          <DescriptionContainer>
            {errors.history && <FeedbackMessage>{fieldFeedback}</FeedbackMessage>}
            <TextArea
              fullHeight
              name="history"
              value={values.history}
              error={errors.history}
              onChange={handleChange}
              placeholder={layout?.placeholder}
            />
          </DescriptionContainer>
        </ContentContainer>
      </Card>
    </Container>
  )
}

export default HistorySection
