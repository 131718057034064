import { Founder, FoundersSection } from 'ducks/aboutLayout'
import React, { MutableRefObject, useRef, useState, FC } from 'react'
import {
  Container,
  Title,
  Text,
  Content,
  FoundersNavigation,
  FoundersContainer,
  NavigationButton,
  NavigationButtonContainer,
} from './styled'
import HtmlRender from 'views/components/HtmlRender'
import FounderIcon from './FounderIcon'
import { MdChevronLeft as LeftIcon, MdChevronRight as RightIcon } from 'react-icons/md'
import FounderCard from './FounderCard'

type Props = {
  layout: FoundersSection
}

const Founders: FC<Props> = (props) => {
  const { layout } = props
  const containerRef = useRef() as MutableRefObject<HTMLDivElement>
  const [selectedFounder, setSelectedFounder] = useState<Founder | null>(null)
  const [isOnStart, setIsOnStart] = useState<boolean>(true)

  const onClickLeft = () => {
    setIsOnStart(true)
    containerRef.current.scroll({
      left: 0,
      behavior: 'smooth',
    })
  }

  const onClickRight = () => {
    setIsOnStart(false)
    containerRef.current.scroll({
      left: containerRef.current.scrollWidth,
      behavior: 'smooth',
    })
  }

  return (
    <Container>
      <Content>
        <Title>{layout?.title}</Title>
        <Text>
          <HtmlRender>{layout?.description}</HtmlRender>
        </Text>
      </Content>
      <FoundersNavigation>
        <NavigationButtonContainer>
          {!isOnStart && (
            <NavigationButton onClick={onClickLeft}>
              <LeftIcon />
            </NavigationButton>
          )}
        </NavigationButtonContainer>
        <FoundersContainer ref={containerRef}>
          {layout?.founders?.map((founder: Founder) => (
            <FounderIcon
              founder={founder}
              key={founder.id}
              onClick={setSelectedFounder}
              active={selectedFounder?.id === founder.id}
            />
          ))}
        </FoundersContainer>
        <NavigationButtonContainer>
          {isOnStart && layout?.founders?.length > 5 && (
            <NavigationButton onClick={onClickRight}>
              <RightIcon />
            </NavigationButton>
          )}
        </NavigationButtonContainer>
      </FoundersNavigation>
      {selectedFounder && (
        <FounderCard
          founder={selectedFounder}
          onClickClose={setSelectedFounder}
          showEmailMessage={layout?.show_email}
          button={layout?.button}
          link={layout?.link}
        />
      )}
    </Container>
  )
}

export default Founders
