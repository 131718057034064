import React, { FC } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import Form from './Form'
import { Newsletter } from 'ducks/layoutData'
import { Record } from 'immutable'

type Props = {
  layout: Record<Newsletter>
}

const NewsletterSubscription: FC<Props> = (props: Props) => {
  const { layout } = props

  return (
    <MailchimpSubscribe
      url={process.env.REACT_APP_MAILCHIMP_URL}
      render={({ subscribe, status }: { subscribe: any; status: string }) => (
        <Form submit={subscribe} layout={layout?.toJS()} status={status} />
      )}
    />
  )
}

export default NewsletterSubscription
