import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  position: relative;
  width: 96.7%;
  margin-top: 20px;
  @media screen and (min-width: 1600px) {
    width: 97.7%;
  }
  @media screen and (max-width: 1250px) {
    width: 100%;
    margin-top: 0;
  }
  @media screen and (max-width: 1024px) {
    margin: 20px 0;
  }
`

export const Section = styled.div`
  transform: translateY(-20%);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1250px) {
    transform: translateY(0);
    flex-direction: column;
  }
`

export const Card = styled.div`
  width: 90%;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20% 2.5rem 3rem;
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  -moz-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  border-radius: 4px;
  @media screen and (min-width: 1600px) {
    padding: 12% 2.5rem 2.5rem;
  }
  @media screen and (max-width: 1250px) {
    width: 100%;
    padding: 2rem;
    min-height: unset;
  }
  @media screen and (max-width: 450px) {
    padding: 2rem 1rem;
  }
`

export const TopMidia = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const Content = styled.div`
  padding-top: 30%;
  right: 0%;
  position: absolute;
  width: 35.7%;
  z-index: 1;
  @media screen and (max-width: 1250px) {
    width: 100%;
    position: unset;
    padding-top: 20px;
  }
`

export const Title = styled.p`
  font-family: PTSerif-Caption;
  font-size: 18px;
  line-height: normal;
  letter-spacing: 1.25px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 1250px) {
    text-align: center;
  }
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  position: relative;
`

export const SectionLine = styled.div`
  width: 45%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.black};
  position: absolute;
  left: -6.5rem;
  top: 12px;
  @media screen and (max-width: 1250px) {
    display: none;
  }
`

export const TopRightImage = styled.div<BackgroundProps>`
  z-index: 1;
  width: 35.7%;
  ${background};
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  height: 350px;
  background-color: grey;
  @media screen and (max-width: 1250px) {
    width: 48%;
    height: 300px;
  }
  @media screen and (min-width: 1600px) {
    height: 450px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 20px;
    width: 100%;
    height: 400px;
  }
  @media screen and (max-width: 450px) {
    height: 250px;
  }
`

export const Description = styled.div`
  width: 70%;
  font-size: 16px;
  margin-top: 2rem;
  line-height: 1.5;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 1250px) {
    width: 100%;
  }
`

export const BottomRightImage = styled.div<BackgroundProps>`
  margin-top: 8%;
  width: 100%;
  ${background};
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  height: 350px;
  background-color: grey;
  @media screen and (max-width: 1250px) {
    margin: 20px 0 20px;
  }
  @media screen and (max-width: 450px) {
    height: 250px;
  }
`
