import React, { FC } from 'react'
import { Container, Image, Title, Subtitle, Description } from './styled'
import { Block } from 'views/components/UI/Contents'
import { VerticalButtonWitchIcon, Icon } from 'views/components/UI/Buttons'
import HtmlRender from 'views/components/HtmlRender'
import { useHistory } from 'react-router-dom'

interface Props {
  image: string
  title: string
  subtitle: string
  description: string
  right_button: string
  right_link: string
}

const FlatArticle: FC<Props> = ({ title, subtitle, image = '', description, right_button, right_link }) => {
  const history = useHistory()

  const onClick = () => {
    history.push(right_link)
  }

  return (
    <Container>
      <Image backgroundImage={`url(${image})`} backgroundSize="cover" />
      <Block width="65%" paddingRight="3rem">
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Description>
          <HtmlRender>{description}</HtmlRender>
        </Description>
        <Block position="absolute" bottom="-30px" right="60px">
          {right_link && (
            <VerticalButtonWitchIcon color="white" bg="#727272" onClick={onClick}>
              <p>
                {right_button}
                <Icon>&gt;</Icon>
              </p>
            </VerticalButtonWitchIcon>
          )}
        </Block>
      </Block>
    </Container>
  )
}
export default FlatArticle
