import styled from 'styled-components'

export const Container = styled.div`
  padding-bottom: 4rem;
  @media screen and (max-width: 450px) {
    padding-bottom: 1rem;
  }
`

export const Content = styled.div`
  padding: 4rem 4rem 1rem;
  @media screen and (max-width: 450px) {
    padding: 1rem 1rem 0;
  }
`

export const BannerContent = styled.div`
  align-self: center;
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`

export const BannerLink = styled.a`
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.formLink};
  text-decoration: underline;
  @media screen and (max-width: 450px) {
    font-size: 14px;
    line-height: 20px;
  }
`

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: -10%;
  right: 5%;
  @media screen and (max-width: 768px) {
    bottom: -5%;
  }
  @media screen and (max-width: 450px) {
    bottom: -15%;
    width: 90%;
  }
`

export const BottomBannerContainer = styled.div`
  width: 100%;
  padding: 1rem 4rem;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 450px) {
    padding: 1rem 1rem 3rem;
  }
`

export const Description = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 450px) {
    font-size: 14px;
    line-height: 20px;
  }
`

export const PathIndicatorContainer = styled.div`
  padding: 0 1rem;
  @media screen and (max-width: 450px) {
    padding: 0;
  }
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 48px;
  line-height: 55px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 450px) {
    font-size: 28px;
    line-height: 35px;
  }
`
