import React, { FC } from 'react'
import { Container, LoaderContainer } from './styled'

type Props = {
  title?: boolean
  rows: number
}

const TextLoader: FC<Props> = ({ title, rows }: Props) => {
  const rowsToRender = [...Array(rows)].map(() => 0).map((x, i) => x + i)

  return (
    <Container>
      {title && (
        <LoaderContainer>
          <rect width="50%" height="100%" />
        </LoaderContainer>
      )}
      {rowsToRender.map((row) => (
        <LoaderContainer key={row}>
          <rect width="100%" height="100%" />
        </LoaderContainer>
      ))}
    </Container>
  )
}

export default TextLoader
