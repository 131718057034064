import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { ILoginLayout } from './../models'
import * as TYPES from './../actionTypes'

export const initialState: ILoginLayout = fromJS({
  loading: false,
  login_section: {},
  create_account_section: {},
})

const loginLayoutReducer: Reducer<ILoginLayout> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET:
      return state.merge(action.payload)
    case TYPES.CONTENT.TRIGGER:
      return state.set('loading', true)
    case TYPES.CONTENT.FAILURE:
      return state.set('error', action.payload)
    case TYPES.CONTENT.FULFILL:
      return state.set('loading', false)
    case TYPES.CONTENT.SUCCESS:
      return state.merge(action.payload)
    default:
      return state
  }
}

export default loginLayoutReducer
