import styled from 'styled-components'

type BodyProps = {
  large?: boolean
}

export const CardBody = styled.div<BodyProps>`
  padding: 2rem;
  padding: ${({ large }) => (large ? '3rem 0 3rem 3rem' : '2rem')};
  @media screen and (min-width: 1600px) {
    padding: ${({ large }) => (large ? '3rem 0 3rem 4rem' : '3rem')};
  }
  @media screen and (max-width: 450px) {
    padding: 1rem;
  }
`

export const Container = styled.div`
  width: 100%;
`

export const CompaniesContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const CompanyLogo = styled.img`
  max-width: 100px;
  max-height: 50px;
`
