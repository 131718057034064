import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

export const TermsContent = styled.div``

export const Text = styled.div`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.default};
`

export const TextLink = styled(Link)`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.09px;
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.formLink};
  color: ${({ theme }) => theme.colors.formLink};
`
