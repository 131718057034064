import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Flag = styled.img`
  height: 17px;
  width: 20px;
  margin-right: 4px;
`

export const Text = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.dark};
`
