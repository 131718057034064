import React, { FC, useEffect } from 'react'
import { CONTENT, contactoSelector, FormValues, FormErrorValues, SUBMIT, SET } from 'ducks/contacto'
import { useDispatch, useSelector } from 'react-redux'
import { useLanguage } from 'hooks/useLanguage'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'
import { useFormik } from 'formik'

import { Container, Section } from './styled'
import Welcome from './Welcome'
import Help from './Help'
import Form from './Form'
import * as yup from 'yup'

const initialValues: FormValues = {
  name: '',
  email: '',
  confirm_email: '',
  phone: '',
  query_type: '',
  message: '',
  checked: false,
  privacy_checked: false,
}

const initialErrors: FormErrorValues = {}

const validationSchema = yup.object({
  name: yup.string().required('Name is Required'),
  email: yup.string().email().required('Email is Required'),
  confirm_email: yup.string().oneOf([yup.ref('email'), undefined], 'Email must match'),
  phone: yup.string(),
  query_type: yup.string().required('Query Type Is Required'),
  message: yup.string().required('Message is required'),
  checked: yup.boolean().oneOf([true], 'Checked is required'),
  privacy_checked: yup.boolean().oneOf([true], 'Checked is required'),
})

const Contacto: FC = () => {
  const dispatch = useDispatch()
  const { currentLanguage } = useLanguage()
  const contacto = useSelector(contactoSelector)
  const success = contacto.get('submit_success')
  const { sendEvent, trackModal } = useGoogleAnalytics()

  const onSubmit = (values: FormValues) => {
    const { name, email, phone, query_type, message } = values
    sendEvent({
      label: 'Contacto',
      category: 'Attempt to Submit Form',
      action: 'Tried to contact us',
    })
    dispatch(
      SUBMIT.trigger({
        name,
        mail: email,
        number: phone,
        query: query_type,
        msg: message,
      })
    )
  }

  const { errors, values, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik<FormValues>({
    initialValues,
    onSubmit,
    initialErrors,
    validationSchema,
  })

  useEffect(() => {
    if (success) {
      resetForm()
      setTimeout(
        () => dispatch({ type: SET, payload: { submit_success: false } }),
        process.env.REACT_APP_TIMEOUT_FEEDBACK || 10000
      )
    }
  }, [success, resetForm, dispatch])

  useEffect(() => {
    if (success) {
      sendEvent({
        label: 'Contacto',
        category: 'Success Submit Form',
        action: 'Successfully contacted us',
      })
      trackModal('/success-contact')
    }
  }, [success, sendEvent, trackModal])

  useEffect(() => {
    dispatch(CONTENT.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage])

  return (
    <Container>
      <Welcome
        background={contacto.getIn(['main_image', 'url'])}
        title={contacto.get('title')}
        description={contacto.get('description')}
      />
      <Section>
        <Help
          title={contacto.get('section_title')}
          visibility={contacto.get('visibility')}
          guide={contacto.get('guide')}
          support={contacto.get('card')}
        />
        <Form
          layout={contacto.get('form')}
          errors={errors}
          values={values}
          onSubmit={handleSubmit}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          success={success}
          loading={contacto.get('submit_loading')}
        />
      </Section>
    </Container>
  )
}

export default Contacto
