import { HomeAboutCole } from 'ducks/elCole'
import React, { FC } from 'react'

import { Container, CardBody, CardHeader, CardWrapper, Image, Line, Text, Title } from './styled'
import { Card } from 'views/components/UI/Contents'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  layout?: HomeAboutCole
}

const AboutColeSection: FC<Props> = (props: Props) => {
  const { layout } = props
  return (
    <Container>
      <CardWrapper>
        <Card color={layout?.card_color}>
          <CardBody>
            <CardHeader>
              <Line />
              <Title>{layout?.first_section.title}</Title>
            </CardHeader>
            <Text>
              <HtmlRender>{layout?.first_section.description}</HtmlRender>
            </Text>
            <Title>{layout?.second_section.title}</Title>
            <Text>
              <HtmlRender>{layout?.second_section.description}</HtmlRender>
            </Text>
          </CardBody>
        </Card>
      </CardWrapper>
      <Image background={`url(${layout?.image?.url})`} />
    </Container>
  )
}

export default AboutColeSection
