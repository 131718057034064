import createImmutableSelector from 'lib/immutable/selector'
import { IProtagonistasLayout } from './../models'

// TODO: import Store type and add reducer type in Store interface
const getProtagonistasLayout = (state: any): IProtagonistasLayout => state.get('protagonistasLayoutReducer')

export const protagonistasLayoutSelector = createImmutableSelector(
  getProtagonistasLayout,
  (protagonistasLayout) => protagonistasLayout
)
