import {
  CreateAccountSection as CreateAccountSectionType,
  RegisterButton as RegisterButtonType,
} from 'ducks/loginLayout'
import React, { FC } from 'react'
import { IconType } from 'react-icons'
import { MdMail } from 'react-icons/md'
import { GrFacebook, GrGoogle } from 'react-icons/gr'

import { Container } from './styled'
import { Title, Subtitle } from 'views/screens/Login/styled'
import RegisterButton from 'views/components/RegisterButton'

type Props = {
  layout: CreateAccountSectionType
}

const CreateAccountSection: FC<Props> = (props: Props) => {
  const { layout } = props

  const renderIcon = (iconName: string): IconType | undefined => {
    switch (iconName) {
      case 'email':
        return MdMail
      case 'google':
        return GrGoogle
      case 'facebook':
        return GrFacebook
      default:
        return undefined
    }
  }

  return (
    <Container>
      <Subtitle>{layout.subtitle}</Subtitle>
      <Title>{layout.title}</Title>
      {layout?.buttons?.map((button: RegisterButtonType) => (
        <RegisterButton
          key={button.value}
          label={button.label}
          backgroundColor={button.background}
          backgroundIcon={button.icon_background}
          textColor={button.text_color}
          icon={renderIcon(button.value)}
          provider={button.value}
        />
      ))}
    </Container>
  )
}

export default CreateAccountSection
