import {
  AboutSection as AboutSectionType,
  FormValues,
  FormErrors,
  MaxCharMessage,
  ImagePreview,
} from 'ducks/profileLayout'
import React, { FC, ChangeEvent, useState, useEffect } from 'react'
import { FiFacebook, FiInstagram, FiTwitter, FiLinkedin, FiMail } from 'react-icons/fi'
import { Option } from 'ducks/contacto/models'

import {
  Container,
  BackgroundImage,
  Card,
  Label,
  Row,
  CategoryAndContryContainer,
  SocialMediaContainer,
  SocialMediaInputContainer,
  InputContainer,
  FeedbackMessage,
  CheckboxContainer,
  CheckboxText,
} from './styled'
import TextInput from 'views/components/TextInput'
import { Block } from 'views/components/UI/Contents'
import TextArea from 'views/components/UI/TextArea'
import ImageDropzone from 'views/components/ImageDropzone'
import Select from 'views/components/Select'
import UseAndPrivacyTerms from 'views/components/UseAndPrivacyTerms'
import Checkbox from 'views/components/Checkbox'
import LearnMoreTerms from 'views/components/LearnMoreTerms'

type Props = {
  layout: AboutSectionType
  values: FormValues
  errors: FormErrors
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  setFieldValue: (field: string, value: File | Array<File> | boolean) => void
  maxChar: number
  maxCharMessage: MaxCharMessage
  fieldFeedback: string
  categoriesOptions: Array<Option>
}

const AboutSection: FC<Props> = (props: Props) => {
  const {
    layout,
    values,
    errors,
    handleChange,
    setFieldValue,
    maxChar,
    maxCharMessage,
    fieldFeedback,
    categoriesOptions,
  } = props
  const [mainPicturePreview, setMainPicturePreview] = useState<Array<ImagePreview>>([])
  const [mainPicture, setMainPicture] = useState<Array<File>>([])
  const [imagePlaceholder, setImagePlaceholder] = useState('')

  useEffect(() => {
    if (mainPicturePreview.length === 0) {
      setImagePlaceholder(layout.image_placeholder?.url)
    } else {
      setImagePlaceholder(mainPicturePreview[0].preview)
    }
  }, [layout.image_placeholder, mainPicturePreview])

  useEffect(() => {
    if (mainPicture.length > 0) {
      setFieldValue('image', mainPicture[0])
    }
  }, [mainPicture, setFieldValue])

  const handleCheckTerms = () => {
    setFieldValue('terms_checked', !values.terms_checked)
  }

  const handleCheck = () => {
    setFieldValue('checked', !values.terms_checked)
  }

  return (
    <Container>
      <BackgroundImage background={`url(${imagePlaceholder})`}>
        <ImageDropzone
          message={mainPicturePreview.length > 0 ? layout.edit_image : layout.add_image}
          maxNumberOfFiles={1}
          showIcon
          setPreviews={setMainPicturePreview}
          error={errors.image}
          setFiles={setMainPicture}
        />
      </BackgroundImage>
      <Card>
        <CategoryAndContryContainer>
          <InputContainer>
            <Label>{layout.category}</Label>
            <Select
              noMargin
              value={values.category}
              options={categoriesOptions}
              name="category"
              error={errors.category}
              customErrorMessage={fieldFeedback}
              onChange={handleChange}
              placeholder={layout.category}
            />
          </InputContainer>
          <InputContainer>
            <Label>{layout.country}</Label>
            <TextInput
              name="country"
              value={values.country}
              error={errors.country}
              customErrorMessage={fieldFeedback}
              onChange={handleChange}
              noMargin
              showError
            />
          </InputContainer>
        </CategoryAndContryContainer>
        <Block marginTop="6px">
          <Label>{layout.name}</Label>
          <Row>
            <InputContainer>
              <TextInput
                name="name"
                value={values.name}
                error={errors.name}
                customErrorMessage={fieldFeedback}
                onChange={handleChange}
                noMargin
                placeholder={layout.first_name}
                showError
              />
            </InputContainer>
            <InputContainer>
              <TextInput
                name="last_name"
                value={values.last_name}
                error={errors.last_name}
                customErrorMessage={fieldFeedback}
                onChange={handleChange}
                noMargin
                placeholder={layout.last_name}
                showError
              />
            </InputContainer>
          </Row>
        </Block>
        <Block marginTop="6px">
          <InputContainer>
            <Label>{layout.nickname}</Label>
            <TextInput name="nickname" value={values.nickname} onChange={handleChange} noMargin />
          </InputContainer>
        </Block>
        <Block marginTop="6px">
          <Label>{layout.about_me}</Label>
          <TextArea
            rows={5}
            name="about_me"
            value={values.about_me}
            error={errors.about_me}
            onChange={handleChange}
            placeholder={layout.about_placeholder}
          />
          {maxChar - values.about_me.length <= 40 && (
            <FeedbackMessage>{`${maxCharMessage.start_message} ${maxChar - values.about_me.length} ${
              maxCharMessage.end_message
            }`}</FeedbackMessage>
          )}
        </Block>
        <Block marginTop="6px">
          <Label>{layout.socials}</Label>
        </Block>
        <SocialMediaContainer>
          <SocialMediaInputContainer>
            <TextInput
              name="facebook"
              value={values.facebook}
              onChange={handleChange}
              icon={FiFacebook}
              noMargin
              placeholder={layout.facebook}
            />
          </SocialMediaInputContainer>
          <SocialMediaInputContainer>
            <TextInput
              name="instagram"
              value={values.instagram}
              onChange={handleChange}
              icon={FiInstagram}
              noMargin
              placeholder={layout.instagram}
            />
          </SocialMediaInputContainer>
          <SocialMediaInputContainer>
            <TextInput
              name="twitter"
              value={values.twitter}
              onChange={handleChange}
              icon={FiTwitter}
              noMargin
              placeholder={layout.twitter}
            />
          </SocialMediaInputContainer>
          <SocialMediaInputContainer>
            <TextInput
              name="linkedin"
              value={values.linkedin}
              onChange={handleChange}
              icon={FiLinkedin}
              noMargin
              placeholder={layout.linkedin}
            />
          </SocialMediaInputContainer>
        </SocialMediaContainer>
        <Block marginTop="6px">
          <Label>{layout.email}</Label>
          <SocialMediaInputContainer>
            <TextInput
              name="email"
              value={values.email}
              onChange={handleChange}
              error={errors.email}
              customErrorMessage={fieldFeedback}
              icon={FiMail}
              noMargin
              placeholder={layout.email_placeholder}
              showError
            />
          </SocialMediaInputContainer>
        </Block>
        <Block marginTop="20px">
          <UseAndPrivacyTerms
            checked={values.terms_checked}
            error={errors.terms_checked}
            handleCheckbox={handleCheckTerms}
          />
          <CheckboxContainer>
            <Checkbox value={values.checked} onChange={handleCheck} error={errors.checked} />
            <CheckboxText>{layout.check}</CheckboxText>
          </CheckboxContainer>
          <LearnMoreTerms />
        </Block>
      </Card>
    </Container>
  )
}

export default AboutSection
