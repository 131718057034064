import styled from 'styled-components'

export const Container = styled.div`
  padding: 1rem 4rem 2rem;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    padding: 1rem 2rem 2rem;
  }
  @media screen and (max-width: 450px) {
    padding: 1rem;
  }
`

export const Subtitle = styled.p`
  font-weight: bold;
  font-size: 28px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: 1rem;
  @media screen and (min-width: 1600px) {
    font-size: 34px;
    line-height: 40px;
  }
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 48px;
    line-height: 54px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 40px;
    line-height: 46px;
  }
  @media screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 42px;
  }
  @media screen and (max-width: 450px) {
    font-size: 27px;
    line-height: 33px;
  }
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 18px;
    line-height: 32px;
  }
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 20px;
  }
`
