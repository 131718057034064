import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  INVITE_FORM_CONTENT,
  CONTENT,
  postularSelector,
  FormValues,
  FormErrors,
  SUBMIT_PROTAGONISTA_SUGGESTION,
} from 'ducks/postular'
import { useLanguage } from 'hooks/useLanguage'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import { INVITE_CONTENT, successLayoutSelector } from 'ducks/successLayout'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

import { Container, Content } from './styled'
import AboutProtagonistaSection from './AboutProtagonistaSection'
import AboutYouSection from './AboutYouSection'
import PathIndicator from 'views/components/PathIndicator'

const initialValues: FormValues = {
  about_protagonista: '',
  facebook: '',
  instagram: '',
  twitter: '',
  prota_email: '',
  name: '',
  email: '',
  message: '',
  terms_checked: false,
  checked: false,
}

const initialErrors: FormErrors = {}

const validationSchema = yup.object().shape(
  {
    about_protagonista: yup.string().required('about_prota is required'),
    facebook: yup.string().when(['instagram', 'twitter', 'prota_email'], {
      is: (instagram, twitter, prota_email) => !instagram && !twitter && !prota_email,
      then: yup.string().required('Facebook is required'),
    }),
    instagram: yup.string().when(['facebook', 'twitter', 'prota_email'], {
      is: (facebook, twitter, prota_email) => !facebook && !twitter && !prota_email,
      then: yup.string().required('Instagram is required'),
    }),
    twitter: yup.string().when(['instagram', 'facebook', 'prota_email'], {
      is: (instagram, facebook, prota_email) => !instagram && !facebook && !prota_email,
      then: yup.string().required('Twitter is required'),
    }),
    prota_email: yup
      .string()
      .email()
      .when(['instagram', 'twitter', 'facebook'], {
        is: (instagram, twitter, facebook) => !instagram && !twitter && !facebook,
        then: yup.string().email().required('Prota Email is required'),
      }),
    name: yup.string().required('name is required'),
    email: yup.string().email().required('email is required'),
    message: yup.string(),
    terms_checked: yup.boolean().oneOf([true], 'Checked is required'),
    checked: yup.boolean().oneOf([true], 'Checked is required'),
  },
  [
    ['instagram', 'twitter'],
    ['facebook', 'twitter'],
    ['prota_email', 'twitter'],
    ['prota_email', 'facebook'],
    ['prota_email', 'instagram'],
    ['facebook', 'instagram'],
  ]
)

const PostularInviteProtaForm: FC = () => {
  const dispatch = useDispatch()
  const postularState = useSelector(postularSelector)
  const successLayoutState = useSelector(successLayoutSelector)
  const { currentLanguage } = useLanguage()
  const history = useHistory()
  const { sendEvent } = useGoogleAnalytics()

  const onSubmit = (values: FormValues) => {
    dispatch(SUBMIT_PROTAGONISTA_SUGGESTION.trigger(values))
    dispatch(INVITE_CONTENT.trigger({ language: currentLanguage }))
    sendEvent({
      label: 'Protagonista Suggestion',
      category: 'Suggestion',
      action: `${values.name} tried to suggest a protagonista`,
    })
  }

  useEffect(() => {
    if (postularState.get('invite_success')) {
      sendEvent({
        label: 'Protagonista Suggestion Succeed',
        category: 'Succeed Suggestion Submission',
        action: `Successfull protagonista suggestion.`,
      })
      history.push({
        pathname: '/success',
        state: {
          success: true,
          title: successLayoutState.get('title'),
          message: successLayoutState.get('description'),
          card_color: successLayoutState.get('card_color'),
          card_background: successLayoutState.get('card_background'),
          link: successLayoutState.get('link'),
          button: successLayoutState.get('button'),
        },
      })
    }
  }, [postularState, history, dispatch, successLayoutState, sendEvent])

  useEffect(() => {
    return () => {
      dispatch(CONTENT.success({ invite_success: false }))
    }
  }, [dispatch])

  const { errors, values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    onSubmit,
    initialErrors,
    validationSchema,
  })

  useEffect(() => {
    dispatch(INVITE_FORM_CONTENT.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage])

  return (
    <Container>
      <PathIndicator
        description={postularState.getIn(['invite_prota_form', 'path', 'description'])}
        sections={postularState.getIn(['invite_prota_form', 'path', 'sections'])}
      />
      <Content>
        <AboutProtagonistaSection
          cardTitle={postularState.getIn(['invite_prota_form', 'title'])}
          cardSubtitle={postularState.getIn(['invite_prota_form', 'subtitle'])}
          cardColor={postularState.getIn(['invite_prota_form', 'card_color'])}
          layout={postularState.getIn(['invite_prota_form', 'protagonista_section'])}
          values={values}
          errors={errors}
          onChange={handleChange}
        />
        <AboutYouSection
          image={postularState.getIn(['invite_prota_form', 'image'])}
          layout={postularState.getIn(['invite_prota_form', 'about_you_section'])}
          values={values}
          errors={errors}
          onChange={handleChange}
          onSubmit={handleSubmit}
          setFieldValue={setFieldValue}
        />
      </Content>
    </Container>
  )
}

export default PostularInviteProtaForm
