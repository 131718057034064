import React, { FC, useContext, useEffect } from 'react'
import { Container, FullRow, LoadMoreText } from './styled'
import { ButtonWithIcon, ButtonIcon } from 'views/components/UI/Buttons'
import ProtagonistaCard from 'views/components/ProtagonistaCard'
import TitleWithIcon from 'views/components/TitleWithIcon'
import ProtagonistaLoader from 'views/components/ProtagonistaCard/ProtagonistaLoader'
import TextLoader from 'views/components/TextLoader'
import { useDispatch } from 'react-redux'
import { CONTENT as LAYOUT_CONTENT } from 'ducks/protagonistasLayout/actionTypes'
import { Grid } from '@material-ui/core'
import { Protagonista } from 'ducks/protagonistas'
import { ThemeContext } from 'styled-components'
import { useLanguage } from 'hooks/useLanguage'
import { useHistory } from 'react-router-dom'

type Props = {
  title?: string
  protagonistas: Array<Protagonista>
  loading?: boolean
  button: string
  message?: string
}

const OthersProtagonistas: FC<Props> = ({ title, protagonistas, loading, button, message }: Props) => {
  const themeContext = useContext(ThemeContext)
  const { colors } = themeContext
  const dispatch = useDispatch()
  const othersProtagonistas = protagonistas ? protagonistas.slice(0, 2) : []
  const loadMoreValue = protagonistas ? protagonistas.length - othersProtagonistas.length : 0
  const loadMore = message?.replace('{}', loadMoreValue.toString())
  const { currentLanguage } = useLanguage()
  const history = useHistory()

  const onClick = () => {
    history.push('/protagonistas')
  }

  useEffect(() => {
    dispatch(LAYOUT_CONTENT.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage])

  return (
    <Container>
      <FullRow>{title && <TitleWithIcon loading={loading} title={title} icon="visibility" />}</FullRow>
      <Grid container direction="row">
        <Grid container justify="space-between" item xs={12} md={9}>
          {loading
            ? [1, 2].map((key) => <ProtagonistaLoader key={key} />)
            : othersProtagonistas?.map((protagonista: Protagonista) => (
                <ProtagonistaCard key={protagonista.id} protagonista={protagonista} />
              ))}
        </Grid>
        <Grid container item xs={12} md={3} direction="column" alignItems="center" justify="center">
          {loading ? <TextLoader title rows={2} /> : <LoadMoreText>{loadMore}</LoadMoreText>}
          <ButtonWithIcon
            bold
            color={colors.primary}
            textColor={colors.dark}
            loading={loading ? true : undefined}
            onClick={onClick}
          >
            {button}
            <ButtonIcon />
          </ButtonWithIcon>
        </Grid>
      </Grid>
    </Container>
  )
}

export default OthersProtagonistas
