import React, { FC } from 'react'
import { Container, Title, Content, VideoWrapper, Description } from './styled'
import { useMediaQuery } from '@material-ui/core'
import HtmlRender from '../HtmlRender'
import VideoOrImage from 'views/components/VideoOrImage'

interface Props {
  title: string
  description: string
  video?: string
  image?: string
}

const PostVideo: FC<Props> = ({ title, description, video, image = '' }) => {
  const matches = useMediaQuery('(max-width: 1250px)')
  return (
    <Container>
      <Content>
        {!matches && <Title>{title}</Title>}
        <Description>
          <HtmlRender>{description}</HtmlRender>
        </Description>
      </Content>
      <VideoWrapper>
        <VideoOrImage video={video} thumbnail={image} />
      </VideoWrapper>
    </Container>
  )
}

export default PostVideo
