import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { IProtagonistasLayout } from './../models'
import * as TYPES from './../actionTypes'

export const initialState: IProtagonistasLayout = fromJS({
  loading: false,
  path: {},
  protagonistas: {},
  filter: {},
})

const protagonistasLayoutReducer: Reducer<IProtagonistasLayout> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET:
      return state.merge(action.payload)
    case TYPES.CONTENT.TRIGGER:
      return state.set('loading', true)
    case TYPES.CONTENT.FAILURE:
      return state.set('error', action.payload)
    case TYPES.CONTENT.SUCCESS:
      return state.merge(action.payload)
    case TYPES.CONTENT.FULFILL:
      return state.set('loading', false)
    default:
      return state
  }
}

export default protagonistasLayoutReducer
