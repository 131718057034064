import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  width: 100%;
  height: 400px;
  background-color: grey;
  @media screen and (max-width: 1250px) {
    height: 350px;
  }
  @media screen and (max-width: 450px) {
    height: 250px;
  }
`

export const Thumbnail = styled.div<BackgroundProps>`
  width: 100%;
  height: 100%;
  ${background};
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
`

export const VideoWrapper = styled.div`
  width: 100% !important;
  height: 100% !important;
`
