import styled from 'styled-components'

type BadgeProps = {
  backgroundColor?: string
  textColor?: string
}

const Badge = styled.div<BadgeProps>`
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '')};
  color: ${(props) => (props.textColor ? props.textColor : '')};
  border-radius: 16.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.55;
  letter-spacing: 0.07px;
  font-family: Lato;
`

export default Badge
