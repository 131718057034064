import styled from 'styled-components'

type ButtonProps = {
  outline?: boolean
}

export const Button = styled.div<ButtonProps>`
  cursor: pointer;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: ${({ outline, theme }) => (outline ? theme.colors.cookieBanner.white : theme.colors.cookieBanner.black)};
  background-color: ${({ outline, theme }) =>
    outline ? theme.colors.cookieBanner.black : theme.colors.cookieBanner.green};
  border: 1px solid;
  border-radius: 4px;
  border-color: ${({ outline, theme }) =>
    outline ? theme.colors.cookieBanner.white : theme.colors.cookieBanner.green};
  @media screen and (max-width: 768px) {
    margin-right: 20px;
  }
  @media screen and (max-width: 450px) {
    margin-bottom: 20px;
    width: 100%;
    margin-right: 0;
  }
`

export const ButtonsContainer = styled.div`
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 1600px) {
    width: 22%;
  }
  @media screen and (max-width: 768px) {
    margin-top: 2rem;
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
    margin-top: 1rem;
  }
`

export const ButtonLink = styled.div`
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.09px;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.cookieBanner.white};
  text-decoration-color: ${({ theme }) => theme.colors.cookieBanner.white};
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 20px;
  }
`

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.cookieBanner.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media screen and (max-width: 450px) {
    padding: 1rem;
  }
`

export const SettingsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  @media screen and (min-width: 1600px) {
    width: 75%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const SettingsText = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.cookieBanner.white};
`

export const SettingsOption = styled.div`
  display: flex;
  align-items: center;
  margin: 0 20px;
`

export const SettingsOptionText = styled.p`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.cookieBanner.white};
`

export const Text = styled.div`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.cookieBanner.white};
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  @media screen and (min-width: 1600px) {
    width: 75%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 20px;
  }
`
