import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.wood};
  align-self: stretch;
  display: flex;
  align-items: center;
  position: relative;
`
export const Image = styled.div<BackgroundProps>`
  width: 60%;
  max-width: 437px;
  margin: 3rem;
  height: 651px;
  background-position-x: center;
  ${background}
  @media screen and (max-width: 1600px) {
    height: 500px;
  }
`
export const Title = styled.div`
  font-size: 1.9rem;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: 1.5rem;
  }
`

export const Description = styled.div`
  font-size: 1rem;
  line-height: 24px;
`

export const Subtitle = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
`
