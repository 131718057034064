import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.wood};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 12px;
`
export const Image = styled.div<BackgroundProps>`
  width: 50%;
  max-height: 300px;
  ${background};
  background-position-y: center;
  background-position-x: center;
  background-size: cover;
  @media screen and (max-width: 1250px) {
    height: 156px;
  }
`
export const Title = styled.div`
  font-size: 1.9rem;
  font-weight: 600;
  @media screen and (max-width: 1250px) {
    font-size: 14px;
  }
`

export const Description = styled.div`
  font-size: 1rem;
  line-height: 24px;
  flex-grow: 2;
  @media screen and (max-width: 1250px) {
    margin: 0%;
    width: 80%;
    font-size: 12px;
    margin-top: 12px;
  }
`

export const Subtitle = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  @media screen and (max-width: 1250px) {
    font-size: 12px;
    margin-top: 12px;
  }
`
