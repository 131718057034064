import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2rem 0;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media screen and (max-width: 450px) {
    padding: 0;
  }
`

export const CardWrapper = styled.div`
  position: absolute;
  right: 0;
  z-index: 1;
  width: 75%;
  @media screen and (max-width: 768px) {
    width: 100%;
    position: unset;
  }
`

export const CardBody = styled.div`
  padding: 4rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: 1600px) {
    padding: 7rem 4rem;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
    padding: 2rem 1rem;
  }
`

export const CardLeftContent = styled.div`
  width: 30%;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`

export const CardRightContent = styled.div`
  width: 70%;
  padding-left: 2rem;
  @media screen and (max-width: 1024px) {
    width: 65%;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    padding-left: 0;
    margin-top: 2rem;
  }
`

export const DescriptionContainer = styled.div`
  margin-bottom: 20px;
`

export const Goal = styled.div`
  border-left: 1px solid black;
  padding-left: 1rem;
`

export const GoalsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 40px;
  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
`

export const Image = styled.img`
  width: 30%;
  height: 300px;
  @media screen and (min-width: 1600px) {
    height: 450px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 600px;
  }
  @media screen and (max-width: 450px) {
    height: 300px;
  }
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 48px;
    line-height: 49px;
  }
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.09px;
  @media screen and (min-width: 1600px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 768px) {
    line-height: 20px;
  }
`
