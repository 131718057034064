import { MiddleSection as MiddleSectionType } from 'ducks/voluntariadoLayout'
import React, { FC } from 'react'
import { Link } from 'ducks/layoutData'

import {
  Container,
  Content,
  CardWrapper,
  CardBody,
  CardDescription,
  SectionTitle,
  CardTitle,
  Description,
  ButtonWrapper,
} from './styled'
import { Title, Subtitle, Text } from 'views/screens/Voluntariado/styled'
import { Card } from 'views/components/UI/Contents'
import HtmlRender from 'views/components/HtmlRender'
import { ButtonIcon, LinkButton, ExternalLinkButton } from 'views/components/UI/Buttons'

type Props = {
  layout: MiddleSectionType
}

const VideoSection: FC<Props> = (props: Props) => {
  const { layout } = props

  const renderLinkButton = (link: Link) => {
    if (link.external) {
      return (
        <ExternalLinkButton href={link.value} target="blank" mobileFullWidth>
          {layout.card_button}
          <ButtonIcon />
        </ExternalLinkButton>
      )
    } else {
      return (
        <LinkButton to={link.value} mobileFullWidth>
          {layout.card_button}
          <ButtonIcon />
        </LinkButton>
      )
    }
  }

  return (
    <Container>
      <Content>
        <Description>
          <Title>{layout.title}</Title>
          <Subtitle>{layout.subtitle}</Subtitle>
          <Text>
            <HtmlRender>{layout.description}</HtmlRender>
          </Text>
        </Description>
        <SectionTitle>{layout.section_title}</SectionTitle>
      </Content>
      <CardWrapper>
        <Card color={layout.card_color} background={`url(${layout.card_background?.url})`}>
          <CardBody>
            <CardTitle>{layout.card_title}</CardTitle>
            <CardDescription>
              <HtmlRender>{layout.card_description}</HtmlRender>
            </CardDescription>
          </CardBody>
          {layout.card_link?.value && (
            <ButtonWrapper>{layout.card_link && renderLinkButton(layout.card_link)}</ButtonWrapper>
          )}
        </Card>
      </CardWrapper>
    </Container>
  )
}

export default VideoSection
