import styled from 'styled-components'

type ColorProps = {
  color?: string
}

export const Container = styled.div<ColorProps>`
  width: 165px;
  height: 165px;
  background-color: ${({ color }) => (color ? color : 'black')};
  padding: 15px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 450px) {
    width: 50%;
    height: 180px;
  }
`

export const Text = styled.div<ColorProps>`
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  word-break: break-all;
  text-transform: uppercase;
  color: ${({ color }) => (color ? color : 'white')};
`
