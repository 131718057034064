import React from 'react'
import { Container, PathLabel, PathText, Separator } from './styled'
import WithSkeletonLoading from 'views/components/WithSkeletonLoading'

type Props = {
  loading?: boolean
  description: string
  color?: string
  sections: Array<{
    link: string
    text: string
  }>
}

const PathIndicator: React.FC<Props> = (props: Props): JSX.Element => {
  const { description, sections, color } = props

  return (
    <Container>
      <PathLabel color={color}>{description}:</PathLabel>
      {sections?.map((section) => (
        <div key={section.link + section.text}>
          <PathText color={color} to={section.link}>
            {section.text}
          </PathText>
          <Separator color={color}>/</Separator>
        </div>
      ))}
    </Container>
  )
}

export default WithSkeletonLoading<Props>(PathIndicator, { height: '100%' })
