import React, { FC } from 'react'
import { FilterTitle, EmptyFilterText } from './styled'
import CategoryFilterBadge from 'views/components/CategoryFilterBadge'
import CountryFilterBadge from 'views/components/CountryFilterBadge'
import { Category } from 'ducks/categories'
import { Country } from 'ducks/countries'
import WithSkeletonLoading from 'views/components/WithSkeletonLoading'

type Props = {
  loading?: boolean
  title: string
  categoriesFilter: Array<Category>
  countriesFilter: Array<Country>
  emptyMessage: string
}

const FilterInUse: FC<Props> = (props: Props) => {
  const { title, categoriesFilter, countriesFilter, emptyMessage } = props
  return (
    <>
      <FilterTitle>{title}:</FilterTitle>
      {categoriesFilter?.map((category: Category) => (
        <CategoryFilterBadge key={category.id} category={category} />
      ))}
      {countriesFilter?.map((country: Country) => (
        <CountryFilterBadge key={country.id} country={country} />
      ))}
      {[...countriesFilter, ...categoriesFilter].length === 0 && <EmptyFilterText>({emptyMessage})</EmptyFilterText>}
    </>
  )
}

export default WithSkeletonLoading<Props>(FilterInUse, { height: '30px', width: '100%' })
