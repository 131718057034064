import axios from 'axios'
import { VoluntariadoLayout } from 'ducks/voluntariadoLayout'
import { TranslatedRequest } from 'lib/axios/models'

const getVoluntariadoLayout = async ({ language }: TranslatedRequest): Promise<VoluntariadoLayout | undefined> => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        voluntariadoLayout {
          path {
            description: description_${language}
            sections {
              link
              text: text_${language}
            }
          }
          intro_section {
            card_color
            card_title: card_title_${language}
            card_description: card_description_${language}
            card_label: card_label_${language}
            background {
              url
            }
            card_image {
              url
            }
            card_background {
              url
            }
          }
          middle_section: video_section {
            card_color
            card_title: card_title_${language}
            card_description: card_description_${language}
            card_button: card_button_${language}
            description: description_${language}
            title: title_${language}
            subtitle: subtitle_${language}
            section_title: section_title_${language}
            card_link {
              external
              value
            }
            card_background {
              url
            }
          }
          work_section {
            title:title_${language}
            subtitle: subtitle_${language}
            description: description_${language}
            first_column {
              title: title_${language}
              display_title: display_title_${language}
              description: description_${language}
            }
            second_column {
              title: title_${language}
              display_title: display_title_${language}
              description: description_${language}
            }
          }
          subscribe_section {
            title: title_${language}
            description: description_${language}
            role_text: role_text_${language}
            terms: terms_${language}
            terms_link
            button: button_${language}
            name {
              label: label_${language}
            }
            email {
              label: label_${language}
            }
            confirm_email {
              label: label_${language}
            }
            phone {
              label: label_${language}
            }
            country {
              label: label_${language}
            }
            message {
              label: label_${language}
            }
          }
          info_section {
            title:title_${language}
            description: description_${language}
            image {
              url
            }
          }
          subscribe_card {
            title: title_${language}
            description: description_${language}
            color
            background {
              url
            }
          }
        }
      }
      `,
    })
    return data
  } catch (error) {
    return
  }
}

export default getVoluntariadoLayout
