import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Wrapper = styled.div`
  width: 165px;
  height: 165px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 450px) {
    width: 50%;
    height: 180px;
  }
`

interface ContainerProps extends BackgroundProps {
  highlight?: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  ${background};
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  filter: ${({ highlight }) => (highlight ? 'grayscale(0)' : 'grayscale(1)')};
`

export const Card = styled.div<BackgroundProps>`
  position: absolute;
  width: 150%;
  height: 150%;
  ${background};
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  display: flex;
  align-items: flex-end;
  z-index: 1;
  @media screen and (max-width: 768px) {
    width: 130%;
    height: 130%;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 100%;
  }
`

type CardContentProps = {
  backgroundColor?: string
}

export const CardContent = styled.div<CardContentProps>`
  width: 100%;
  padding: 10px;
  opacity: 0.8;
  background-color: ${({ backgroundColor, theme }) => (backgroundColor ? backgroundColor : theme.colors.white)};
`

export const CardTitle = styled.p`
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.dark};
`

export const CardText = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.dark};
`

type OverlayProps = {
  color?: string
}

export const Overlay = styled.div<OverlayProps>`
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: ${({ color }) => color && color};
`
