import ReactGA from 'react-ga'

type EventConfig = {
  category: string
  label: string
  action: string
  value?: number
}

type UseGoogleAnalyticsType = {
  initializeAnalytics: () => void
  setCustomDimension: (data: unknown) => void
  trackNavigation: (path: string) => void
  sendEvent: (config: EventConfig) => void
  trackModal: (path: string) => void
}

const useGoogleAnalytics = (): UseGoogleAnalyticsType => {
  const initializeAnalytics = (): void => {
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID)
  }

  const setCustomDimension = (data: unknown): void => {
    ReactGA.set(data)
  }

  const sendEvent = (config: EventConfig): void => {
    ReactGA.event(config)
  }

  const trackModal = (path: string): void => {
    ReactGA.modalview(path)
  }

  const trackNavigation = (path: string): void => {
    ReactGA.pageview(path)
  }

  return { initializeAnalytics, setCustomDimension, trackNavigation, sendEvent, trackModal }
}

export default useGoogleAnalytics
