import React, { FC } from 'react'

import { Container, Label } from './styled'
import MotivateIcon from '../MotivateIcon'

type Props = {
  color?: string
  value: number
}

const MotivatedIndicator: FC<Props> = ({ color, value }) => (
  <Container color={color}>
    <MotivateIcon color={color} />
    <Label>{value}</Label>
  </Container>
)

export default MotivatedIndicator
