import axios from 'axios'
import { AuthResponse, UserState } from 'ducks/user'
import { parseAuthResponse } from './parser'

type Credentials = {
  email: string
  password: string
}

const login = async (credentials: Credentials): Promise<UserState | undefined> => {
  try {
    const { email, password } = credentials
    const response = await axios.post(`${process.env.REACT_APP_MAIN_API}/auth/local`, {
      identifier: email,
      password,
    })
    if (response.status === 200) {
      const { data }: { data: AuthResponse } = response
      localStorage.setItem('token', data.jwt)
      return parseAuthResponse(data)
    }
  } catch (error) {
    return
  }
}

type SocialLoginType = {
  provider: string
  query: string
}

export const socialLogin = async ({ provider, query }: SocialLoginType): Promise<UserState | undefined> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_MAIN_API}/auth/${provider}/callback?${query}`)
    if (response.status === 200) {
      const { data }: { data: AuthResponse } = response
      localStorage.setItem('token', data.jwt)
      return parseAuthResponse({ ...data, provider })
    }
  } catch (error) {
    const { status, statusText } = error.response
    throw new Error(`${status} - ${statusText}`)
  }
}

export default login
