import axios from 'axios'
import { PostularResponse } from 'ducks/postular'
import { TranslatedRequest } from 'lib/axios/models'

export const getPostularHome = async ({ language }: TranslatedRequest): Promise<PostularResponse | undefined> => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        home: postularHome {
          path {
            description: description_${language}
            sections {
              link
              text: text_${language}
            }
          }
          intro_section {
            title:title_${language}
            card_title:card_title_${language}
            description: description_${language}
            video
            button: button_${language}
            link {
              external
              value
            }
            steps {
              number
              label: label_${language}
            }
            video_placeholder {
              url
            }
          }
          invite_section {
            title: title_${language}
            subtitle: subtitle_${language}
            card_color
            card_title: card_title_${language}
            card_description: card_description_${language}
            card_button: card_button_${language}
            whatsapp_button: whatsapp_button_${language}
            copy_button: copy_button_${language}
            copied_button: copied_button_${language}
            invite_messages {
              message: message_${language}
              color
            }
          }
          postular_section {
            title: title_${language}
            description: description_${language}
            button: button_${language}
            link
            background_color
            background {
              url
            }
          }
          end_section {
            card_color
            card_title: card_title_${language}
            card_description:card_description_${language}
            card_button: card_button_${language}
            card_link {
              external
              value
            }
          }
        }
      }
      `,
    })
    return data
  } catch (error) {
    return
  }
}

export const getPostularInviteProtaForm = async ({
  language,
}: TranslatedRequest): Promise<PostularResponse | undefined> => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        invite_prota_form: postularInviteProta {
          title: title_${language}
          subtitle: subtitle_${language}
          card_color
          image {
            url
          }
          path {
            description: description_${language}
            sections {
              link
              text: text_${language}
            }
          }
          protagonista_section {
            title: title_${language}
            social_error: social_error_${language}
            message {
              label: label_${language}
            }
            social_title: social_title_${language}
            facebook {
              label: label_${language}
            }
            instagram {
              label: label_${language}
            }
            twitter {
              label: label_${language}
            }
            email {
              label: label_${language}
            }
          }
          about_you_section {
            title: title_${language}
            description: description_${language}
            name {
              label: label_${language}
            }
            email {
              label: label_${language}
            }
            message {
              label: label_${language}
            }
            check: check_${language}
            button: button_${language}
          }
        }
      }
      `,
    })
    return data
  } catch (error) {
    return
  }
}

type NewProtagonista = {
  about_protagonista: string
  facebook: string
  instagram: string
  twitter: string
  prota_email: string
  name: string
  email: string
  message: string
}

export const submitProtagonistaSuggestion = async (newProtagonista: NewProtagonista): Promise<boolean | undefined> => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_MAIN_API}/suggested-protagonistas/submit`,
      newProtagonista
    )
    if (data) {
      return true
    }
    return false
  } catch (error) {
    return
  }
}
