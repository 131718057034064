import React, { FC } from 'react'
import { Container, CancelButton, Text, Flag } from './styled'
import { useFilter } from 'hooks/useFilter'
import { Country } from 'ducks/countries'

type Props = {
  country: Country
}

const CountryFilterBadge: FC<Props> = ({ country }: Props) => {
  const { handleCountryFilter } = useFilter()

  return (
    <Container>
      <Flag src={country.image?.url} />
      <Text>{country.name}</Text>
      <CancelButton onClick={() => handleCountryFilter(country)} />
    </Container>
  )
}

export default CountryFilterBadge
