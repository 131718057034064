import { IPost } from 'ducks/blog'
import React, { FC } from 'react'

import { CategoryLabel, Container, Category, Text, Title } from './styled'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  post: IPost
}

const FeaturedBlogPost: FC<Props> = ({ post }) => (
  <Container to={`/blog/post/${post.url}`}>
    <Category>
      <CategoryLabel color={post?.category?.color} />
      {post?.category?.name}
    </Category>
    <Title>{post?.title}</Title>
    <Text>
      <HtmlRender>{post?.short_description}</HtmlRender>
    </Text>
  </Container>
)

export default FeaturedBlogPost
