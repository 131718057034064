import axios from 'axios'

type DataReturnType = {
  success: boolean
}

const verifyCaptcha = async (token: string): Promise<boolean> => {
  try {
    const {
      data: { success },
    }: { data: DataReturnType } = await axios.post(`${process.env.REACT_APP_MAIN_API}/captcha/verify`, {
      token,
    })
    return success
  } catch (error) {
    return false
  }
}

export default verifyCaptcha
