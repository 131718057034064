import { Collaborator, WhereWeAre } from 'ducks/colaboraLayout'
import React, { FC } from 'react'

import { Container } from './styled'
import { Subtitle } from '../styled'
import DisplayCard from 'views/components/DisplayCard'
import { TeamCard } from 'ducks/elCole'

type Props = {
  layout?: WhereWeAre
}

const WhereWeAreSection: FC<Props> = (props: Props) => {
  const { layout } = props

  const formatCollaborators = (collaborator: Collaborator): TeamCard => {
    const { name, role, description, image, email, location } = collaborator
    return {
      email,
      description,
      image,
      title: name,
      subtitle: role,
      label: location,
    }
  }

  return (
    <Container>
      <Subtitle>{layout?.title}</Subtitle>
      {layout?.collaborators?.map(formatCollaborators).map((formatedCollaborator: TeamCard) => (
        <DisplayCard key={formatedCollaborator.title} layout={formatedCollaborator} />
      ))}
    </Container>
  )
}

export default WhereWeAreSection
