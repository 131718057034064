import { MiddleSection } from 'ducks/aboutLayout'
import React, { FC } from 'react'
import { Container, Card, Image, Title } from './styled'
import { Text } from 'views/screens/AboutUs/styled'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  layout: MiddleSection
}

const MidSection: FC<Props> = (props: Props) => {
  const { layout } = props
  return (
    <Container>
      <Card>
        <Image background={`url(${layout?.image?.url})`} />
        <Title>{layout?.title}</Title>
        <Text>
          <HtmlRender>{layout?.description}</HtmlRender>
        </Text>
      </Card>
    </Container>
  )
}

export default MidSection
