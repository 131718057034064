import React, { FC } from 'react'
import { Container, Title, Element, Subtitle, Value } from './styled'
import { Indicators } from 'ducks/home'
import { Record, List } from 'immutable'
import { Block } from '../UI/Contents'

interface Props {
  items: List<Record<Indicators>>
}

const Indicator: FC<Props> = ({ items }) => {
  return (
    <Container>
      {items
        ?.map((item) => (
          <Element key={item.get('title')}>
            <Value>{item.get('value')}</Value>
            <Block>
              <Title>{item.get('title')}</Title>
              <Subtitle>{item.get('subtitle')}</Subtitle>
            </Block>
          </Element>
        ))
        ?.toArray()}
    </Container>
  )
}

export default Indicator
