import { SagaIterator } from '@redux-saga/core'
import { all, put, takeLatest, call } from 'redux-saga/effects'
import { Action } from 'lib/redux/models'
import { CONTENT } from '../actionTypes'
import getLayout from 'services/movimientos/getLayout'

function* loadProtagonistasContent(action: Action) {
  yield put(CONTENT.request())
  try {
    const movimientosLayoutContent = yield call(getLayout, action?.payload)
    if (movimientosLayoutContent) {
      yield put(CONTENT.success(movimientosLayoutContent))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

export function* movimientosLayoutSagas(): SagaIterator {
  yield all([takeLatest(CONTENT.TRIGGER, loadProtagonistasContent)])
}
