import styled from 'styled-components'
import { color, position, space, layout, LayoutProps, SpaceProps, ColorProps, PositionProps } from 'styled-system'
import { Link } from 'react-router-dom'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import WithSkeletonLoading from 'views/components/WithSkeletonLoading'

export type BlockProps = LayoutProps & SpaceProps & ColorProps & PositionProps

export const Button = styled.div<BlockProps>`
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.main};
  color: #fff;
  padding: 1rem;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${layout}
  ${space}
  ${color}
  ${position}
`
export const ButtonIcon = styled(ChevronRightIcon)`
  font-size: 14px;
`
interface ButtonWithIconProps extends BlockProps {
  bold?: boolean
  textColor?: string
  loading?: boolean
  color?: string
  large?: boolean
  onClick?: () => void
  mobileFullWidth?: boolean
  disabled?: boolean
}

export const LinkButton = styled(Link)<ButtonWithIconProps>`
  position: relative;
  background-color: ${({ theme, color }) => (color ? color : theme.colors.black)};
  color: ${({ theme, textColor }) => (textColor ? textColor : theme.colors.white)};
  min-width: 216px;
  font-size: 12px;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  padding-left: ${({ mobileFullWidth }) => (mobileFullWidth ? '0' : '12%')};
  justify-content: ${({ mobileFullWidth }) => (mobileFullWidth ? 'center' : 'space-between')};
  align-items: center;
  font-weight: bold;
  ${ButtonIcon} {
    color: ${({ theme, textColor }) => (textColor ? textColor : theme.colors.white)};
    position: ${({ mobileFullWidth }) => (mobileFullWidth ? 'absolute' : 'unset')};
    right: ${({ mobileFullWidth }) => (mobileFullWidth ? '10px' : 'unset')};
    @media screen and (max-width: 450px) {
      color: ${({ mobileFullWidth, theme }) => !mobileFullWidth && theme.colors.dark};
    }
  }
  @media screen and (min-width: 1600px) {
    padding: 1rem;
    font-size: 14px;
    padding-left: ${({ mobileFullWidth }) => (mobileFullWidth ? '0' : '5%')};
  }
  @media screen and (max-width: 768px) {
    padding: 6px 0 6px 8px;
  }
  @media screen and (max-width: 450px) {
    padding: ${({ mobileFullWidth }) => (mobileFullWidth ? '15px 0' : '6px 0 6px 8px')};
    background-color: ${({ mobileFullWidth }) => !mobileFullWidth && 'transparent'};
    min-width: ${({ mobileFullWidth }) => !mobileFullWidth && 'unset'};
    color: ${({ mobileFullWidth, theme }) => !mobileFullWidth && theme.colors.dark};
    padding: ${({ mobileFullWidth }) => (mobileFullWidth ? '15px 0' : '6px 0 6px 8px')};
    width: ${({ mobileFullWidth }) => (mobileFullWidth ? '100%' : '50%')};
  }
`

export const ExternalLinkButton = styled.a<ButtonWithIconProps>`
  position: relative;
  background-color: ${({ theme, color }) => (color ? color : theme.colors.black)};
  color: ${({ theme, textColor }) => (textColor ? textColor : theme.colors.white)};
  min-width: 216px;
  font-size: 12px;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  padding-left: ${({ mobileFullWidth }) => (mobileFullWidth ? '0' : '12%')};
  justify-content: ${({ mobileFullWidth }) => (mobileFullWidth ? 'center' : 'space-between')};
  align-items: center;
  font-weight: bold;
  ${layout}
  ${space}
  ${position}
  ${ButtonIcon} {
    color: ${({ theme, textColor }) => (textColor ? textColor : theme.colors.white)};
    position: ${({ mobileFullWidth }) => (mobileFullWidth ? 'absolute' : 'unset')};
    right: ${({ mobileFullWidth }) => (mobileFullWidth ? '10px' : 'unset')};
  }
  @media screen and (min-width: 1600px) {
    padding: 1rem;
    font-size: 14px;
    padding-left: ${({ mobileFullWidth }) => (mobileFullWidth ? '0' : '5%')};
  }
  @media screen and (max-width: 768px) {
    padding: 6px 0 6px 8px;
  }
  @media screen and (max-width: 450px) {
    min-width: ${({ mobileFullWidth }) => !mobileFullWidth && 'unset'};
    padding: ${({ mobileFullWidth }) => (mobileFullWidth ? '15px 0' : '6px 0 6px 8px')};
  }
`

export const ButtonWithIcon = WithSkeletonLoading<ButtonWithIconProps>(
  styled.div<ButtonWithIconProps>`
    position: relative;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    display: flex;
    padding: 0 10px;
    padding-left: ${({ mobileFullWidth }) => (mobileFullWidth ? '0' : '12%')};
    justify-content: ${({ mobileFullWidth }) => (mobileFullWidth ? 'center' : 'space-between')};
    align-items: center;
    background-color: ${({ theme, color, disabled }) => {
      if (disabled) {
        return theme.colors.inactiveSlide
      }
      return color ? color : theme.colors.black
    }};
    color: ${({ theme, textColor }) => (textColor ? textColor : theme.colors.white)};
    border-radius: 4px;
    height: ${({ large }) => (large ? '48px' : '34px')};
    min-width: 180px;
    font-size: 14px;
    font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
    ${ButtonIcon} {
      color: ${(props) => props.textColor};
      position: ${({ mobileFullWidth }) => (mobileFullWidth ? 'absolute' : 'unset')};
      right: ${({ mobileFullWidth }) => (mobileFullWidth ? '10px' : 'unset')};
    }
    @media screen and (min-width: 1600px) {
      padding: 1rem;
      font-size: 14px;
      padding-left: ${({ mobileFullWidth }) => (mobileFullWidth ? '0' : '5%')};
    }
    @media screen and (max-width: 768px) {
      padding: 6px 0 6px 8px;
    }
    @media screen and (max-width: 450px) {
      padding: ${({ mobileFullWidth }) => (mobileFullWidth ? '15px 0' : '6px 0 6px 8px')};
      width: ${({ mobileFullWidth }) => (mobileFullWidth ? '100%' : '50%')};
    }
  `,
  { height: '34px', width: '50%' }
)

export const ButtonText = styled.p``

export const Icon = styled.span`
  font-size: 0.9rem;
`

export const VerticalButtonWitchIcon = styled.div<ColorProps>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-orientation: upright;
  writing-mode: vertical-rl;
  letter-spacing: 0.15px;
  ${color};
  border-radius: 4px;
  height: 125px;
  font-size: 0.6rem;
  padding: 0;
  font-weight: bold;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    height: 85px;
  }
`

export const SuccessFeedbackButton = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  height: 34px;
  min-width: 180px;
  max-width: 250px;
  width: 50%;
  font-size: 14px;
  padding: 0 10px;
`

export const OutlineLinkButton = styled(Link)`
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 4px;
  min-width: 170px;
  height: 34px;
  padding: 0 10px;
  background-color: transparent;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  display: flex;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  min-width: 180px;
  font-size: 14px;

  @media screen and (min-width: 1600px) {
    padding: 1rem;
    font-size: 14px;
  }
  @media screen and (max-width: 768px) {
    padding: 6px 0 6px 8px;
  }
  @media screen and (max-width: 450px) {
    padding: 6px 0 6px 8px;
    width: 100%;
  }
`
