import React, { FC, useEffect } from 'react'
import { Container, Text, Content } from './styled'
import { useDropzone } from 'react-dropzone'
import { IoMdImages } from 'react-icons/io'

type PreviewObject = {
  name: string
  preview: string
}

type Props = {
  maxNumberOfFiles?: number
  message: string
  showIcon?: boolean
  setPreviews?: (previews: Array<PreviewObject>) => void
  error?: string
  setFiles: (files: Array<File>) => void
}

const ImageDropzone: FC<Props> = (props: Props) => {
  const { maxNumberOfFiles = 0, message, showIcon, setPreviews, error, setFiles } = props
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    accept: 'image/*',
    maxFiles: maxNumberOfFiles,
    onDropAccepted: (acceptedFiles) => {
      if (setPreviews) {
        setPreviews(
          acceptedFiles.map((file) => ({
            name: file.name,
            preview: URL.createObjectURL(file),
          }))
        )
      }
    },
  })

  useEffect(() => {
    setFiles(acceptedFiles)
  }, [acceptedFiles, setFiles])

  return (
    <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject: error ? true : isDragReject })}>
      <input {...getInputProps()} />
      <Content>
        {showIcon && <IoMdImages size={50} />}
        <Text>{message}</Text>
      </Content>
    </Container>
  )
}

export default ImageDropzone
