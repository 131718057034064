import theme from 'lib/theme'
import styled from 'styled-components'

type ContainerProps = {
  error?: string
  noMargin?: boolean
  color?: string
  feedbackColor?: string
}

const renderBorderColor = ({ error, color, feedbackColor }: ContainerProps) => {
  if (error) {
    return feedbackColor ? feedbackColor : theme.colors.error
  }
  return color ? color : theme.colors.inputBorder
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  border: 1px solid ${({ error, color, feedbackColor }) => renderBorderColor({ error, color, feedbackColor })};
  border-radius: 4px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  color: ${({ color }) => (color ? color : '#757575')};
  height: 32px;
  @media screen and (min-width: 1600px) {
    height: 40px;
  }
`

export const Input = styled.input<ContainerProps>`
  width: 100%;
  flex: 1;
  border: none;
  color: ${({ color, theme }) => (color ? color : theme.colors.default)};
  font-size: 14px;
  height: 100%;
  padding: 0 4px;
  background-color: transparent;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ color, theme }) => (color ? color : theme.colors.default)};
    opacity: 1; /* Firefox */
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ color, theme }) => (color ? color : theme.colors.default)};
  }
  ::-webkit-input-placeholder {
    /* Microsoft Edge */
    color: ${({ color, theme }) => (color ? color : theme.colors.default)};
  }
`

export const IconWrapper = styled.div`
  font-size: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Main = styled.div<ContainerProps>`
  width: 100%;
  margin: ${({ noMargin }) => (noMargin ? '0' : '14px 0')};
`
