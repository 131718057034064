import styled from 'styled-components'

export const Container = styled.div`
  width: 70%;
  position: absolute;
  top: 0;
  left: 0;
  @media screen and (max-width: 768px) {
    width: 100%;
    position: unset;
  }
`

type CardProps = {
  color?: string
}

export const Card = styled.div<CardProps>`
  width: 100%;
  padding: 40px 100px 20px 40px;
  background-color: ${({ color, theme }) => (color ? color : theme.colors.defaultCardBackground)};
  @media screen and (min-width: 1600px) {
    padding: 70px 100px 50px 70px;
  }
  @media screen and (max-width: 1024px) {
    padding: 40px 20px 20px 40px;
  }
  @media screen and (max-width: 450px) {
    padding: 1rem;
  }
`

export const CardTitle = styled.p`
  width: 70%;
  font-weight: bold;
  font-size: 40px;
  line-height: 45px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 48px;
    line-height: 55px;
  }
  @media screen and (max-width: 1024px) {
    width: 80%;
    font-size: 32px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    font-size: 27px;
    line-height: 30px;
  }
`

export const CardSubtitle = styled.p`
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 34px;
    line-height: 55px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 24px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    font-size: 20px;
    line-height: 30px;
  }
`

export const Content = styled.div`
  width: 70%;
  padding-left: 2rem;
  margin-top: 20px;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
  }
  @media screen and (max-width: 450px) {
    padding: 0 1rem;
  }
`

export const TextWrapper = styled.div`
  margin-top: 20px;
`
