import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
  padding: 3rem;
  @media screen and (max-width: 1024px) {
    padding: 2rem;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 40px;
    padding: 0;
    padding-top: 40px;
    border-left: none;
    border-top: 1px solid black;
    box-shadow: unset;
  }
  @media screen and (max-width: 450px) {
    width: 90%;
    align-self: center;
    margin-top: 20px;
    padding: 1rem 0;
  }
`

export const RoleSelectorSection = styled.div`
  width: 100%;
  margin: 15px 0;
`

export const RoleText = styled.p`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 20px;
    line-height: 25px;
  }
  @media screen and (max-width: 768px) {
    font-weight: bold;
    margin: 20px 0 10px;
  }
`

export const CheckboxWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
`

export const PrivacyWrapper = styled.div`
  margin-bottom: 10px;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
