import styled from 'styled-components'

export const Container = styled.div``

export const Content = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 450px) {
    padding: 1rem;
  }
`

export const LineSeparatorContainer = styled.div`
  width: 50%;
  align-self: flex-end;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  padding: 3rem 2rem;
  @media screen and (max-width: 1024px) {
    padding: 3rem 0;
    grid-column-gap: 10px;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
`
