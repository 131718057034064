import styled from 'styled-components'
import ContentLoader from 'react-content-loader'

export const Container = styled(ContentLoader)`
  width: 48%;
  height: 360px;
  margin: 10px 0;
  @media (max-width: 1280px) {
    width: 100%;
  }
`
