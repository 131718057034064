import React, { FC, useEffect } from 'react'
import { countriesSelector, Country } from 'ducks/countries'
import { categoriesSelector, Category } from 'ducks/categories'
import { useSelector, useDispatch } from 'react-redux'
import { protagonistasSelector } from 'ducks/protagonistas/selectors'
import { movimientosLayoutSelector } from 'ducks/movimientosLayout/selectors'
import { movimientosSelector } from 'ducks/movimientos/selectors'
import { CONTENT as LAYOUT_CONTENT } from 'ducks/movimientosLayout/actionTypes'
import { useFilter } from 'hooks/useFilter'
import { useLanguage } from 'hooks/useLanguage'
import { Movimiento } from 'ducks/movimientos'

import {
  Container,
  ContentContainer,
  FullRow,
  FilterContainer,
  MovimientosContainer,
  SectionHeader,
  SectionLine,
  SectionTitle,
} from './styled'
import PathIndicator from 'views/components/PathIndicator'
import { PathContainer } from 'views/components/PathIndicator/styled'
import Filter from 'views/components/Filter'
import MovimientoCard from 'views/components/MovimientoCard'
import FilterInUse from 'views/components/Filter/FilterInUse'
import TitleWithIcon from 'views/components/TitleWithIcon'
import { CONTENT as PROTAGONISTAS_CONTENT } from 'ducks/protagonistas/actionTypes'
import LineSeparator from 'views/components/UI/LineSeparator'
import OtherProtagonistas from 'views/components/OtherProtagonistas'
import CardLoader from 'views/components/CardLoader'

const Movimientos: FC = () => {
  const dispatch = useDispatch()
  const protagonistas = useSelector(protagonistasSelector)
  const movimientosLayout = useSelector(movimientosLayoutSelector)
  const movimientos = useSelector(movimientosSelector)
  const { countriesFilter, categoriesFilter, clearFilter } = useFilter()
  const { currentLanguage } = useLanguage()
  const noFeaturedMovimientos = movimientos.get('movimientos')
  const countries = useSelector(countriesSelector).get('countries')
  const categories = useSelector(categoriesSelector).get('categories')

  useEffect(() => {
    dispatch(LAYOUT_CONTENT.trigger({ language: currentLanguage }))
    dispatch(PROTAGONISTAS_CONTENT.trigger({ language: currentLanguage, countries: [], categories: [] }))
  }, [dispatch, currentLanguage])

  useEffect(() => {
    return () => {
      clearFilter()
    }
  }, [clearFilter])

  return (
    <Container>
      <PathContainer loading={movimientos.get('loading') ? 1 : 0}>
        <PathIndicator
          loading={movimientos.get('loading')}
          description={movimientosLayout.getIn(['path', 'description'])}
          sections={movimientosLayout.getIn(['path', 'sections'])}
        />
      </PathContainer>
      <ContentContainer>
        <FilterContainer>
          <Filter
            loading={movimientos.get('loading')}
            title={movimientosLayout.getIn(['filter', 'title'])}
            country={movimientosLayout.getIn(['filter', 'country'])}
            category={movimientosLayout.getIn(['filter', 'category'])}
            countriesOptions={countries.filter((country: Country) => country.movimientos.length > 0)}
            categoriesOptions={categories.filter((category: Category) => category.movimientos.length > 0)}
          />
        </FilterContainer>
        <Container paddingLeft>
          <FullRow>
            <FilterInUse
              loading={movimientos.get('loading')}
              title={movimientosLayout.getIn(['filter', 'in_use'])}
              categoriesFilter={categoriesFilter}
              countriesFilter={countriesFilter}
              emptyMessage={movimientosLayout.getIn(['filter', 'no_filters'])}
            />
          </FullRow>
          {!movimientos.get('loading') && <LineSeparator />}
          <FullRow marginVertical>
            <TitleWithIcon
              loading={movimientos.get('loading')}
              title={movimientosLayout.getIn(['movimientos', 'month'])}
              icon="star"
            />
          </FullRow>
          <MovimientosContainer flexWrap="wrap" justifyContent="space-between">
            {movimientos.get('loading')
              ? [1, 2, 3, 4].map((key) => <CardLoader key={key} />)
              : movimientos
                  .get('featuredMovimientos')
                  ?.slice(0, 2)
                  ?.map((movimiento: Movimiento) => <MovimientoCard key={movimiento.id} movimiento={movimiento} />)}
          </MovimientosContainer>
        </Container>
      </ContentContainer>
      {noFeaturedMovimientos && noFeaturedMovimientos?.length > 0 && (
        <MovimientosContainer flexWrap="wrap" marginTop="48px" justifyContent="flex-start">
          {!movimientos.get('loading') &&
            noFeaturedMovimientos?.map((movimiento: Movimiento) => (
              <MovimientoCard key={movimiento.id} movimiento={movimiento} size="32%" margin="0 10px 15px" />
            ))}
        </MovimientosContainer>
      )}
      {!movimientos.get('loading') && (
        <SectionHeader>
          <SectionTitle>{movimientosLayout.getIn(['movimientos', 'recommendations'])} </SectionTitle>
          <SectionLine />
        </SectionHeader>
      )}
      <OtherProtagonistas
        loading={movimientos.get('loading')}
        title={movimientosLayout.getIn(['movimientos', 'protagonistas'])}
        protagonistas={protagonistas.get('protagonistas')}
        button={movimientosLayout.getIn(['movimientos', 'load_more_button'])}
        message={movimientosLayout.getIn(['movimientos', 'load_more'])}
      />
    </Container>
  )
}

export default Movimientos
