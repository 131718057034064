import axios from 'axios'
import { TranslatedRequest } from 'lib/axios/models'
import { ElColeHome } from 'ducks/elCole'

export const getHomeContent = async ({ language }: TranslatedRequest): Promise<ElColeHome | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        elCole {
          banner {
            title: title_${language}
            background {
              url
            }
          }
          path {
            description: description_${language}
            sections {
              link
              text: text_${language}
            }
          }
          intro_section {
            left_content {
              title: title_${language}
              description: description_${language}
              image {
                url
              }
            }
            right_content {
              card_color
              title: title_${language}
              subtitle: subtitle_${language}
              description: description_${language}
              image {
                url
              }
            }
          }
          team_cards {
            id
            image {
              url
            }
            title: title_${language}
            description: description_${language}
          }
          herramienta_section {
            image {
              url
            }
            title: title_${language}
            card_color
            description: description_${language}
            tool_goals {
              id
              description: description_${language}
            }
          }
          about_cole_section {
            image {
              url
            }
            card_color
            first_section {
              title: title_${language}
              description: description_${language}
            }
            second_section {
              title: title_${language}
              description: description_${language}
            }
          }
          subscribe_section {
            image {
              url
            }
            title: title_${language}
            description: description_${language}
            subscribe_loading: subscribe_loading_${language}
            subscribe_error: subscribe_error_${language}
            role: role_${language}
            roles {
              value: value_${language}
            }
            country {
              label: label_${language}
            }
            city {
              label: label_${language}
            }
            email {
              label: label_${language}
            }
            confirm_email {
              label: label_${language}
            }
            terms: terms_${language}
            terms_link
            button: button_${language}
          }
        }
      }
      `,
    })
    return data.elCole
  } catch (error) {
    return
  }
}
