import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 66px 0;
`

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  -moz-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  @media screen and (max-width: 1250px) {
    height: unset;
  }
  @media screen and (max-width: 450px) {
    margin-top: 20px;
  }
`
export const SectionLine = styled.div`
  width: 45%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.black};
  position: absolute;
  right: -6rem;
  top: 14px;
  @media screen and (max-width: 1250px) {
    display: none;
  }
  @media screen and (min-width: 1600px) {
    width: 47%;
  }
`

export const CardTitle = styled.div`
  margin: 0;
  font-family: 'PT Serif Caption', serif;
  font-size: 18px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.25px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 1250px) {
    text-align: center;
  }
`
export const CardHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
  @media screen and (max-width: 450px) {
    margin-bottom: 0;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: 360px;
  flex-grow: 1;
  width: 100%;
  @media screen and (max-width: 1250px) {
    flex-direction: column;
  }
  @media screen and (min-width: 1600px) {
    height: 600px;
  }
  @media screen and (max-width: 1024px) {
    height: unset;
  }
`

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  height: 100%;
  @media screen and (max-width: 1250px) {
    width: 100%;
    margin-top: 20px;
  }
`

export const BackgroundImage = styled.div<BackgroundProps>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 40px;
  ${background};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  @media screen and (max-width: 1024px) {
    height: 400px;
  }
  @media screen and (max-width: 450px) {
    height: 200px;
  }
`

export const DescriptionContainer = styled.div`
  width: 40%;
  height: 100%;
  padding-left: 20px;
  @media screen and (max-width: 1250px) {
    width: 100%;
  }
  @media screen and (max-width: 1024px) {
    padding-left: 0;
    height: 400px;
    margin-top: 40px;
  }
  @media screen and (max-width: 450px) {
    margin-top: 0;
    height: 320px;
  }
`

export const ButtonContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.trashIcon};
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
`

export const FeedbackMessage = styled.p`
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.error};
`
