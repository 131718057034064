import styled from 'styled-components'
import ContentLoader from 'react-content-loader'

export const Container = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 16px;
`

export const LoaderContainer = styled(ContentLoader)`
  margin: 2px 0;
  height: 15px;
`

export const Row = styled.rect`
  width: 100%;
`
