import React, { useState, createContext, useContext, FC } from 'react'

type Props = {
  children: Array<JSX.Element> | JSX.Element
}

type LanguageContextType = {
  currentLanguage: string
  setCurrentLanguage: (language: string) => void
}

const LanguageContext = createContext<LanguageContextType | null>(null)

const LanguageProvider: FC<Props> = ({ children }: Props) => {
  const [currentLanguage, setCurrentLanguage] = useState<string>('es')

  return (
    <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage }}> {children} </LanguageContext.Provider>
  )
}

const useLanguage = (): LanguageContextType => {
  const context: LanguageContextType | null = useContext(LanguageContext)
  if (!context) {
    throw new Error('useLanguages must be used inside LanguageProvider')
  } else {
    return context
  }
}

export { useLanguage, LanguageProvider }
