import axios from 'axios'
import { AboutLayout } from 'ducks/aboutLayout'
import { TranslatedRequest } from 'lib/axios/models'

const getAboutLayout = async ({ language }: TranslatedRequest): Promise<AboutLayout | undefined> => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        aboutUsLayout {
          background {
            url
          }
          path {
            description:description_${language}
            sections {
              link
              text: text_${language}
            }
          }
          intro_section {
            left_image {
              url
            }
            left_title: left_title_${language}
            right_image {
              url
            }
            right_title: right_title_${language}
            right_description: right_description_${language}
          }
          middle_section {
            title: title_${language}
            description: description_${language}
            image {
              url
            }
          }
          end_section {
            link
            title: title_${language}
            button: button_${language}
            description: description_${language}
          }
          founders_section {
            title: title_${language}
            description: description_${language}
            button: button_${language}
            link
            show_email: show_email_${language}
            founders {
              id
              email
              label: label_${language}
              title:title_${language}
              description: description_${language}
              background_color
              icon {
                url
              }
              gallery {
                url
              }
            }
          }
        }
      }
      `,
    })
    return data
  } catch (error) {
    return
  }
}

export default getAboutLayout
