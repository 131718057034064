import React, { FC } from 'react'
import { Container, CoverImage, Content, Title, Description } from './styled'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  background: string
  title: string
  description: string
}

const Welcome: FC<Props> = (props: Props) => {
  const { background, title, description } = props
  return (
    <Container>
      <CoverImage background={`url(${background})`} />
      <Content>
        <Title>{title}</Title>
        <Description>
          <HtmlRender>{description}</HtmlRender>
        </Description>
      </Content>
    </Container>
  )
}

export default Welcome
