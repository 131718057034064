import { Language, layoutDataSelector } from 'ducks/layoutData'
import { userSelector } from 'ducks/user'
import { useLanguage } from 'hooks/useLanguage'
import { List, Record } from 'immutable'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Container,
  Content,
  SectionContainer,
  SectionElement,
  Separator,
  LanguageLabel,
  LanguageOption,
  LanguageContainer,
  ActiveIcon,
  CloseButton,
  CloseButtonContainer,
  MenuButton,
} from './styled'

interface Props {
  login_button: string
  sections: List<
    Record<{
      text: string
      link: string
    }>
  >
  languages: List<Record<Language>>
  select_language: string
  activeLocation: string
  onClickLink: (link: string) => void
  onLogout: () => void
}

const SideMenu: FC<Props> = ({
  sections,
  login_button,
  activeLocation,
  languages,
  select_language,
  onClickLink,
  onLogout,
}) => {
  const { currentLanguage, setCurrentLanguage } = useLanguage()
  const userState = useSelector(userSelector)
  const layoutState = useSelector(layoutDataSelector)
  const [open, setOpen] = useState(false)

  const handleLink = (link: string) => {
    setOpen(false)
    onClickLink(link)
  }

  const handleLogout = () => {
    setOpen(false)
    onLogout()
  }

  return (
    <>
      <MenuButton onClick={() => setOpen(true)} />
      {open && (
        <Container>
          <Content>
            <SectionContainer>
              {sections.toJS()?.map((section) => (
                <SectionElement
                  key={section.link}
                  className={activeLocation === section.link ? 'active' : ''}
                  onClick={() => handleLink(section.link)}
                >
                  {section.text}
                </SectionElement>
              ))}
              {!userState.get('token') ? (
                <SectionElement
                  className={activeLocation === '/login' ? 'active' : ''}
                  onClick={() => handleLink('/login')}
                >
                  {login_button}
                </SectionElement>
              ) : (
                <SectionElement onClick={handleLogout}>
                  {layoutState.getIn(['userMenu', 'logout', 'label'])}
                </SectionElement>
              )}
            </SectionContainer>
            <Separator />
            <LanguageLabel>{select_language}:</LanguageLabel>
            <LanguageContainer>
              {languages?.map((language: Record<Language>) => (
                <LanguageOption
                  key={language.get('value')}
                  className={currentLanguage === language.get('value') ? 'active' : ''}
                  onClick={() => setCurrentLanguage(language.get('value'))}
                >
                  <ActiveIcon />
                  {language.get('label')}
                </LanguageOption>
              ))}
            </LanguageContainer>
          </Content>
          <CloseButtonContainer>
            <CloseButton onClick={() => setOpen(false)} />
          </CloseButtonContainer>
        </Container>
      )}
    </>
  )
}

export default SideMenu
