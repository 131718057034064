import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px;
  width: 40%;
  @media screen and (max-width: 900px) {
    width: 100%;
    padding: 0;
    margin-top: 20px;
  }
`

export const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 49px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 650px) {
    font-size: 32px;
  }
`

export const Subtitle = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 45px;
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: 20px;
  @media screen and (max-width: 650px) {
    font-size: 24px;
  }
`

export const CheckboxWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
`

export const PrivacyWrapper = styled.div`
  margin-bottom: 10px;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
