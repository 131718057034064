import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 3rem;
  background-color: ${({ theme }) => theme.colors.light};
  @media screen and (max-width: 768px) {
    padding: 0 1rem;
  }
`

export const Content = styled.div`
  display: flex;
  padding: 2rem 0;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
`

export const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  @media screen and (max-width: 460px) {
    flex-wrap: wrap;
  }
`

export const Separator = styled.div`
  height: 16.5px;
  width: 1px;
  margin: 0 12px;
  background-color: ${({ theme }) => theme.colors.footerElementSeparator};
`

export const LineSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.footerContentSeparator};
`

export const LightText = styled.p`
  margin: 0;
  font-size: 13px;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: -0.38px;
  color: ${({ theme }) => theme.colors.lightTextFooter};
`

export const BottomLink = styled(Link)`
  margin: 0;
  font-size: 13px;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: -0.38px;
  color: ${({ theme }) => theme.colors.dark};
`
