import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { IVoluntariadoLayout } from './../models'
import * as TYPES from './../actionTypes'

export const initialState: IVoluntariadoLayout = fromJS({
  loading: false,
  path: {},
  intro_section: {},
  middle_section: {},
  work_section: {},
  subscribe_section: {},
  info_section: {},
  subscribe_card: {},
})

const voluntariadoLayoutReducer: Reducer<IVoluntariadoLayout> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET:
      return state.merge(action.payload)
    case TYPES.CONTENT.TRIGGER:
      return state.set('loading', true)
    case TYPES.CONTENT.FULFILL:
      return state.set('loading', false)
    case TYPES.CONTENT.FAILURE:
      return state.set('error', action.payload)
    case TYPES.CONTENT.SUCCESS:
      return state.merge(action.payload)
    default:
      return state
  }
}

export default voluntariadoLayoutReducer
