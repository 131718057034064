import React, { FC } from 'react'
import { CollaborateCard as CollaborateCardType } from 'ducks/colaboraLayout'

import { Container, ButtonWrapper } from './styled'
import { Subtitle, Text } from '../../styled'
import { Card } from 'views/components/UI/Contents'
import HtmlRender from 'views/components/HtmlRender'
import { ExternalLinkButton, LinkButton, ButtonIcon } from 'views/components/UI/Buttons'

type Props = {
  layout?: CollaborateCardType
}

const CollaborateCard: FC<Props> = (props: Props) => {
  const { layout } = props
  const renderButton = (card: CollaborateCardType) => {
    if (card.external_link) {
      return (
        <ExternalLinkButton href={card?.link} target="blank" mobileFullWidth>
          {card.button}
          <ButtonIcon />
        </ExternalLinkButton>
      )
    } else {
      return (
        <LinkButton to={card?.link || ''} mobileFullWidth>
          {card.button}
          <ButtonIcon />
        </LinkButton>
      )
    }
  }

  return (
    <Card color={layout?.background_color}>
      <Container>
        <Subtitle>{layout?.title}</Subtitle>
        <Text>
          <HtmlRender>{layout?.description}</HtmlRender>
        </Text>
        {layout?.link && <ButtonWrapper>{renderButton(layout)}</ButtonWrapper>}
      </Container>
    </Card>
  )
}

export default CollaborateCard
