import styled from 'styled-components'

export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem;
  background-color: ${({ theme }) => theme.colors.white};
  @media screen and (max-width: 600px) {
    padding: 1rem 2rem;
  }
`

export const Title = styled.h1`
  display: flex;
  flex-direction: column;
  font-weight: 900;
  margin: 0;
  margin-left: 1rem;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.dark};
  & > span:nth-child(2) {
    font-weight: 300;
  }
`

export const Logo = styled.img`
  width: 40px;
  height: 40px;
`
