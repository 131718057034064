import axios from 'axios'
import { LayoutData } from 'ducks/layoutData'
import { parseLayoutData } from './parser'
import { TranslatedRequest } from 'lib/axios/models'

const getLayoutContent = async ({ language }: TranslatedRequest): Promise<LayoutData | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
        query {
          cookieBanner {
            description: description_${language}
            accept_button: accept_button_${language}
            deny_button: deny_button_${language}
            custom_button: custom_button_${language}
            settings_title: settings_title_${language}
            confirm_button: confirm_button_${language}
            options {
              label
              value
            }
          }
          termsAndPolicy {
            subscribe_policy {
              part_one: part_one_${language}
              part_two: part_two_${language}
              link_label: link_label_${language}
              link
            }
            learn_more {
              button: button_${language}
              description: description_${language}
            }
            privacy_policy {
              link
              link_label: link_label_${language}
              description: description_${language}
            }
            use_and_privacy_policy {
              part_one: part_one_${language}
              part_two: part_two_${language}
              use_link
              privacy_link
              use_link_label: use_link_label_${language}
              privacy_link_label: privacy_link_label_${language}
            }
          }
          userMenu {
            options {
              label: label_${language}
              icon
              link
            }
            logout {
              label: label_${language}
              icon
            }
          }
          header {
            id
            title
            subtitle
            login_button: login_button_${language}
            logo{
              url
            }
            sections {
              id
              text: text_${language}
              link
            }
            Languages {
              value
              label: label_${language}
            }
            store {
              text: text_${language}
              link
            }
            header_title: header_main {
              text: text_${language}
              link {
                value
                external
              }
            }
            select_language: select_language_${language}
          }
          footer{
            bottom_part {
              link
              label: label_${language}
            }
            newsletter {
              title: title_${language}
              placeholder: placeholder_${language}
              button: button_${language}
              success: success_${language}
              loading: loading_${language}
              error: error_${language}
            }
            columns {
              id
              show_title
              margin_top
              title: title_${language}
              socials {
                link
                icon
              }
              rows {
                __typename
                ... on ComponentFooterLogoRow {
                  image {
                    url
                  }
                  title
                  subtitle
                }
                ... on ComponentFooterLinkRow {
                  link: link_content {
                    value
                    external
                  }
                  label: label_${language}
                }
                ... on ComponentFooterTextRow {
                  value: value_${language}
                }
              }
            }
          }
        }
      `,
    })
    if (data) {
      return parseLayoutData(data)
    }
  } catch (err) {
    throw new Error('Something went wrong')
  }
  throw new Error('Something went wrong')
}

export default getLayoutContent
