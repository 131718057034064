import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 1rem;
  }
`

export const Image = styled.img`
  width: 60%;
  max-height: 400px;
  @media screen and (min-width: 1600px) {
    width: 65%;
    max-height: 600px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const Content = styled.div`
  width: 40%;
  padding-left: 2rem;
  @media screen and (min-width: 1600px) {
    width: 35%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    padding-top: 1rem;
  }
`
