import axios from 'axios'
import { Category } from 'ducks/categories'
import { parseCategoriesData } from './parser'
import { TranslatedRequest } from 'lib/axios/models'

const categories = async ({ language }: TranslatedRequest): Promise<Array<Category> | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query{
        categories {
          protagonistas {
            id
          }
          movimientos {
            id
          }
          id
          name: name_${language}
          color
          overlay
          blog_color
          Badge {
            text
            background
          }
        }
      }
      `,
    })
    return parseCategoriesData(data)
  } catch (error) {
    return
  }
}

export default categories
