import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 44px 0;
`

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 31px 2rem;
  height: 500px;
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  -moz-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  @media screen and (max-width: 1250px) {
    padding: 20px 10px;
    height: unset;
  }
  @media screen and (min-width: 1600px) {
    height: 646px;
  }
`
export const SectionLine = styled.div`
  width: 38%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.black};
  position: absolute;
  right: -4rem;
  top: 14px;
  @media screen and (max-width: 1250px) {
    display: none;
  }
`

export const CardTitle = styled.div`
  margin: 0;
  font-family: 'PT Serif Caption', serif;
  font-size: 18px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.25px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 1250px) {
    text-align: center;
  }
`
export const CardHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  position: relative;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
`
