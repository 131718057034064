import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Author = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 20px;
  }
`

export const AuthorImage = styled.div<BackgroundProps>`
  ${background};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
  @media screen and (max-width: 450px) {
    height: 40px;
    width: 40px;
    margin-right: 10px;
  }
`

type BookmarkProps = {
  backgroundColor?: string
}

export const Bookmark = styled.div<BookmarkProps>`
  padding: 20px 5px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  max-width: 85px;
  min-height: 80px;
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.white};
`

export const BookmarkText = styled.p`
  font-weight: 800;
  font-size: 17px;
  line-height: 18px;
  letter-spacing: -0.32px;
  text-transform: uppercase;
  word-break: break-all;
  width: 72%;
  color: ${({ theme }) => theme.colors.black};
`

export const Container = styled(Link)``

export const Content = styled.div`
  width: 100%;
  padding: 0.5rem;
`

export const Image = styled.div<BackgroundProps>`
  ${background};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  width: 100%;
  height: 350px;
  position: relative;
  @media screen and (max-width: 450px) {
    height: 150px;
  }
`

export const MotivationWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black};
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 20px;
  }
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 450px) {
    font-size: 20px;
    line-height: 20px;
  }
`
