import React, { FC } from 'react'
import { Home as HomeIcon } from '@material-ui/icons'
import { AboutSection } from 'ducks/protagonistaLayout'
import { Protagonista } from 'ducks/protagonistas'

import {
  Container,
  BackgroundImage,
  ButtonWithIcon,
  CardContainer,
  Card,
  CardHeader,
  HeaderLabelContainer,
  CardTitle,
  CardBody,
  CardFooter,
  FooterTitle,
  FooterContentRow,
  LinkContainer,
  LinkIcon,
  LinkText,
  LinkLabel,
  ExternalLinkText,
  CardFooterHeader,
} from './styled'
import Badge from 'views/components/UI/Badge'
import CountryBadge from 'views/components/UI/CountryBadge'
import LineSeparator from 'views/components/UI/LineSeparator'
import HtmlRender from 'views/components/HtmlRender'
import ShareThumbnail from 'views/components/UI/ShareThumbnail'

type Props = {
  layout: AboutSection
  profile?: Protagonista
}

const About: FC<Props> = ({ layout, profile }: Props) => {
  const { category, country, socials, group } = profile as Protagonista
  const coverImage = profile?.cover_image?.url || profile?.image?.url

  return (
    <Container>
      <BackgroundImage backgroundImage={`url(${coverImage})`}>
        <ShareThumbnail src={coverImage} />
        <ButtonWithIcon color="rgba(0, 0, 0, 0.87)" bg="#bbdefb">
          <HomeIcon className="icon" />
          Protagonista categoria LOCAL
        </ButtonWithIcon>
      </BackgroundImage>
      <CardContainer>
        <Card>
          <CardHeader>
            <HeaderLabelContainer>
              <Badge backgroundColor={category?.Badge.background} textColor={category?.Badge.text}>
                {category?.name}
              </Badge>
            </HeaderLabelContainer>
            <HeaderLabelContainer>
              <CountryBadge country={country} />
            </HeaderLabelContainer>
          </CardHeader>
          <CardTitle>
            {profile?.name}
            {profile?.nickname ? ` - "${profile.nickname}"` : ''}
          </CardTitle>
          <LineSeparator color="#e0e0e0" />
          <CardBody>
            <HtmlRender>{profile?.description}</HtmlRender>
          </CardBody>
          <CardFooter>
            {(group || socials) && (
              <CardFooterHeader>
                <FooterTitle>{layout.movement}:</FooterTitle>
                <LineSeparator color="#e0e0e0" />
              </CardFooterHeader>
            )}
            {group && (
              <FooterContentRow>
                <LinkContainer key={group.codename}>
                  <LinkIcon fontSize="small" />
                  <LinkText to={`/movimiento/${group.codename}`}>{group.name}</LinkText>
                </LinkContainer>
              </FooterContentRow>
            )}
            {socials && (
              <FooterContentRow noPaddingTop>
                {socials.instagram && (
                  <LinkContainer>
                    <LinkLabel>{layout.socials?.instagram}:</LinkLabel>
                    <ExternalLinkText href={`https://www.instagram.com/${socials.instagram}/`} target="blank">
                      @{socials.instagram}
                    </ExternalLinkText>
                  </LinkContainer>
                )}
                {socials.website && (
                  <LinkContainer>
                    <LinkLabel>{layout.socials?.site}:</LinkLabel>
                    <ExternalLinkText href={socials.website}>{socials.website}</ExternalLinkText>
                  </LinkContainer>
                )}
                {socials.email && (
                  <LinkContainer>
                    <LinkLabel>{layout.socials?.email}:</LinkLabel>
                    <ExternalLinkText href={`mailto:${socials.email}`}>{socials.email}</ExternalLinkText>
                  </LinkContainer>
                )}
              </FooterContentRow>
            )}
          </CardFooter>
        </Card>
      </CardContainer>
    </Container>
  )
}

export default About
