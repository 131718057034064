import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 5%;
  bottom: -5%;
  @media screen and (min-width: 1600px) {
    bottom: -10%;
  }
  @media screen and (max-width: 450px) {
    width: 90%;
  }
`

export const Container = styled.div`
  padding: 2rem 2rem 3rem;
  min-height: 280px;
  position: relative;
  @media screen and (min-width: 1600px) {
    min-height: 330px;
    padding: 3rem 3rem 4rem;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    min-height: unset;
  }
`
