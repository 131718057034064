import { Intro } from 'ducks/colaboraLayout'
import React, { FC } from 'react'

import { Container, Image, Content } from './styled'
import { Title, Text } from '../styled'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  layout?: Intro
}

const IntroSection: FC<Props> = (props: Props) => {
  const { layout } = props
  return (
    <Container>
      <Image src={layout?.image?.url} />
      <Content>
        <Title>{layout?.title}</Title>
        <Text>
          <HtmlRender>{layout?.description}</HtmlRender>
        </Text>
      </Content>
    </Container>
  )
}

export default IntroSection
