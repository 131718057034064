import styled from 'styled-components'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 450px;
  background-color: #000c;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11;
  display: flex;
  flex-direction: row;
  -webkit-box-shadow: -1px 0px 5px 0px rgba(105, 105, 105, 1);
  -moz-box-shadow: -1px 0px 5px 0px rgba(105, 105, 105, 1);
  box-shadow: -1px 0px 5px 0px rgba(105, 105, 105, 1);
`

export const Content = styled.div`
  height: 100%;
  min-width: 258px;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 26px 0;
  display: flex;
  flex-direction: column;
`
export const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  & > .active {
    background-color: #6dbed1;
    color: ${({ theme }) => theme.colors.white};
  }
`

export const SectionElement = styled.div`
  cursor: pointer;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.default};
  letter-spacing: 0.03px;
  line-height: 24px;
  text-align: center;
`

export const Separator = styled.div`
  background-color: #333333;
  border: 1px solid #333;
  height: 2px;
  width: 80%;
  align-self: center;
  margin: 15px 0;
`

export const LanguageLabel = styled.p`
  font-size: 12px;
  letter-spacing: 0.04px;
  color: #333;
  font-style: italic;
  width: 80%;
  align-self: center;
  margin: 0 0 10px 0;
`

export const LanguageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  & > .active {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.green};
  }
`

export const LanguageOption = styled.div`
  cursor: pointer;
  height: 36px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10%;
  font-size: 14px;
`

export const ActiveIcon = styled(CheckIcon)`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 30px;
`

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 50px;
`

export const CloseButton = styled(CloseIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 35px;
`

export const MenuButton = styled(MenuIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  font-size: 20px;
`
