import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CONTENT, loadingSelector } from 'ducks/loading'
import { useLoading } from 'hooks/useLoading'

import { Container, LoadingAnimation } from './styled'
import LoadingGif from 'assets/loading.gif'

const Loading: FC = () => {
  const dispatch = useDispatch()
  const loadingState = useSelector(loadingSelector)
  const { loading } = useLoading()

  useEffect(() => {
    dispatch(CONTENT.trigger())
  }, [dispatch])

  return loading ? (
    <Container>
      <LoadingAnimation src={loadingState.getIn(['image', 'url']) || LoadingGif} />
    </Container>
  ) : null
}

export default Loading
