import { SagaIterator } from '@redux-saga/core'
import { Action } from 'lib/redux/models'
import { all, takeLatest, put, call } from 'redux-saga/effects'
import { CONTENT } from '../actionTypes'
import getLoginLayout from 'services/getLoginLayout'

function* loadLoginLayout(action: Action) {
  yield put(CONTENT.request())
  try {
    const { data } = yield call(getLoginLayout, action?.payload)
    if (data) {
      yield put(CONTENT.success(data.loginLayout))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

export function* loginLayoutSagas(): SagaIterator {
  yield all([takeLatest(CONTENT.TRIGGER, loadLoginLayout)])
}
