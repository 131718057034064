import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const Container = styled.div`
  max-width: 400px;
`

export const Title = styled.p`
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.103636px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 450px) {
    margin-top: 20px;
  }
  @media screen and (min-width: 1600px) {
    min-width: 400px;
  }
`

export const SuccessFeedback = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  height: 34px;
  min-width: 180px;
  max-width: 250px;
  width: 50%;
  font-size: 14px;
  padding: 0 10px;
`

export const InputContainer = styled.div`
  margin: 14px 0;
`
