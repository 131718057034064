import React, { FC } from 'react'
import { Container, Flag, Text } from './styled'
import { Country } from 'ducks/countries'

type Props = {
  country?: Country
}

const CountryBadge: FC<Props> = ({ country }: Props) => (
  <Container>
    <Flag src={country?.image?.url} />
    <Text>{country?.name}</Text>
  </Container>
)

export default CountryBadge
