import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: #fffc;
`

export const LoadingAnimation = styled.img`
  width: 100px;
  height: 100px;
`
