import React, { FC, useState, useEffect } from 'react'
import { Container, SlideButton, ImageContainer, Image, Paginator, Dot } from './styled'
import { ChevronLeft as LeftIcon, ChevronRight as RightIcon } from '@material-ui/icons'
import { GalleryItem, Protagonista } from 'ducks/protagonistas'
import VideoOrImage from 'views/components/VideoOrImage'

type Props = {
  protagonista?: Protagonista
}

enum SlideActions {
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS',
}

const ProtagonistaSlider: FC<Props> = ({ protagonista }: Props) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const [currentItem, setCurrentItem] = useState<GalleryItem | null>(null)
  const [hasSlide, setHasSlide] = useState<boolean>(false)

  useEffect(() => {
    if (protagonista && protagonista.gallery?.length > 1) {
      setHasSlide(true)
      const firstItem = protagonista?.gallery[currentItemIndex]
      setCurrentItem(firstItem)
    } else {
      setHasSlide(false)
    }
    if (protagonista?.gallery) {
      const item = protagonista?.gallery[currentItemIndex]
      setCurrentItem(item)
    }
  }, [protagonista, currentItemIndex])

  const slideNavigation = (action: SlideActions) => {
    const slideSize = protagonista?.gallery?.length || 1
    if (action === SlideActions.NEXT) {
      setCurrentItemIndex((prevState) => (prevState + 1) % slideSize)
    } else if (currentItemIndex === 0) {
      setCurrentItemIndex(slideSize - 1)
    } else {
      setCurrentItemIndex((prevState) => prevState - 1)
    }
  }

  const renderMediaContent = () => {
    if (protagonista && protagonista.gallery?.length > 0) {
      return <VideoOrImage video={currentItem?.video_url} thumbnail={currentItem?.image?.url || ''} />
    } else {
      return <VideoOrImage thumbnail={protagonista?.cover_image?.url || ''} />
    }
  }

  return (
    <Container>
      {hasSlide && (
        <SlideButton onClick={() => slideNavigation(SlideActions.PREVIOUS)}>
          <LeftIcon className="icon" fontSize="inherit" />
        </SlideButton>
      )}
      <ImageContainer>
        <Image>{renderMediaContent()}</Image>
        {hasSlide && (
          <Paginator>
            {protagonista?.gallery?.map((galleryItem, index) => (
              <Dot
                key={`${galleryItem?.label}${index}`}
                active={index === currentItemIndex}
                onClick={() => setCurrentItemIndex(index)}
              />
            ))}
          </Paginator>
        )}
      </ImageContainer>
      {hasSlide && (
        <SlideButton onClick={() => slideNavigation(SlideActions.NEXT)}>
          <RightIcon className="icon" fontSize="inherit" />
        </SlideButton>
      )}
    </Container>
  )
}

export default ProtagonistaSlider
