import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 4rem 0;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0 0 4rem;
  }
`

export const FormContainer = styled.div`
  width: 45%;
  @media screen and (min-width: 1600px) {
    width: 40%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 40px;
  }
  @media screen and (max-width: 450px) {
    margin-top: 20px;
  }
`

export const Image = styled.img`
  width: 50%;
  height: 500px;
  transform: translateY(-6rem);
  @media screen and (min-width: 1600px) {
    height: 700px;
  }
  @media screen and (max-width: 768px) {
    transform: none;
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    height: 300px;
  }
`
