import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1250px) {
    flex-direction: column-reverse;
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    padding: 0 1rem;
  }
`

export const Title = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 1.6rem;
`

export const Description = styled.div`
  line-height: 1.2rem;
  margin: 16px 0;
  width: 90%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 1250px) {
    font-size: 12px;
    letter-spacing: 0.04px;
  }
`

export const Image = styled.img`
  width: 90%;
  max-width: 657px;
  height: 371px;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`

export const VideoWrapper = styled.div`
  width: 90% !important;
  height: 300px !important;
  @media screen and (min-width: 1600px) {
    height: 400px !important;
  }
  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
  @media screen and (max-width: 1250px) {
    height: 200px !important;
  }
`
