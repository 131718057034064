import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { background, BackgroundProps, ColorProps } from 'styled-system'

export const CategoryMark = styled.div<ColorProps>`
  width: 80px;
  height: 100px;
  display: flex;
  align-items: flex-end;
  padding: 5px 13px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ color, theme }) => color || theme.colors.white};
  position: absolute;
  top: 0;
  left: 2rem;
  z-index: 1;
  p {
    width: 100%;
    word-break: break-all;
  }
`

export const Container = styled(Link)`
  padding: 1rem;
  background: ${({ theme }) => theme.colors.dark};
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`

export const Image = styled.div<BackgroundProps>`
  padding: 1rem;
  ${background};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  filter: grayscale(1);
  width: 100%;
  height: 315px;
  display: flex;
  justify-content: flex-end;
  @media screen and (min-width: 1600px) {
    height: 400px;
  }
`

export const Label = styled.p`
  font-size: 14px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.white};
  @media screen and (min-width: 1600px) {
    font-size: 15px;
  }
`

export const StarWrapper = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 1;
`

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 15px;
  line-height: 20px;
  @media screen and (min-width: 1600px) {
    font-size: 17px;
  }
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.white};
  margin: 10px 0;
  @media screen and (min-width: 1600px) {
    font-size: 27px;
    line-height: 29px;
  }
`
