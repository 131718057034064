import { SagaIterator } from '@redux-saga/core'
import { all, takeLatest, put, call } from 'redux-saga/effects'
import * as TYPES from '../actionTypes'
import protagonistas from 'services/protagonistas'
import getProtagonista from 'services/protagonistas/getProtagonista'
import submitProfile from 'services/protagonistas/submitProfile'
import { Action } from 'lib/redux/models'
import { SET_LOADING } from 'ducks/loading'

function* getProtagonistas(action: Action) {
  yield put(TYPES.CONTENT.request())
  try {
    const data = yield call(protagonistas, action?.payload)
    if (data) {
      yield put(TYPES.CONTENT.success(data))
    }
  } catch (error) {
    yield put(TYPES.CONTENT.failure(error.message))
  } finally {
    yield put(TYPES.CONTENT.fulfill())
  }
}

function* getMoreProtagonistas(action: Action) {
  yield put(TYPES.LOAD_PROTAGONISTAS.request())
  try {
    const data = yield call(protagonistas, action?.payload)
    if (data) {
      yield put(TYPES.LOAD_PROTAGONISTAS.success(data))
    }
  } catch (error) {
    yield put(TYPES.CONTENT.failure(error.message))
  } finally {
    yield put(TYPES.LOAD_PROTAGONISTAS.fulfill())
  }
}

function* getOneProtagonista(action: Action) {
  try {
    const data = yield call(getProtagonista, action?.payload)
    if (data) {
      yield put(TYPES.CONTENT.success(data))
    }
  } catch (error) {
    yield put(TYPES.GET.failure(error.message))
  } finally {
    yield put(TYPES.GET.fulfill())
  }
}

function* createProfile(action: Action) {
  yield put(SET_LOADING.trigger())
  try {
    const data = yield call(submitProfile, action?.payload)
    if (data) {
      yield put(TYPES.CONTENT.success({ profile_success: true }))
    }
  } catch (error) {
    yield put(TYPES.CONTENT.failure(error.message))
  } finally {
    yield put(SET_LOADING.fulfill())
  }
}

export function* protagonistasSagas(): SagaIterator {
  yield all([
    takeLatest(TYPES.CONTENT.TRIGGER, getProtagonistas),
    takeLatest(TYPES.GET.TRIGGER, getOneProtagonista),
    takeLatest(TYPES.SUBMIT_PROFILE.TRIGGER, createProfile),
    takeLatest(TYPES.LOAD_PROTAGONISTAS.TRIGGER, getMoreProtagonistas),
  ])
}
