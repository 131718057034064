import { SagaIterator } from '@redux-saga/core'
import { Action } from 'lib/redux/models'
import { all, put, call, takeLatest } from 'redux-saga/effects'
import { CONTENT, POST } from 'ducks/volunteers/actionTypes'
import { getVolunteers, submitVolunteer } from 'services/volunteers'

function* loadVolunteers(action: Action) {
  yield put(CONTENT.request())
  const { data } = yield call(getVolunteers, action?.payload)
  if (data) {
    yield put(CONTENT.success(data))
  }
  try {
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* createVolunteer(action: Action) {
  yield put(POST.request())
  try {
    const success = yield call(submitVolunteer, action?.payload)
    if (success) {
      yield put(POST.success())
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(POST.fulfill())
  }
}

export function* volunteersSagas(): SagaIterator {
  yield all([takeLatest(CONTENT.TRIGGER, loadVolunteers), takeLatest(POST.TRIGGER, createVolunteer)])
}
