import styled from 'styled-components'
import CancelIcon from '@material-ui/icons/Cancel'

type ContainerProps = {
  backgroundColor?: string
  textColor?: string
}

export const Text = styled.p`
  margin: 0px 3px 0 6px;
  font-size: 12px;
  line-height: 1.55;
  letter-spacing: 0.07px;
  cursor: default;
`

export const Flag = styled.img`
  width: 20px;
  height: 17px;
`

export const Container = styled.div<ContainerProps>`
  background-color: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.dark};
  border-radius: 16.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 5px 2px 5px 10px;
  font-family: Lato;
  margin: 0 5px;
  ${Text} {
    color: ${(props) => props.textColor};
  }
`

export const CancelButton = styled(CancelIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
`
