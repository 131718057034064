import React, { FC, useEffect, useState } from 'react'
import { Container, Header } from './styled'
import PathIndicator from 'views/components/PathIndicator'
import { useSelector, useDispatch } from 'react-redux'
import { protagonistasLayoutSelector } from 'ducks/protagonistasLayout/selectors'
import { protagonistasSelector } from 'ducks/protagonistas/selectors'
import { protagonistaLayoutSelector } from 'ducks/protagonistaLayout/selectors'
import { CONTENT as PROTAGONISTAS_LAYOUT_CONTENT } from 'ducks/protagonistasLayout/actionTypes'
import { GET, CONTENT } from 'ducks/protagonistas/actionTypes'
import { CONTENT as LAYOUT_CONTENT } from 'ducks/protagonistaLayout/actionTypes'
import { useLanguage } from 'hooks/useLanguage'
import { useParams } from 'react-router-dom'
import About from './About'
import History from './History'
import Recommendations from './Recommendations'
import { Section } from 'ducks/protagonistasLayout'
import { Category } from 'ducks/categories'

const Protagonista: FC = () => {
  const dispatch = useDispatch()
  const [pathSections, setPathSections] = useState([])
  const [overlay, setOverlay] = useState('transparent')
  const protagonistasLayout = useSelector(protagonistasLayoutSelector)
  const protagonistas = useSelector(protagonistasSelector)
  const protagonistaLayout = useSelector(protagonistaLayoutSelector)
  const { currentLanguage } = useLanguage()
  const { username } = useParams<{ username: string }>()

  useEffect(() => {
    dispatch(PROTAGONISTAS_LAYOUT_CONTENT.trigger({ language: currentLanguage }))
    dispatch(LAYOUT_CONTENT.trigger({ language: currentLanguage }))
    dispatch(GET.trigger({ language: currentLanguage, username }))
    dispatch(CONTENT.trigger({ language: currentLanguage, countries: [], categories: [] }))
  }, [dispatch, currentLanguage, username])

  useEffect(() => {
    if (protagonistas.get('protagonista')) {
      const sections = protagonistasLayout.getIn(['path', 'sections'])?.map((section: Section) => section)
      sections?.push({
        link: `/protagonista/${protagonistas.getIn(['protagonista', 'username'])}`,
        text:
          protagonistas.getIn(['protagonista', 'nickname'])?.toUpperCase() ||
          protagonistas.getIn(['protagonista', 'name'])?.toUpperCase(),
      })
      setPathSections(sections)
    }
    const protagonistCategory: Category = protagonistas.getIn(['protagonista', 'category'])
    setOverlay(protagonistCategory?.Badge.background)
  }, [protagonistas, protagonistasLayout])

  return (
    <>
      {/* <Background />
      <Overlay backgroundColor={overlay} /> */}
      <Container backgroundColor={overlay}>
        <Header>
          <PathIndicator description={protagonistasLayout.getIn(['path', 'description'])} sections={pathSections} />
          {/* <PlaceHolder>PLACEHOLDER</PlaceHolder> */}
        </Header>
        <About layout={protagonistaLayout.get('about')} />
        <History layout={protagonistaLayout.get('history')} />
        <Recommendations title={protagonistaLayout.get('recommendation')} />
      </Container>
    </>
  )
}

export default Protagonista
