import React, { FC, useState } from 'react'
import { Container, Header, HeaderTitle, Option, OptionLabel, OptionText } from './styled'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Category } from 'ducks/categories'
import { useFilter } from 'hooks/useFilter'

type Props = {
  title: string
  options: Array<Category>
}

const CategoryFilter: FC<Props> = (props: Props) => {
  const { title, options } = props
  const [open, setOpen] = useState<boolean>(true)
  const { handleCategoryFilter, includesCategory } = useFilter()

  return (
    <Container open={open} optionsLength={options.length}>
      <Header>
        <HeaderTitle>{title}</HeaderTitle>
        <ExpandMoreIcon data-testid="expandButton" onClick={() => setOpen((prevState) => !prevState)} />
      </Header>
      {options.map((option, index) => (
        <Option
          key={option.name + option.color}
          onClick={() => handleCategoryFilter(option)}
          selected={includesCategory(option)}
          last={index === options.length - 1}
        >
          <OptionLabel color={option.color} />
          <OptionText>{option.name}</OptionText>
        </Option>
      ))}
    </Container>
  )
}

export default CategoryFilter
