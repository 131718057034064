import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { ILoading } from './../models'
import * as TYPES from './../actionTypes'

export const initialState: ILoading = fromJS({
  loading: false,
})

const loadingReducer: Reducer<ILoading> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET:
      return state.merge(action.payload)
    case TYPES.SET_LOADING.TRIGGER:
      return state.set('loading', true)
    case TYPES.SET_LOADING.FULFILL:
      return state.set('loading', false)
    case TYPES.CONTENT.FAILURE:
      return state.set('error', action.payload)
    case TYPES.CONTENT.SUCCESS:
      return state.merge(action.payload)
    default:
      return state
  }
}

export default loadingReducer
