import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  @media screen and (min-width: 1600px) {
    padding-top: 80px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 0px;
  }
`

export const CardBody = styled.div`
  width: 100%;
  padding: 4rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
  @media screen and (max-width: 450px) {
    padding: 1rem 1rem 3rem;
    flex-direction: column;
  }
`

export const CardText = styled.p`
  width: 40%;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.white};
  @media screen and (min-width: 1600px) {
    font-size: 24px;
    line-height: 30px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
  }
`

export const CardTitle = styled.p`
  width: 55%;
  font-weight: bold;
  font-size: 36px;
  line-height: 35px;
  @media screen and (min-width: 1600px) {
    font-size: 48px;
    line-height: 51px;
  }
  @media screen and (max-width: 768px) {
    font-size: 25px;
    line-height: 25px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 1rem;
  }
`

export const CardButtonWrapper = styled.div`
  position: absolute;
  bottom: -5%;
  right: 5%;
  @media screen and (min-width: 1600px) {
    bottom: -12%;
  }
  @media screen and (max-width: 768px) {
    bottom: -10%;
  }
  @media screen and (max-width: 450px) {
    bottom: -10%;
    width: 90%;
  }
`
