import { HomeIntro, Step as StepType } from 'ducks/postular'
import { Link } from 'ducks/home'
import React, { FC } from 'react'

import {
  CardBody,
  CardTitle,
  CardWrapper,
  Container,
  VideoContainer,
  ContentContainer,
  Content,
  StepsContainer,
  Step,
  StepNumber,
  Separator,
  ButtonWrapper,
} from './styled'
import VideoOrImage from 'views/components/VideoOrImage'
import { Title, Text } from 'views/screens/PostularHome/styled'
import HtmlRender from 'views/components/HtmlRender'
import { ExternalLinkButton, ButtonIcon, LinkButton } from 'views/components/UI/Buttons'
import { Card } from 'views/components/UI/Contents'

type Props = {
  layout: HomeIntro
}

const IntroSection: FC<Props> = (props: Props) => {
  const { layout } = props

  const renderLinkButton = (link: Link) => {
    if (link?.external) {
      return (
        <ExternalLinkButton href={link?.value} target="blank" mobileFullWidth>
          {layout.button}
          <ButtonIcon />
        </ExternalLinkButton>
      )
    } else {
      return (
        <LinkButton to={link?.value || ''} mobileFullWidth>
          {layout.button}
          <ButtonIcon />
        </LinkButton>
      )
    }
  }

  return (
    <>
      <Container>
        <VideoContainer>
          <VideoOrImage video={layout?.video} thumbnail={layout?.video_placeholder?.url} />
        </VideoContainer>
        <ContentContainer>
          <Content>
            <Title>{layout.title}</Title>
            <Text>
              <HtmlRender>{layout.description}</HtmlRender>
            </Text>
          </Content>
        </ContentContainer>
      </Container>
      <CardWrapper>
        <Card>
          <CardBody>
            <CardTitle>{layout?.card_title}</CardTitle>
            <StepsContainer>
              {layout.steps?.map((step: StepType) => (
                <>
                  <Step key={step.number}>
                    <StepNumber>{step.number}</StepNumber>
                    {step.label}
                  </Step>
                  {step.number < layout.steps?.length && <Separator />}
                </>
              ))}
            </StepsContainer>
            <ButtonWrapper>{renderLinkButton(layout?.link)}</ButtonWrapper>
          </CardBody>
        </Card>
      </CardWrapper>
    </>
  )
}

export default IntroSection
