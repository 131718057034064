import React, { useEffect, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLanguage } from 'hooks/useLanguage'
import { tienditaLayoutSelector, CONTENT } from 'ducks/tienditaLayout'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

import HtmlRender from 'views/components/HtmlRender'
import { Card } from 'views/components/UI/Contents'
import {
  Title,
  FirstSection,
  Description,
  Image,
  Container,
  CoverPageLeft,
  CoverPageRight,
  SecondSection,
  CardWrapper,
  ThreeSection,
  SentenceRight,
  FourSection,
  FiveSection,
  ImageBottom,
  TextWrapper,
  BottomText,
  Some,
  MainContainer,
  PathContainerWrapper,
} from './styled'
import Form from './Form'
import PathIndicator from 'views/components/PathIndicator'

const Tiendita: FC = () => {
  const dispatch = useDispatch()
  const tienditaLayout = useSelector(tienditaLayoutSelector)
  const { currentLanguage } = useLanguage()
  useEffect(() => {
    dispatch(CONTENT.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage])
  return (
    <MainContainer>
      <PathContainerWrapper>
        <PathIndicator
          description={tienditaLayout.getIn(['path', 'description'])}
          sections={tienditaLayout.getIn(['path', 'sections'])}
        />
      </PathContainerWrapper>
      <Container>
        <Some>
          <Card height={'400px'}>
            <FirstSection>
              <Title>{tienditaLayout.getIn(['title'])}</Title>
              <Description>
                <HtmlRender>{tienditaLayout.getIn(['description'])}</HtmlRender>
              </Description>
            </FirstSection>
          </Card>
        </Some>
        <Image src={`${tienditaLayout.getIn(['image_right', 'url'])}`} />
      </Container>
      <SecondSection>
        <CoverPageLeft src={`${tienditaLayout.getIn(['cover_page_left', 'url'])}`} />
        <CoverPageRight src={`${tienditaLayout.getIn(['cover_page_right', 'url'])}`} />
      </SecondSection>
      <ThreeSection>
        <CardWrapper>
          <Card color={'#E4848F'}>
            <MailchimpSubscribe
              url={process.env.REACT_APP_MAILCHIMP_TIENDITA}
              render={({ subscribe, status }: { subscribe: any; status: string }) => (
                <Form
                  text={tienditaLayout.getIn(['subscribe_text'])}
                  placeholder={tienditaLayout.getIn(['subscribe_placeholder'])}
                  button={tienditaLayout.getIn(['subscribe_button'])}
                  submit={subscribe}
                  status={status}
                  errorMessage={tienditaLayout.getIn(['subscribe_error'])}
                  feedbackColor={tienditaLayout.getIn(['subscribe_feedback_color'])}
                />
              )}
            />
          </Card>
        </CardWrapper>
      </ThreeSection>
      <FourSection>
        <Image src={`${tienditaLayout.getIn(['image_left', 'url'])}`} />
        <SentenceRight>{tienditaLayout.getIn(['sentence_right'])}</SentenceRight>
      </FourSection>
      <FiveSection>
        <ImageBottom src={`${tienditaLayout.getIn(['city_background', 'url'])}`} />
        <TextWrapper>
          <BottomText>{tienditaLayout.getIn(['sentence_bottom'])}</BottomText>
        </TextWrapper>
      </FiveSection>
    </MainContainer>
  )
}

export default Tiendita
