import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { LOAD } from 'ducks/layoutData/actionTypes'
import { layoutDataSelector, ILayoutData } from 'ducks/layoutData'

const useLayoutLoader = (currentLanguage: string): ILayoutData => {
  const dispatch = useDispatch()
  const layoutData = useSelector(layoutDataSelector)
  useEffect(() => {
    dispatch(LOAD.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage])
  return layoutData
}

export default useLayoutLoader
