import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Container = styled.div<ColorProps>`
  padding: 2% 2rem;
  ${color}
  @media screen and (max-width: 1250px) {
    padding: 10px 10px;
  }
`

export const Background = styled.div`
  position: absolute;
  width: 99%;
  height: 99%;
  left: 0;
  top: 20%;
  z-index: -2;
  background: linear-gradient(135deg, #fff 40%, transparent 30%) -50px 0,
    linear-gradient(225deg, #fff 40%, transparent 41%) -50px 0, linear-gradient(315deg, #fff 30%, transparent 30%),
    linear-gradient(45deg, #fff 30%, transparent 30%);
  background-size: 100px 78px;
  background-color: #111;
  transform: rotate(-1deg);
`

export const Overlay = styled.div<ColorProps>`
  position: absolute;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  ${color}
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

export const PlaceHolder = styled.div`
  width: 55%;
  height: 70px;
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.dark};
  background-color: grey;
`
