import React, { FC, useEffect, useState } from 'react'
import { List, Record } from 'immutable'
import { LOGOUT } from 'ducks/user'
import { useHistory } from 'react-router-dom'
import { Language } from 'ducks/layoutData'
import { useDispatch } from 'react-redux'

import { Header, Logo, Title } from './styled'
import { Block } from '../UI/Contents'
import SideMenu from '../SideMenu'

interface Props {
  logo: string
  title: string
  subtitle: string
  login_button: string
  sections: List<
    Record<{
      text: string
      link: string
    }>
  >
  languages: List<Record<Language>>
  store: Record<{
    text: string
    link: string
  }>
  header_title: Record<{
    text: string
    link: string
  }>
  select_language: string
}

const HeaderMobile: FC<Props> = ({ logo, title, subtitle, sections, languages, select_language, login_button }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [activeLocation, setActiveLocation] = useState(history.location.pathname)

  useEffect(() => {
    return history.listen((location) => {
      setActiveLocation(location.pathname)
    })
  }, [history])

  const onClickLogo = () => {
    history.push('/')
  }

  const onClickLink = (link: string) => {
    history.push(link)
  }

  const handleLogout = () => {
    dispatch(LOGOUT.trigger())
    history.push('/')
  }

  return (
    <Header>
      <Block display="flex" width="100%" flexDirection="row" alignItems="flex-start" justifyContent="space-between">
        <Block display="flex" flexDirection="row" alignItems="center" onClick={onClickLogo} hover>
          <Logo src={logo} />
          <Title>
            <span>{title}</span>
            <span>{subtitle}</span>
          </Title>
        </Block>
        <SideMenu
          sections={sections}
          login_button={login_button}
          activeLocation={activeLocation}
          languages={languages}
          select_language={select_language}
          onClickLink={onClickLink}
          onLogout={handleLogout}
        />
      </Block>
    </Header>
  )
}

export default HeaderMobile
