import { IntroSection as IntroSectionType } from 'ducks/voluntariadoLayout'
import React, { FC } from 'react'

import {
  Container,
  Background,
  CardWrapper,
  CardContent,
  CardImage,
  CardTitle,
  CardDescription,
  CardLabel,
  CardBox,
  CardBody,
} from './styled'
import HtmlRender from 'views/components/HtmlRender'
import { Card } from 'views/components/UI/Contents'

type Props = {
  layout: IntroSectionType
}

const IntroSection: FC<Props> = (props: Props) => {
  const { layout } = props

  return (
    <Container>
      <CardWrapper>
        <Card height="100%" background={`url(${layout?.card_background?.url})`}>
          <CardBody>
            {layout.card_image && <CardImage background={`url(${layout.card_image?.url})`} />}
            <CardContent>
              <CardTitle>{layout.card_title}</CardTitle>
              <CardBox>
                <CardDescription>
                  <HtmlRender>{layout.card_description}</HtmlRender>
                </CardDescription>
                <CardLabel>{layout.card_label}</CardLabel>
              </CardBox>
            </CardContent>
          </CardBody>
        </Card>
      </CardWrapper>
      <Background background={`url(${layout.background?.url})`} />
    </Container>
  )
}

export default IntroSection
