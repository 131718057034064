import React, { FC } from 'react'
import { Category } from 'ducks/categories'
import { Country } from 'ducks/countries'
import {
  Container,
  LeftContainer,
  ArticleTitle,
  Card,
  CardHeader,
  HeaderLabelContainer,
  CardTitle,
  CardBody,
  CardFooter,
  FooterTitle,
  FooterContentRow,
  LinkContainer,
  LinkText,
  LinkLabel,
  ExternalLinkText,
} from './styled'
import Badge from 'views/components/UI/Badge'
import CountryBadge from 'views/components/UI/CountryBadge'
import LineSeparator from 'views/components/UI/LineSeparator'
import { Details } from 'ducks/movimientoLayout'
import { Protagonista } from 'ducks/protagonistas'
import PathIndicator from 'views/components/PathIndicator'
import { Path } from 'ducks/protagonistasLayout'
import { Block } from 'views/components/UI/Contents'
import HtmlRender from 'views/components/HtmlRender'
import { Movimiento } from 'ducks/movimientos'

type Props = {
  layout: Details
  path: Path
  articleTitle?: string
  movimiento: Movimiento
  orderedCategories: Array<Category>
}

const About: FC<Props> = (props: Props) => {
  const { layout, path, articleTitle, movimiento, orderedCategories } = props
  const country: Country = movimiento?.country

  return (
    <Container>
      <LeftContainer>
        <PathIndicator description={path.description} sections={path.sections} />
        <ArticleTitle>{articleTitle}</ArticleTitle>
      </LeftContainer>
      <Card>
        <CardHeader>
          <HeaderLabelContainer>
            {orderedCategories?.map((category) => (
              <Block key={category.name} marginRight="6px">
                <Badge backgroundColor={category?.Badge.background} textColor={category?.Badge.text}>
                  {category?.name}
                </Badge>
              </Block>
            ))}
          </HeaderLabelContainer>
          <HeaderLabelContainer>
            <CountryBadge country={country} />
          </HeaderLabelContainer>
        </CardHeader>
        <CardTitle>
          <span className="uppercase"> {movimiento?.name}</span>
        </CardTitle>
        <LineSeparator color="#e0e0e0" />
        <CardBody>
          <HtmlRender>{movimiento?.description}</HtmlRender>
        </CardBody>
        <CardFooter>
          <FooterTitle>{layout.protagonistas}:</FooterTitle>
          <LineSeparator color="#e0e0e0" />
          <FooterContentRow>
            <LinkContainer>
              {movimiento?.protagonistas?.map((protagonista: Protagonista) => (
                <LinkText to={`/protagonista/${protagonista.username}`} key={protagonista.id}>
                  {protagonista.nickname || protagonista.name},
                </LinkText>
              ))}
            </LinkContainer>
          </FooterContentRow>
          {movimiento?.socials && (
            <>
              <FooterTitle>{layout.socials?.title}:</FooterTitle>
              <LineSeparator color="#e0e0e0" />
              <FooterContentRow>
                {movimiento?.socials.instagram && (
                  <LinkContainer>
                    <LinkLabel>{layout.socials?.instagram}:</LinkLabel>
                    <ExternalLinkText
                      href={`https://www.instagram.com/${movimiento?.socials.instagram}/`}
                      target="blank"
                    >
                      @{movimiento?.socials.instagram}
                    </ExternalLinkText>
                  </LinkContainer>
                )}
                {movimiento?.socials.website && (
                  <LinkContainer>
                    <LinkLabel>{layout.socials?.site}:</LinkLabel>
                    <ExternalLinkText href={movimiento?.socials.website}>
                      {movimiento?.socials.website}
                    </ExternalLinkText>
                  </LinkContainer>
                )}
                {movimiento?.socials.email && (
                  <LinkContainer>
                    <LinkLabel>{layout.socials?.email}:</LinkLabel>
                    <ExternalLinkText href={`mailto:${movimiento?.socials.email}`}>
                      {movimiento?.socials.email}
                    </ExternalLinkText>
                  </LinkContainer>
                )}
              </FooterContentRow>
            </>
          )}
        </CardFooter>
      </Card>
    </Container>
  )
}

export default About
