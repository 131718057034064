import axios from 'axios'
import { SuccessLayout } from 'ducks/successLayout'
import { TranslatedRequest } from 'lib/axios/models'

export const getSuccessInviteLayout = async ({ language }: TranslatedRequest): Promise<SuccessLayout | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        successInviteLayout {
          title:title_${language}
          description: description_${language}
          button: button_${language}
          link
          card_color
          card_background {
            url
          }
        }
      }
      `,
    })
    return data.successInviteLayout
  } catch (error) {
    return
  }
}

export const getSuccessProfileLayout = async ({ language }: TranslatedRequest): Promise<SuccessLayout | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        successProfileLayout {
          title:title_${language}
          description: description_${language}
          button: button_${language}
          link
          card_color
          card_background {
            url
          }
        }
      }
      `,
    })
    return data.successProfileLayout
  } catch (error) {
    return
  }
}
