import React, { FC, useContext, ChangeEvent } from 'react'
import { Form as IForm, FormErrorValues, FormValues } from 'ducks/contacto'
import { FiUser, FiMail, FiFileText, FiSmartphone, FiCheck } from 'react-icons/fi'

import { ButtonWithIcon, ButtonIcon, SuccessFeedbackButton } from 'views/components/UI/Buttons'
import { Container, Title, Subtitle, CheckboxWrapper, ButtonWrapper, PrivacyWrapper } from './styled'
import { ThemeContext } from 'styled-components'
import TextInput from 'views/components/TextInput'
import Select from 'views/components/Select'
import Checkbox from 'views/components/Checkbox'
import TextArea from 'views/components/UI/TextArea'
import LoadingButtonAnimation from 'views/components/LoadingButtonAnimation'
import LearnMoreTerms from 'views/components/LearnMoreTerms'
import PrivacyTerms from 'views/components/PrivacyTerms'

type Props = {
  layout: IForm
  errors: FormErrorValues
  values: FormValues
  onSubmit: () => void
  handleChange: (e: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  setFieldValue: (field: string, value: boolean) => void
  success?: boolean
  loading?: boolean
}

const Form: FC<Props> = (props: Props) => {
  const themeContext = useContext(ThemeContext)
  const { colors } = themeContext
  const { layout, values, onSubmit, handleChange, errors, setFieldValue, success, loading } = props

  const onHandleCheck = () => {
    setFieldValue('checked', !values.checked)
  }

  const onHandleCheckPrivacy = () => {
    setFieldValue('privacy_checked', !values.checked)
  }

  const renderButton = () => {
    if (success) {
      return (
        <SuccessFeedbackButton>
          <FiCheck />
        </SuccessFeedbackButton>
      )
    }
    if (loading) {
      return (
        <ButtonWithIcon mobileFullWidth>
          <LoadingButtonAnimation />
        </ButtonWithIcon>
      )
    }
    return (
      <ButtonWithIcon color={colors.dark} textColor={colors.white} onClick={onSubmit} mobileFullWidth>
        {layout.button}
        <ButtonIcon />
      </ButtonWithIcon>
    )
  }

  return (
    <Container>
      <Title>{layout.title}</Title>
      <Subtitle>{layout.subtitle}</Subtitle>
      <TextInput
        onChange={handleChange}
        icon={FiUser}
        placeholder={layout.name?.label}
        value={values.name}
        error={errors.name}
        name="name"
      />
      <TextInput
        onChange={handleChange}
        icon={FiMail}
        placeholder={layout.email?.label}
        inputType="email"
        value={values.email}
        error={errors.email}
        name="email"
      />
      <TextInput
        onChange={handleChange}
        icon={FiMail}
        placeholder={layout.confirm_email?.label}
        inputType="email"
        value={values.confirm_email}
        error={errors.confirm_email}
        name="confirm_email"
      />
      <TextInput
        onChange={handleChange}
        icon={FiSmartphone}
        placeholder={layout.phone?.label}
        inputType="tel"
        value={values.phone}
        name="phone"
      />
      <Select
        onChange={handleChange}
        name="query_type"
        value={values.query_type}
        icon={FiFileText}
        placeholder={layout.query_type?.label}
        options={layout.query_type_options}
        error={errors.query_type}
      />
      <TextArea
        placeholder={layout.message?.label}
        rows={5}
        name="message"
        value={values.message}
        onChange={handleChange}
        error={errors.message}
      />
      <CheckboxWrapper>
        <PrivacyWrapper>
          <PrivacyTerms
            error={errors.privacy_checked}
            checked={values.privacy_checked}
            handleCheckbox={onHandleCheckPrivacy}
          />
        </PrivacyWrapper>
        <Checkbox
          value={values.checked}
          error={errors.checked}
          onChange={onHandleCheck}
          label={layout.terms}
          link={layout?.terms_link}
        />
        <LearnMoreTerms />
      </CheckboxWrapper>
      <ButtonWrapper>{renderButton()}</ButtonWrapper>
    </Container>
  )
}

export default Form
