import React, { FC, useEffect, useState } from 'react'
import { HomeSubscribeCole, FormValues, FormErrors, Role, NewsletterForm } from 'ducks/elCole'
import { FiGlobe, FiMail, FiMapPin, FiCheck } from 'react-icons/fi'
import * as yup from 'yup'
import { useFormik } from 'formik'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

import {
  Container,
  Title,
  Text,
  Description,
  RoleContainer,
  CheckboxContainer,
  ButtonContainer,
  InputsContainer,
  TermsContainer,
} from './styled'
import TextInput from 'views/components/TextInput'
import HtmlRender from 'views/components/HtmlRender'
import RadioButton from 'views/components/RadioButton'
import Checkbox from 'views/components/Checkbox'
import PrivacyTerms from 'views/components/PrivacyTerms'
import { ButtonIcon, ButtonWithIcon, SuccessFeedbackButton } from 'views/components/UI/Buttons'
import { ErrorMessage } from 'views/components/UI/Typography'
import LoadingButtonAnimation from 'views/components/LoadingButtonAnimation'
import LearnMoreTerms from 'views/components/LearnMoreTerms'

const initialValues: FormValues = {
  role: '',
  country: '',
  city: '',
  email: '',
  confirm_email: '',
  checked: false,
  privacy_checked: false,
}

const initialErrors: FormErrors = {}

const validationSchema = yup.object({
  role: yup.string().required('Role is required'),
  country: yup.string().required('Country is required'),
  city: yup.string().required('City is required'),
  email: yup.string().email('Email needs to be valid').required('Email is required'),
  confirm_email: yup.string().oneOf([yup.ref('email')], 'Email must match'),
  checked: yup.boolean().oneOf([true], 'Checked is required'),
  privacy_checked: yup.boolean().oneOf([true], 'Checked is required'),
})

type Props = {
  layout?: HomeSubscribeCole
  submit: (values: NewsletterForm) => void
  status?: string
}

const Form: FC<Props> = (props: Props) => {
  const { layout, submit, status } = props
  const { sendEvent, trackModal } = useGoogleAnalytics()
  const [submitedSuccess, setsubmitedSuccess] = useState(false)

  const onSubmit = (values: FormValues) => {
    const { email, city, country, role } = values
    submit({
      EMAIL: email,
      COUNTRY: country,
      CITY: city,
      ROLE: role,
    })
    sendEvent({
      label: 'El Cole',
      category: 'Attempt to Subscription',
      action: 'Tried to subscribe into El Cole Newsletter',
    })
  }

  const { values, errors, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues,
    initialErrors,
    onSubmit,
    validationSchema,
    validateOnChange: false,
  })

  const renderRadioOption = (role: Role) => {
    const onClick = () => {
      setFieldValue('role', role.value)
    }
    return (
      <RadioButton
        key={role.value}
        selected={values.role === role.value}
        label={role.value}
        error={errors.role}
        onClick={onClick}
      />
    )
  }

  const onHandleCheck = () => {
    setFieldValue('checked', !values.checked)
  }

  const onHandleCheckPrivacy = () => {
    setFieldValue('privacy_checked', !values.checked)
  }

  const renderButton = () => {
    switch (status) {
      case 'success':
        if (submitedSuccess) {
          return (
            <SuccessFeedbackButton>
              <FiCheck />
            </SuccessFeedbackButton>
          )
        } else {
          return (
            <ButtonWithIcon mobileFullWidth onClick={handleSubmit}>
              {layout?.button}
              <ButtonIcon />
            </ButtonWithIcon>
          )
        }
      case 'sending':
        return (
          <ButtonWithIcon mobileFullWidth>
            <LoadingButtonAnimation />
          </ButtonWithIcon>
        )
      default:
        return (
          <ButtonWithIcon mobileFullWidth onClick={handleSubmit}>
            {layout?.button}
            <ButtonIcon />
          </ButtonWithIcon>
        )
    }
  }

  useEffect(() => {
    if (status === 'success') {
      resetForm()
      setsubmitedSuccess((prevState) => {
        if (!prevState) {
          setTimeout(() => setsubmitedSuccess(false), process.env.REACT_APP_TIMEOUT_FEEDBACK || 10000)
        }
        return !prevState
      })
    }
  }, [status, resetForm])

  useEffect(() => {
    if (status === 'success') {
      trackModal('/success-cole-subscription')
      sendEvent({
        label: 'El Cole',
        category: 'Subscription',
        action: 'Subscribed into El Cole Newsletter',
      })
    }
  }, [status, sendEvent, trackModal])

  return (
    <Container>
      <Title>{layout?.title}</Title>
      <Description>
        <Text>
          <HtmlRender>{layout?.description}</HtmlRender>
        </Text>
      </Description>
      <Text>{layout?.role}</Text>
      <RoleContainer>{layout?.roles.map(renderRadioOption)}</RoleContainer>
      <InputsContainer>
        <TextInput
          name="country"
          value={values.country}
          error={errors.country}
          placeholder={layout?.country.label}
          onChange={handleChange}
          icon={FiGlobe}
          noMargin
        />
        <TextInput
          name="city"
          value={values.city}
          error={errors.city}
          placeholder={layout?.city.label}
          onChange={handleChange}
          icon={FiMapPin}
          noMargin
        />
        <TextInput
          name="email"
          value={values.email}
          error={errors.email}
          placeholder={layout?.email.label}
          onChange={handleChange}
          icon={FiMail}
          noMargin
        />
        <TextInput
          name="confirm_email"
          value={values.confirm_email}
          error={errors.confirm_email}
          placeholder={layout?.confirm_email.label}
          onChange={handleChange}
          icon={FiMail}
          noMargin
        />
      </InputsContainer>
      <TermsContainer>
        <PrivacyTerms
          error={errors.privacy_checked}
          checked={values.privacy_checked}
          handleCheckbox={onHandleCheckPrivacy}
        />
      </TermsContainer>
      <CheckboxContainer>
        <Checkbox
          onChange={onHandleCheck}
          value={values.checked}
          label={layout?.terms}
          error={errors.checked}
          link={layout?.terms_link}
        />
        <LearnMoreTerms />
      </CheckboxContainer>
      <ButtonContainer>
        {status === 'error' && <ErrorMessage>{layout?.subscribe_error}</ErrorMessage>}
        {renderButton()}
      </ButtonContainer>
    </Container>
  )
}

export default Form
