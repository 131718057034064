import React, { FC } from 'react'
import { Redirect, Route } from 'react-router-dom'

type Props = {
  children: JSX.Element
  path: string
  exact?: boolean
}

const PrivateRoute: FC<Props> = ({ children, path, exact }: Props) => {
  const token = localStorage.getItem('token')

  return token ? (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  ) : (
    <Redirect to="/login" />
  )
}

export default PrivateRoute
