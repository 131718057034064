import styled from 'styled-components'

export const Form = styled.div`
  width: 550px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 450px) {
    width: 90%;
  }
`

type ImageProps = {
  src: string | undefined
}

export const Image = styled.img<ImageProps>`
  width: 70%;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

export const ImageBottom = styled.img<ImageProps>`
  width: 90%;
`

export const CoverPageLeft = styled.img<ImageProps>`
  width: 40%;
  position: absolute;
  z-index: 1;
  top: 80px;
  left: 10%;
`

export const CoverPageRight = styled.img<ImageProps>`
  top: -22px;
  width: 40%;
  position: absolute;
  right: 18%;
`

export const Title = styled.h1`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 0;
  @media screen and (max-width: 700px) {
    font-size: 25px;
    line-height: 25px;
  }
  @media screen and (max-width: 500px) {
    font-size: 30px;
    line-height: 30px;
  }
`

export const Description = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.87);
  @media screen and (max-width: 700px) {
    font-size: 15px;
  }
  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
`

export const FirstSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: 'center';
`

export const Some = styled.div`
  left: 0;
  position: absolute;
  width: 40%;
  @media screen and (max-width: 500px) {
    font-size: 10px;
    position: relative;
    width: 100%;
  }
`

export const SecondSection = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  position: relative;
  @media screen and (min-width: 1240px) {
    height: 800px;
  }
  @media screen and (min-width: 1240px) {
    height: 700px;
  }
  @media screen and (min-width: 1040px) {
    height: 600px;
  }
  @media screen and (max-width: 650px) {
    height: 400px;
  }
  @media screen and (max-width: 500px) {
    height: 350px;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 100px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

export const CardWrapper = styled.div`
  align-items: center;
  display: flex;
  padding: 30px 0 30px 50px;
  width: 600px;
  position: absolute;
  z-index: 2;
  right: 100px;
  @media (max-width: 900px) {
    position: static;
    z-index: 0;
    right: 0;
    padding: 0;
  }
`

export const ThreeSection = styled.div`
  position: relative;
  height: 250px;
  @media (max-width: 900px) {
    display: flex;
    justify-content: center;
  }
`

export const FourSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-left: 10%;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
`

export const SentenceRight = styled.div`
  font-size: 30px;
  margin: 0 50px 50px 50px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.07px;
  color: rgba(0, 0, 0, 0.870588);
  @media screen and (max-width: 500px) {
    font-size: 20px;
    margin: 30px 15px;
  }
`

export const FiveSection = styled.div`
  position: relative;
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const TextWrapper = styled.div`
  z-index: 1;
  position: absolute;
  width: 300px;
  margin-right: 30px;
  @media screen and (max-width: 500px) {
    margin-right: 5px;
  }
`

export const BottomText = styled.h1`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.black};
  @media screen and (max-width: 500px) {
    font-size: 15px;
  }
`

export const MainContainer = styled.div`
  padding-bottom: 2rem;
`

export const PathContainerWrapper = styled.div`
  padding-left: 4%;
  position: absolute;
  z-index: 0;
`
