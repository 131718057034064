import { Image as ImageType, Founder } from 'ducks/aboutLayout'
import React, { FC, useContext, useState, useEffect } from 'react'
import { MdClose } from 'react-icons/md'

import {
  Container,
  ButtonWrapper,
  Image,
  Content,
  Title,
  Text,
  Gallery,
  Navigation,
  NavigationButton,
  ClickArea,
  EmailContainer,
  Email,
  ShowEmail,
  LinkWrapper,
} from './styled'
import { ThemeContext } from 'styled-components'
import HtmlRender from 'views/components/HtmlRender'
import CopyButton from 'views/components/CopyButton'
import { LinkButton, ButtonIcon } from 'views/components/UI/Buttons'

type Props = {
  founder: Founder
  onClickClose: (founder: Founder | null) => void
  showEmailMessage: string
  button?: string
  link?: string
}

const FounderCard: FC<Props> = (props: Props) => {
  const { founder, onClickClose, showEmailMessage, button, link } = props
  const { colors } = useContext(ThemeContext)
  const [currentImage, setCurrentImage] = useState('')
  const [haveGallery, setHaveGallery] = useState(false)
  const [haveNavigation, setHaveNavigation] = useState(false)
  const [showEmail, setShowEmail] = useState(false)

  const handleCloseClick = () => {
    onClickClose(null)
  }

  useEffect(() => {
    if (haveGallery && founder.gallery && founder?.gallery?.length > 0) {
      setCurrentImage(founder.gallery[0].url)
    }
  }, [founder, haveGallery])

  useEffect(() => {
    if (founder.gallery && founder?.gallery?.length > 0) {
      setHaveGallery(true)
    } else {
      setHaveGallery(false)
    }
  }, [founder])

  useEffect(() => {
    if (founder.gallery && founder.gallery.length > 1) {
      setHaveNavigation(true)
    } else {
      setHaveNavigation(false)
    }
  }, [founder])

  const displayEmail = () => {
    setShowEmail(true)
  }

  return (
    <Container background={founder.background_color ? founder?.background_color : colors.founderDefaultBackground}>
      <ButtonWrapper onClick={handleCloseClick}>
        <MdClose />
      </ButtonWrapper>
      {haveGallery && (
        <Gallery>
          <Image background={`url(${currentImage})`} />
          {haveNavigation && (
            <Navigation>
              {founder?.gallery?.map((image: ImageType) => (
                <ClickArea key={image.url} onClick={() => setCurrentImage(image.url)}>
                  <NavigationButton active={image.url === currentImage} />
                </ClickArea>
              ))}
            </Navigation>
          )}
        </Gallery>
      )}
      <Content>
        <Title>{founder.title}</Title>
        <Text>
          <HtmlRender>{founder.description}</HtmlRender>
        </Text>
        {founder?.email &&
          (showEmail ? (
            <EmailContainer>
              <Email>{founder?.email}</Email>
              <CopyButton text={founder?.email || ''} withFeedback />
            </EmailContainer>
          ) : (
            <ShowEmail onClick={displayEmail}>
              <Email>{showEmailMessage}</Email>
            </ShowEmail>
          ))}
        <LinkWrapper>
          <LinkButton to={link || ''} mobileFullWidth>
            {button}
            <ButtonIcon />
          </LinkButton>
        </LinkWrapper>
      </Content>
    </Container>
  )
}

export default FounderCard
