import styled from 'styled-components'

export const Container = styled.div`
  width: 40%;
  border-right: 1px solid black;
  padding: 40px 100px 40px 40px;
  @media screen and (min-width: 1600px) {
    width: 40%;
    padding: 80px 160px 80px 80px;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
    padding: 40px 80px 20px 20px;
  }
  @media screen and (max-width: 768px) {
    padding: 20px 40px 20px 0px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    border-right: none;
    padding: 0;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid black;
  }
`
