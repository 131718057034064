import axios from 'axios'
import { Protagonistas } from 'ducks/protagonistas'
import { filterNullData } from './parser'
import { TranslatedRequest } from 'lib/axios/models'

interface ProtagonistasRequest extends TranslatedRequest {
  countries: Array<number>
  categories: Array<number>
  language: string
  start: number
}

const FIRST_REQUEST_AMOUNT = process.env.REACT_APP_OBJECTS_ON_FIRST_REQUEST || 20
const REQUEST_AMOUNT = process.env.REACT_APP_OBJECTS_PER_REQUEST || 10

const protagonistas = async ({
  countries,
  categories,
  language,
  start,
}: ProtagonistasRequest): Promise<Protagonistas | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
        query {
          protagonistasCount(where: {
            country: [${countries.join(', ')}],
            category: [${categories.join(', ')}]
          })
          protagonistas(start: ${start}, limit: ${
        start === 0 ? FIRST_REQUEST_AMOUNT : REQUEST_AMOUNT
      }, sort: "id:DESC", where: {
            country: {
              id: [${countries.join(', ')}]
            },
            category: {
              id: [${categories.join(', ')}]
            }
          }) {
            id
            nickname
            username
            name
            description: description_${language}
            card_description: card_description_${language}
            image {
              url
            }
            country {
              shortname
              image {
                url
              }
            }
            category {
              name: name_${language}
              overlay
              Badge {
                text
                background
              }
            }
            group {
              name
            }
          }
        }
      `,
    })
    return filterNullData(data)
  } catch (error) {
    return
  }
}

export default protagonistas
