import axios from 'axios'
import { Loading } from 'ducks/loading'

const getLoading = async (): Promise<Loading | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
       query {
         loading {
           image {
             url
           }
           button_animation {
             url
           }
         }
       }
      `,
    })
    return data.loading
  } catch (error) {
    return
  }
}

export default getLoading
