import axios from 'axios'
import { TranslatedRequest } from 'lib/axios/models'
import { parseProtagonistaData } from 'services/protagonistas/getProtagonista/parser'
import { ProfileType, FileFromStrapi } from 'ducks/protagonistas'
import { privateApi } from 'lib/axios'
import _ from 'lodash'

export const requestCreatedProfile = async (userId: number): Promise<boolean> => {
  try {
    const {
      data: { data },
    } = await privateApi.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        newProfiles(where: {
           user: ${userId}
        }) {
          nickname
        }
      }
      `,
    })
    if (data?.newProfiles?.length > 0) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

export const requestUpdateProfile = async (userId: number): Promise<boolean> => {
  try {
    const {
      data: { data },
    } = await privateApi.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        updateProfiles(where: {
           user: ${userId}
        }) {
          nickname
        }
      }
      `,
    })
    if (data?.updateProfiles?.length > 0) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

interface ProtagonistaRequest extends TranslatedRequest {
  userId: number
}

export const getProfile = async ({ language, userId }: ProtagonistaRequest): Promise<unknown | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        protagonistas(where:{user: "${userId}"}) {
          id
          nickname
          name
          username
          description: description_${language}
          history: history_${language}
          image {
            url
            id
          }
          cover_image {
            url
            id
          }
          gallery {
            video_url
            label
            image {
              url
              id
            }
          }
          country {
            shortname
            name: name_${language}
            image {
              url
            }
          }
          category {
            name: name_${language}
            overlay
            Badge {
              text
              background
            }
          }
          group {
            id
            name
            codename
          }
          socials {
            email
            instagram
            website
          }
        }
      }
      `,
    })
    return parseProtagonistaData(data.protagonistas)
  } catch (error) {
    return
  }
}

const uploadImageFile = async (files: Array<Blob>): Promise<Array<FileFromStrapi>> => {
  try {
    const formData = new FormData()
    files.map((file: Blob) => formData.append('files', file))
    const { data } = await privateApi.post(`${process.env.REACT_APP_MAIN_API}/upload`, formData)
    return data
  } catch (error) {
    return []
  }
}

export const submitUpdateProfile = async (profile: ProfileType): Promise<boolean> => {
  try {
    const model = [
      'category',
      'country',
      'nickname',
      'first_name',
      'last_name',
      'about_me',
      'instagram',
      'facebook',
      'twitter',
      'linkedin',
      'email',
      'history',
      'user',
      'picture',
      'gallery',
    ]
    const formData = new FormData()
    const formatedProfile = _.pick(profile, model)

    // Handling new Images
    const picture = profile.picture as FileFromStrapi
    const gallery = profile.gallery as Array<FileFromStrapi>
    // Checking if the Picture is already a File from strapi
    if (!picture.id) {
      const pictureFromStrapi = await uploadImageFile([profile.picture])
      if (pictureFromStrapi.length > 0) {
        formatedProfile.picture = pictureFromStrapi[0]
      }
    }
    // Checking if exists a new gallery file to upload
    const filesToUpload = _.filter(gallery, (galleryItem) => !galleryItem.id)
    if (filesToUpload.length > 0) {
      const missingGalleryItemsFromStrapi = await uploadImageFile(filesToUpload)
      const filesUploaded = _.filter(gallery, (galleryItem) => galleryItem.id) as Array<FileFromStrapi>
      formatedProfile.gallery = [...filesUploaded, ...missingGalleryItemsFromStrapi]
    }

    formData.append('files.picture', profile.picture as Blob)
    formData.append('data', JSON.stringify(formatedProfile))
    _.map(profile.gallery, (value) => {
      formData.append('files.gallery', value as Blob)
    })

    const response = await privateApi.post('/update-profiles', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    if (response.status === 200) {
      return true
    }

    return false
  } catch (error) {
    return false
  }
}

export const getImageFile = async (imageId?: string): Promise<unknown | undefined> => {
  try {
    if (imageId) {
      const { data } = await privateApi.get(`${process.env.REACT_APP_MAIN_API}/upload/files/${imageId}`)
      return data
    }
    return
  } catch (error) {
    return
  }
}
