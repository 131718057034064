import styled from 'styled-components'

type ContainerProps = {
  error?: string
  noMargin?: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.error : theme.colors.inputBorder)};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  color: #757575;
  height: 32px;
  @media screen and (min-width: 1600px) {
    height: 40px;
  }
`

export const Selector = styled.select`
  flex: 1;
  border: none;
  color: ${({ theme }) => theme.colors.default};
  font-size: 14px;
  height: 100%;
  background-color: transparent;
`

export const IconWrapper = styled.div`
  font-size: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Main = styled.div<ContainerProps>`
  margin: ${({ noMargin }) => (noMargin ? '0' : '14px 0')};
`
