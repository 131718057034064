import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { IPostular } from './../models'
import * as TYPES from './../actionTypes'

export const initialState: IPostular = fromJS({
  loading: false,
  home: {
    intro_section: {},
    invite_section: {},
    end_section: {},
  },
  invite_prota_form: {
    title: '',
    subtitle: '',
    image: {
      url: '',
    },
    protagonista_section: {},
    about_you_section: {},
  },
  postular_invite: {
    cards: [{ message: '', color: '' }],
    title: '',
    description: '',
    inviteSection: {},
    copy_button: '',
    whatsapp_button: '',
    copied_button: '',
    endSection: {},
  },
  invite_success: false,
})

const postularReducer: Reducer<IPostular> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET:
      return state.merge(action.payload)
    case TYPES.CONTENT.TRIGGER:
      return state.set('loading', true)
    case TYPES.CONTENT.FULFILL:
      return state.set('loading', false)
    case TYPES.CONTENT.FAILURE:
      return state.set('error', action.payload)
    case TYPES.CONTENT.SUCCESS:
      return state.merge(action.payload)
    default:
      return state
  }
}

export default postularReducer
