import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  @media screen and (max-width: 450px) {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    margin-right: 0;
  }
`

export const Label = styled.p`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.checkbox};
`
