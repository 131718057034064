import styled from 'styled-components'

export const Container = styled.div`
  padding: 1rem 4rem 2rem;
  @media screen and (min-width: 1600px) {
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 1024px) {
    padding: 1rem 2rem 2rem;
  }
  @media screen and (max-width: 450px) {
    padding: 1rem 0;
  }
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.07px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 48px;
    line-height: 49px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 32px;
    line-height: 32px;
  }
  @media screen and (max-width: 450px) {
    font-size: 26px;
    line-height: 26px;
  }
`

export const Subtitle = styled.p`
  font-weight: bold;
  font-size: 28px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 34px;
    line-height: 40px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 21px;
  }
`

export const Text = styled.div`
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 34px;
    line-height: 37px;
  }
`

export const Content = styled.div`
  width: 60%;
  z-index: 1;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 40px;
  }
`
