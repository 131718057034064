import React, { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CONTENT, voluntariadoLayoutSelector, FormValues, FormErrorValues } from 'ducks/voluntariadoLayout'
import { CONTENT as VOLUNTEERS_CONTENT, POST, volunteersSelector, SET } from 'ducks/volunteers'
import { useLanguage } from 'hooks/useLanguage'
import { useFormik } from 'formik'
import * as yup from 'yup'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

import PathIndicator from 'views/components/PathIndicator'
import HtmlRender from 'views/components/HtmlRender'
import { Card } from 'views/components/UI/Contents'
import { Container, Section, SectionColumn, CardBody, Text, Subtitle, CardContainer } from './styled'
import IntroSection from './IntroSection'
import MiddleSection from './MiddleSection'
import VoluntariadoSection from './VoluntariadoSection'
import WorksSection from './WorksSection'
import SubscribeSection from './SubscribeSection'
import InfoSection from './InfoSection'

const initialValues: FormValues = {
  name: '',
  email: '',
  confirm_email: '',
  phone: '',
  country: '',
  message: '',
  checked: false,
  privacy_checked: false,
  volunteer_type: [],
}

const initialErrors: FormErrorValues = {}

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup.string().email().required('Email is required.'),
  confirm_email: yup.string().oneOf([yup.ref('email')], 'Email must match'),
  phone: yup.string(),
  country: yup.string().required('Country is Required'),
  message: yup.string(),
  checked: yup.boolean().oneOf([true], 'Checked is required'),
  privacy_checked: yup.boolean().oneOf([true], 'Checked is required'),
  volunteer_type: yup.array(yup.string()).min(1).required('Volunteer type is required'),
})

const Voluntariado: FC = () => {
  const dispatch = useDispatch()
  const voluntariadoLayout = useSelector(voluntariadoLayoutSelector)
  const volunteersState = useSelector(volunteersSelector)
  const { currentLanguage } = useLanguage()
  const { sendEvent, trackModal } = useGoogleAnalytics()
  const success = volunteersState.get('create_success')

  const onSubmit = (values: FormValues) => {
    const { name, email, phone, country, volunteer_type, message } = values
    sendEvent({
      label: 'Voluntariado',
      category: 'Attempt to Submit Form',
      action: 'Tried to submit a new volunteer',
    })
    const newVolunteer = {
      name,
      email,
      phone,
      country,
      volunteer_type: volunteer_type.join(', '),
      other_contribution: message,
    }
    dispatch(POST.trigger(newVolunteer))
  }

  const { errors, values, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik<FormValues>({
    initialValues,
    onSubmit,
    initialErrors,
    validationSchema,
  })

  useEffect(() => {
    dispatch(CONTENT.trigger({ language: currentLanguage }))
    dispatch(VOLUNTEERS_CONTENT.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage])

  useEffect(() => {
    if (success) {
      resetForm()
      setTimeout(
        () => dispatch({ type: SET, payload: { create_success: false } }),
        process.env.REACT_APP_TIMEOUT_FEEDBACK || 10000
      )
    }
  }, [resetForm, success, dispatch])

  useEffect(() => {
    if (success) {
      sendEvent({
        label: 'Voluntariado',
        category: 'Success Submit Form',
        action: 'Submitted a new volunteer',
      })
      trackModal('/sucess-volunteer')
    }
  }, [success, sendEvent, trackModal])

  return (
    <Container>
      <PathIndicator
        description={voluntariadoLayout.getIn(['path', 'description'])}
        sections={voluntariadoLayout.getIn(['path', 'sections'])}
      />
      <IntroSection layout={voluntariadoLayout.get('intro_section')} />
      <MiddleSection layout={voluntariadoLayout.get('middle_section')} />
      <VoluntariadoSection types={volunteersState.get('types')} volunteers={volunteersState.get('volunteers')} />
      <Section>
        <SectionColumn>
          <WorksSection layout={voluntariadoLayout.get('work_section')} />
          {voluntariadoLayout.get('info_section') && <InfoSection layout={voluntariadoLayout.get('info_section')} />}
          <CardContainer>
            <Card
              background={`url(${voluntariadoLayout.getIn(['subscribe_card', 'background', 'url'])})`}
              color={voluntariadoLayout.getIn(['subscribe_card', 'color'])}
            >
              <CardBody>
                <Subtitle>{voluntariadoLayout.getIn(['subscribe_card', 'title'])}</Subtitle>
                <Text>
                  <HtmlRender>{voluntariadoLayout.getIn(['subscribe_card', 'description'])}</HtmlRender>
                </Text>
              </CardBody>
            </Card>
          </CardContainer>
        </SectionColumn>
        <SectionColumn>
          <SubscribeSection
            layout={voluntariadoLayout.get('subscribe_section')}
            errors={errors}
            values={values}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
            types={volunteersState.get('types')}
            loading={volunteersState.get('create_loading')}
            success={success}
          />
        </SectionColumn>
      </Section>
    </Container>
  )
}

export default Voluntariado
