import axios from 'axios'
import { LoginLayoutResponse } from 'ducks/loginLayout/models'
import { TranslatedRequest } from 'lib/axios/models'

const getLoginLayout = async ({ language }: TranslatedRequest): Promise<LoginLayoutResponse | undefined> => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        loginLayout {
          feedback {
            title: title_${language}
            description: description_${language}
          }
          login_section {
            title: title_${language}
            subtitle: subtitle_${language}
            email: email_${language}
            password: password_${language}
            forgot_password: forgot_password_${language}
            button: button_${language}
          }
          create_account_section {
            title: title_${language}
            subtitle: subtitle_${language}
            buttons {
              label: label_${language}
              value
              text_color
              background
              icon_background
            }
          }
        }
      }
      `,
    })
    return data
  } catch (error) {
    return
  }
}

export default getLoginLayout
