import axios from 'axios'
import { RegisterLayoutBruteState } from 'ducks/registerLayout'
import { parserRegisterLayout } from './parser'
import { TranslatedRequest } from 'lib/axios/models'

const getRegisterLayout = async ({ language }: TranslatedRequest): Promise<RegisterLayoutBruteState | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        registerLayout {
          title: title_${language}
          name {
            label: label_${language}
          }
          email {
            label: label_${language}
          }
          password {
            label: label_${language}
          }
          link {
            value
          }
          link_label: link_label_${language}
          link_description: link_description_${language}
          button: button_${language}
        }
      }
      `,
    })
    return parserRegisterLayout(data.registerLayout)
  } catch (error) {
    return
  }
}

export default getRegisterLayout
