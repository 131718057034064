import React, { FC } from 'react'
import { Container, Card, CardHeader, CardTitle, ContentContainer, SectionLine } from './styled'
import { useSelector } from 'react-redux'
import { protagonistasSelector } from 'ducks/protagonistas/selectors'
import { protagonistasLayoutSelector } from 'ducks/protagonistasLayout/selectors'
import OtherProtagonistas from 'views/components/OtherProtagonistas'

type Props = {
  title: string
}

const Recommendations: FC<Props> = ({ title }: Props) => {
  const protagonistas = useSelector(protagonistasSelector)
  const protagonistasLayout = useSelector(protagonistasLayoutSelector)

  return (
    <Container>
      <Card>
        <CardHeader>
          <CardTitle>{title}</CardTitle>
          <SectionLine />
        </CardHeader>
        <ContentContainer>
          <OtherProtagonistas
            title={protagonistasLayout.getIn(['protagonistas', 'others'])}
            protagonistas={protagonistas.get('protagonistas')}
            loading={protagonistas.get('loading')}
            button={protagonistasLayout.getIn(['protagonistas', 'load_more_button'])}
            message={protagonistasLayout.getIn(['protagonistas', 'load_more'])}
          />
        </ContentContainer>
      </Card>
    </Container>
  )
}

export default Recommendations
