import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 0 4rem;
  margin-top: 3.5rem;
  @media screen and (max-width: 1024px) {
    padding: 0 2rem;
    margin-top: 2.5rem;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
  @media screen and (max-width: 450px) {
    padding: 0 1rem;
  }
`

export const Subtitle = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.07px;
  margin: 5px 0;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 24px;
    line-height: 24px;
  }
`

export const RolesSection = styled.div`
  display: flex;
  height: 200px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 2rem 0 3rem;
  @media screen and (min-width: 1600px) {
    height: 250px;
    margin-top: 50px;
  }
  @media screen and (max-width: 1024px) {
    height: 280px;
  }
  @media screen and (max-width: 768px) {
    height: 220px;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
    height: unset;
    align-items: center;
  }
`

export const Column = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`

export const RoleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-height: 65px;
  @media screen and (max-width: 450px) {
    margin: 15px 0;
  }
`

export const RoleTitleBox = styled.div`
  display: flex;
  width: 35%;
  min-width: 65px;
  justify-content: flex-end;
  font-weight: 800;
  font-size: 18px;
  line-height: 18px;
  text-align: right;
  @media screen and (min-width: 1600px) {
    font-size: 22px;
    line-height: 22px;
  }
  @media screen and (max-width: 450px) {
    font-size: 22px;
    line-height: 22px;
    min-width: 70px;
  }
`

export const RoleDescription = styled.div`
  width: 75%;
  line-height: 14px;
  font-size: 12px;
  letter-spacing: 0.07px;
  padding-left: 10px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    line-height: 18px;
    font-size: 16px;
  }
  @media screen and (max-width: 450px) {
    line-height: 18px;
    font-size: 16px;
  }
`
