import axios from 'axios'
import { VolunteersResponse } from 'ducks/volunteers'
import { TranslatedRequest } from 'lib/axios/models'

export const getVolunteers = async ({ language }: TranslatedRequest): Promise<VolunteersResponse | undefined> => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        types: volunteerTypes {
          __typename
          id
          name: name_${language}
          display_name: display_name_${language}
          label: label_${language}
          color
        }
        volunteers: volunteerPeople(where: {
          active: true
        }) {
          __typename
          id
          name
          label: label_${language}
          image {
            url
          }
          types {
            id
            name: name_${language}
            color
          }
        }
      }
      `,
    })
    return data
  } catch (error) {
    return
  }
}

type NewVolunteer = {
  name: string
  email: string
  phone?: string
  country: string
  volunteer_type: string
  other_contribution: string
}

export const submitVolunteer = async (newVolunteer: NewVolunteer): Promise<boolean | undefined> => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_MAIN_API}/new-volunteers/submit`, newVolunteer)
    if (data) {
      return true
    }
    return false
  } catch (error) {
    return
  }
}
