import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`

export const Label = styled.p`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.default};
`

export const LinkLabel = styled(Link)`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.formLink};
`
