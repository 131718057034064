import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: column;
  padding: 0 1rem;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const FullRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 40px;
  margin: 24px 0;
  @media (max-width: 600px) {
    padding-left: 0;
    justify-content: center;
  }
`

export const LoadMoreText = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  width: 50%;
  font-size: 16px;
  letter-spacing: 0.3px;
  margin: 0;
  margin-bottom: 16px;
  text-align: start;
  line-height: 1.5;
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`
