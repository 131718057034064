import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 3rem 0;
  justify-content: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 3rem 0 0 0;
  }
`

export const LeftContainer = styled.div`
  width: 100%;
`

export const Banner = styled.div<BackgroundProps>`
  ${background}
  width: 100%;
  height: 300px;
  @media screen and (max-width: 1250px) {
    height: 200px;
  }
`

export const LeftContent = styled.article`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
`

export const LeftDescription = styled.div`
  margin: 0;
  color: ${({ theme }) => theme.colors.dark};
  font-size: 12px;
  padding: 12px;
  letter-spacing: 0.04px;
`

export const LeftSubTitle = styled.h3`
  margin: 0;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
`

export const LeftTitle = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  font-size: 24px;
`
export const RightContainer = styled.div`
  width: 100%;
  padding: 24px 12px;
  display: flex;
  align-items: flex-start;
`

export const LinkImage = styled(Link)`
  width: 50%;
`

export const RightImage = styled.div<BackgroundProps>`
  ${background};
  width: 50%;
  min-height: 160px;
  max-height: 320px;
  min-width: 150px;
  max-width: 300px;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  @media screen and (max-width: 1250px) {
    max-height: 160px;
  }
`
export const RightContent = styled.article`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-top: 1.5rem;
`
export const RightTitle = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 1.7rem;
  letter-spacing: 0.05px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 470px) {
    line-height: 18px;
    font-size: 0.9rem;
  }
`

export const RightDescription = styled.div`
  margin: 0;
  font-weight: 600;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 470px) {
    font-size: 0.6rem;
  }
`
