import React, { FC } from 'react'
import { CustomIcon, Title } from './styled'
import WithSkeletonLoading from 'views/components/WithSkeletonLoading'

type Props = {
  title: string
  icon?: string
  loading?: boolean
}

const TitleWithIcon: FC<Props> = ({ title, icon }: Props) => (
  <>
    <CustomIcon>{icon}</CustomIcon>
    <Title>{title}</Title>
  </>
)

export default WithSkeletonLoading<Props>(TitleWithIcon, { height: '30px' })
