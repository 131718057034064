import React, { FC, useEffect, useState } from 'react'
import { List, Record } from 'immutable'
import { Language } from 'ducks/layoutData'
import { LOGOUT, userSelector } from 'ducks/user'
import { useHistory } from 'react-router-dom'
import { useLanguage } from 'hooks/useLanguage'
import { useSelector, useDispatch } from 'react-redux'

import {
  Container,
  Logo,
  Section,
  SectionContainer,
  Title,
  SectionElement,
  LanguageSelect,
  ButtonWithIcon,
  LinkButtonWithIcon,
  StoreButton,
} from './styled'
import { Block } from '../UI/Contents'
import StarIcon from '@material-ui/icons/Star'
import Menu from 'views/components/Menu'
import SideMenu from '../SideMenu'
interface Props {
  logo: string
  title: string
  subtitle: string
  login_button: string
  select_language: string
  sections: List<
    Record<{
      text: string
      link: string
    }>
  >
  languages: List<Record<Language>>
  store: Record<{
    text: string
    link: string
  }>
  header_title: Record<{
    text: string
    link: string
  }>
}

const Header: FC<Props> = ({
  logo,
  title,
  subtitle,
  sections,
  languages,
  store,
  header_title,
  login_button,
  select_language,
}) => {
  const history = useHistory()
  const { currentLanguage, setCurrentLanguage } = useLanguage()
  const [activeLocation, setActiveLocation] = useState(history.location.pathname)
  const userState = useSelector(userSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    return history.listen((location) => {
      setActiveLocation(location.pathname)
    })
  }, [history])

  const onClickLogo = () => {
    history.push('/')
  }

  const onClickStore = () => {
    history.push(store.get('link'))
  }

  const onClickLink = (link: string) => {
    history.push(link)
  }

  const onLogout = () => {
    dispatch(LOGOUT.trigger())
    history.push('/')
  }

  return (
    <Container>
      <Block display="flex" width="65%" flexDirection="row" alignItems="center">
        <Block display="flex" onClick={onClickLogo} hover>
          <Logo src={logo} />
          <Title>
            <span>{title}</span>
            <span>{subtitle}</span>
          </Title>
        </Block>
        <SectionContainer>
          {sections?.slice(0, 3)?.map((section) => (
            <SectionElement
              key={section.get('link')}
              className={activeLocation === section.get('link') ? 'active' : ''}
            >
              <Section
                to={section.get('link')}
                color={activeLocation === section.get('link') ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.54)'}
              >
                {section.get('text')}
              </Section>
            </SectionElement>
          ))}
        </SectionContainer>
        {header_title?.getIn(['link', 'external']) ? (
          <LinkButtonWithIcon href={header_title?.getIn(['link', 'value'])} target="blank">
            <StarIcon className="icon" />
            {header_title?.get('text')}
          </LinkButtonWithIcon>
        ) : (
          <ButtonWithIcon to={header_title?.getIn(['link', 'value'])}>
            <StarIcon className="icon" />
            {header_title?.get('text')}
          </ButtonWithIcon>
        )}
      </Block>
      <Block display="flex" width="20%" flexDirection="row" alignItems="center" justifyContent="center">
        <StoreButton onClick={onClickStore}>
          <StarIcon className="icon" />
          {store?.get('text')}
        </StoreButton>
        <LanguageSelect value={currentLanguage} onChange={(e) => setCurrentLanguage(e.target.value)}>
          {languages?.map((language: Record<Language>) => (
            <option key={language.get('value')} value={language.get('value')}>
              {language.get('label')}
            </option>
          ))}
        </LanguageSelect>
      </Block>
      {userState.get('token') ? <Menu /> : <Section to="/login">{login_button}</Section>}
      <SideMenu
        login_button={login_button}
        languages={languages}
        sections={sections}
        onClickLink={onClickLink}
        onLogout={onLogout}
        activeLocation={activeLocation}
        select_language={select_language}
      />
    </Container>
  )
}

export default Header
