import { EndSection } from 'ducks/aboutLayout'
import React, { FC } from 'react'
import { Container, Card, Title, ButtonWrapper } from './styled'
import { Text } from 'views/screens/AboutUs/styled'
import HtmlRender from 'views/components/HtmlRender'
import { ButtonIcon, LinkButton } from 'views/components/UI/Buttons'

type Props = {
  layout: EndSection
}

const FinalSection: FC<Props> = (props: Props) => {
  const { layout } = props
  return (
    <Container>
      <Card>
        <Title>{layout?.title}</Title>
        <Text>
          <HtmlRender>{layout?.description}</HtmlRender>
        </Text>
        {layout?.link && (
          <ButtonWrapper>
            <LinkButton to={layout?.link} padding="8px">
              {layout?.button}
              <ButtonIcon />
            </LinkButton>
          </ButtonWrapper>
        )}
      </Card>
    </Container>
  )
}

export default FinalSection
