import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CONTENT, colaboraLayoutSelector } from 'ducks/colaboraLayout'
import { useLanguage } from 'hooks/useLanguage'

import { Container } from './styled'
import PathIndicator from 'views/components/PathIndicator'
import IntroSection from './IntroSection'
import WhereWeAreSection from './WhereWeAreSection'
import CollaborateSection from './CollaborateSection'
import ExampleSection from './ExampleSection'
import CompaniesSection from './CompaniesSection'

const Colabora: FC = () => {
  const dispatch = useDispatch()
  const { currentLanguage } = useLanguage()

  const colaboraLayoutState = useSelector(colaboraLayoutSelector)

  useEffect(() => {
    dispatch(CONTENT.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage])

  return (
    <Container>
      <PathIndicator
        description={colaboraLayoutState.getIn(['path', 'description'])}
        sections={colaboraLayoutState.getIn(['path', 'sections'])}
      />
      <IntroSection layout={colaboraLayoutState.get('intro_section')} />
      <WhereWeAreSection layout={colaboraLayoutState.get('where_we_are_section')} />
      <CollaborateSection layout={colaboraLayoutState.get('collaborate_section')} />
      <ExampleSection layout={colaboraLayoutState.get('example_section')} />
      <CompaniesSection layout={colaboraLayoutState.get('companies_section')} />
    </Container>
  )
}

export default Colabora
