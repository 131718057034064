import { InviteProtaSection, FormValues, FormErrors } from 'ducks/postular'
import React, { FC, ChangeEvent } from 'react'
import { FiFacebook, FiTwitter, FiInstagram, FiMail } from 'react-icons/fi'

import { Container, Card, CardTitle, CardSubtitle, Content, TextWrapper } from './styled'
import { Title, Text } from 'views/screens/PostularInviteProtaForm/styled'
import TextArea from 'views/components/UI/TextArea'
import TextInput from 'views/components/TextInput'
import { ErrorMessage } from 'views/components/UI/Typography'

type Props = {
  layout: InviteProtaSection
  cardTitle: string
  cardSubtitle: string
  cardColor?: string
  values: FormValues
  errors: FormErrors
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const AboutProtagonistaSection: FC<Props> = (props: Props) => {
  const { layout, cardTitle, cardSubtitle, cardColor, values, errors, onChange } = props

  const renderErrorMessage = () => {
    if (errors.facebook || errors.prota_email || errors.instagram || errors.twitter) {
      return <ErrorMessage>{layout?.social_error}</ErrorMessage>
    }
  }
  return (
    <Container>
      <Card color={cardColor}>
        <CardTitle>{cardTitle}</CardTitle>
        <CardSubtitle>{cardSubtitle}</CardSubtitle>
      </Card>
      <Content>
        <Title>{layout?.title}</Title>
        <TextArea
          name="about_protagonista"
          value={values.about_protagonista}
          error={errors.about_protagonista}
          placeholder={layout?.message?.label}
          onChange={onChange}
          rows={5}
        />
        <TextWrapper>
          <Text>{layout?.social_title}</Text>
        </TextWrapper>
        <TextInput
          name="facebook"
          value={values.facebook}
          error={errors.facebook}
          onChange={onChange}
          placeholder={layout?.facebook?.label}
          icon={FiFacebook}
        />
        <TextInput
          name="instagram"
          value={values.instagram}
          error={errors.instagram}
          onChange={onChange}
          placeholder={layout?.instagram?.label}
          icon={FiInstagram}
        />
        <TextInput
          name="twitter"
          value={values.twitter}
          error={errors.twitter}
          onChange={onChange}
          placeholder={layout?.twitter?.label}
          icon={FiTwitter}
        />
        <TextInput
          name="prota_email"
          value={values.prota_email}
          error={errors.prota_email}
          onChange={onChange}
          placeholder={layout?.email?.label}
          icon={FiMail}
        />
        {renderErrorMessage()}
      </Content>
    </Container>
  )
}

export default AboutProtagonistaSection
