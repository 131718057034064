import createImmutableSelector from 'lib/immutable/selector'
import { IProtagonistaLayout } from './../models'

// TODO: import Store type and add reducer type in Store interface
const getProtagonistaLayout = (state: any): IProtagonistaLayout => state.get('protagonistaLayoutReducer')

export const protagonistaLayoutSelector = createImmutableSelector(
  getProtagonistaLayout,
  (protagonistaLayout) => protagonistaLayout
)
