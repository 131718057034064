import { HomeSubscribeCole } from 'ducks/elCole'
import React, { FC } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

import { Container, Image, FormContainer } from './styled'
import { FloatingCard } from 'views/components/UI/Contents'
import Form from './Form'

type Props = {
  layout?: HomeSubscribeCole
}

const SubscribeSection: FC<Props> = (props: Props) => {
  const { layout } = props

  return (
    <Container>
      <Image src={layout?.image?.url} />
      <FormContainer>
        <FloatingCard>
          <MailchimpSubscribe
            url={process.env.REACT_APP_MAILCHIMP_EL_COLE}
            render={({ subscribe, status }: { subscribe: any; status: string }) => (
              <Form layout={layout} submit={subscribe} status={status} />
            )}
          />
        </FloatingCard>
      </FormContainer>
    </Container>
  )
}

export default SubscribeSection
