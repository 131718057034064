import { HomeInvite, HomePostular, PostularCard } from 'ducks/postular'
import React, { FC, useState, useEffect } from 'react'
import { FiLink } from 'react-icons/fi'
import { FaWhatsapp } from 'react-icons/fa'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'
import CopyToClipboard from 'react-copy-to-clipboard'

import {
  Button,
  Container,
  Content,
  CardBody,
  CardTitle,
  CardDescription,
  InviteButtonWrapper,
  InviteCardsContainer,
  InviteContainer,
  PostularContainer,
  TextWrapper,
  PostularButtonWrapper,
  SocialText,
  Subtitle,
  Title,
} from './styled'
import HtmlRender from 'views/components/HtmlRender'
import { ButtonIcon, LinkButton } from 'views/components/UI/Buttons'
import { Card, Row } from 'views/components/UI/Contents'
import InviteCard from 'views/components/InviteCard'

type Props = {
  inviteLayout: HomeInvite
  postularLayout: HomePostular
}

const InviteSection: FC<Props> = (props: Props) => {
  const { inviteLayout, postularLayout } = props
  const { sendEvent, trackModal } = useGoogleAnalytics()
  const [isCopied, setIsCopied] = useState(false)
  const [selectText, setSelectText] = useState('')
  const [whatsappShareUrl, setWhatsappShareUrl] = useState('')

  const url = `${origin}/postular`

  useEffect(() => {
    if (selectText) {
      const encodedText = encodeURI(selectText + '\n' + url)
      setWhatsappShareUrl(encodedText)
    } else {
      setWhatsappShareUrl(url)
    }
  }, [url, selectText])

  const renderTextToCopy = () => {
    if (selectText && selectText !== '') {
      return selectText + '\n' + url
    } else {
      return url
    }
  }

  const inviteViaWhatsappEvent = () => {
    trackModal('/invite-success/whatsapp')
    sendEvent({
      label: 'Via Whatsapp',
      category: 'Invitation',
      action: 'Invited a protagonista using whatsapp invitation.',
    })
  }

  const copyInvitationEvent = () => {
    trackModal('/invite-success/copy-link')
    sendEvent({
      label: 'Copy Link',
      category: 'Invitation',
      action: 'Invited a protagonista copying the invitation link.',
    })
  }

  return (
    <Container>
      <Row flexWrap="wrap">
        <Title>{inviteLayout.title}</Title>
        <Subtitle>{inviteLayout.subtitle}</Subtitle>
      </Row>
      <Content>
        <InviteContainer>
          <Card color={inviteLayout?.card_color}>
            <CardBody>
              <CardTitle>{inviteLayout?.card_title}</CardTitle>
              <CardDescription>
                <HtmlRender>{inviteLayout?.card_description}</HtmlRender>
              </CardDescription>
              <InviteCardsContainer>
                {inviteLayout?.invite_messages?.map((inviteMessage: PostularCard) => (
                  <InviteCard
                    key={inviteMessage.color + inviteMessage.message}
                    layout={inviteMessage}
                    onSelect={setSelectText}
                    selected={selectText === inviteMessage.message}
                  />
                ))}
              </InviteCardsContainer>
            </CardBody>
            <InviteButtonWrapper>
              <div onClick={inviteViaWhatsappEvent} style={{ marginBottom: '10px' }}>
                <a target="blank" href={`https://api.whatsapp.com/send?text=${whatsappShareUrl}`}>
                  <Button style={{ color: 'rgba(255, 255, 255, 0.87)', backgroundColor: '#9ED898' }}>
                    <FaWhatsapp />
                    <SocialText style={{ color: 'rgba(255, 255, 255, 0.87)' }}>
                      {inviteLayout.whatsapp_button}
                    </SocialText>
                  </Button>
                </a>
              </div>
              <div onClick={copyInvitationEvent}>
                <CopyToClipboard text={renderTextToCopy()} onCopy={() => setIsCopied(true)}>
                  <Button
                    style={{
                      color: isCopied ? 'rgba(0, 0, 0, 0.87)' : 'white',
                      backgroundColor: isCopied ? '#B9E8E9' : '#555',
                    }}
                  >
                    <FiLink />
                    <SocialText style={{ color: isCopied ? 'rgba(0, 0, 0, 0.87)' : 'white' }}>
                      {isCopied ? inviteLayout.copied_button : inviteLayout.copy_button}
                    </SocialText>
                  </Button>
                </CopyToClipboard>
              </div>
            </InviteButtonWrapper>
          </Card>
        </InviteContainer>
        <PostularContainer>
          <Card background={`url(${postularLayout?.background?.url})`} color={postularLayout?.background_color}>
            <CardBody>
              <CardTitle>{postularLayout?.title}</CardTitle>
              <TextWrapper>
                <CardDescription>
                  <HtmlRender>{postularLayout?.description}</HtmlRender>
                </CardDescription>
              </TextWrapper>
            </CardBody>
            <PostularButtonWrapper>
              {postularLayout?.link && (
                <LinkButton to={postularLayout?.link} mobileFullWidth>
                  {postularLayout?.button}
                  <ButtonIcon />
                </LinkButton>
              )}
            </PostularButtonWrapper>
          </Card>
        </PostularContainer>
      </Content>
    </Container>
  )
}

export default InviteSection
