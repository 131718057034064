import { SagaIterator } from '@redux-saga/core'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  MOTIVATION,
  CONTENT,
  TRIGGER_BLOG_LAYOUT,
  TRIGGER_POST,
  TRIGGER_POST_LAYOUT,
  POSTS,
  SET,
  SUBMIT_MOTIVATION,
} from 'ducks/blog'
import { getBlogLayout, getMotivation, getPost, getPostLayout, getPosts, postMotivation } from 'services/blog'
import { Action } from 'lib/redux/models'
import { IBlog } from '../models'

function* loadBlogLayout(action: Action) {
  yield put(CONTENT.request())
  try {
    const data: IBlog = yield call(getBlogLayout, action.payload)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* loadMotivation(action: Action) {
  try {
    const data: IBlog = yield call(getMotivation, action.payload)
    if (data) {
      yield put(MOTIVATION.success(data))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  }
}

function* loadPost(action: Action) {
  yield put(CONTENT.request())
  try {
    const data: IBlog = yield call(getPost, action.payload)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* loadPosts(action: Action) {
  yield put(CONTENT.request())
  try {
    const data: IBlog = yield call(getPosts, action.payload)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* loadPostLayout(action: Action) {
  yield put(CONTENT.request())
  try {
    const data: IBlog = yield call(getPostLayout, action.payload)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* submitMotivation(action: Action) {
  try {
    const data: IBlog = yield call(postMotivation, action.payload)
    if (data) {
      yield put(SUBMIT_MOTIVATION.success(data))
      yield put({ type: SET, payload: { current_post: { canMotivate: false } } })
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  }
}

export function* blogSagas(): SagaIterator {
  yield all([
    takeLatest(TRIGGER_POST, loadPost),
    takeLatest(POSTS.TRIGGER, loadPosts),
    takeLatest(TRIGGER_POST_LAYOUT, loadPostLayout),
    takeLatest(TRIGGER_BLOG_LAYOUT, loadBlogLayout),
    takeLatest(MOTIVATION.TRIGGER, loadMotivation),
    takeLatest(SUBMIT_MOTIVATION.TRIGGER, submitMotivation),
  ])
}
