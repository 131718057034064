import React, { FC } from 'react'
import Router from './Router'
import store from 'lib/redux'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import theme from 'lib/theme'
import StyledApp from 'lib/theme/StyledApp'
import Persist from 'views/components/Persist'
import { LanguageProvider } from 'hooks/useLanguage'
import { LoadingProvider } from 'hooks/useLoading'
import Loading from 'views/components/Loading'

const App: FC = () => (
  <Provider store={store}>
    <Persist loading={null}>
      <ThemeProvider theme={theme}>
        <LanguageProvider>
          <LoadingProvider>
            <Loading />
            <StyledApp />
            <Router />
          </LoadingProvider>
        </LanguageProvider>
      </ThemeProvider>
    </Persist>
  </Provider>
)
export default App
