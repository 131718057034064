import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  transform: translateY(-15%);
  @media screen and (max-width: 768px) {
    flex-direction: column;
    transform: none;
  }
`

export const LeftContainer = styled.div`
  width: 40%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const RightContainer = styled.div`
  width: 55%;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 20px 0 40px;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 300px;
  @media screen and (min-width: 1600px) {
    height: 400px;
  }
  @media screen and (max-width: 768px) {
    height: 400px;
  }
  @media screen and (max-width: 450px) {
    height: 200px;
  }
`

export const Title = styled.p`
  margin: 10px 0;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 34px;
    line-height: 39px;
  }
`

type SubtitleProps = {
  marginVertical?: boolean
}

export const Subtitle = styled.div<SubtitleProps>`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.07px;
  margin: ${({ marginVertical }) => (marginVertical ? '30px 0' : '')};
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 20px;
    line-height: 32px;
  }
  @media screen and (max-width: 450px) {
    margin: ${({ marginVertical }) => (marginVertical ? '20px 0' : '')};
  }
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 768px) {
    line-height: 20px;
  }
`

export const CardBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 4rem 2rem;
  display: flex;
  @media screen and (min-width: 1600px) {
    padding: 5rem 3rem;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
    padding: 2rem 1rem;
  }
`

export const CardImage = styled.div<BackgroundProps>`
  width: 45%;
  ${background};
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (max-width: 450px) {
    height: 300px;
    width: 100%;
  }
`

export const CardContent = styled.div`
  width: 55%;
  padding-left: 2rem;
  @media screen and (min-width: 1600px) {
    padding-left: 3rem;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    padding-left: 0;
    margin-top: 1rem;
  }
`
