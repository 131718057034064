import React, { MutableRefObject, FC, useEffect, useCallback, useState, useRef } from 'react'
import { countriesSelector, Country } from 'ducks/countries'
import { categoriesSelector, Category } from 'ducks/categories'
import { useSelector, useDispatch } from 'react-redux'
import { protagonistasLayoutSelector } from 'ducks/protagonistasLayout/selectors'
import { protagonistasSelector } from 'ducks/protagonistas/selectors'
import { CONTENT as LAYOUT_CONTENT } from 'ducks/protagonistasLayout/actionTypes'
import { Grid } from '@material-ui/core'
import { LOAD_PROTAGONISTAS, Protagonista } from 'ducks/protagonistas'
import { useFilter } from 'hooks/useFilter'
import { useLanguage } from 'hooks/useLanguage'
import useInfiniteLoader from 'hooks/useInfiniteLoader'

import PathIndicator from 'views/components/PathIndicator'
import { PathContainer } from 'views/components/PathIndicator/styled'
import Filter from 'views/components/Filter'
import ProtagonistaCard from 'views/components/ProtagonistaCard'
import FilterInUse from 'views/components/Filter/FilterInUse'
import TitleWithIcon from 'views/components/TitleWithIcon'
import ProtagonistaLoader from 'views/components/ProtagonistaCard/ProtagonistaLoader'
import LineSeparator from 'views/components/UI/LineSeparator'
import OtherProtagonistas from 'views/components/OtherProtagonistas'
import { Container, ContentContainer, FullRow, FilterContainer } from './styled'

const Protagonistas: FC = () => {
  const dispatch = useDispatch()
  const protagonistasLayout = useSelector(protagonistasLayoutSelector)
  const protagonistas = useSelector(protagonistasSelector)
  const { countriesFilter, categoriesFilter, clearFilter } = useFilter()
  const { currentLanguage } = useLanguage()
  const countries = useSelector(countriesSelector).get('countries')
  const categories = useSelector(categoriesSelector).get('categories')
  const [haveContentToLoad, setHaveContentToLoad] = useState(false)
  const contentRef = useRef() as MutableRefObject<HTMLDivElement>
  const loadingProtagonistas = protagonistas.get('loading_protagonistas')
  const { shouldInfiniteLoad } = useInfiniteLoader({ ref: contentRef })

  const handleLoadMore = useCallback(() => {
    const loading = protagonistas.toJS().loading_protagonistas
    if (haveContentToLoad && !loading) {
      const lastElement = protagonistas.toJS().protagonistas.length
      const countriesId = countriesFilter.map<number>((countryFilter: Country) => countryFilter.id)
      const categoriesId = categoriesFilter.map<number>((categoryFilter: Category) => categoryFilter.id)
      dispatch(
        LOAD_PROTAGONISTAS.trigger({
          countries: countriesId,
          categories: categoriesId,
          language: currentLanguage,
          start: lastElement,
        })
      )
    }
  }, [protagonistas, currentLanguage, dispatch, haveContentToLoad, countriesFilter, categoriesFilter])

  useEffect(() => {
    if (shouldInfiniteLoad) {
      handleLoadMore()
    }
  }, [handleLoadMore, shouldInfiniteLoad])

  useEffect(() => {
    const protagonistasSize = protagonistas.toJS().protagonistas.length
    const count = protagonistas.get('count') || protagonistasSize
    setHaveContentToLoad(protagonistasSize < count)
  }, [protagonistas])

  useEffect(() => {
    dispatch(LAYOUT_CONTENT.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage])

  useEffect(() => {
    return () => {
      clearFilter()
    }
  }, [clearFilter])

  return (
    <Container>
      <PathContainer loading={protagonistas.get('loading') ? 1 : 0}>
        <PathIndicator
          loading={protagonistas.get('loading')}
          description={protagonistasLayout.getIn(['path', 'description'])}
          sections={protagonistasLayout.getIn(['path', 'sections'])}
        />
      </PathContainer>
      <ContentContainer>
        <FilterContainer>
          <Filter
            loading={protagonistas.get('loading')}
            title={protagonistasLayout.getIn(['filter', 'title'])}
            country={protagonistasLayout.getIn(['filter', 'country'])}
            category={protagonistasLayout.getIn(['filter', 'category'])}
            countriesOptions={countries.filter((country: Country) => country.protagonistas.length > 0)}
            categoriesOptions={categories.filter((category: Category) => category.protagonistas.length > 0)}
          />
        </FilterContainer>
        <Container paddingLeft>
          <FullRow>
            <FilterInUse
              loading={protagonistas.get('loading')}
              title={protagonistasLayout.getIn(['filter', 'in_use'])}
              categoriesFilter={categoriesFilter}
              countriesFilter={countriesFilter}
              emptyMessage={protagonistasLayout.getIn(['filter', 'no_filters'])}
            />
          </FullRow>
          {!protagonistas.get('loading') && <LineSeparator />}
          <FullRow marginVertical>
            <TitleWithIcon
              loading={protagonistas.get('loading')}
              title={protagonistasLayout.getIn(['protagonistas', 'month'])}
              icon="star"
            />
          </FullRow>
          <Grid container justify="space-between" ref={contentRef}>
            {protagonistas.get('loading')
              ? [1, 2, 3, 4].map((key) => <ProtagonistaLoader key={key} />)
              : protagonistas
                  .get('protagonistas')
                  ?.map((protagonista: Protagonista) => (
                    <ProtagonistaCard key={protagonista.id} protagonista={protagonista} />
                  ))}
            {loadingProtagonistas && [1, 2, 3, 4].map((key) => <ProtagonistaLoader key={key} />)}
          </Grid>
        </Container>
      </ContentContainer>
      <OtherProtagonistas
        title={protagonistasLayout.getIn(['protagonistas', 'others'])}
        protagonistas={protagonistas.get('protagonistas')}
        loading={protagonistas.get('loading')}
        button={protagonistasLayout.getIn(['protagonistas', 'load_more_button'])}
        message={protagonistasLayout.getIn(['protagonistas', 'load_more'])}
      />
    </Container>
  )
}

export default Protagonistas
