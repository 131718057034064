import axios from 'axios'
import { parseAuthResponse } from './parser'
import { AuthResponse, UserState } from 'ducks/user'

type ProtagonistaRegister = {
  email: string
  name: string
  password: string
  username: string
}

const register = async (protagonista: ProtagonistaRegister): Promise<UserState | undefined> => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_MAIN_API}/auth/local/register`, protagonista)
    if (response.status === 200) {
      const { data }: { data: AuthResponse } = response
      localStorage.setItem('token', data.jwt)
      return parseAuthResponse(data)
    }
  } catch (error) {
    return
  }
}

export default register
