import createImmutableSelector from 'lib/immutable/selector'
import { IMovimientoLayout } from './../models'

// TODO: import Store type and add reducer type in Store interface
const getMovimientoLayout = (state: any): IMovimientoLayout => state.get('movimientoLayoutReducer')

export const movimientoLayoutSelector = createImmutableSelector(
  getMovimientoLayout,
  (movimientoLayout) => movimientoLayout
)
