import { SagaIterator } from '@redux-saga/core'
import { all, put, call, takeEvery } from 'redux-saga/effects'
import * as TYPES from './../actionTypes'
import getLayoutContent from 'services/getLayoutContent'
import { fromJS } from 'immutable'
import { Action } from 'lib/redux/models'

function* loadLayoutData(action: Action) {
  yield put(TYPES.LOAD.request())
  try {
    const data = yield call(getLayoutContent, action?.payload)
    yield put(TYPES.LOAD.success(fromJS(data)))
  } catch (err) {
    yield put(TYPES.LOAD.failure(err.message))
  } finally {
    yield put(TYPES.LOAD.fulfill())
  }
}

export function* layoutDataSagas(): SagaIterator {
  yield all([takeEvery(TYPES.LOAD.TRIGGER, loadLayoutData)])
}
