import React, { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { countriesSelector, Country } from 'ducks/countries'
import { categoriesSelector, Category } from 'ducks/categories'
import { CONTENT as COUNTRIES_CONTENT } from 'ducks/countries/actionTypes'
import { CONTENT as CATEGORIES_CONTENT } from 'ducks/categories/actionTypes'
import { Container, TitleContainer, Title } from './styled'
import CategoryFilter from './CategoryFilter'
import CountryFilter from './CountryFilter'
import WithSkeletonLoading from '../WithSkeletonLoading'
import { useLanguage } from 'hooks/useLanguage'

type Props = {
  loading?: boolean
  title: string
  country: string
  category: string
  countriesOptions?: Array<Country>
  categoriesOptions?: Array<Category>
}

const FilterContainer: FC<Props> = (props: Props) => {
  const { title, country, category, countriesOptions, categoriesOptions } = props
  const dispatch = useDispatch()
  const countries = useSelector(countriesSelector)
  const categories = useSelector(categoriesSelector)
  const { currentLanguage } = useLanguage()

  useEffect(() => {
    dispatch(COUNTRIES_CONTENT.trigger({ language: currentLanguage }))
    dispatch(CATEGORIES_CONTENT.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage])

  return (
    <Container>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <CategoryFilter title={category} options={categoriesOptions || categories.get('categories')} />
      <CountryFilter title={country} options={countriesOptions || countries.get('countries')} />
    </Container>
  )
}

export default WithSkeletonLoading<Props>(FilterContainer, { height: '600px', width: '100%' })
