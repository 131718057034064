import React, { FC } from 'react'
import {
  Container,
  LeftContainer,
  LeftTitle,
  LeftContent,
  LeftSubTitle,
  LeftDescription,
  RightContainer,
  RightImage,
  RightContent,
  RightTitle,
  RightDescription,
} from './styled'
import { Featured } from 'ducks/home'
import { LinkButton } from 'views/components/UI/Buttons'
import { Block } from 'views/components/UI/Contents'
import HtmlRender from 'views/components/HtmlRender'

const DualFeaturedSection: FC<Featured> = ({
  left_title,
  left_subtitle,
  left_link,
  left_description,
  left_image = '',
  right_image = '',
  right_description,
  right_title,
  right_link,
  button,
}) => (
  <Container>
    <LeftContainer>
      <LeftContent backgroundImage={`url(${left_image})`} backgroundSize="cover">
        <LeftSubTitle>{left_subtitle}</LeftSubTitle>
        <LeftTitle>{left_title}</LeftTitle>
        <LeftDescription>
          <HtmlRender>{left_description}</HtmlRender>
        </LeftDescription>
      </LeftContent>
      <Block position="absolute" zIndex={1} bottom="-1.5rem" right="6rem" bg="#fff" borderRadius="4px">
        {left_link && <LinkButton to={left_link}>{button}</LinkButton>}
      </Block>
    </LeftContainer>
    <RightContainer>
      <RightImage backgroundImage={`url(${right_image})`} backgroundSize="cover" />
      <RightContent>
        <RightTitle>{right_title}</RightTitle>
        <RightDescription>
          <HtmlRender>{right_description}</HtmlRender>
        </RightDescription>
        <Block py="1rem">{right_link && <LinkButton to={right_link}>{button}</LinkButton>}</Block>
      </RightContent>
    </RightContainer>
  </Container>
)

export default DualFeaturedSection
