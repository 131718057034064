import { RegisterLayoutResponse, RegisterLayoutBruteState } from 'ducks/registerLayout'

export const parserRegisterLayout = ({
  title,
  name,
  email,
  password,
  link,
  link_description,
  link_label,
  button,
}: RegisterLayoutResponse): RegisterLayoutBruteState => ({
  title,
  name: name.label,
  email: email.label,
  password: password.label,
  link: link.value,
  link_description,
  link_label,
  button,
})
