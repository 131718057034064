import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const MasterContainer = styled.div`
  width: 40%;
  display: flex;
  position: relative;
  margin-bottom: 4rem;
`

export const Container = styled.div<BackgroundProps>`
  margin-left: 30px;
  margin-right: 50px;
  max-width: 564px;
  max-height: 901px;
  position: relative;
  ${background};
  background-size: cover;
`

export const Content = styled.div`
  width: 90%;
  height: 100%;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
export const Title = styled.div`
  font-weight: 600;
  font-size: 2.5rem;
  width: 100%;
`
export const Subtitle = styled.div`
  width: 100%;
`
export const Image = styled.img`
  width: 50px;
  height: 50px;
  margin: 1rem 1rem 1rem 0;
  display: block;
  object-fit: cover;
  border-radius: 50%;
  border: solid 1px #444;
`

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: -24px;
  right: 45px;
`
