import { Image } from 'ducks/aboutLayout'
import { Record } from 'immutable'

export interface Volunteers {
  loading: boolean
  create_loading?: boolean
  create_success?: boolean
  error?: string
  types: Array<VolunteerType>
  volunteers: Array<Volunteer>
}

export interface VolunteerType {
  __typename: string
  id: string
  name: string
  label?: string
  color?: string
  display_name: string
}

export interface Volunteer {
  __typename: string
  id: string
  name: string
  image: Image
  label?: string
  types: Array<VolunteerType>
}

export interface VolunteersResponse {
  data: Volunteers
}

export enum CardTypes {
  VOLUNTEER = 'VolunteerPerson',
  TYPE = 'VolunteerType',
}

// immutable types
export type IVolunteers = Record<Volunteers>
