import styled from 'styled-components'

type ContainerProps = {
  marginTop?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: ${({ marginTop }) => (marginTop ? '30px' : '0')};
`

export const Title = styled.p`
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 5px;
  letter-spacing: -0.1px;
  color: ${({ theme }) => theme.colors.dark};
`

export const SocialLink = styled.a`
  color: ${({ theme }) => theme.colors.default};
`

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 200px;
  align-items: center;
  width: 50%;
  @media screen and (max-width: 500px) {
    width: 70%;
    margin-bottom: 20px;
  }
`

export const TextRow = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.lightTextFooter};
  @media screen and (max-width: 500px) {
    max-width: 100px;
  }
`
