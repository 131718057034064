import styled from 'styled-components'
import { layout, LayoutProps, background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 6% 0 4% 12%;
  @media screen and (max-width: 1025px) {
    padding: 40% 0 30% 4%;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    padding: 6% 0 4% 12%;
  }
  @media screen and (min-width: 1600px) {
    padding: 10% 0 6% 12%;
  }
`

export const Card = styled.div<LayoutProps>`
  ${layout};
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background-color: #eaeaea;
  @media screen and (min-width: 1600px) {
    padding: 40px;
  }
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 62px;
  line-height: 60px;
  letter-spacing: 0.35px;
  margin: 0;
  color: ${({ theme }) => theme.colors.dark};
  text-transform: uppercase;
  word-break: break-all;
  @media screen and (min-width: 1600px) {
    font-size: 72px;
    line-height: 70px;
    padding: 15px 35px 50px 15px;
  }
`

export const Subtitle = styled.p`
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.075px;
  color: ${({ theme }) => theme.colors.dark};
  margin: 0;
  margin-bottom: 10px;
  @media screen and (min-width: 1600px) {
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 20px;
  }
`

type ImageProps = BackgroundProps & LayoutProps

export const RightImage = styled.div<ImageProps>`
  ${background};
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  width: 40%;
  height: 300px;
  @media screen and (min-width: 1600px) {
    height: 400px;
  }
`

export const LeftImage = styled.div<ImageProps>`
  ${background};
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  width: 50%;
  height: 240px;
  @media screen and (max-width: 1025px) {
    height: 300px;
  }
  @media screen and (min-width: 1600px) {
    height: 340px;
  }
`

export const Content = styled.div<LayoutProps>`
  ${layout};
  width: 50%;
  padding: 30px 0 30px 30px;
`

export const LeftBox = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  width: 35%;
  @media screen and (max-width: 1025px) {
    width: 40%;
  }
  @media screen and (max-width: 768px) {
    width: 60%;
    align-items: center;
  }
`

export const RightBox = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  width: 55%;
  @media screen and (max-width: 1025px) {
    width: 55%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    align-items: center;
  }
`
