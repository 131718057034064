import { MutableRefObject, useState, useCallback, useEffect } from 'react'

type Props = {
  ref: MutableRefObject<HTMLDivElement>
}

type IfiniteLoaderAttributes = {
  shouldInfiniteLoad: boolean
}

const useInfiniteLoader = ({ ref }: Props): IfiniteLoaderAttributes => {
  const [shouldInfiniteLoad, setShouldInfiniteLoad] = useState(false)

  const handleLoadScroll = useCallback(() => {
    const contentOfsetHeight = ref.current.offsetHeight
    const scrollPosition = window.scrollY
    const windowHeight = window.innerHeight
    const shouldLoad = contentOfsetHeight - windowHeight * 0.7 <= scrollPosition
    if (shouldLoad) {
      setShouldInfiniteLoad(true)
    } else {
      setShouldInfiniteLoad(false)
    }
  }, [ref])

  useEffect(() => {
    window.addEventListener('scroll', handleLoadScroll)
    return () => {
      window.removeEventListener('scroll', handleLoadScroll)
    }
  }, [handleLoadScroll])

  return { shouldInfiniteLoad }
}

export default useInfiniteLoader
