import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { IContacto } from './../models'
import * as TYPES from './../actionTypes'

export const initialState: IContacto = fromJS({
  loading: false,
  title: '',
  main_image: {
    url: '',
  },
  description: '',
  section_title: '',
  visibility: {},
  guide: {},
  card: {},
  form: {},
})

const contactoReducer: Reducer<IContacto> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET:
      return state.merge(action.payload)
    case TYPES.CONTENT.TRIGGER:
      return state.set('loading', true)
    case TYPES.CONTENT.FULFILL:
      return state.set('loading', false)
    case TYPES.CONTENT.SUCCESS:
      return state.merge(action.payload)
    case TYPES.CONTENT.FAILURE:
      return state.set('error', action.payload)
    case TYPES.SUBMIT.REQUEST:
      return state.set('submit_loading', true)
    case TYPES.SUBMIT.FULFILL:
      return state.set('submit_loading', false)
    case TYPES.SUBMIT.SUCCESS:
      return state.set('submit_success', true)
    default:
      return state
  }
}

export default contactoReducer
