import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div<BackgroundProps>`
  ${background};
  display: flex;
  flex-direction: column;
`

export const CoverImage = styled.div<BackgroundProps>`
  width: 100%;
  height: 80vh;
  max-height: 682px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem 4rem;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.black};
  font-size: 48px;
  ${background};
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 1250px) {
    padding: 20px 10px;
    max-height: 341px;
  }
`

export const TitleLabel = styled.p`
  margin: 0;
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.white};
  width: 35%;
  @media screen and (max-width: 1250px) {
    width: 100%;
  }
`

export const Title = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  font-size: 3.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  text-transform: uppercase;
  width: 35%;
  @media screen and (max-width: 1250px) {
    font-size: 2.5rem;
    width: 100%;
  }
`

export const Content = styled.div`
  transform: translateY(-200px);
  padding: 0 4rem;
  position: relative;
  @media screen and (min-width: 1600px) {
    transform: translateY(-250px);
  }
  @media screen and (max-width: 1250px) {
    transform: translateY(0);
    padding: 0 10px;
    display: flex;
    flex-direction: column;
  }
`
