import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled(Link)`
  width: 100%;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const Content = styled.div`
  width: 90%;
`

interface ImageProps extends BackgroundProps {
  highlightedColor?: string
}

export const Image = styled.div<ImageProps>`
  ${background};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  width: 95%;
  height: 492px;
  -webkit-box-shadow: 30px -30px 0px 0px ${({ highlightedColor }) => highlightedColor || '#212121'};
  -moz-box-shadow: 30px -30px 0px 0px ${({ highlightedColor }) => highlightedColor || '#212121'};
  box-shadow: 30px -30px 0px 0px ${({ highlightedColor }) => highlightedColor || '#212121'};
  @media screen and (max-width: 450px) {
    height: 200px;
  }
`

type TextProps = {
  marginBottom?: boolean
}

export const Text = styled.div<TextProps>`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: ${({ marginBottom }) => marginBottom && '8px'};
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 20px;
  }
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 48px;
  line-height: 49px;
  color: ${({ theme }) => theme.colors.dark};
  margin: 24px 0 8px;
  @media screen and (max-width: 450px) {
    font-size: 20px;
    line-height: 21px;
  }
`
