import axios from 'axios'
import { ProtagonistasLayout } from 'ducks/protagonistasLayout'
import { parseProtagonistasLayout } from './parsers'
import { TranslatedRequest } from 'lib/axios/models'

const protagonistasLayout = async ({ language }: TranslatedRequest): Promise<ProtagonistasLayout | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query{
        protagonistasLayout {
          filter {
            title: title_${language}
            category: category_${language}
            country: country_${language}
            in_use:in_use_${language}
            no_filters: no_filters_${language}
          }
          path {
            description: description_${language}
            sections {
              text: text_${language}
              link
            }
          }
          protagonistas {
            month: month_${language}
            others: others_${language}
            load_more: load_more_${language}
            load_more_button: load_more_button_${language}
            card_button: card_button_${language}
            max_character
          }
        }
      }
      `,
    })
    return parseProtagonistasLayout(data)
  } catch (error) {
    return
  }
}

export default protagonistasLayout
