const theme: any = {
  colors: {
    main: '#440381',
    accent: '#D72638',
    error: '#ff0000',
    light: '#f6f6f6',
    dark: 'rgba(0, 0, 0, 0.87)',
    default: 'rgba(0, 0, 0, 0.54)',
    wood: '#d7d4bb',
    blue: '#3f6ff2',
    primary: '#c6c3b0',
    white: '#ffffff',
    black: '#000',
    boxShadow: 'rgba(36,36,36,1)',
    grey: '#e0e0e0',
    greyButton: '#bdbdbd',
    lightBackground: '#f4f6f8',
    inactiveSlide: '#999999',
    activeSlide: '#466FF2',
    gold: '#77713d',
    light_gold: '#c6c3b0',
    border_gold: '#d7d4bb',
    green: '#91cc8d',
    lightTextFooter: 'rgba(44, 46, 63, 0.7)',
    footerElementSeparator: '#696a77',
    footerContentSeparator: '#d8d8d8',
    footerLogoText: '#7b7b7b',
    inputBorder: 'rgba(0, 0, 0, 0.23)',
    checkbox: '#979797',
    founderDefaultBackground: '#88C6C9',
    formLink: '#266FCF',
    defaultCardBackground: '#91CC8D',
    stepNumberContainer: '#94D5DC',
    label: '#6E6E6E',
    trashIcon: '#ED8C9B',
    avatarColors: ['#94D5DC', '#6DBED1', '#D7D4BB', '#F27E8E'],
    postularCardLabel: '#378F9C',
    cookieBanner: {
      white: '#fff',
      black: '#212121',
      green: '#94D5DC',
    },
  },
}

export default theme
