import { HowItWorkSection, RoleSection } from 'ducks/voluntariadoLayout'
import React, { FC } from 'react'

import { Container, Subtitle, RolesSection, Column, RoleContainer, RoleTitleBox, RoleDescription } from './styled'
import { Subtitle as Title, Text } from 'views/screens/Voluntariado/styled'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  layout: HowItWorkSection
}

const WorksSection: FC<Props> = (props: Props) => {
  const { layout } = props

  const renderRoleBox = (role: RoleSection) => {
    return (
      <RoleContainer key={role.title}>
        <RoleTitleBox>
          <HtmlRender>{role.display_title}</HtmlRender>
        </RoleTitleBox>
        <RoleDescription>
          <HtmlRender>{role.description}</HtmlRender>
        </RoleDescription>
      </RoleContainer>
    )
  }

  return (
    <Container>
      <Title>{layout.title}</Title>
      {layout?.subtitle && <Subtitle>{layout.subtitle}</Subtitle>}
      <Text>
        <HtmlRender>{layout.description}</HtmlRender>
      </Text>
      <RolesSection>
        <Column>{layout.first_column?.map(renderRoleBox)}</Column>
        <Column>{layout.second_column?.map(renderRoleBox)}</Column>
      </RolesSection>
    </Container>
  )
}

export default WorksSection
