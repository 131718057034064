import styled, { css } from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    margin-top: 23px;
    align-items: flex-end;
  }
`

export const SectionTitle = styled.div`
  font-size: 1.1rem;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
  font-family: 'PT Serif Caption', serif;
  &::after {
    content: '';
    flex: 1;
    margin-left: 2rem;
    position: relative;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.dark};
  }
  @media screen and (max-width: 768px) {
    padding: 0 18px;
  }
`
export const Image = styled.div<BackgroundProps>`
  width: 100%;
  height: 580px;
  background-position-x: center;
  background-position-y: center;
  ${background}
  @media screen and (max-width: 768px) {
    width: 100%;
    max-height: 200px;
  }
  @media screen and (max-width: 1250px) {
    width: 100%;
    max-height: 150px;
  }
`
const activeDot = css`
  transform: scale(2);
  background-color: ${({ theme }) => theme.colors.blue};
`
const inactiveDot = css`
  transform: scale(1);
`

type DotIndicatorProps = {
  isActive?: boolean
}

export const DotIndicator = styled.div<DotIndicatorProps>`
  width: 0.4rem;
  height: 0.4rem;
  background-color: ${({ theme }) => theme.colors.default};
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
  ${({ isActive }) => (isActive ? activeDot : inactiveDot)}
`
export const Title = styled.div`
  font-size: 2.6rem;
  font-weight: 600;
  @media screen and (max-width: 1250px) {
    font-size: 18px;
  }
`

export const Description = styled.div`
  margin-top: 1rem;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  line-height: 1.8rem;
  @media screen and (max-width: 1250px) {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.04px;
  }
`
