import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
`

export const SlideButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  font-size: 48px;
  & > .icon {
    color: ${({ theme }) => theme.colors.dark};
  }
  @media screen and (max-width: 1250px) {
    width: 36px;
    height: 36px;
    font-size: 36px;
  }
`
export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`
export const Image = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  height: 360px;
  width: 100%;
  @media screen and (min-width: 1600px) {
    height: 600px;
  }
  @media screen and (max-width: 1024px) {
    height: 400px;
  }
  @media screen and (max-width: 450px) {
    height: 200px;
  }
`

export const Paginator = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
  align-items: center;
`

type DotProps = {
  active?: boolean
}

export const Dot = styled.div<DotProps>`
  width: ${({ active }) => (active ? '12px' : '6px')};
  height: ${({ active }) => (active ? '12px' : '6px')};
  background-color: ${({ active, theme }) => (active ? theme.colors.activeSlide : theme.colors.inactiveSlide)};
  border-radius: ${({ active }) => (active ? '12px' : '6px')};
  margin: 0 12px;
`
