import styled from 'styled-components'
import { ColorProps } from 'styled-system'
import { Link } from 'react-router-dom'

export const Category = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.081388px;
  color: ${({ theme }) => theme.colors.default};
`

export const CategoryLabel = styled.div<ColorProps>`
  height: 38px;
  width: 16px;
  margin-right: 10px;
  background-color: ${({ color }) => color};
`

export const Container = styled(Link)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const Text = styled.div`
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.black};
  @media screen and (max-width: 450px) {
    font-size: 14px;
    line-height: 20px;
  }
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 21px;
  line-height: 23px;
  margin: 10px 0 5px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 450px) {
    font-size: 18px;
    line-height: 20px;
  }
`
