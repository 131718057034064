import axios from 'axios'
import { Home } from 'ducks/home'
import { parseHomeContent } from './parsers'
import { TranslatedRequest } from 'lib/axios/models'

const getHomeContent = async ({ language }: TranslatedRequest): Promise<Home | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query{
        home {
          featured {
            left_image {
              url
            }
            left_link
            left_title: left_title_${language}
            left_subtitle: left_subtitle_${language}
            left_description: left_description_${language}
            right_image {
              url
            }
            right_link
            right_title: right_title_${language}
            right_description: right_description_${language}
            button: button_${language}
            button_mobile: button_mobile_${language}
          }
          indicators {
            value
            title: title_${language}
            subtitle: subtitle_${language}
          }
          news_section {
            left_image {
              url
            }
            left_video
            left_title: left_title_${language}
            left_description: left_description_${language}
            right_link
            right_image {
              url
            }
            right_title: right_title_${language}
            right_subtitle: right_subtitle_${language}
            right_description: right_description_${language}
            right_button: right_button_${language}
          }
          card {
            background {
              url
            }
            image {
              url
            }
            link {
              external
              value
            }
            title: title_${language}
            subtitle: subtitle_${language}
            button_mobile: button_mobile_${language}
            button: button_${language}
          }
          slide_title: slide_title_${language}
          slide_button: slide_button_${language}
          slide_button_mobile: slide_button_mobile_${language}
          slider {
            id
            image {
              url
            }
            link
            title: title_${language}
            description: description_${language}
          }
        }
      }
      `,
    })
    return parseHomeContent(data.home)
  } catch (err) {
    return
  }
}

export default getHomeContent
