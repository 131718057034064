import React, { FC, useContext, useState, useEffect, useCallback } from 'react'
import { userSelector, LOGOUT } from 'ducks/user'
import { layoutDataSelector } from 'ducks/layoutData'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from 'react-avatar'
import { ThemeContext } from 'styled-components'
import _ from 'lodash'
import { MdClose } from 'react-icons/md'
import { FiLogOut, FiUser } from 'react-icons/fi'
import { Record } from 'immutable'
import { MenuOption } from 'ducks/layoutData'
import { useHistory } from 'react-router-dom'

import { Container, Content, Title, ButtonWrapper, Header, MenuButton, MenuLink, MenuIcon } from './styled'

const Menu: FC = () => {
  const { colors } = useContext(ThemeContext)
  const dispatch = useDispatch()
  const history = useHistory()
  const userState = useSelector(userSelector)
  const layoutState = useSelector(layoutDataSelector)
  const [isOpen, setIsOpen] = useState(false)
  const [avatarColor, setAvatarColor] = useState('')

  const getRandomColor = useCallback(() => {
    return _.sample(colors.avatarColors)
  }, [colors])

  useEffect(() => {
    setAvatarColor(getRandomColor())
  }, [getRandomColor])

  const handleMenuToggle = (): void => {
    setIsOpen((prevState) => !prevState)
  }

  const renderIcon = (iconName: string): JSX.Element | null => {
    switch (iconName) {
      case 'exit':
        return <FiLogOut />
      case 'user':
        return <FiUser />
      default:
        return null
    }
  }

  const handleLogout = () => {
    setIsOpen(false)
    dispatch(LOGOUT.trigger())
    history.push('/')
  }

  return (
    <Container>
      <Avatar
        name={userState.get('name')}
        size="40"
        round
        color={avatarColor}
        onClick={handleMenuToggle}
        style={{ cursor: 'pointer' }}
      />
      <Content open={isOpen}>
        <ButtonWrapper onClick={handleMenuToggle}>
          <MdClose />
        </ButtonWrapper>
        <Header>
          <Title>{userState.get('name')}</Title>
        </Header>
        {layoutState.getIn(['userMenu', 'options'])?.map((option: Record<MenuOption>) => (
          <MenuLink to={option.get('link') || ''} key={option.get('label')} onClick={handleMenuToggle}>
            <MenuIcon>{renderIcon(option.get('icon'))}</MenuIcon>
            {option.get('label')}
          </MenuLink>
        ))}
        {layoutState.getIn(['userMenu', 'logout']) && (
          <MenuButton onClick={handleLogout}>
            <MenuIcon>{renderIcon(layoutState.getIn(['userMenu', 'logout'])?.get('icon'))}</MenuIcon>
            {layoutState.getIn(['userMenu', 'logout'])?.get('label')}
          </MenuButton>
        )}
      </Content>
    </Container>
  )
}

export default Menu
