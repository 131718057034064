import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { color, ColorProps } from 'styled-system'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem 1rem;
  background-color: ${({ theme }) => theme.colors.white};
`

export const Title = styled.h1`
  display: flex;
  flex-direction: column;
  font-weight: 900;
  margin: 0;
  margin-left: 1rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.dark};
  & > span:nth-child(2) {
    font-weight: 300;
  }
`

export const Logo = styled.img`
  width: 50px;
  height: 50px;
`

export const SectionContainer = styled.div`
  display: flex;
  width: 45%;
  max-width: 500px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  & > .active {
    border-bottom: 2px solid #6dbed1;
  }
  @media screen and (min-width: 1600px) {
    width: 50%;
    margin: 0 50px 0 100px;
  }
`

export const SectionElement = styled.div`
  padding: 9px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Section = styled(Link)<ColorProps>`
  color: ${({ theme }) => theme.colors.dark};
  ${color};
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.03px;
  line-height: 24px;
  text-align: center;
`

export const LanguageSelect = styled.select`
  color: ${({ theme }) => theme.colors.dark};
  background-color: transparent;
  border: none;
  font-family: 'Nunito Sans';
  letter-spacing: 0.3px;
  font-weight: bold;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('expand_more.png') no-repeat transparent;
  background-position: right center;
  background-size: contain;
  overflow: hidden;
  box-sizing: border-box;
  width: 90px;
`

export const ButtonWithIcon = styled(Link)`
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 12px;
  padding: 4px 7px;
  margin: 0 12px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 22px;
  letter-spacing: 0.3px;
  justify-self: center;
  align-self: center;
  & > .icon {
    margin-right: 7px;
    color: ${({ theme }) => theme.colors.light_gold};
    font-size: 18px;
  }
  color: ${({ theme }) => theme.colors.gold};
  border: 1px solid ${({ theme }) => theme.colors.border_gold};
  border-radius: 4px;
  @media screen and (min-width: 1600px) {
    font-size: 13px;
  }
`

export const LinkButtonWithIcon = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 12px;
  padding: 4px 7px;
  margin: 0 12px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 22px;
  letter-spacing: 0.3px;
  justify-self: center;
  align-self: center;
  & > .icon {
    margin-right: 7px;
    color: ${({ theme }) => theme.colors.light_gold};
    font-size: 18px;
  }
  color: ${({ theme }) => theme.colors.gold};
  border: 1px solid ${({ theme }) => theme.colors.border_gold};
  border-radius: 4px;
  @media screen and (min-width: 1600px) {
    font-size: 13px;
  }
`

export const StoreButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.03px;
  line-height: 24px;
  margin-right: 20%;
  color: ${({ theme }) => theme.colors.black};
  & > .icon {
    margin-right: 7px;
    color: ${({ theme }) => theme.colors.black};
    font-size: 18px;
  }
`
