import React, { FC, useState } from 'react'
import { Container, Header, HeaderTitle, Option, OptionText, OptionFlag } from './styled'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Country } from 'ducks/countries'
import { useFilter } from 'hooks/useFilter'

type Props = {
  title: string
  options: Array<Country>
}

const CategoryFilter: FC<Props> = (props: Props) => {
  const { title, options } = props
  const [open, setOpen] = useState<boolean>(true)
  const { handleCountryFilter, includesCountry } = useFilter()

  return (
    <Container open={open} optionsLength={options.length}>
      <Header>
        <HeaderTitle>{title}</HeaderTitle>
        <ExpandMoreIcon onClick={() => setOpen((prevState) => !prevState)} data-testid="expandButton" />
      </Header>
      {options.map((option, index) => (
        <Option
          key={option.shortname}
          onClick={() => handleCountryFilter(option)}
          selected={includesCountry(option)}
          last={index === options.length - 1}
        >
          {option.image && <OptionFlag src={option.image?.url} alt="Country Flag" />}
          <OptionText>{option.name}</OptionText>
        </Option>
      ))}
    </Container>
  )
}

export default CategoryFilter
