/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import persist from 'ducks/persist/reducer'
import { combineReducers } from 'redux-immutable'
import { Action, IState } from '../models'
import { Reducer } from 'redux'
import userReducer from 'ducks/user/reducer'
import homeReducer from 'ducks/home/reducer'
import layoutDataReducer from 'ducks/layoutData/reducer'
import protagonistasLayoutReducer from 'ducks/protagonistasLayout/reducer'
import countriesReducer from 'ducks/countries/reducer'
import categoriesReducer from 'ducks/categories/reducer'
import protagonistasReducer from 'ducks/protagonistas/reducer'
import protagonistaLayoutReducer from 'ducks/protagonistaLayout/reducer'
import movimientosLayoutReducer from 'ducks/movimientosLayout/reducer'
import movimientosReducer from 'ducks/movimientos/reducer'
import movimientoLayoutReducer from 'ducks/movimientoLayout/reducer'
import aboutLayoutReducer from 'ducks/aboutLayout/reducer'
import pagesReducer from 'ducks/pages/reducer'
import contactoReducer from 'ducks/contacto/reducer'
import loginLayoutReducer from 'ducks/loginLayout/reducer'
import voluntariadoLayoutReducer from 'ducks/voluntariadoLayout/reducer'
import volunteersReducer from 'ducks/volunteers/reducer'
import postularReducer from 'ducks/postular/reducer'
import profileLayoutReducer from 'ducks/profileLayout/reducer'
import registerLayoutReducer from 'ducks/registerLayout/reducer'
import successLayoutReducer from 'ducks/successLayout/reducer'
import loadingReducer from 'ducks/loading/reducer'
import elColeReducer from 'ducks/elCole/reducer'
import tienditaLayoutReducer from 'ducks/tienditaLayout/reducer'
import colaboraLayoutReducer from 'ducks/colaboraLayout/reducer'
import blogReducer from 'ducks/blog/reducer'

const appReducer: Reducer<IState, Action> = combineReducers({
  userReducer,
  homeReducer,
  layoutDataReducer,
  protagonistasLayoutReducer,
  countriesReducer,
  categoriesReducer,
  protagonistasReducer,
  protagonistaLayoutReducer,
  movimientosLayoutReducer,
  movimientosReducer,
  movimientoLayoutReducer,
  aboutLayoutReducer,
  contactoReducer,
  pagesReducer,
  loginLayoutReducer,
  voluntariadoLayoutReducer,
  volunteersReducer,
  postularReducer,
  profileLayoutReducer,
  registerLayoutReducer,
  successLayoutReducer,
  loadingReducer,
  elColeReducer,
  tienditaLayoutReducer,
  colaboraLayoutReducer,
  blogReducer,
  _persist: persist,
})

const rootReducer = (state: IState | undefined, action: Action): IState => {
  return appReducer(state, action)
}

export default rootReducer
