import axios from 'axios'
import { MovimientoPayload } from 'ducks/movimientos'
import { parseMovimientoData } from './parser'
import { TranslatedRequest } from 'lib/axios/models'

interface ProtagonistaRequest extends TranslatedRequest {
  codename: string
}

const getMovimiento = async ({ codename, language }: ProtagonistaRequest): Promise<MovimientoPayload | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        groups(where:{codename: "${codename}"}) {
          id
          name
    			cover_image {
            url
          }
          history_article {
            title: title_${language}
            part_one {
              description: description_${language}
              left_image {
                url
              }
              right_image {
                url
              }
            }
            part_two {
              description: description_${language}
              image {
                url
              }
            }
            part_three {
              description: description_${language}
              top_video
              top_thumbnail {
                url
              }
              bottom_right_video
              bottom_right_thumbnail {
                url
              }
              top_right_image {
                url
              }
              bottom_right_image {
                url
              }
            }
          }
          codename
          description: description_${language}
          image {
            url
          }
          country {
            name: name_${language}
            image {
              url
            }
          }
          categories {
            name: name_${language}
            overlay
            Badge {
              text
              background
            }
          }
          ordered_categories {
            label
            eje {
              name: name_${language}
              overlay
              Badge {
                text
                background
              }
            }
          }
          protagonistas {
            name
            nickname
            id
            username
          }
          socials {
            email
            instagram
            website
          }
        }
      }
      `,
    })
    return parseMovimientoData(data.groups)
  } catch (error) {
    return
  }
}

export default getMovimiento
