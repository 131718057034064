import styled from 'styled-components'
import { BackgroundProps, background } from 'styled-system'

type ContainerProps = {
  haveImages?: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ haveImages }) => (haveImages ? '3rem 2.5rem 150px' : '3rem 2.5rem')};
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  -moz-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  border-radius: 4px;
  margin-bottom: 200px;
  @media screen and (max-width: 1250px) {
    max-height: unset;
    padding: 2rem;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 450px) {
    padding: 2rem 1rem;
  }
`

export const Title = styled.p`
  font-family: PTSerif-Caption;
  font-size: 18px;
  line-height: normal;
  letter-spacing: 1.25px;
  color: ${({ theme }) => theme.colors.dark};
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  position: relative;
`

export const SectionLine = styled.div`
  width: 45%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.black};
  position: absolute;
  right: -6.5rem;
  top: 12px;
  @media screen and (max-width: 1250px) {
    display: none;
  }
`

export const Description = styled.div`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.09px;
  margin: 2rem 0;
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  padding: 0 2.5rem;
  bottom: -170px;
  @media screen and (max-width: 1250px) {
    position: unset;
    padding: 0;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`

export const LeftImage = styled.div<BackgroundProps>`
  width: 60%;
  height: 327px;
  ${background}
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (max-width: 1250px) {
    width: 48%;
    height: 220px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`

export const RightImage = styled.div<BackgroundProps>`
  width: 37%;
  height: 327px;
  ${background}
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (max-width: 1250px) {
    width: 48%;
    height: 220px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    margin-top: 20px;
  }
`
