import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

interface ContainerProps extends BackgroundProps {
  active?: boolean
}

export const Container = styled.div<ContainerProps>`
  transform: ${({ active }) => (active ? 'translateY(20px)' : 'none')};
  width: 150px;
  height: 150px;
  ${background};
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  position: relative;
  cursor: pointer;
  margin-right: 20px;
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 450px) {
    margin-bottom: 20px;
    margin-right: 0;
    width: 160px;
    height: 160px;
  }
`

export const Overlay = styled.div<BackgroundProps>`
  ${background};
  position: absolute;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
`

export const Content = styled.div<BackgroundProps>`
  ${background};
  width: 100%;
  padding: 4px 8px;
  opacity: 0.75;
  height: 44px;
`

export const Label = styled.p`
  font-weight: 600;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.0385px;
  color: ${({ theme }) => theme.colors.dark};
  text-align: left;
`
