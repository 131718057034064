import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  width: 95%;
  padding: 2rem 0 6rem 6rem;
  position: relative;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 2rem 0 0;
  }
`

export const CardBody = styled.div`
  padding: 3rem 2rem 4rem 2rem;
  width: 75%;
  @media screen and (min-width: 1600px) {
    padding: 5rem 3rem 6rem 3rem;
  }
  @media screen and (max-width: 1024px) {
    width: 60%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    padding: 2rem 1rem;
  }
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CardWrapper = styled.div`
  width: 90%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const Image = styled.div<BackgroundProps>`
  ${background};
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 400px;
  height: 350px;
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
  @media screen and (min-width: 1600px) {
    width: 500px;
    height: 450px;
  }
  @media screen and (max-width: 1024px) {
    transform: translateY(-80px);
  }
  @media screen and (max-width: 768px) {
    transform: none;
    position: unset;
    width: 100%;
    margin: 20px 0;
  }
  @media screen and (max-width: 450px) {
    height: 250px;
  }
`

export const Line = styled.div`
  height: 2px;
  background-color: ${({ theme }) => theme.colors.black};
  position: absolute;
  width: 40%;
  left: -32%;
  @media screen and (min-width: 1600px) {
    left: -34%;
  }
  @media screen and (max-width: 450px) {
    display: none;
  }
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 768px) {
    line-height: 20px;
  }
`

export const Title = styled.p`
  margin: 20px 60px;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.09px;
  font-family: PT Serif Caption;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media screen and (max-width: 450px) {
    margin: 20px 0;
  }
`
