import { Companies } from 'ducks/colaboraLayout'
import React, { FC } from 'react'

import { Container, Header, Content, StackedCardsContainer, StackedTopCard, RightCardContainer } from './styled'
import { Title } from '../styled'
import CompanyCard from './CompanyCard'

type Props = {
  layout?: Companies
}

const CompaniesSection: FC<Props> = (props: Props) => {
  const { layout } = props
  return (
    <Container>
      <Header>
        <Title>{layout?.title}</Title>
      </Header>
      <Content>
        <StackedCardsContainer>
          <StackedTopCard>
            <CompanyCard layout={layout?.origin_section} />
          </StackedTopCard>
          <CompanyCard layout={layout?.partners_section} large />
        </StackedCardsContainer>
        <RightCardContainer>
          <CompanyCard layout={layout?.trust_section} />
        </RightCardContainer>
      </Content>
    </Container>
  )
}

export default CompaniesSection
