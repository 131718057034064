import React, { FC } from 'react'
import { Container, CancelButton, Text } from './styled'
import { Category } from 'ducks/categories'
import { useFilter } from 'hooks/useFilter'

type Props = {
  category: Category
}

const CategoryFilterBadge: FC<Props> = ({ category }: Props) => {
  const { handleCategoryFilter } = useFilter()

  return (
    <Container backgroundColor={category.Badge?.background} textColor={category.Badge?.text}>
      <Text>{category.name}</Text>
      <CancelButton onClick={() => handleCategoryFilter(category)} />
    </Container>
  )
}

export default CategoryFilterBadge
