import { SubscribeSection as SubscribeSectionType, FormValues, FormErrorValues } from 'ducks/voluntariadoLayout'
import { VolunteerType } from 'ducks/volunteers'
import React, { ChangeEvent, FC, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { FiUser, FiMail, FiSmartphone, FiGlobe, FiCheck } from 'react-icons/fi'
import _ from 'lodash'

import { Container, RoleSelectorSection, CheckboxWrapper, ButtonWrapper, RoleText, PrivacyWrapper } from './styled'
import { Title, Text } from 'views/screens/Voluntariado/styled'
import HtmlRender from 'views/components/HtmlRender'
import TextInput from 'views/components/TextInput'
import Checkbox from 'views/components/Checkbox'
import { ButtonWithIcon, ButtonIcon, SuccessFeedbackButton } from 'views/components/UI/Buttons'
import TextArea from 'views/components/UI/TextArea'
import LoadingButtonAnimation from 'views/components/LoadingButtonAnimation'
import LearnMoreTerms from 'views/components/LearnMoreTerms'
import PrivacyTerms from 'views/components/PrivacyTerms'

type Props = {
  layout: SubscribeSectionType
  errors: FormErrorValues
  values: FormValues
  handleChange: (e: string | ChangeEvent) => void
  handleSubmit: () => void
  setFieldValue: (field: string, value: boolean | string | Array<string>) => void
  types: Array<VolunteerType>
  loading?: boolean
  success?: boolean
}

const SubscribeSection: FC<Props> = (props: Props) => {
  const { layout, errors, values, handleChange, types = [], setFieldValue, handleSubmit, loading, success } = props
  const themeContext = useContext(ThemeContext)
  const { colors } = themeContext

  const onHandleCheck = () => {
    setFieldValue('checked', !values.checked)
  }

  const onHandleCheckPrivacy = () => {
    setFieldValue('privacy_checked', !values.checked)
  }

  const renderRadioButton = (volunteerType: VolunteerType) => {
    const onClick = () => {
      if (values.volunteer_type.includes(volunteerType.name)) {
        const newValue = _.clone(values.volunteer_type)
        _.remove(newValue, (value) => value === volunteerType.name)
        setFieldValue('volunteer_type', newValue)
      } else {
        setFieldValue('volunteer_type', [...values.volunteer_type, volunteerType.name])
      }
    }
    let errorMessage = undefined
    if (errors.volunteer_type && errors.volunteer_type.length > 0) {
      errorMessage = errors?.volunteer_type[0]
    }
    return (
      <Checkbox
        onChange={onClick}
        key={volunteerType.id}
        error={errorMessage}
        label={volunteerType?.label ? volunteerType?.label : volunteerType?.name}
        value={values.volunteer_type.includes(volunteerType.name)}
      />
    )
  }

  const renderButton = () => {
    if (success) {
      return (
        <SuccessFeedbackButton>
          <FiCheck />
        </SuccessFeedbackButton>
      )
    }
    if (loading) {
      return (
        <ButtonWithIcon mobileFullWidth>
          <LoadingButtonAnimation />
        </ButtonWithIcon>
      )
    }
    return (
      <ButtonWithIcon color={colors.dark} textColor={colors.white} onClick={handleSubmit} mobileFullWidth>
        {layout.button}
        <ButtonIcon />
      </ButtonWithIcon>
    )
  }

  return (
    <Container>
      <Title>{layout.title}</Title>
      <Text>
        <HtmlRender>{layout.description}</HtmlRender>
      </Text>
      <TextInput
        placeholder={layout.name?.label}
        name="name"
        value={values.name}
        error={errors.name}
        onChange={handleChange}
        icon={FiUser}
      />
      <TextInput
        placeholder={layout.email?.label}
        name="email"
        value={values.email}
        error={errors.email}
        onChange={handleChange}
        icon={FiMail}
      />
      <TextInput
        placeholder={layout.confirm_email?.label}
        name="confirm_email"
        value={values.confirm_email}
        error={errors.confirm_email}
        onChange={handleChange}
        icon={FiMail}
      />
      <TextInput
        placeholder={layout.phone?.label}
        name="phone"
        value={values.phone}
        onChange={handleChange}
        icon={FiSmartphone}
      />
      <TextInput
        placeholder={layout.country?.label}
        name="country"
        value={values.country}
        error={errors.country}
        onChange={handleChange}
        icon={FiGlobe}
      />
      <RoleSelectorSection>
        <RoleText>{layout.role_text}</RoleText>
        {types?.map(renderRadioButton)}
      </RoleSelectorSection>
      <TextArea
        placeholder={layout.message?.label}
        rows={5}
        name="message"
        value={values.message}
        onChange={handleChange}
      />
      <CheckboxWrapper>
        <PrivacyWrapper>
          <PrivacyTerms
            error={errors.privacy_checked}
            checked={values.privacy_checked}
            handleCheckbox={onHandleCheckPrivacy}
          />
        </PrivacyWrapper>
        <Checkbox
          value={values.checked}
          error={errors.checked}
          onChange={onHandleCheck}
          label={layout.terms}
          link={layout?.terms_link}
        />
        <LearnMoreTerms />
      </CheckboxWrapper>
      <ButtonWrapper>{renderButton()}</ButtonWrapper>
    </Container>
  )
}

export default SubscribeSection
