import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { IProfileLayout } from './../models'
import * as TYPES from './../actionTypes'

export const initialState: IProfileLayout = fromJS({
  loading: false,
  title: '',
  description: '',
  message: '',
  button: '',
  path: {},
  max_char_message: {},
  about_section: {},
  history_section: {},
})

const profileLayoutReducer: Reducer<IProfileLayout> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET:
      return state.merge(action.payload)
    case TYPES.CONTENT.TRIGGER:
      return state.set('loading', true)
    case TYPES.CONTENT.FULFILL:
      return state.set('loading', false)
    case TYPES.CONTENT.FAILURE:
      return state.set('error', action.payload)
    case TYPES.CONTENT.SUCCESS:
      return state.merge(action.payload)
    default:
      return state
  }
}

export default profileLayoutReducer
