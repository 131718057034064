import React, { FC } from 'react'
import { Collaborate } from 'ducks/colaboraLayout'

import { Container, Header, Content, CardBig, CardMedium } from './styled'
import { Subtitle } from '../styled'
import CollaborateCard from './CollaborateCard'

type Props = {
  layout?: Collaborate
}

const CollaborateSection: FC<Props> = (props: Props) => {
  const { layout } = props

  return (
    <Container>
      <Header>
        <Subtitle>{layout?.title}</Subtitle>
      </Header>
      <Content>
        <CardMedium>
          <CollaborateCard layout={layout?.donation} />
        </CardMedium>
        <CardMedium>
          <CollaborateCard layout={layout?.follow} />
        </CardMedium>
        <CardBig>
          <CollaborateCard layout={layout?.responsibility} />
        </CardBig>
      </Content>
    </Container>
  )
}

export default CollaborateSection
