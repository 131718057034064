import styled from 'styled-components'

export const Container = styled.div`
  width: 40%;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  padding-right: 1rem;
  border-right: 1px solid black;
  @media screen and (min-width: 1600px) {
    padding-right: 4rem;
    width: 35%;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    border-right: unset;
    padding-right: 0;
  }
`
