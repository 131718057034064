import styled from 'styled-components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Link } from 'react-router-dom'

type ColorProps = {
  color?: string
}

export const Container = styled.div<ColorProps>`
  width: 100%;
  padding: 20px 20px 30px;
  background-color: ${({ color }) => (color ? color : '#94d5dc')};
  position: relative;
  height: 48%;
  @media screen and (max-width: 600px) {
    height: 100%;
    width: 48%;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    margin-bottom: 20px;
  }
`

export const Title = styled.p<ColorProps>`
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0.07px;
  color: ${({ theme, color }) => (color ? color : theme.colors.dark)};
  margin-bottom: 20px;
`

export const Subtitle = styled.p`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  margin: 0;
  color: ${({ theme }) => theme.colors.dark};
`

export const Description = styled.div`
  margin: 0;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.dark};
`

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: -5%;
  right: 10%;
`

export const ButtonIcon = styled(ChevronRightIcon)`
  font-size: 14px;
`

export const LinkButton = styled(Link)`
  background-color: ${(props) => props.theme.colors.dark};
  cursor: pointer;
  color: #fff;
  width: 240px;
  font-size: 14px;
  letter-spacing: 0.3px;
  padding: 8px 10px 8px 20px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  ${ButtonIcon} {
    color: ${(props) => props.color};
    font-size: 13px;
  }
  @media screen and (max-width: 768px) {
    min-width: unset;
    padding: 6px 0 6px 0;
  }
  @media screen and (min-width: 1600px) {
    width: 280px;
  }
`
