import styled from 'styled-components'
import { background, BackgroundProps, color, ColorProps, BorderRadiusProps, borderRadius } from 'styled-system'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
  @media screen and (max-width: 1250px) {
    flex-direction: column;
  }
`

export const BackgroundImage = styled.div<BackgroundProps>`
  position: relative;
  width: 55%;
  height: 640px;
  min-width: 300px;
  padding: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  ${background};
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (max-width: 1250px) {
    width: 100%;
    height: 320px;
    margin-bottom: 10px;
    padding: 25px;
    justify-content: center;
  }
`

interface ButtonWithIconType extends ColorProps, BorderRadiusProps {
  bold?: boolean
  uppercase?: boolean
}

export const ButtonWithIcon = styled.div<ButtonWithIconType>`
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 15px;
  padding: 10px 20px;
  margin: 0 12px;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
  ${color}
  ${borderRadius}
  & > .icon {
    margin-right: 8px;
  }
`

export const CardContainer = styled.div`
  width: 50%;
  z-index: 1;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1250px) {
    width: 100%;
    position: relative;
  }
`

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 31px 46px;
  height: 550px;
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  -moz-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  border-radius: 4px;
  @media screen and (max-width: 1250px) {
    height: unset;
    padding: 20px 10px;
  }
`

export const CardHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const HeaderLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const CardTitle = styled.p`
  margin: 0;
  margin-top: 32px;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 1250px) {
    font-size: 18px;
  }
`

export const CardBody = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.dark};
`

export const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
`

export const CardFooterHeader = styled.div`
  margin-bottom: 24px;
`

export const FooterTitle = styled.p`
  margin: 0;
  text-transform: uppercase;
  font-size: 16px;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.09px;
`

type FooterContentRowProps = {
  noPaddingTop?: boolean
}

export const FooterContentRow = styled.div<FooterContentRowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: ${({ noPaddingTop }) => (noPaddingTop ? '0 0 16px' : '16px 0')};
  width: 100%;
`

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const LinkIcon = styled(VisibilityIcon)`
  color: ${({ theme }) => theme.colors.dark};
  margin-right: 8px;
`

export const LinkText = styled(Link)`
  font-size: 14px;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.blue};
`

export const ExternalLinkText = styled.a`
  font-size: 14px;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.blue};
  margin-right: 10px;
`

export const LinkLabel = styled.p`
  margin: 0;
  margin-right: 8px;
  font-size: 16px;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.default};
`

export const ButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: relative;
`

export const ShareButton = styled.div`
  height: 32px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.dark};
  cursor: pointer;
  -webkit-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  -moz-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
`

export const PlusButton = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.dark};
  cursor: pointer;
  -webkit-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  -moz-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin-right: 20px;
  &:after {
    content: '+';
  }
`

export const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
`
