import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 4rem;
  @media screen and (max-width: 1024px) {
    padding: 0 2rem;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`

export const Description = styled.div`
  margin-top: 1rem;
  @media screen and (max-width: 450px) {
    padding: 0 1rem;
  }
`

export const Image = styled.div<BackgroundProps>`
  width: 100%;
  height: 500px;
  ${background};
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  @media screen and (min-width: 1600px) {
    height: 700px;
  }
  @media screen and (max-width: 768px) {
    height: 450px;
  }
`
