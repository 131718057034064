import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

export const CardWrapper = styled.div`
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const CardBody = styled.div`
  position: relative;
  padding: 2rem 4rem 3rem;
  width: 80%;
  @media screen and (min-width: 1600px) {
    padding: 3rem 5rem 4rem;
  }
  @media screen and (max-width: 1024px) {
    padding: 30px 20px 50px 60px;
  }
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    padding: 1rem;
    padding-bottom: 40px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  padding-left: 4rem;
  transform: translateY(30px);
  padding-right: 30px;
  @media screen and (max-width: 1024px) {
    padding-left: 2rem;
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    padding: 1rem;
    transform: translateY(0);
    margin-top: 30px;
  }
`

export const CardTitle = styled.p`
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 39px;
  }
`

export const CardDescription = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 20px;
    line-height: 25px;
  }
  @media screen and (max-width: 450px) {
    font-size: 13px;
  }
`

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: -5%;
  right: 10%;
  @media screen and (min-width: 1600px) {
    bottom: -10%;
  }
  @media screen and (max-width: 768px) {
    bottom: -5%;
  }
  @media screen and (max-width: 450px) {
    width: 90%;
    right: 0;
    align-self: center;
    right: 5%;
    bottom: -10%;
  }
`

export const Description = styled.div`
  margin-bottom: 24px;
`

export const SectionTitle = styled.p`
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 34px;
    line-height: 35px;
  }
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 30px;
  }
  @media screen and (max-width: 450px) {
    font-size: 20px;
    line-height: 20px;
  }
`
