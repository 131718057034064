import { ProtagonistaLayout, ProtagonistaLayoutResponse } from 'ducks/protagonistaLayout'

export const parseLayoutContent = ({
  about,
  history,
  recommendation,
}: ProtagonistaLayoutResponse): ProtagonistaLayout => ({
  about: {
    ...about,
    socials: about.Socials,
  },
  history,
  recommendation,
})
