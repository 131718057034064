import styled from 'styled-components'

export const Container = styled.div`
  margin: 2rem 0;
  width: 100%;
  display: flex;
  border: 3px ${({ theme }) => theme.colors.wood} solid;
  padding: 2rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (max-width: 768px) {
    margin: 12px;
    padding: 1rem 0.8rem;
    justify-content: space-between;
  }
  @media screen and (max-width: 1250px) {
    margin: 12px;
    padding: 1rem 0.5rem;
    justify-content: space-between;
  }
`

export const Element = styled.div`
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  align-items: center;
  &::before {
    content: '';
    width: 20px;
    height: 20px;
    margin-right: 1vw;
    background-color: ${({ theme }) => theme.colors.wood};
    border-radius: 50%;
  }
  &:nth-child(1)::before {
    content: '';
    width: 0px;
    height: 0px;
    margin: 0;
  }
  @media screen and (max-width: 768px) {
    &::before {
      width: 0px;
      height: 0px;
      margin: 0;
    }
    margin: 5px 10px;
  }
`

export const Value = styled.div`
  font-size: 2.8rem;
  margin-right: 1rem;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    font-weight: bold;
  }
`

export const Title = styled.div`
  font-size: 1.5rem;
  @media screen and (max-width: 768px) {
    font-size: 0.7rem;
    font-weight: 600;
  }
`

export const Subtitle = styled.div`
  @media screen and (max-width: 768px) {
    font-size: 0.7rem;
    font-weight: 600;
    font-style: italic;
  }
`
