import axios from 'axios'
import { TienditaResponse } from 'ducks/tienditaLayout'
import { TranslatedRequest } from 'lib/axios/models'

export const getTienditaLayout = async ({ language }: TranslatedRequest): Promise<TienditaResponse | undefined> => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        tiendita {
          title: title_${language}
          description: description_${language}
          image_right {
            url
          }
          path {
            description:description_${language}
            sections {
              link
              text: text_${language}
            }
          }
          cover_page_left {
            url
          }
          cover_page_right {
            url
          }
          subscribe_text: subscribe_text_${language}
          subscribe_placeholder: subscribe_placeholder_${language}
          subscribe_button: subscribe_button_${language}
          subscribe_error: subscribe_error_${language}
          subscribe_feedback_color
          image_left {
            url
          }
          sentence_right: sentence_right_${language}
          city_background {
            url
          }
          sentence_bottom: sentence_bottom_${language}
        }
      }
      `,
    })
    return data
  } catch (error) {
    return
  }
}
