import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 450px) {
    padding: 2rem 1rem;
  }
`

export const ContainerCreatePost = styled.div`
  width: 25%;
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`

export const ContainerFeatured = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

type FeaturedsProps = {
  width?: string
}

export const ContainerFeatureds = styled.div<FeaturedsProps>`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
  width: ${({ width }) => width || '20%'};
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const ContainerFilter = styled.div`
  grid-area: filter;
  @media screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }
`

export const ContainerHighlighted = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  padding-top: 30px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 2rem;
  }
`

type MotivatedProps = {
  align?: string
}

export const ContainerMotivated = styled.div<MotivatedProps>`
  width: 100%;
  margin: 4rem 0;
  display: flex;
  justify-content: space-between;
  align-items: ${({ align }) => align || 'flex-end'};
  @media screen and (max-width: 1024px) {
    margin: 2rem 0 4rem;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const ContainerMotivatedPosts = styled.div`
  width: 71%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 25px;
  @media screen and (min-width: 1600px) {
    grid-column-gap: 50px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
`

export const ContainerPath = styled.div`
  padding: 0 2rem;
`

type PostsProps = {
  filterInUse?: boolean
}

export const ContainerPosts = styled.div<PostsProps>`
  padding: 4rem 0 4rem;
  display: grid;
  grid-template-columns: 0.55fr 1fr 1fr;
  grid-column-gap: 5%;
  grid-template-areas:
    '${({ filterInUse }) => (filterInUse ? 'top-content top-content filter' : 'filter top-content top-content')}'
    'content content content';
  @media screen and (max-width: 1024px) {
    grid-template-columns: 0.7fr 1fr 1fr;
    grid-column-gap: 1rem;
  }
  @media screen and (max-width: 768px) {
    grid-template-areas:
      'filter filter filter'
      'top-content top-content top-content'
      'content content content';
  }
  @media screen and (max-width: 450px) {
    padding: 2rem 0 2rem;
  }
`

export const ContainerPostsResults = styled.div`
  padding-top: 2rem;
  padding-right: 4rem;
  grid-area: content;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 50px;
  grid-column-gap: 5%;
  @media screen and (max-width: 768px) {
    grid-column-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
    grid-row-gap: 1rem;
  }
  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`

export const ContainerTopResults = styled.div<PostsProps>`
  grid-area: top-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10%;
  padding: ${({ filterInUse }) => (filterInUse ? '0' : '0 4rem')};
  @media screen and (max-width: 768px) {
    grid-column-gap: 1rem;
    padding: 0;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`

export const CreateCard = styled.div`
  grid-area: filter;
  background: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.white};
  padding: 2rem 2rem 4rem;
  position: relative;
  @media screen and (min-width: 1600px) {
    padding: 2rem 3rem 4rem;
  }
`

export const GreenCreateCard = styled.div`
  grid-area: filter;
  background: ${({ theme }) => theme.colors.defaultCardBackground};
  color: ${({ theme }) => theme.colors.black};
  padding: 2rem 10rem 4rem 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 1600px) {
    padding: 4rem 10rem 4rem 4rem;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 3rem;
  }
  @media screen and (max-width: 450px) {
    padding: 2rem 4rem 4rem 2rem;
  }
`

export const CreateCardButton = styled.div`
  position: absolute;
  bottom: -5%;
  right: 5%;
  @media screen and (min-width: 1600px) {
    bottom: -10%;
  }
  @media screen and (max-width: 1024px) {
    bottom: -3%;
  }
  @media screen and (max-width: 768px) {
    bottom: -5%;
  }
  @media screen and (max-width: 450px) {
    width: 90%;
  }
`

export const GreenCreateCardButton = styled.div`
  position: absolute;
  bottom: -5%;
  right: 5%;
  @media screen and (max-width: 1024px) {
    bottom: -3%;
  }
  @media screen and (max-width: 768px) {
    bottom: -5%;
  }
  @media screen and (max-width: 450px) {
    width: 90%;
  }
`

export const CreateCardMessage = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: inherit;
  @media screen and (min-width: 1600px) {
    font-size: 19px;
    line-height: 24px;
  }
`

export const CreateCardTitle = styled.p`
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 40px;
  line-height: 41px;
  color: inherit;
  @media screen and (min-width: 1600px) {
    font-size: 48px;
    line-height: 49px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 28px;
    line-height: 30px;
  }
`

export const CreateContent = styled.div`
  padding: 1rem 2rem 4rem;
  @media screen and (min-width: 1600px) {
    padding: 1rem 3rem 4rem;
  }
  @media screen and (max-width: 450px) {
    padding: 2rem 3rem 3rem;
  }
`

export const CreateTitle = styled.p`
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.07px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 34px;
    line-height: 39px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 20px;
    line-height: 25px;
  }
`

export const CreateSubtitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 20px;
    line-height: 32px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const FilterInUseContainer = styled.div`
  margin-bottom: 20px;
  padding-left: 25px;
  width: 75%;
  display: flex;
  align-items: center;
  align-self: flex-end;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const MotivatedTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const MotivatedTitle = styled.p`
  font-family: PT Serif Caption;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.dark};
  margin: 0 30px;
  @media screen and (max-width: 450px) {
    margin: 0 10px;
  }
`

export const MotivatedSeparator = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.black};
`
