import { SagaIterator } from '@redux-saga/core'
import { all, put, takeEvery, call } from 'redux-saga/effects'
import * as TYPES from '../actionTypes'
import getHomeContent from 'services/home/getHomeContent'
import { fromJS } from 'immutable'
import { Action } from 'lib/redux/models'
import { SET_LOADING } from 'ducks/loading'

function* loadHomeContent(action: Action) {
  yield put(SET_LOADING.trigger())
  try {
    const homeContent = yield call(getHomeContent, action?.payload)
    if (homeContent) {
      yield put(TYPES.CONTENT.success(fromJS(homeContent)))
    } else {
      yield put(TYPES.CONTENT.success('something went wrong'))
    }
  } catch (err) {
    yield put(TYPES.CONTENT.failure(err.message))
  } finally {
    yield put(SET_LOADING.fulfill())
  }
}

export function* homeSagas(): SagaIterator {
  yield all([takeEvery(TYPES.CONTENT.TRIGGER, loadHomeContent)])
}
