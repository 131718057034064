import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

type CardProps = {
  bottomCard?: boolean
}

export const CardWrapper = styled.div<CardProps>`
  width: 100%;
  min-height: 515px;
  margin-top: ${({ bottomCard }) => (bottomCard ? '0' : '1rem')};
  min-height: ${({ bottomCard }) => (bottomCard ? 'unset' : '515px')};
  @media screen and (min-width: 1600px) {
    min-height: ${({ bottomCard }) => (bottomCard ? 'unset' : '750px')};
  }
  @media screen and (max-width: 768px) {
    min-height: unset;
  }
`

export const CardBody = styled.div<CardProps>`
  padding: ${({ bottomCard }) => (bottomCard ? '2rem' : '4rem 3rem 4rem 6rem')};
  @media screen and (min-width: 1600px) {
    padding: ${({ bottomCard }) => (bottomCard ? '3rem' : '4rem 4rem 4rem 8rem')};
  }
  @media screen and (max-width: 1024px) {
    padding: ${({ bottomCard }) => (bottomCard ? '2rem' : '2rem 2rem 2rem 4rem')};
  }
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
  @media screen and (max-width: 450px) {
    padding: 1rem;
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`

export const FirstContent = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  margin-bottom: 3rem;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    margin-bottom: 2rem;
  }
`

export const FirstLeftImage = styled.div<BackgroundProps>`
  ${background};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  width: 50%;
  position: absolute;
  z-index: 1;
  left: 0;
  height: 600px;
  @media screen and (min-width: 1600px) {
    height: 850px;
  }
  @media screen and (max-width: 1024px) {
    height: 450px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    position: unset;
    height: 700px;
  }
  @media screen and (max-width: 450px) {
    height: 350px;
  }
`

export const FirstRightContent = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
export const ImageLabel = styled.p`
  font-family: PT Serif Caption;
  font-style: italic;
  letter-spacing: 1.245px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: 10px;
  @media screen and (min-width: 1600px) {
    font-size: 18px;
    line-height: 32px;
  }
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 20px;
  }
`

export const Sentence = styled.p`
  font-weight: bold;
  width: 80%;
  font-size: 50px;
  line-height: 59px;
  text-align: right;
  letter-spacing: 0.07px;
  margin-top: 2rem;
  align-self: flex-end;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 60px;
    line-height: 69px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 36px;
    line-height: 44px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 450px) {
    font-size: 30px;
    line-height: 33px;
  }
`

export const SecondContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

export const SecondLeftContent = styled.div`
  width: 50%;
  padding-right: 3rem;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-right: 0;
  }
`

export const SecondRightImage = styled.div<BackgroundProps>`
  ${background};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  width: 50%;
  height: 500px;
  @media screen and (min-width: 1600px) {
    height: 700px;
  }
  @media screen and (max-width: 1024px) {
    height: 350px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 500px;
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 450px) {
    height: 250px;
  }
`
