import axios from 'axios'
import { Movimientos } from 'ducks/movimientos'
import { parseContent, parseFeaturedContent } from './parser'
import { TranslatedRequest } from 'lib/axios/models'

interface MovimientosRequest extends TranslatedRequest {
  countries: Array<number>
  categories: Array<number>
  language: string
}

export const movimientos = async ({
  countries,
  categories,
  language,
}: MovimientosRequest): Promise<Movimientos | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        groups(sort: "id:DESC", where: {
          featured: false,
          enabled: true,
          country: {
            id: [${countries.join(', ')}]
          },
          categories: {
            id: [${categories.join(', ')}]
          }
        }) {
          id
          name
          codename
          description: description_${language}
          card_description: card_description_${language}
          image {
            url
          }
          country {
            shortname
            image {
              url
            }
          }
          categories {
            name: name_${language}
            overlay
            Badge {
              text
              background
            }
          }
          ordered_categories {
            label
            eje {
              name: name_${language}
              overlay
              Badge {
                text
                background
              }
            }
          }
          protagonistas {
            name
            nickname
            id
            username
          }
        }
      }
      `,
    })
    return parseContent(data)
  } catch (error) {
    return
  }
}

export const getFeaturedMovimientos = async ({
  countries,
  categories,
  language,
}: MovimientosRequest): Promise<Movimientos | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        groups(sort: "id:DESC", where: {
          featured: true,
          enabled: true,
          country: {
            id: [${countries.join(', ')}]
          },
          categories: {
            id: [${categories.join(', ')}]
          }
        }) {
          id
          name
          codename
          description: description_${language}
          card_description: card_description_${language}
          image {
            url
          }
          country {
            shortname
            image {
              url
            }
          }
          categories {
            name: name_${language}
            overlay
            Badge {
              text
              background
            }
          }
          ordered_categories {
            label
            eje {
              name: name_${language}
              overlay
              Badge {
                text
                background
              }
            }
          }
          protagonistas {
            name
            nickname
            id
            username
          }
        }
      }
      `,
    })
    return parseFeaturedContent(data)
  } catch (error) {
    return
  }
}

export default movimientos
