import styled from 'styled-components'

type TextareaProps = {
  error?: string
  fullHeight?: boolean
}

const TextArea = styled.textarea<TextareaProps>`
  width: 100%;
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.error : theme.colors.inputBorder)};
  color: ${({ theme }) => theme.colors.default};
  font-size: 14px;
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'unset')};
  padding: 15px;
  font-family: Nunito Sans;
  border-radius: 4px;
`

export default TextArea
