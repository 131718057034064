import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { IRegisterLayout } from './../models'
import * as TYPES from './../actionTypes'

export const initialState: IRegisterLayout = fromJS({
  loading: false,
  title: '',
  button: '',
  link: '',
  link_label: '',
  link_description: '',
})

const registerLayoutReducer: Reducer<IRegisterLayout> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET:
      return state.merge(action.payload)
    case TYPES.CONTENT.TRIGGER:
      return state.set('loading', true)
    case TYPES.CONTENT.FULFILL:
      return state.set('loading', false)
    case TYPES.CONTENT.FAILURE:
      return state.set('error', action.payload)
    case TYPES.CONTENT.SUCCESS:
      return state.merge(action.payload)
    default:
      return state
  }
}

export default registerLayoutReducer
