import React, { FC } from 'react'
import { Container, Image, Title, Subtitle, Description } from './styled'
import { Block } from 'views/components/UI/Contents'
import { VerticalButtonWitchIcon, Icon } from 'views/components/UI/Buttons'
import HtmlRender from 'views/components/HtmlRender'
import { useHistory } from 'react-router-dom'

interface Props {
  image: string
  title: string
  subtitle: string
  description: string
  right_button: string
  right_link: string
}

const FlatArticleMobile: FC<Props> = ({ title, subtitle, image = '', description, right_button, right_link }) => {
  const history = useHistory()

  const onClick = () => {
    history.push(right_link)
  }

  return (
    <Container>
      <Block width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Image background={`url(${image})`} />
        <Block width="45%" px="1rem">
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Block>
      </Block>
      <Block display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
        <Description>
          <HtmlRender>{description}</HtmlRender>
        </Description>
        <Block position="relative" top="50px">
          {right_link && (
            <VerticalButtonWitchIcon color="white" bg="#727272" onClick={onClick}>
              <p>
                {right_button}
                <Icon>&gt;</Icon>
              </p>
            </VerticalButtonWitchIcon>
          )}
        </Block>
      </Block>
    </Container>
  )
}

export default FlatArticleMobile
