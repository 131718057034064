import React, { FC } from 'react'
import {
  Container,
  LeftContainer,
  LeftTitle,
  LeftContent,
  LeftSubTitle,
  LeftDescription,
  Banner,
  RightContainer,
  LinkImage,
  RightImage,
  RightContent,
  RightTitle,
  RightDescription,
} from './styled'
import { Featured } from 'ducks/home'
import { LinkButton, ButtonIcon } from 'views/components/UI/Buttons'
import { Block } from 'views/components/UI/Contents'
import HtmlRender from 'views/components/HtmlRender'

const DualFeaturedSection: FC<Featured> = ({
  left_title,
  left_subtitle,
  left_link,
  left_description,
  left_image = '',
  right_image = '',
  right_description,
  right_title,
  right_link,
  button_mobile,
}) => {
  return (
    <Container>
      <LeftContainer>
        <Banner backgroundImage={`url(${left_image})`} backgroundSize="cover" backgroundPosition="center">
          <LeftContent>
            <LeftSubTitle>{left_subtitle}</LeftSubTitle>
            <LeftTitle>{left_title}</LeftTitle>
          </LeftContent>
        </Banner>
        <LeftDescription>
          <HtmlRender>{left_description}</HtmlRender>
        </LeftDescription>
        <Block bg="#fff" mx="12px" borderBottom="1px solid #333" display="flex" justifyContent="flex-end">
          {left_link && (
            <LinkButton to={left_link} bg="transparent" color="rgba(0, 0, 0, 0.87)">
              {button_mobile}
              <ButtonIcon />
            </LinkButton>
          )}
        </Block>
      </LeftContainer>
      <RightContainer>
        {right_link ? (
          <LinkImage to={right_link}>
            <RightImage background={`url(${right_image})`} />
          </LinkImage>
        ) : (
          <RightImage background={`url(${right_image})`} />
        )}
        <RightContent>
          <RightTitle>{right_title}</RightTitle>
          <RightDescription>
            <HtmlRender>{right_description}</HtmlRender>
          </RightDescription>
        </RightContent>
      </RightContainer>
    </Container>
  )
}

export default DualFeaturedSection
