import styled from 'styled-components'
import { Link } from 'react-router-dom'

type ContainerProps = {
  size?: string
  margin?: string
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: ${({ size }) => (size ? size : '48%')};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  background-position-x: center;
  background-position-y: center;
  -webkit-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  -moz-box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  box-shadow: 0.5px 2px 4px -1px ${({ theme }) => theme.colors.boxShadow};
  margin-bottom: 25px;
  margin: ${({ margin }) => (margin ? margin : '')};
  @media (max-width: 1120px) {
    width: 48%;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`
type ImageProps = {
  url: string | undefined
}

export const Image = styled.div<ImageProps>`
  width: 100%;
  height: 240px;
  opacity: 0.87;
  background: url(${(props) => props.url}),
    linear-gradient(to bottom, ${(props) => props.color}, ${(props) => props.color});
  background-blend-mode: luminosity;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  @media (max-width: 1024px) {
    width: 100%;
    height: 300px;
  }
  @media (max-width: 475px) {
    width: 100%;
    height: 200px;
  }
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  @media (max-width: 475px) {
    width: 100%;
  }
`

export const Title = styled.p`
  margin: 12px 0;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.dark};
  text-transform: uppercase;
`

export const Country = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  letter-spacing: 0.3px;
`

export const CountryFlag = styled.img`
  width: 20px;
  height: 17px;
  margin-right: 10px;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`

export const Label = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.black};
`

export const ProtagonistaLink = styled(Link)`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.blue};
  margin-right: 10px;
  text-transform: capitalize;
`

export const MoreProtagonistasText = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.blue};
  margin: 0;
`

export const ProtagonistasContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 55%;
  @media screen and (max-width: 450px) {
    margin-bottom: 30px;
  }
`

export const ButtonWrapper = styled.div`
  width: auto;
  @media screen and (max-width: 450px) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`

export const Categories = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
`

export const Description = styled.div`
  margin: 0;
  margin-bottom: 40px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.black};
`
