import { LoginSection as LoginSectionType } from 'ducks/loginLayout'
import React, { ChangeEvent, FC } from 'react'
import { FormValues, ErrorsValues } from 'views/screens/Login'

import { Container, ForgotPasswordLink, ButtonWrapper } from './styled'
import { Title, Subtitle } from 'views/screens/Login/styled'
import TextInput from 'views/components/TextInput'
import { ButtonIcon, ButtonWithIcon } from 'views/components/UI/Buttons'

type Props = {
  layout: LoginSectionType
  errors: ErrorsValues
  values: FormValues
  handleChange: (e: string | ChangeEvent<HTMLInputElement>) => void
  handleSubmit: (values: FormValues) => void
}

const LoginSection: FC<Props> = (props: Props) => {
  const { layout, errors, values, handleChange, handleSubmit } = props

  const onSubmit = () => {
    handleSubmit(values)
  }

  return (
    <Container>
      <Subtitle>{layout.subtitle}</Subtitle>
      <Title>{layout.title}</Title>
      <TextInput
        name="email"
        error={errors.email}
        value={values.email}
        onChange={handleChange}
        placeholder={layout.email}
      />
      <TextInput
        name="password"
        error={errors.password}
        value={values.password}
        onChange={handleChange}
        placeholder={layout.password}
        inputType="password"
      />
      <ForgotPasswordLink href="#">{layout.forgot_password}</ForgotPasswordLink>
      <ButtonWrapper>
        <ButtonWithIcon onClick={onSubmit} mobileFullWidth>
          {layout.button}
          <ButtonIcon />
        </ButtonWithIcon>
      </ButtonWrapper>
    </Container>
  )
}

export default LoginSection
