import React, { FC, useState } from 'react'
import {
  Container,
  Card,
  CardHeader,
  CardTitle,
  ContentContainer,
  SliderContainer,
  DescriptionContainer,
  Text,
  TextBox,
  ButtonWithIcon,
  FadeOverlay,
  SectionLine,
} from './styled'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ProtagonistaSlider from 'views/components/ProtagonistaSlider'
import { HistorySection } from 'ducks/protagonistaLayout'
import HtmlRender from 'views/components/HtmlRender'
import { Protagonista } from 'ducks/protagonistas'

type Props = {
  layout: HistorySection
  profile?: Protagonista
}

const History: FC<Props> = ({ layout, profile }: Props) => {
  const [showFullText, setShowFullText] = useState(true)

  return (
    <Container>
      <Card>
        <CardHeader>
          <CardTitle>{layout?.title}</CardTitle>
          <SectionLine />
        </CardHeader>
        <ContentContainer>
          <SliderContainer>
            <ProtagonistaSlider protagonista={profile} />
          </SliderContainer>
          <DescriptionContainer>
            <Text>{layout?.subtitle}:</Text>
            <TextBox>
              <Text>
                <HtmlRender>{profile?.history}</HtmlRender>
              </Text>
              {!showFullText && <FadeOverlay />}
            </TextBox>
            {!showFullText && (
              <ButtonWithIcon onClick={() => setShowFullText(true)}>
                {layout?.read_more} <ExpandMoreIcon className="icon" />
              </ButtonWithIcon>
            )}
          </DescriptionContainer>
        </ContentContainer>
      </Card>
    </Container>
  )
}

export default History
