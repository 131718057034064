import React, { FC } from 'react'
import { Container, Content, Title, Subtitle, Image, MasterContainer, ButtonWrapper } from './styled'
import { Link } from 'ducks/home'
import { Block } from 'views/components/UI/Contents'
import { ButtonIcon, ExternalLinkButton, LinkButton } from 'views/components/UI/Buttons'
import { Record } from 'immutable'

interface Props {
  title: string
  subtitle: string
  background?: string
  image?: string
  link: Record<Link>
  button_mobile?: string
  button?: string
}

const CardTitle: FC<Props> = ({ image = '', background = '', title, link, subtitle, button }) => {
  const renderLinkButton = () => {
    if (link?.get('external')) {
      return (
        <ExternalLinkButton href={link?.get('value')} target="blank">
          {button}
          <ButtonIcon />
        </ExternalLinkButton>
      )
    } else {
      return (
        <LinkButton to={link?.get('value')}>
          {button}
          <ButtonIcon />
        </LinkButton>
      )
    }
  }

  return (
    <MasterContainer>
      <Block position="relative" width="1px" height="500px" backgroundColor="dark" bottom="-500px" left={0}>
        <span />
      </Block>
      <Container backgroundImage={`url(${background})`}>
        <Content>
          <Title>{title}</Title>
          <Block display="flex" alignItems="center">
            <Image src={image} />
            <Subtitle>{subtitle}</Subtitle>
          </Block>
          {link && <ButtonWrapper>{renderLinkButton()}</ButtonWrapper>}
        </Content>
      </Container>
    </MasterContainer>
  )
}

export default CardTitle
