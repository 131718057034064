import React, { FC, useState, useEffect } from 'react'
import HtmlRender from 'views/components/HtmlRender'
import { Container, Title, Header, SectionLine, ImageContainer, LeftImage, RightImage, Description } from './styled'

type Image = {
  url: string
}

type Props = {
  title?: string
  section?: {
    description: string
    left_image?: Image
    right_image?: Image
  }
}

const FirstSection: FC<Props> = (props: Props) => {
  const { title, section } = props
  const [haveImages, setHaveImages] = useState(false)

  useEffect(() => {
    if (section?.left_image?.url || section?.right_image?.url) {
      setHaveImages(true)
    } else {
      setHaveImages(false)
    }
  }, [section])

  return (
    <Container haveImages={haveImages}>
      <Header>
        <Title>{title}</Title>
        <SectionLine />
      </Header>
      <Description>
        <HtmlRender>{section?.description}</HtmlRender>
      </Description>
      {haveImages && (
        <ImageContainer>
          <LeftImage background={`url(${section?.left_image?.url})`} />
          <RightImage background={`url(${section?.right_image?.url})`} />
        </ImageContainer>
      )}
    </Container>
  )
}

export default FirstSection
