import styled from 'styled-components'

export const Container = styled.div`
  margin-left: 26px;
  margin-bottom: 20px;
`

export const Button = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.formLink};
  cursor: pointer;
  align-items: flex-end;
`

export const ButtonText = styled.p`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.09px;
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.formLink};
  cursor: pointer;
`

export const Description = styled.div`
  margin-top: 10px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.default};
`
