import React, { FC, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { protagonistaLayoutSelector, CONTENT as LAYOUT_CONTENT } from 'ducks/protagonistaLayout'
import { CONTENT as PROFILE_LAYOUT_CONTENT, profileLayoutSelector } from 'ducks/profileLayout'
import { GET_PROFILE, CHECK_UPDATE_PROFILE, userSelector } from 'ducks/user'
import { useLanguage } from 'hooks/useLanguage'
import { Category } from 'ducks/categories'
import { Redirect } from 'react-router-dom'

import { Container, Header, Content } from './styled'
import History from './History'
import About from './About'
import PathIndicator from 'views/components/PathIndicator'
import Banner from 'views/components/Banner'
import { BannerContainer, Row } from 'views/components/UI/Contents'
import { OutlineLinkButton } from 'views/components/UI/Buttons'
import HtmlRender from 'views/components/HtmlRender'
import { BannerTitle, BannerText, BannerExternalLink } from 'views/components/UI/Typography'

const Protagonista: FC = () => {
  const dispatch = useDispatch()
  const [overlay, setOverlay] = useState('transparent')
  const protagonistaLayout = useSelector(protagonistaLayoutSelector)
  const profileLayoutState = useSelector(profileLayoutSelector)
  const userState = useSelector(userSelector)
  const { currentLanguage } = useLanguage()
  const userId = userState.get('id')
  const [redirect, setRedirect] = useState(false)
  const [shareLink, setShareLink] = useState('')

  useEffect(() => {
    if (!userState.get('updatedProfile')) {
      dispatch({ type: CHECK_UPDATE_PROFILE, payload: userId })
    }
    dispatch(LAYOUT_CONTENT.trigger({ language: currentLanguage }))
    dispatch(PROFILE_LAYOUT_CONTENT.trigger({ language: currentLanguage }))
  }, [dispatch, currentLanguage, userState, userId])

  useEffect(() => {
    dispatch({
      type: GET_PROFILE,
      payload: {
        language: currentLanguage,
        userId,
      },
    })
  }, [dispatch, userId, currentLanguage])

  useEffect(() => {
    if (!userState.get('profile') && !userState.get('loading')) {
      setRedirect(true)
    } else if (userState.get('profile')) {
      const { origin } = new URL(process.env.PUBLIC_URL, window.location.href)
      const url = `${origin}/protagonista/${userState.getIn(['profile', 'username'])}`
      setShareLink(url)
    }
  }, [userState])

  useEffect(() => {
    const protagonistCategory: Category = userState.getIn(['profile', 'category'])
    setOverlay(protagonistCategory?.Badge.background)
  }, [userState])

  const renderBanner = () => {
    if (userState.get('updatedProfile')) {
      return (
        <Banner visible closeable backgroundColor={profileLayoutState.getIn(['update_feedback', 'background_color'])}>
          <BannerContainer>
            <BannerTitle>{profileLayoutState.getIn(['update_feedback', 'title'])}</BannerTitle>
            <BannerText>
              <HtmlRender>{profileLayoutState.getIn(['update_feedback', 'description'])}</HtmlRender>
            </BannerText>
          </BannerContainer>
        </Banner>
      )
    }
    return (
      <Banner visible closeable backgroundColor={profileLayoutState.getIn(['live_feedback', 'background_color'])}>
        <BannerContainer>
          <BannerTitle>{profileLayoutState.getIn(['live_feedback', 'title'])}</BannerTitle>
          <BannerText>
            <HtmlRender>{profileLayoutState.getIn(['live_feedback', 'description'])}</HtmlRender>
          </BannerText>
          <Row>
            <BannerText>{profileLayoutState.getIn(['live_feedback', 'share'])}</BannerText>
            <BannerExternalLink target="blank" href={shareLink}>
              {shareLink}
            </BannerExternalLink>
          </Row>
        </BannerContainer>
      </Banner>
    )
  }

  return redirect ? (
    <Redirect to="/crear-perfil" />
  ) : (
    <Container backgroundColor={overlay}>
      <Header>
        <PathIndicator
          description={profileLayoutState.getIn(['path', 'description'])}
          sections={profileLayoutState.getIn(['path', 'sections'])}
        />
        <OutlineLinkButton to="/editar-perfil">{profileLayoutState.get('edit')}</OutlineLinkButton>
      </Header>
      {userState.get('profile') && (
        <>
          {renderBanner()}
          <Content>
            <About layout={protagonistaLayout.get('about')} profile={userState.get('profile')} />
            <History layout={protagonistaLayout.get('history')} profile={userState.get('profile')} />
          </Content>
        </>
      )}
    </Container>
  )
}

export default Protagonista
