import React, { FC } from 'react'
import { Container, Title, Subtitle, Description, LinkButton, ButtonIcon, ButtonWrapper } from './styled'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  title: string
  subtitle: string
  description: string
  button: string
  link?: string
  backgroundColor: string
  titleColor: string
}

const Card: FC<Props> = (props: Props) => {
  const { title, subtitle, description, button, link, backgroundColor, titleColor } = props

  return (
    <Container color={backgroundColor}>
      <Title color={titleColor}>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Description>
        <HtmlRender>{description}</HtmlRender>
      </Description>
      {link && (
        <ButtonWrapper>
          <LinkButton to={link}>
            {button} <ButtonIcon />
          </LinkButton>
        </ButtonWrapper>
      )}
    </Container>
  )
}

export default Card
