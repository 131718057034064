import { Volunteer, VolunteerType } from 'ducks/volunteers'
import React, { FC, useState, useEffect } from 'react'

import { Container, Overlay, Card, Wrapper, CardContent, CardTitle, CardText } from './styled'

type Props = {
  currentType?: VolunteerType | null
  volunteer: Volunteer
  highlight?: boolean
  active: boolean
  onClick: (volunteer: Volunteer | null) => void
}

const VolunteerCard: FC<Props> = (props: Props) => {
  const { volunteer, highlight, active, onClick, currentType } = props
  const [color, setColor] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (currentType) {
      const volunteerTypesId = volunteer.types.map((volunteerType: VolunteerType) => volunteerType.id)
      if (volunteerTypesId.includes(currentType.id)) {
        setColor(currentType.color)
      } else {
        setColor('white')
      }
    } else {
      if (volunteer.types.length > 0) {
        setColor(volunteer.types[0].color)
      } else {
        setColor('white')
      }
    }
  }, [volunteer.types, currentType])

  const handleClick = () => {
    onClick(volunteer)
  }

  const handleClickToDeselect = () => {
    onClick(null)
  }

  return (
    <Wrapper>
      <Container highlight={highlight} background={`url(${volunteer.image.url})`} onClick={handleClick}>
        {highlight && <Overlay color={color || 'white'} />}
      </Container>
      {active && (
        <Card background={`url(${volunteer.image.url})`} onClick={handleClickToDeselect}>
          <CardContent backgroundColor={color || 'white'}>
            <CardTitle>{volunteer.name}</CardTitle>
            {volunteer?.label && <CardText>{volunteer?.label}</CardText>}
          </CardContent>
        </Card>
      )}
    </Wrapper>
  )
}

export default VolunteerCard
