import { useLayoutEffect, useState } from 'react'

const useScrollPosition = (): number => {
  const [scrollPosition, setPosition] = useState<number>(0)

  useLayoutEffect(() => {
    const updatePosition = () => {
      setPosition(window.pageYOffset)
    }
    window.addEventListener('scroll', updatePosition, { passive: true })
    updatePosition()
    return () => window.removeEventListener('scroll', updatePosition)
  }, [])

  return scrollPosition
}

export default useScrollPosition
