import React, { FC } from 'react'
import { Container, IconsContainer, SocialLink, Title, TextRow } from './styled'
import { FiFacebook, FiTwitter, FiInstagram, FiYoutube, FiLinkedin } from 'react-icons/fi'
import { FooterColumn as IFooterColumn, SocialNetwork, FooterRow, FooterRowTypes } from 'ducks/layoutData'
import LogoRow from './LogoRow'
import LinkRow from './LinkRow'

type Props = {
  content: IFooterColumn
}

const FooterColumn: FC<Props> = ({ content }: Props) => {
  const _renderIcon = (socialNetwork: SocialNetwork) => {
    switch (socialNetwork.icon) {
      case 'facebook':
        return (
          <SocialLink href={socialNetwork.link} key={socialNetwork.icon} target="blank">
            <FiFacebook />
          </SocialLink>
        )
      case 'twitter':
        return (
          <SocialLink href={socialNetwork.link} key={socialNetwork.icon} target="blank">
            <FiTwitter />
          </SocialLink>
        )
      case 'instagram':
        return (
          <SocialLink href={socialNetwork.link} key={socialNetwork.icon} target="blank">
            <FiInstagram />
          </SocialLink>
        )
      case 'youtube':
        return (
          <SocialLink href={socialNetwork.link} key={socialNetwork.icon} target="blank">
            <FiYoutube />
          </SocialLink>
        )
      case 'linkedin':
        return (
          <SocialLink href={socialNetwork.link} key={socialNetwork.icon} target="blank">
            <FiLinkedin />
          </SocialLink>
        )
      default:
        break
    }
  }

  const renderRow = (row: FooterRow) => {
    switch (row.__typename) {
      case FooterRowTypes.LOGO:
        return <LogoRow key={row.image.url} content={row} />
      case FooterRowTypes.LINK:
        return (
          <LinkRow key={row.label} content={row}>
            {row.label}
          </LinkRow>
        )
      case FooterRowTypes.TEXT:
        return <TextRow key={row.value}>{row.value}</TextRow>
      default:
        return
    }
  }

  return (
    <Container marginTop={content.margin_top}>
      {content.show_title && <Title>{content.title}</Title>}
      {content?.rows?.map((row: FooterRow) => renderRow(row))}
      {content?.socials?.length > 0 && (
        <IconsContainer>{content?.socials?.map((socialNetwork) => _renderIcon(socialNetwork))}</IconsContainer>
      )}
    </Container>
  )
}

export default FooterColumn
