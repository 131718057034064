import React, { FC, useState, useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { FilterProvider } from 'hooks/useFilter'
import { useLanguage } from 'hooks/useLanguage'
import { useMediaQuery } from '@material-ui/core'
import { CONTENT as PROTAGONISTAS_CONTENT } from 'ducks/protagonistas/actionTypes'
import { CONTENT as MOVIMIENTOS_CONTENT } from 'ducks/movimientos/actionTypes'
import useScrollPosition from 'hooks/useScrollPosition'
import { userSelector } from 'ducks/user'
import { useSelector } from 'react-redux'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

import PrivateRoute from 'views/components/PrivateRoute'
import Header from 'views/components/Header'
import HeaderMobile from 'views/components/HeaderMobile'
import { Block } from 'views/components/UI/Contents'
import Footer from 'views/components/Footer'
import Home from 'views/screens/Home'
import Protagonistas from 'views/screens/Protagonistas'
import Protagonista from 'views/screens/Protagonista'
import Movimientos from 'views/screens/Movimientos'
import Movimiento from 'views/screens/Movimiento'
import AboutUs from 'views/screens/AboutUs'
import Contacto from 'views/screens/Contacto'
import useLayoutLoader from 'hooks/useLayoutLoader'
import Page from 'views/screens/Pages'
import Login from 'views/screens/Login'
import Voluntariado from 'views/screens/Voluntariado'
import PostularHome from 'views/screens/PostularHome'
import PostularInviteProtaForm from 'views/screens/PostularInviteProtaForm'
import CreateProfile from 'views/screens/CreateProfile'
import Register from 'views/screens/Register'
import Success from 'views/screens/Success'
import ElCole from 'views/screens/ElCole'
import Tiendita from 'views/screens/Tiendita'
import Profile from 'views/screens/Profile'
import EditProfile from 'views/screens/EditProfile'
import Colabora from 'views/screens/Colabora'
import CookieConsent from 'views/components/CookieConsent'
import BlogHome from 'views/screens/BlogHome'
import BlogPost from 'views/screens/BlogPost'
import { POSTS } from 'ducks/blog'

const MainRouter: FC = () => {
  const { currentLanguage } = useLanguage()
  const layout = useLayoutLoader(currentLanguage)
  const matches = useMediaQuery('(max-width: 1050px)')
  const scrollPosition = useScrollPosition()
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const history = useHistory()
  const userState = useSelector(userSelector)
  const { initializeAnalytics, setCustomDimension, trackNavigation } = useGoogleAnalytics()
  const [showCookieBanner, setShowCookieBanner] = useState(false)

  useEffect(() => {
    const cookieAwareness = localStorage.getItem('cookie_awareness')
    if (cookieAwareness) {
      setShowCookieBanner(false)
    } else {
      setShowCookieBanner(true)
    }
  }, [])

  useEffect(() => {
    // Method to go to the top of page when change route
    history.listen(() => {
      window.scrollTo(0, 0)
    })
    // Recording Pageview for Google Analytics
    history.listen((location) => {
      if (userState.get('email')) {
        setCustomDimension({
          user_id: userState.get('email'),
        })
      }
      if (userState.get('name')) {
        setCustomDimension({
          name: userState.get('name'),
        })
      }
      setCustomDimension({ page: location.pathname })
      trackNavigation(location.pathname)
    })
  }, [history, userState, setCustomDimension, trackNavigation])

  useEffect(() => {
    if (scrollPosition >= 30) {
      setShowMobileMenu(true)
    } else {
      setShowMobileMenu(false)
    }
  }, [scrollPosition])

  const onAcceptCookies = () => {
    initializeAnalytics()
  }

  return (
    <Block minHeight="100%" margin="0" width="100%">
      {showCookieBanner && <CookieConsent onAccept={onAcceptCookies} />}
      {matches || showMobileMenu ? (
        <HeaderMobile
          logo={layout.getIn(['header', 'logo'])}
          title={layout.getIn(['header', 'title'])}
          subtitle={layout.getIn(['header', 'subtitle'])}
          sections={layout.getIn(['header', 'sections'])}
          languages={layout.getIn(['header', 'languages'])}
          store={layout.getIn(['header', 'store'])}
          header_title={layout.getIn(['header', 'header_title'])}
          login_button={layout.getIn(['header', 'login_button'])}
          select_language={layout.getIn(['header', 'select_language'])}
        />
      ) : (
        <Header
          logo={layout.getIn(['header', 'logo'])}
          title={layout.getIn(['header', 'title'])}
          subtitle={layout.getIn(['header', 'subtitle'])}
          sections={layout.getIn(['header', 'sections'])}
          languages={layout.getIn(['header', 'languages'])}
          store={layout.getIn(['header', 'store'])}
          header_title={layout.getIn(['header', 'header_title'])}
          login_button={layout.getIn(['header', 'login_button'])}
          select_language={layout.getIn(['header', 'select_language'])}
        />
      )}
      <Block width="100%" maxWidth="1920px" margin="auto">
        <Switch>
          <Route path="/" exact={true}>
            <Home />
          </Route>
          <Route path="/protagonistas" exact={true}>
            <FilterProvider callback={PROTAGONISTAS_CONTENT.trigger}>
              <Protagonistas />
            </FilterProvider>
          </Route>
          <Route path="/protagonista/:username" exact={true}>
            <Protagonista />
          </Route>
          <Route path="/movimiento/:codename" exact={true}>
            <Movimiento />
          </Route>
          <Route path="/pages/:slug">
            <Page />
          </Route>
          <Route path="/movimientos" exact={true}>
            <FilterProvider callback={MOVIMIENTOS_CONTENT.trigger}>
              <Movimientos />
            </FilterProvider>
          </Route>
          <Route path="/quienes-somos" exact={true}>
            <AboutUs />
          </Route>
          <Route path="/contacto" exact={true}>
            <Contacto />
          </Route>
          <Route path="/login" exact={true}>
            <Login />
          </Route>
          <Route path="/voluntariado" exact={true}>
            <Voluntariado />
          </Route>
          <Route path="/postular" exact={true}>
            <PostularHome />
          </Route>
          <Route path="/postular/suggest-protagonista" exact={true}>
            <PostularInviteProtaForm />
          </Route>
          <Route path="/success" exact={true}>
            <Success />
          </Route>
          <PrivateRoute path="/crear-perfil" exact={true}>
            <CreateProfile />
          </PrivateRoute>
          <PrivateRoute path="/perfil" exact={true}>
            <Profile />
          </PrivateRoute>
          <PrivateRoute path="/editar-perfil" exact={true}>
            <EditProfile />
          </PrivateRoute>
          <Route path="/register" exact={true}>
            <Register />
          </Route>
          <Route path="/el-cole" exact={true}>
            <ElCole />
          </Route>
          <Route path="/tiendita" exact={true}>
            <Tiendita />
          </Route>
          <Route path="/colabora" exact={true}>
            <Colabora />
          </Route>
          <Route path="/blog" exact={true}>
            <FilterProvider callback={POSTS.trigger}>
              <BlogHome />
            </FilterProvider>
          </Route>
          <Route path="/blog/post/:slug" exact={true}>
            <BlogPost />
          </Route>
        </Switch>
      </Block>
      <Footer content={layout.get('footer')} />
    </Block>
  )
}

export default MainRouter
