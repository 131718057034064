import { createRoutine } from 'redux-saga-routines'

export const SET = 'USER/SET'
export const LOGOUT = createRoutine('USER/LOGOUT')
export const LOGIN = createRoutine('USER/LOGIN')
export const REGISTER = createRoutine('USER/REGISTER')
export const SOCIAL_LOGIN = createRoutine('USER/SOCIAL_LOGIN')
export const CHECK_CREATE_PROFILE = 'USER/CHECK_CREATE_PROFILE'
export const CHECK_UPDATE_PROFILE = 'USER/CHECK_UPDATE_PROFILE'
export const GET_PROFILE = 'USER/GET_PROFILE'
export const CLEAR_ERROR = 'USER/CLEAR_ERROR'
export const UPDATE_PROFILE = createRoutine('PROTAGONISTAS/UPDATE_PROFILE')
