import createImmutableSelector from 'lib/immutable/selector'
import { IMovimientosLayout } from './../models'

// TODO: import Store type and add reducer type in Store interface
const getMovimientosLayout = (state: any): IMovimientosLayout => state.get('movimientosLayoutReducer')

export const movimientosLayoutSelector = createImmutableSelector(
  getMovimientosLayout,
  (movimientosLayout) => movimientosLayout
)
