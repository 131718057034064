import styled from 'styled-components'
import { Link } from 'react-router-dom'

type ContainerProps = {
  backgroundColor?: string
  textColor?: string
}

export const Container = styled(Link)<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, backgroundColor }) => (backgroundColor ? backgroundColor : theme.colors.dark)};
  color: ${({ theme, textColor }) => (textColor ? textColor : theme.colors.white)};
  margin: 15px 0;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  padding-right: 40px;
  @media screen and (max-width: 768px) {
    padding-right: 20px;
  }
`

export const ProviderContainer = styled.a<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, backgroundColor }) => (backgroundColor ? backgroundColor : theme.colors.dark)};
  color: ${({ theme, textColor }) => (textColor ? textColor : theme.colors.white)};
  margin: 15px 0;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  padding-right: 40px;
  @media screen and (max-width: 768px) {
    padding-right: 20px;
  }
`

type IconWrapperProps = {
  backgroundColor?: string
}

export const IconWrapper = styled.div<IconWrapperProps>`
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  margin-right: 40px;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'inherit')};
  @media screen and (max-width: 768px) {
    margin-right: 20px;
  }
`
