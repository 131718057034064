import React, { FC, useContext, useEffect, useState } from 'react'
import {
  Container,
  Image,
  Content,
  Title,
  Country,
  CountryFlag,
  Header,
  Footer,
  Label,
  ProtagonistasContainer,
  ProtagonistaLink,
  ButtonWrapper,
  MoreProtagonistasText,
  Categories,
  Description,
} from './styled'
import { ButtonWithIcon, ButtonIcon } from 'views/components/UI/Buttons'
import LineSeparator from 'views/components/UI/LineSeparator'
import Badge from 'views/components/UI/Badge'
import { ThemeContext } from 'styled-components'
import { useSelector } from 'react-redux'
import { movimientosLayoutSelector } from 'ducks/movimientosLayout/selectors'
import { useHistory } from 'react-router-dom'
import { Movimiento, OrderedCategory } from 'ducks/movimientos'
import { Protagonista } from 'ducks/protagonistas'
import { Category } from 'ducks/categories'
import HtmlRender from 'views/components/HtmlRender'
import { Block } from 'views/components/UI/Contents'
import { useMediaQuery } from '@material-ui/core'

type Props = {
  size?: string
  margin?: string
  movimiento: Movimiento
}

const MovimientoCard: FC<Props> = ({ movimiento, size, margin }: Props) => {
  const matches = useMediaQuery('(max-width: 1250px)')
  const themeContext = useContext(ThemeContext)
  const { colors } = themeContext
  const { categories, country, ordered_categories } = movimiento
  const movimientosLayout = useSelector(movimientosLayoutSelector)
  const history = useHistory()
  const [protagonistasToShow, setProtagonistasToShow] = useState<Array<Protagonista>>([])
  const [showMoreMessage, setShowMoreMessage] = useState<boolean>(false)
  const count = movimientosLayout.getIn(['movimientos', 'prota_number_to_show'])
  const [textToDisplay, setTextToDisplay] = useState('')
  const [categoryToDisplay, setCategoryToDisplay] = useState<Category | undefined>()
  const [orderedCategories, setOrderedCategories] = useState<Array<Category>>([])

  useEffect(() => {
    if (movimiento.protagonistas.length > count) {
      setProtagonistasToShow(movimiento.protagonistas.slice(0, count))
      setShowMoreMessage(true)
    } else {
      setProtagonistasToShow(movimiento.protagonistas)
      setShowMoreMessage(false)
    }
  }, [movimiento, count])

  useEffect(() => {
    if (ordered_categories && ordered_categories?.length > 0) {
      const categoriesOrdered = ordered_categories?.map((category: OrderedCategory) => ({ ...category.eje }))
      setOrderedCategories(categoriesOrdered)
      setCategoryToDisplay(categoriesOrdered[0])
    } else {
      if (categories.length > 0) {
        setOrderedCategories(categories)
        setCategoryToDisplay(categories[0])
      }
    }
  }, [categories, ordered_categories])

  useEffect(() => {
    const maxCharacter = movimientosLayout.getIn(['movimientos', 'max_character'])
    if (movimiento?.card_description?.length > maxCharacter) {
      const shortText = movimiento.card_description.slice(0, maxCharacter)
      setTextToDisplay(`${shortText}...`)
    } else {
      setTextToDisplay(movimiento.card_description)
    }
  }, [movimiento, movimientosLayout])

  const onClickCard = () => {
    history.push(`movimiento/${movimiento.codename}`)
  }

  return (
    <Container size={size} margin={margin}>
      <Image url={movimiento.image?.url} color={categoryToDisplay?.overlay} />
      <Content>
        <Header>
          <Country>
            <CountryFlag src={country?.image?.url} />
            {country?.shortname}
          </Country>
          <Categories>
            {orderedCategories?.map((category) => (
              <Block key={category.name} marginLeft="4px">
                <Badge backgroundColor={category?.Badge.background} textColor={category?.Badge.text}>
                  {category?.name}
                </Badge>
              </Block>
            ))}
          </Categories>
        </Header>
        <Title>{movimiento.name}</Title>
        <LineSeparator />
        <Block marginTop="14px" marginBottom={matches ? '0px' : '30px'}>
          <Description>
            <HtmlRender>{textToDisplay}</HtmlRender>
          </Description>
        </Block>
        <Label>{movimientosLayout.getIn(['movimientos', 'protagonistas_label'])}:</Label>
        <Footer>
          <ProtagonistasContainer data-testid="protagonistasContainer">
            {movimiento.protagonistas.length > 0 &&
              protagonistasToShow.map((protagonista) => (
                <ProtagonistaLink to={`/protagonista/${protagonista.username}`} key={protagonista.id}>
                  {protagonista.nickname || protagonista.name},
                </ProtagonistaLink>
              ))}
            {showMoreMessage && (
              <MoreProtagonistasText>
                {`${movimientosLayout.getIn(['movimientos', 'and'])} ${
                  movimiento.protagonistas.length - count
                } ${movimientosLayout.getIn(['movimientos', 'more'])}.
              `}
              </MoreProtagonistasText>
            )}
          </ProtagonistasContainer>
          <ButtonWrapper>
            <ButtonWithIcon color={colors.dark} textColor={colors.white} onClick={onClickCard}>
              {movimientosLayout.getIn(['movimientos', 'card_button'])} <ButtonIcon />
            </ButtonWithIcon>
          </ButtonWrapper>
        </Footer>
      </Content>
    </Container>
  )
}

export default MovimientoCard
