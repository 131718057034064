import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5% 0;
  @media screen and (max-width: 1024px) {
    height: 55vh;
    padding: 10% 0;
  }
  @media screen and (max-width: 768px) {
    height: 60vh;
  }
`

export const Card = styled.div`
  z-index: 2;
  background: #6dbed1;
  padding: 40px;
  min-width: 500px;
  width: 40%;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 450px) {
    min-width: unset;
    padding: 20px;
    width: 90%;
  }
`

export const Image = styled.div<BackgroundProps>`
  ${background};
  width: 100%;
  height: 75%;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  @media screen and (max-width: 1024px) {
    height: 70%;
  }
  @media screen and (max-width: 450px) {
    height: 60%;
  }
`

export const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 51px;
  letter-spacing: 0.075px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 34px;
    line-height: 42px;
    margin: 10px 0;
  }
`
