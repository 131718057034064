import styled from 'styled-components'

export const BannerTitle = styled.p`
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 48px;
    line-height: 55px;
  }
  @media screen and (max-width: 768px) {
    font-size: 30px;
    line-height: 38px;
  }
  @media screen and (max-width: 450px) {
    font-size: 24px;
    line-height: 30px;
  }
`

export const BannerText = styled.div`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.07px;
`

export const BannerExternalLink = styled.a`
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.dark};
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.07px;
  font-weight: bold;
`

export const Title = styled.h4`
  font-weight: bold;
`

type FeedbackProps = {
  color?: string
}

export const ErrorMessage = styled.p<FeedbackProps>`
  font-size: 12px;
  color: ${({ theme, color }) => (color ? color : theme.colors.error)};
  @media screen and (max-width: 450px) {
    font-size: 10px;
  }
`

export const InputFeedbackMessage = styled.p<FeedbackProps>`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.09px;
  color: ${({ theme, color }) => (color ? color : theme.colors.error)};
`
