import styled from 'styled-components'

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const CheckboxContainer = styled.div`
  margin: 10px 0 20px;
`

export const TermsContainer = styled.div`
  margin: 20px 0;
`

export const Description = styled.div`
  width: 80%;
  margin: 10px 0 20px;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`

export const Container = styled.div`
  padding: 3rem;
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
  @media screen and (max-width: 450px) {
    padding: 1rem;
  }
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 16px;
    line-height: 29px;
  }
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 48px;
    line-height: 49px;
  }
  @media screen and (max-width: 450px) {
    font-size: 30px;
    line-height: 30px;
  }
`

export const RoleContainer = styled.div`
  display: grid;
  grid-row-gap: 5px;
  margin-bottom: 20px;
  @media screen and (max-width: 450px) {
    grid-row-gap: 0px;
  }
`

export const InputsContainer = styled.div`
  display: grid;
  grid-row-gap: 14px;
`
