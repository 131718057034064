import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  position: relative;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
`

export const BackgroundContainer = styled.div<BackgroundProps>`
  position: relative;
  width: 100%;
  min-height: 88vh;
  ${background}
  background-position-x: center;
  background-position-y: top;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (max-width: 1024px) {
    min-height: 96vh;
  }
  @media screen and (max-width: 450px) {
    min-height: 91vh;
  }
  @media screen and (min-width: 1600px) {
    min-height: 94vh;
  }
`

type GrayScaleProps = {
  gradientHeight: number
}

export const GrayScale = styled.div<GrayScaleProps>`
  width: 100%;
  z-index: 0;
  height: ${({ gradientHeight }) => `${gradientHeight}px`};
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 100%,
    rgba(101, 130, 135, 0) 100%,
    rgba(0, 212, 255, 0) 100%
  );
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.12px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 21px;
    line-height: 24px;
  }
  @media screen and (max-width: 450px) {
    line-height: 20px;
  }
`

export const PathContainerWrapper = styled.div`
  padding-left: 4%;
  position: absolute;
  z-index: 1;
`
