import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div<BackgroundProps>`
  ${background};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 60px 30px 40px;
  width: 100%;
  @media screen and (min-width: 1600px) {
    width: 90%;
    margin-top: 30px;
    padding: 90px 60px 70px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 80px 60px 40px;
  }
  @media screen and (max-width: 450px) {
    padding: 1rem;
  }
`

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.dark};
  cursor: pointer;
  font-size: 30px;
  @media screen and (min-width: 1600px) {
    font-size: 48px;
    top: 20px;
    right: 20px;
  }
  @media screen and (max-width: 768px) {
    top: 15px;
    right: 15px;
  }
`

export const Content = styled.div`
  padding: 0 60px 0 30px;
  width: 40%;
  @media screen and (min-width: 1600px) {
    padding: 0 90px 0 60px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 20px 0 0;
  }
`

export const Gallery = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`

export const Navigation = styled.div`
  display: flex;
  margin-top: 20px;
  padding-right: 20px;
  justify-content: space-between;
  align-items: center;
`

type NavigationButtonProps = {
  active?: boolean
}

export const NavigationButton = styled.div<NavigationButtonProps>`
  width: ${({ active }) => (active ? '12px' : '6px')};
  height: ${({ active }) => (active ? '12px' : '6px')};
  background-color: ${({ active, theme }) => (active ? theme.colors.activeSlide : theme.colors.inactiveSlide)};
  border-radius: ${({ active }) => (active ? '12px' : '6px')};
`

export const ClickArea = styled.div`
  margin: 0 10px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const Image = styled.div<BackgroundProps>`
  ${background};
  width: 100%;
  height: 370px;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  @media screen and (min-width: 1600px) {
    height: 570px;
  }
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 20px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 34px;
    line-height: 51px;
  }
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.dark};
  margin: 0;
  @media screen and (min-width: 1600px) {
    font-size: 21px;
    line-height: 24px;
  }
`

export const EmailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`

export const Email = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.18px;
  @media screen and (min-width: 1600px) {
    font-size: 21px;
    line-height: 24px;
  }
`

export const ShowEmail = styled.div`
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;
`
