import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem;
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    padding: 1rem;
  }
`

export const TextInputContainer = styled.div`
  width: 100%;
  margin: 14px 0;
`
