import { SagaIterator } from '@redux-saga/core'
import { all, put, takeLatest, call } from 'redux-saga/effects'
import { CONTENT, SUBMIT } from 'ducks/contacto/actionTypes'
import { Action } from 'lib/redux/models'
import { SubmitAction } from '../models/actions'
import contacto from 'services/contacto'
import submitContacto from 'services/contacto/submitContacto'

function* loadContacto(action: Action) {
  yield put(CONTENT.request())
  try {
    const data = yield call(contacto, action?.payload)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* sendContacto(action: SubmitAction) {
  yield put(SUBMIT.request())
  const data = yield call(submitContacto, action.payload)
  if (data) {
    yield put(SUBMIT.success())
  }
  try {
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(SUBMIT.fulfill())
  }
}

export function* contactoSagas(): SagaIterator {
  yield all([takeLatest(CONTENT.TRIGGER, loadContacto), takeLatest(SUBMIT.TRIGGER, sendContacto)])
}
