import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  @media screen and (max-width: 1250px) {
    flex-wrap: wrap;
  }
`

export const PathLabel = styled.p`
  margin: 16px 0;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.09px;
  color: ${({ theme, color }) => (color ? color : theme.colors.dark)};
  @media screen and (max-width: 450px) {
    margin: 0;
    margin-right: 5px;
  }
`

export const PathText = styled(Link)`
  font-size: 16px;
  color: ${({ theme, color }) => (color ? color : theme.colors.default)};
  margin: 0 12px;
  @media screen and (max-width: 450px) {
    margin: 0;
  }
`

export const Separator = styled.span`
  color: ${({ theme, color }) => (color ? color : theme.colors.dark)};
  font-size: 16px;
  @media screen and (max-width: 450px) {
    margin: 0 5px;
  }
`

type PathContainerProps = {
  loading: number
}

export const PathContainer = styled.div<PathContainerProps>`
  width: ${({ loading }) => (loading ? '22%' : '100%')};
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  height: ${({ loading }) => (loading ? '30px' : 'auto')};
  margin: ${({ loading }) => (loading ? '30px 15px' : '0')};
  @media (max-width: 768px) {
    width: ${({ loading }) => (loading ? '66%' : '100%')};
  }
  @media (max-width: 900px) {
    width: ${({ loading }) => (loading ? '44%' : '100%')};
  }
`
