import { ProtagonistasLayout, ProtagonistasLayoutResponse } from 'ducks/protagonistasLayout'

export const parseProtagonistasLayout = (data: ProtagonistasLayoutResponse): ProtagonistasLayout => {
  const { filter, path, protagonistas } = data.protagonistasLayout
  return {
    filter,
    path,
    protagonistas,
  }
}
