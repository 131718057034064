import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 3%;
  bottom: -8%;
  @media screen and (min-width: 1600px) {
    right: 5%;
    bottom: -12%;
  }
  @media screen and (max-width: 450px) {
    width: 95%;
    bottom: -10%;
  }
`

export const CardBody = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 3rem;
  @media screen and (min-width: 1600px) {
    padding: 3rem;
  }
  @media screen and (max-width: 1024px) {
    padding: 2rem;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media screen and (max-width: 450px) {
    padding: 1rem 1rem 3rem;
  }
`

export const CardTitle = styled.p`
  width: 40%;
  font-weight: bold;
  font-size: 35px;
  line-height: 45px;
  color: ${({ theme }) => theme.colors.white};
  @media screen and (min-width: 1600px) {
    font-size: 48px;
    line-height: 57px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 25px;
    line-height: 35px;
  }
  @media screen and (max-width: 768px) {
    width: 60%;
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 1rem;
  }
`

export const CardWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media screen and (max-width: 450px) {
    padding: 0 1rem;
  }
`

export const VideoContainer = styled.div`
  width: 60%;
  height: 300px;
  @media screen and (min-width: 1600px) {
    height: 500px;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
    height: 400px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    height: 250px;
  }
`

export const ContentContainer = styled.div`
  width: 40%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 1600px) {
    padding-left: 40px;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }
`

export const Content = styled.div``

export const StepsContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    width: 55%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const Step = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;
  @media screen and (min-width: 1600px) {
    font-size: 24px;
    line-height: 24px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 300px;
    font-size: 14px;
  }
  @media screen and (max-width: 450px) {
    font-size: 11px;
  }
`

export const StepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  margin: 0 20px 20px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.defaultCardBackground};
  font-size: 20px;
  @media screen and (max-width: 1024px) {
    margin: 0 20px 10px;
  }
  @media screen and (max-width: 450px) {
    margin: 0 5px 5px;
  }
`

export const Separator = styled.div`
  margin-top: 18px;
  height: 1px;
  width: 200px;
  flex-grow: 1;
  background-color: #4a8047;
  @media screen and (max-width: 450px) {
    width: 120px;
  }
`
