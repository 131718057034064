import { Home, HomeResponse, SliderResponse, Slider } from 'ducks/home'

export const parseHomeContent = ({
  featured,
  indicators,
  news_section,
  card,
  slider,
  slide_title,
  slide_button,
  slide_button_mobile,
}: HomeResponse): Home => ({
  featured: {
    ...featured,
    left_image: featured?.left_image?.url,
    right_image: featured?.right_image?.url,
  },
  indicators,
  news_section: {
    ...news_section,
    left_image: news_section?.left_image?.url,
    right_image: news_section?.right_image?.url,
  },
  card: {
    ...card,
    image: card?.image?.url,
    background: card?.background?.url,
  },
  slide_title,
  slide_button,
  slide_button_mobile,
  slider: slider?.map<Slider>((sl: SliderResponse) => ({ ...sl, image: sl?.image?.url })),
})
