import React, { FC } from 'react'
import HtmlRender from 'views/components/HtmlRender'
import { Container, Content, Header, Title, SectionLine, Image, Description } from './styled'

type Image = {
  url: string
}

type Props = {
  title?: string
  section?: {
    description: string
    image?: Image
  }
}

const SecondSection: FC<Props> = (props: Props) => {
  const { title, section } = props
  return (
    <Container>
      <Content>
        <Header>
          <Title>{title}</Title>
          <SectionLine />
        </Header>
        <Description>
          <HtmlRender>{section?.description}</HtmlRender>
        </Description>
      </Content>
      <Image background={`url(${section?.image?.url})`} />
    </Container>
  )
}

export default SecondSection
