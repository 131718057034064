import { Record } from 'immutable'

export interface SocialNetwork {
  icon: string
  link: string
}

export interface BottomFooter {
  link: string
  label: string
}

export interface CookieBanner {
  description: string
  accept_button: string
  deny_button: string
  custom_button: string
  settings_title: string
  confirm_button: string
  options: Array<
    Record<{
      label: string
      value: string
    }>
  >
}

export interface Footer {
  bottom_part: Array<Record<BottomFooter>>
  columns: Array<Record<FooterColumn>>
  newsletter: Record<Newsletter>
  __typename: string
}

export interface FooterColumn {
  id: number
  show_title: boolean
  margin_top: boolean
  title: string
  socials: Array<SocialNetwork>
  rows: Array<FooterRow>
}

export interface Newsletter {
  title: string
  placeholder: string
  button: string
  success: string
  loading: string
  error: string
  see_more: string
}

export interface SubscribePolicy {
  part_one: string
  part_two: string
  link_label: string
  link: string
}

export type FooterRow = LogoRow & LinkRow & TextRow

export enum FooterRowTypes {
  LOGO = 'ComponentFooterLogoRow',
  LINK = 'ComponentFooterLinkRow',
  TEXT = 'ComponentFooterTextRow',
}
export interface LogoRow {
  __typename: string
  image: {
    url: string
  }
  title: string
  subtitle: string
}

export interface LinkRow {
  __typename: string
  link: {
    external?: boolean
    value: string
  }
  label: string
}

export interface TextRow {
  __typename: string
  value: string
}
export interface HeaderSection {
  text: string
  link: string
}

export interface Language {
  label: string
  value: string
}

export interface Header {
  id: string
  title: string
  subtitle: string
  logo: string
  sections: Array<HeaderSection>
  languages: Array<Language>
  store: HeaderSection
  header_title: HeaderSection
  select_language: string
  login_button: string
}

export interface HeaderTitle {
  text: string
  link: Link
}

export interface Link {
  value: string
  external?: boolean
}

export interface LayoutData {
  loading?: boolean
  error?: string
  footer: Footer
  header: Header
  userMenu: UserMenu
  termsAndPolicy: TermsAndPolicy
  cookieBanner: CookieBanner
}

export interface TermsAndPolicy {
  subscribe_policy: SubscribePolicy
  learn_more: {
    button: string
    description: string
  }
  privacy_policy: PrivacyPolicy
  use_and_privacy_policy: UseAndPrivacyPolicy
}

export interface UserMenu {
  options: Array<Record<MenuOption>>
  logout: Record<MenuOption>
}

export interface UseAndPrivacyPolicy {
  part_one: string
  part_two: string
  use_link: string
  privacy_link: string
  use_link_label: string
  privacy_link_label: string
}

export interface PrivacyPolicy {
  link: string
  link_label: string
  description: string
}

export interface MenuOption {
  label: string
  icon: string
  link?: string
}

export interface ResquestedHeader {
  id: string
  title: string
  subtitle: string
  logo: {
    url: string
  }
  sections: Array<HeaderSection>
  Languages: Array<Language>
  store: HeaderSection
  header_title: HeaderSection
  select_language: string
  login_button: string
}

export interface RequestedLayoutData {
  loading?: boolean
  error?: string
  footer: Footer
  header: ResquestedHeader
  userMenu: UserMenu
  termsAndPolicy: TermsAndPolicy
  cookieBanner: CookieBanner
}

// immutable types
export type ILayoutData = Record<{
  loading?: boolean
  error?: string
  footer: Record<Footer>
  header: Record<Header>
  termsAndPolicy: Record<TermsAndPolicy>
  cookieBanner: Record<CookieBanner>
}>
