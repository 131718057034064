import React, { FC, useState, useEffect } from 'react'
import { Volunteer, VolunteerType, CardTypes } from 'ducks/volunteers'

import { Container, Board } from './styled'
import VolunteerTypeCard from 'views/components/VolunteerTypeCard'
import VolunteerCard from 'views/components/VolunteerCard'

type Props = {
  types: Array<VolunteerType>
  volunteers: Array<Volunteer>
}

const VoluntariadoSection: FC<Props> = (props: Props) => {
  const { types, volunteers } = props
  const [cardComponents, setCardComponents] = useState<Array<VolunteerType | Volunteer>>([])
  const [currentTypeSelected, setCurrentTypeSelected] = useState<VolunteerType | null>()
  const [selectedVolunteer, setSelectedVolunteer] = useState<Volunteer | null>()

  useEffect(() => {
    const typesArray = types || []
    const volunteersArray = volunteers || []
    setCardComponents(shuffleArray([...typesArray, ...volunteersArray]))
  }, [types, volunteers])

  const shuffleArray = (array: Array<VolunteerType | Volunteer>) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * i)
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  }

  const renderCard = (element: VolunteerType | Volunteer) => {
    switch (element.__typename) {
      case CardTypes.TYPE:
        const typeElement = element as VolunteerType
        return (
          <VolunteerTypeCard
            key={typeElement?.id + typeElement.name}
            type={typeElement}
            active={typeElement?.id === currentTypeSelected?.id}
            onClick={setCurrentTypeSelected}
          />
        )
      case CardTypes.VOLUNTEER:
        const volunteerElement = element as Volunteer
        const volunteerTypesId: Array<string | undefined> = volunteerElement.types.map(
          (volunteerType: VolunteerType) => volunteerType.id
        )
        return (
          <VolunteerCard
            key={volunteerElement.id}
            volunteer={volunteerElement}
            currentType={currentTypeSelected}
            highlight={volunteerTypesId.includes(currentTypeSelected?.id) ? true : false}
            active={selectedVolunteer?.id === volunteerElement.id}
            onClick={setSelectedVolunteer}
          />
        )
      default:
        return null
    }
  }

  return (
    <Container>
      <Board>{cardComponents.map(renderCard)}</Board>
    </Container>
  )
}

export default VoluntariadoSection
