import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 500px;
  @media screen and (min-width: 1600px) {
    height: 700px;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column-reverse;
    height: 415px;
  }
`

export const Background = styled.div<BackgroundProps>`
  ${background};
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  width: 80%;
  height: 100%;
  @media screen and (min-width: 1600px) {
    width: 90%;
  }
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`

export const CardBody = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 2rem;
  height: 100%;
  @media screen and (min-width: 1600px) {
    padding: 3rem;
  }
  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
`

export const CardWrapper = styled.div`
  width: 60%;
  height: 80%;
  position: absolute;
  left: 0;
  @media screen and (min-width: 1600px) {
    width: 50%;
    height: 75%;
  }
  @media screen and (max-width: 1024px) {
    width: 60%;
  }
  @media screen and (max-width: 768px) {
    height: 70%;
    width: 70%;
  }
  @media screen and (max-width: 450px) {
    position: unset;
    width: 100%;
    height: 120%;
  }
`

export const CardImage = styled.div<BackgroundProps>`
  height: 100%;
  width: 40%;
  ${background};
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
`

export const CardContent = styled.div`
  width: 60%;
  height: 100%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const CardTitle = styled.p`
  font-weight: bold;
  font-size: 34px;
  line-height: 45px;
  margin: 0;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 48px;
    line-height: 60px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 31px;
    line-height: 33px;
  }
  @media screen and (max-width: 768px) {
    font-size: 21px;
    line-height: 25px;
  }
  @media screen and (max-width: 450px) {
    line-height: 31px;
  }
`

export const CardBox = styled.div``

export const CardDescription = styled.div`
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (min-width: 1600px) {
    font-size: 34px;
    line-height: 39px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 20px;
    line-height: 25px;
  }
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 25px;
  }
  @media screen and (max-width: 450px) {
    font-size: 14px;
    line-height: 20px;
  }
`

export const CardLabel = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.07px;
  color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 450px) {
    line-height: 12px;
    font-size: 11px;
  }
`
