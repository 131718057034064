import { IPost } from 'ducks/blog'
import React, { FC } from 'react'
import { Container, Content, Image, Text, Title } from './styled'
import HtmlRender from 'views/components/HtmlRender'

type Props = {
  post?: IPost
  highlightedColor?: string
}

const HighlightedBlogPost: FC<Props> = ({ post, highlightedColor }) => (
  <Container to={`/blog/post/${post?.url}`}>
    <Image background={`url(${post?.cover_image?.url})`} highlightedColor={highlightedColor} />
    <Content>
      <Title>{post?.title}</Title>
      <Text marginBottom>
        {post?.author?.name}
        {post?.author?.nickname ? ` - ${post?.author?.nickname}` : ''}
      </Text>
      <Text>
        <HtmlRender>{`${post?.content?.substr(0, 400)}...`}</HtmlRender>
      </Text>
    </Content>
  </Container>
)

export default HighlightedBlogPost
