import { createGlobalStyle } from 'styled-components'

const StyledApp = createGlobalStyle`
  body {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100vh;
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: Nunito Sans, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  *{
    box-sizing: border-box;
  }
  p {
    margin: 0;
  }
  input:focus {
    outline: none;
  }
  textarea:focus {
    outline: none;
  }
`
export default StyledApp
