import createImmutableSelector from 'lib/immutable/selector'
import { IVoluntariadoLayout } from './../models'

// TODO: import Store type and add reducer type in Store interface
const getVoluntariadoLayout = (state: any): IVoluntariadoLayout => state.get('voluntariadoLayoutReducer')

export const voluntariadoLayoutSelector = createImmutableSelector(
  getVoluntariadoLayout,
  (voluntariadoLayout) => voluntariadoLayout
)
