import React, { FC } from 'react'
import ContentLoader from 'react-content-loader'

interface SkeletonLoading {
  loading?: boolean
  children?: FC
}

interface SkeletonStyle {
  width?: string
  maxWidth?: string
  height?: string
}

function WithSkeletonLoading<T extends SkeletonLoading>(Component: FC<T>, skeletonStyle?: SkeletonStyle): FC<T> {
  return function WithSkeletonLoadingComponent(props: T) {
    if (props.loading) {
      return (
        <ContentLoader data-testid="skeletonLoading" style={{ width: '100%', height: '100%', ...skeletonStyle }}>
          <rect width="100%" height="100%" />
        </ContentLoader>
      )
    }
    return <Component {...props}>{props?.children}</Component>
  }
}

export default WithSkeletonLoading
